import React, { useState } from 'react';
import { SelectDialog, AnalyticalTable, FlexBox, Button, TextAlign, CheckBox } from '@ui5/webcomponents-react';
import ReactPaginate from 'react-paginate';
import { Configuration, SFPositionList, SFPositionsApi, SFUserApi, SFUSerList, PositionSFDto } from '../api/generated';
import { useUser } from '../hooks/userName';
import { Position } from 'reactflow';

interface User {
    userId: string;
    firstName: string;
    lastName: string;
    // Diğer kullanıcı alanları...
}

interface UserSelectDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (user: User) => void;

}

const RelatedUserSelectDialogMultiSelect: React.FC<UserSelectDialogProps> = ({
    open,
    onClose,
    onConfirm,
}) => {

    const [searchText, setSearchText] = useState("");
    const [sfUserList, setSfUserList] = useState<SFPositionList[]>([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [sfUserSearchDataCount, setsfUserSearchDataCount] = useState(0);
    const [searchBusy, setsearchBusy] = useState(false);

    const { username, setUsername } = useUser();
    const itemsPerPage = 10;
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });
    const onSearhboxSelect = (instance: any) => {
        onConfirm(instance.row.original);
    };
    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % sfUserSearchDataCount;
        getSfUserList(searchText, newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    async function getSfUserList(event: any, skip: number = 0, top: number = 10) {


        setsearchBusy(true);
        var api = new SFPositionsApi(configuration);
        let searchText = "";
        if (event.detail == null) {
            searchText = event;
        }
        else {
            searchText = event.detail.value;
        }


        setSearchText(searchText);
        let dto: PositionSFDto = { count: 0, list: [], sfPositionList: [] };
        var data = await api.apiSFPositionsByUserIdAllUserIdPost(username, dto);

        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        console.log(data);
        const currentItems = data.data.sfPositionList!.slice(itemOffset, endOffset);
        setsfUserSearchDataCount(data.data.count!);
        setpageCount(Math.ceil(data.data.count! / itemsPerPage));
        setSfUserList(data.data.sfPositionList!);
        setsearchBusy(false);
    }
    return (
        <SelectDialog
            open={open}
            style={{ width: "800px" }}
            headerText="Kullanıcı Seç"
            onAfterClose={onClose}
            onSearch={getSfUserList}
            onAfterOpen={() => { }}
            onBeforeClose={() => { }}
            onBeforeOpen={() => { }}
            onCancel={onClose}
            onClear={() => { }}
            onConfirm={() => { }}
            onLoadMore={() => { }}

            onSearchInput={() => { }}
        >
            <div style={{ marginBottom: "5px", width: '100%' }}>
                <AnalyticalTable
                    columns={[


                        {
                            Header: 'Pozisyon Adı',
                            accessor: 'name',
                            autoResizable: true,
                            headerTooltip: 'Adı',
                            width: 200
                        },{
                            Header: 'Pozisyon Kodu',
                            accessor: 'externalCode',
                            autoResizable: true,
                            headerTooltip: 'Adı',
                            width: 150
                        },
                        {
                            Header: 'Adı-Soyadı',
                            accessor: 'userName',
                            autoResizable: true,
                            headerTooltip: 'Adı',
                            width: 200
                        },
                        {
                            Header: 'Sicil No',
                            accessor: 'userId',
                            autoResizable: true,
                            headerTooltip: 'Adı',
                            width: 150
                        },
                        {
                            Cell: (instance: any) => {
                                const { webComponentsReactProperties } = instance;
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                return (
                                    <FlexBox>
                                        <Button icon="accept" onClick={() => onSearhboxSelect(instance)} disabled={isOverlay} />
                                    </FlexBox>
                                );
                            },
                            Header: 'Seç',
                            hAlign: TextAlign.Center,
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 70
                        }
                    ]}
                    loading={searchBusy}
                    data={sfUserList}
                    filterable
                    onAutoResize={() => { }}
                    onColumnsReorder={() => { }}
                    onGroup={() => { }}
                    onLoadMore={() => { }}
                    onRowClick={() => { }}
                    onRowExpandChange={() => { }}
                    onRowSelect={() => { }}
                    onSort={() => { }}
                    onTableScroll={() => { }}
                    selectionMode="None"
                    tableHooks={[]}
                    withRowHighlight
                    withNavigationHighlight={false}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactPaginate
                    previousLabel="Geri"
                    nextLabel="İleri"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </SelectDialog>
    );
};

export default RelatedUserSelectDialogMultiSelect;
