import React, { ForwardedRef, useRef, useState } from 'react';
import { SelectDialog, AnalyticalTable, FlexBox, Button, TextAlign } from '@ui5/webcomponents-react';
import ReactPaginate from 'react-paginate';
import { Configuration, SFUserApi, SFUSerList } from '../api/generated';

export interface SelectDialog {
    externalCode: string;
    name: string;
}

interface UserSelectDialogProps {
    value: ForwardedRef<SelectDialog | null>;
    open: boolean;
    onClose: () => void;
    onConfirm: (user: SelectDialog) => void;
    list: any,
    headerText: any,
    pageCount: any,
    datacount: any,
    method: (skip: number, top: number) => void; // Kullanıcıl


}
const GenericSelectDialog: React.FC<UserSelectDialogProps> = ({
    value,
    open,
    onClose,
    onConfirm,
    list,
    headerText,
    pageCount,
    method,
    datacount,


}) => {


    const [sfUserSearchDataCount, setsfUserSearchDataCount] = useState(0);
    const [searchBusy, setsearchBusy] = useState(false);
    const itemsPerPage = 10;

    const localValueRef = useRef<SelectDialog | null>(null);
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });
    const onSearhboxSelect = (instance: any) => {
        onConfirm(instance.row.original);

        localValueRef.current = {
            externalCode: "initialExternalCode",
            name: "initialName"
        };

        if (localValueRef.current) {

            localValueRef.current.externalCode = instance.row.original.externalCode;
            localValueRef.current.name = instance.row.original.name;
        }

        if (value && typeof value === 'object') {

            value.current = {
                externalCode: "",
                name: ""
            };
            value.current!.externalCode = instance.row.original.externalCode;
            value.current!.name = instance.row.original.name;


        }


    };
    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % datacount;
        //  getSfUserList(searchText, newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        method(newOffset, 10);
    };
    const search = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % datacount;
        //  getSfUserList(searchText, newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        method(newOffset, 10);
    };
    return (
        <SelectDialog
            open={open}
            style={{ width: "800px" }}
            headerText={headerText}
            onAfterClose={onClose}
            onSearch={search}
            onAfterOpen={() => { }}
            onBeforeClose={() => { }}
            onBeforeOpen={() => { }}
            onCancel={onClose}
            onClear={() => { }}
            onConfirm={() => { }}
            onLoadMore={() => { }}

            onSearchInput={() => { }}
        >
            <div style={{ marginBottom: "15px", marginLeft: '20px', width: '100%' }}>
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'Code',
                            accessor: 'externalCode',
                            autoResizable: false,
                            headerTooltip: 'externalCode',
                            width: 150
                        },
                        {
                            Header: 'name',
                            accessor: 'name',
                            autoResizable: false,
                            headerTooltip: 'name',
                            width: 300
                        },
                        {
                            Cell: (instance: any) => {
                                const { webComponentsReactProperties } = instance;
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                return (
                                    <FlexBox>
                                        <Button icon="accept" onClick={() => onSearhboxSelect(instance)} disabled={isOverlay} />
                                    </FlexBox>
                                );
                            },
                            Header: 'Seç',
                            hAlign: TextAlign.Center,
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 70
                        }
                    ]}
                    loading={searchBusy}
                    data={list}
                    filterable
                    onAutoResize={() => { }}
                    onColumnsReorder={() => { }}
                    onGroup={() => { }}
                    onLoadMore={() => { }}
                    onRowClick={() => { }}
                    onRowExpandChange={() => { }}
                    onRowSelect={() => { }}
                    onSort={() => { }}
                    onTableScroll={() => { }}
                    selectionMode="None"
                    tableHooks={[]}
                    withRowHighlight
                    withNavigationHighlight={false}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactPaginate
                    previousLabel="Geri"
                    nextLabel="İleri"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </SelectDialog>
    );
};

export default GenericSelectDialog;
