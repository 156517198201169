//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";

import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/numbered-text";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/history";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, SegmentedButton, SegmentedButtonItem, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPromotionRequestApi, BudgetPromotionRequestListDto, Configuration, } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { getStatusText, isValidDate, parseDateString } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { getuid } from 'process';
import { uuid } from 'uuidv4';
import { useAlert } from '../hooks/useAlert'
import { useBusy } from '../hooks/useBusy'
import ShowHistory from '../Components/ShowHistory';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/userName';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");

// setTheme("sap_belize");
function PromotionList() {
    const { sideNavCollapsed, setSideNavCollapsed } = useUser();
    const { loginUser, setLoginUser } = useUser();
    const { username, setUsername } = useUser();
    const [addButonActive, setaddButonActive] = useState(false);

    const [historyOpen, sethistoryOpen] = useState(false);
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");
    const [gridData, setGridData] = useState<BudgetPromotionRequestListDto[]>([]);

    const navigate = useNavigate();
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()

    useEffect(() => {
        getActivePeriod();
        getData();

    }, []); //
    async function getData() {
        var api = new BudgetPromotionRequestApi(configuration);
        var data = await api.apiBudgetPromotionRequestGet();

        setGridData(data.data);
        console.log(data)

    }

    function onNew(): void {
        navigate("/PromotionDetail")
    }
    function showHistory(obj: any): void {

        setselectedWorkFlowId(obj.cell.row.original.workflowHeadId);
        sethistoryOpen(true);
    }
    function onEdit(obj: any): void {
        navigate("/PromotionDetail?id=" + obj.cell.row.original.id);
        return;
    }
    async function onDelete(obj: any): Promise<void> {
        setdeleteOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetPromotionRequestApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetPromotionRequestIdDelete(selectedWorkFlowId)
            await getData();
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Terfi':
                navigate("/PromotionList")
                break;
            case "Terfi Talep Detayı":
                navigate("/PromotionDetail")
                break;
            default:
                break;
        }

    }

    async function onSegmentedButtonChange(event: CustomEvent) {
        dispatchBusy({ isBusy: true });
        var api = new BudgetPromotionRequestApi(configuration);
        var data = await api.apiBudgetPromotionRequestGet();

        const selectedItemTxt = event.detail.selectedItem.innerText;
        switch (selectedItemTxt) {
            case "Hepsi":
                setGridData(data.data);
                break;
            case "Onay Sürecinde":
                var inProgress = data.data.filter(i => i.workflowHead?.workFlowStatus == 1)
                setGridData(inProgress)
                break;
            case "Tamamlandı":
                var completed = data.data.filter(i => i.workflowHead?.workFlowStatus == 2)
                setGridData(completed)
                break;

            default:
                break;
        }
        dispatchBusy({ isBusy: false });
    }

    async function getActivePeriod() {
        dispatchBusy({ isBusy: true });
        var api = new BudgetPeriodUserApi(configuration);
        console.log(loginUser)
        var result = api.apiBudgetPeriodUserGetActivePeriodUseridGet(username, 3)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {
                setaddButonActive(false);
            })
            .catch(error => {
                setaddButonActive(true);
            }).finally(() => {

            });
        console.log(result);
        dispatchBusy({ isBusy: false });
    }

    return (
        <DashboardLayout>


            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={<DynamicPageTitle actions={<><Button icon='add' disabled={addButonActive} onClick={onNew} design="Emphasized">Yeni</Button><Button design="Transparent" /></>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem>Terfi</BreadcrumbsItem>
                        </Breadcrumbs>}
                    navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>}
                    header={<Title>Terfiler</Title>} subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <SegmentedButton
                    id="idSB1"
                    onSelectionChange={onSegmentedButtonChange}
                >
                    <SegmentedButtonItem>
                        Hepsi
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Onay Sürecinde
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Tamamlandı
                    </SegmentedButtonItem>
                </SegmentedButton>
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'Sicil No',
                            accessor: 'empCode',
                            autoResizable: true,
                            headerTooltip: 'Çalışan Grubu',
                            width: 130
                        },
                        {
                            Header: 'Pozisyon Kodu',
                            accessor: 'positionCode',
                            autoResizable: true,
                            headerTooltip: 'Şirket',
                            width: 130
                        },
                        {
                            Header: 'Terfi Tarihi',
                            accessor: 'promotionDate',
                            autoResizable: true,
                            headerTooltip: 'Durum',
                            width: 150,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                        },
                        {
                            Header: 'Açıklama',
                            accessor: 'description',
                            autoResizable: true,
                            headerTooltip: 'Durum',
                            width: 150,
                            hAlign: TextAlign.Center,
                        },
                        {
                            Header: 'Güncellenme Tarihi',
                            accessor: 'updatedDate',
                            autoResizable: true,
                            headerTooltip: 'updatedDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Header: 'Durumu',
                            accessor: 'workflowHead.workFlowStatus',
                            autoResizable: true,
                            headerTooltip: 'Durum',
                            width: 150,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => {
                                // Satır rengi belirleme
                                let textcolor: string;
                                switch (value) {
                                    case 1:
                                        textcolor = "#ffce00"; //turuncu
                                        break;
                                    case 2:
                                        textcolor = "#399918"; //yesil
                                        break;
                                    case 4:
                                        textcolor = "#fd0200"; //kirmizi
                                        break;
                                    default:
                                        textcolor = "black";
                                }

                                // Tüm hücreler için arka plan rengini ayarla
                                return (
                                    <div style={{ color: textcolor }}>
                                        {getStatusText(value)}
                                    </div>
                                );
                            },
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                // const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={(instance.cell.row.original.workflowHead?.workFlowStatus != null &&
                                            instance.cell.row.original.workflowHead?.workFlowStatus != 4
                                        ) ? true : false} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 85
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox style={{ alignItems: 'center' }}>
                                        <Button icon="history" onClick={() => showHistory(instance)} disabled={instance.cell.row.original.workflowHeadId != null ? false : true} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Tarihçe',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'history',
                            width: 80
                        }
                    ]}

                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="None"
                    tableHooks={[]}
                    withRowHighlight={false}
                />
                <div style={{
                    width: sideNavCollapsed ? "75.5%" : "85.3%",
                    marginRight: "24.5%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 10,
                    padding: '10px 0'
                }}>  <ReactPaginate
                        previousLabel="Geri"
                        nextLabel="İleri"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={5}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        // onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>
            </DynamicPage>
            {historyOpen && (
                <ShowHistory

                    approveId={selectedWorkFlowId}
                    open={historyOpen}
                    onClose={() => sethistoryOpen(false)}
                />
            )}
            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen terfi silinecektir, devam edilsin mi?
            </MessageBox>
        </DashboardLayout>
    );
}
export default PromotionList;

