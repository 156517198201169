import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import { ComboBox, ComboBoxItem } from '@ui5/webcomponents-react';
import { Configuration, PickList, PickListApi, PickListDto } from '../api/generated';
import { useBusy } from '../hooks/useBusy';

interface ComboBoxComponentProps {
    pickListId: PickList;
    value: ForwardedRef<string>;
    displayValue?: String;
}

const PickListComboBoxComponent: React.FC<ComboBoxComponentProps> = ({ pickListId, value, displayValue = "" }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<PickListDto[]>([]);
    const dispatchBusy = useBusy()
    const localValueRef = useRef<string>("");

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });


    const [comboName, setcomboName] = useState("");
    const [comboId, setcomboId] = useState("");

    useEffect(() => {

    }, []);

    const handleCustPositionLevel = (event: any) => {
        const selectedItem = event.detail.item;
        setcomboName(selectedItem.text);
        setcomboId(selectedItem.getAttribute('data-id'));
        localValueRef.current = selectedItem.getAttribute('data-id');
        if (value && typeof value === 'object') {
            value.current = selectedItem.getAttribute('data-id');
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            dispatchBusy({ isBusy: true });
            try {
                const pickListApi = new PickListApi(configuration);
                const response = await pickListApi.apiPickListGet(pickListId);
                setItems(response.data);
                if (value && typeof value === 'object') {
                    const item = response.data.find(p => p.externalCode === value.current);
                    if (item) {

                        setcomboName(item.label_tr_TR ? item.label_tr_TR! : item.name!);
                    }
                }
                if (displayValue != "" && displayValue != null) {
                    const item = response.data.find(p => p.externalCode === displayValue);
                    if (item) {

                        if (value && typeof value === 'object') {
                            value.current = displayValue.toString();
                        }
                        setcomboName(item.label_tr_TR ? item.label_tr_TR! : item.name!);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            dispatchBusy({ isBusy: false });

            setLoading(false);
        };
        fetchData();
    }, [pickListId, displayValue]);


    return (
        <ComboBox

            disabled={loading}
            loading={loading}
            onSelectionChange={handleCustPositionLevel}
            value={comboName}
            valueState="None"
        >
            {items.map((item) => (
                <ComboBoxItem key={item.externalCode} text={item.label_tr_TR ? item.label_tr_TR! : item.name!} data-id={item.externalCode} />
            ))}
        </ComboBox>
    );
};

export default PickListComboBoxComponent;
