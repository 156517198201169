//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";
import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/search";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/excel-attachment.js";
import "@ui5/webcomponents-icons/dist/menu.js";
import "@ui5/webcomponents-icons/dist/upload.js";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FileUploader, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Tab, TabContainer, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, ToolbarSpacer, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserInsertDto, BudgetPeriodUserUpdateDto, Configuration, Permission, ProcessType, RequestType, SFUSerList, SFUserApi } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { isValidDate } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { getuid } from 'process';
import { uuid } from 'uuidv4';
import { useAlert } from '../hooks/useAlert'
import { useBusy } from '../hooks/useBusy'
import { da } from 'date-fns/locale';

import * as XLSX from 'xlsx';

// setTheme("sap_horizon");
setTheme("sap_fiori_3");


// setTheme("sap_belize");
function PeriodUser() {

    const [searchText, setSearchText] = useState("");
    const [dialogOpen, setdialogOpen] = useState(false);
    const [dialogExcelOpen, setdialogExcelOpen] = useState(false);
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [selectedPeriodId, setselectedPeriodId] = useState("");
    const [isedit, setisedit] = useState(false);
    const [gridData, setGridData] = useState<BudgetPeriodUserListDto[]>([]);
    const [periodComboData, setperiodComboData] = useState<BudgetPeriodListDto[]>([]);
    const [periodComboBusy, setperiodComboBusy] = useState(false);
    const [openSearchDialog, setopenSearchDialog] = useState(false);
    const [sfUserList, setSfUserList] = useState<SFUSerList[]>([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [sfUserSearchDataCount, setsfUserSearchDataCount] = useState(0);
    const [searchBusy, setsearchBusy] = useState(false);
    const itemsPerPage = 10;
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });
    const [periodCodeId, setperiodCodeId] = useState("");
    const [periodCode, setperiodCode] = useState("");
    const [id, setId] = useState("");
    const [userName, setuserName] = useState("");
    const [comboscreenTypeId, setcomboscreenTypeId] = useState("");
    const [comboscreenType, setcomboscreenType] = useState("");
    const [comboPermissionTypeId, setcomboPermissionTypeId] = useState("");
    const [comboPermissionType, setcomboPermissionType] = useState("");

    const [screenCombobox, setscreenCombobox] = useState("");


    const [comboprocessTypeId, setCombosetprocessTypeId] = useState("");
    const [comboprocessType, setCombosetprocessType] = useState("");

    const [excelData, setExcelData] = useState<any[]>([]); //Excel verileri
    const [activeTab, setActiveTab] = useState("tab1");
    const [fileName, setFileName] = useState("");


    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()



    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % sfUserSearchDataCount;
        getSfUserList(searchText, newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    const handlecomboscreenType = (event: any) => {
        const selectedItem = event.detail.item;
        setcomboscreenType(selectedItem.text);
        setcomboscreenTypeId(selectedItem.getAttribute('data-id'));
    };
    const handleProcessType = (event: any) => {
        const selectedItem = event.detail.item;

        setCombosetprocessTypeId(selectedItem.getAttribute('data-id'));
        setCombosetprocessType(selectedItem.text);
    }
    const handlePermissionLevel = (event: any) => {

        const selectedItem = event.detail.item;
        setcomboPermissionType(selectedItem.text);
        setcomboPermissionTypeId(selectedItem.getAttribute('data-id'));

    };
    const handlePeriodCodeUserInput = (event: any) => {

        const selectedItem = event.detail.item;
        setperiodCode(selectedItem.text);
        setperiodCodeId(selectedItem.getAttribute('data-id'));
    };

    const handleUserInput = (e: any) => {
        setuserName(e.target.value);
    };


    useEffect(() => {
        getData();
        getPeriodList();

        if (dialogExcelOpen) {
            setActiveTab("tab1");
            setExcelData([]);
            setFileName("");
        }

    }, [dialogExcelOpen]); //

    async function getData() {

        var api = new BudgetPeriodUserApi(configuration);
        var data = await api.apiBudgetPeriodUserGet();
        console.log(data.data)
        setGridData(data.data);
    }
    async function handleopenSearchDialog() {
        setopenSearchDialog(true);
    }
    async function getPeriodList() {

        setperiodComboBusy(true);
        var api = new BudgetPeriodApi(configuration);
        var result = api.apiBudgetPeriodGet()
            .then((response: AxiosResponse<BudgetPeriodListDto[]>) => {

                setperiodComboData(response.data);
            })
            .catch(error => {

            }).finally(() => {
                setperiodComboBusy(false);
            });
    }

    async function getSfUserList(event: any, skip: number = 0, top: number = 10) {

        setsearchBusy(true);
        var api = new SFUserApi(configuration);
        let searchText = "";
        if (event.detail == null) {
            searchText = event;
        }
        else {
            searchText = event.detail.value;
        }
        setSearchText(searchText);
        var data = await api.apiSFUserGet(skip, top, searchText);

        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = data.data.sfuSerList!.slice(itemOffset, endOffset);
        setsfUserSearchDataCount(data.data.count!);
        setpageCount(Math.ceil(data.data.count! / itemsPerPage));
        setSfUserList(data.data.sfuSerList!);
        setsearchBusy(false);
    }
    function onSubmit(e: any) {
        let api = new BudgetPeriodUserApi(configuration);

        if (isedit == false) {

            let insertDto: BudgetPeriodUserInsertDto = {
                budgetPeriodCode: ""
            };
            insertDto.budgetPeriodCode = periodCode;
            insertDto.permission = Number.parseInt(comboPermissionTypeId) as Permission;
            insertDto.requestType = Number.parseInt(comboscreenTypeId) as RequestType;
            insertDto.userName = userName;
            insertDto.processType = Number.parseInt(comboprocessTypeId) as ProcessType;
            insertDto.nameSurname = "";

            var result = api.apiBudgetPeriodUserPost(insertDto)
                .then(response => {
                    dispatchAlert({ message: "Kayıt Başarılı", type: MessageBoxTypes.Success })
                    setdialogOpen(false);
                    getData();
                })
                .catch(error => {
                    dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Error })
                }).finally(() => {

                });
        }
        else {

            let updateDto: BudgetPeriodUserUpdateDto = { budgetPeriodCode: "" };
            updateDto.budgetPeriodCode = periodCode;
            updateDto.id = id;
            updateDto.userName = userName;
            updateDto.permission = Number.parseInt(comboPermissionTypeId) as Permission;
            updateDto.requestType = Number.parseInt(comboscreenTypeId) as RequestType;

            updateDto.processType = Number.parseInt(comboprocessTypeId) as ProcessType;

            var result = api.apiBudgetPeriodUserPut(updateDto)
                .then(response => {
                    // showMessage("Kayıt Güncelleme Başarılı", MessageBoxTypes.Success);

                    dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    setdialogOpen(false);
                    getData();
                })
                .catch(error => {
                    dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Error })
                });
        }
        e.preventDefault();
    }
    function closeDialog(): void {
        setdialogOpen(false);
    }
    function onNew(): void {




        setperiodCode("");
        setperiodCodeId("");
        setcomboPermissionType("");
        setcomboPermissionTypeId("");

        setcomboscreenType("");
        setcomboscreenTypeId("");
        setuserName("");
        setisedit(false);
        setdialogOpen(true);
    }
    async function onSearhboxSelect(event: any) {
        setuserName(event.cell.row.original.userId);
        setopenSearchDialog(false);
    }
    function onEdit(obj: any): void {

        dispatchBusy({ isBusy: true });

        var api = new BudgetPeriodUserApi(configuration);
        var result = api.apiBudgetPeriodUserIdGet(obj.cell.row.original.id)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {
                setId(response.data.id!);
                setperiodCode(response.data.budgetPeriodCode);
                setuserName(response.data.userName!);
                setcomboscreenType(response.data.requestTypeText!)
                setcomboPermissionType(response.data.permissiontypeText!)
                setcomboPermissionTypeId(response.data.permission!.toString());
                setcomboscreenTypeId(response.data.requestType!.toString());

                setCombosetprocessType(response.data.processtypeText!)
                setCombosetprocessTypeId(response.data.processType!.toString());





                setdialogOpen(true);
                setisedit(true);
            })
            .catch(error => {
                // alert.s("hata");
            });

        dispatchBusy({ isBusy: false });
    }
    async function onDelete(obj: any): Promise<void> {
        setdeleteOpen(true);
        setselectedPeriodId(obj.cell.row.original.id)


    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetPeriodUserApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetPeriodUserIdDelete(selectedPeriodId)
            await getData();
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }

    const handleScreenComboChange = (event: any) => {
        var selectedItem = event.target.value
        setscreenCombobox(selectedItem);
    }



    const openExcelDialog = () => {
        setActiveTab("tab1"); // Aktif tab'i sıfırla
        setdialogExcelOpen(true);
    };
    const closeExcelDialog = () => {
        setdialogExcelOpen(false);
        setActiveTab("tab1");
        setExcelData([]);
        setFileName("");
    }




    // Dosyayı yüklerken çalışacak fonksiyon
    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];

        if (file) {
            setFileName(file.name); // Seçilen dosya adını sakla
        }

        const reader = new FileReader();

        reader.onload = (e: any) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });

            // İlk sayfayı oku
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

            // Veriyi state'e kaydet
            setExcelData(excelData);
            setActiveTab("tab2");
            console.log("excelData", excelData);
        };

        reader.readAsBinaryString(file);
    };
    const handleTabSelect = (event: any) => {
        const selectedTab = event.detail.tab.dataset.key;
        setActiveTab(selectedTab);
    };

    async function onExcelSubmit() {
        var api = new BudgetPeriodUserApi(configuration);
        // dispatchBusy({ isBusy: true });
        let results = [];

        for (const row of excelData.slice(1)) {
            let insertDto: BudgetPeriodUserInsertDto = {
                budgetPeriodCode: row[0],
                userName: row[1].toString(),
                requestType: row[2],
                processType: row[3],
                permission: 2,
                nameSurname: ""
            };

            try {
                await api.apiBudgetPeriodUserPost(insertDto);
                results.push({ status: "success", row: row, message: `${row[1]} için kayıt başarılı.` });
            } catch (error: unknown) {
                let errorMessage = "Bir hata oluştu";

                if (error instanceof Error && "response" in error) {
                    const err = error as { response: { data: string } };

                    if (err.response && err.response.data) {
                        errorMessage = err.response.data;
                    } else {
                        errorMessage = error.message;
                    }
                }
                results.push({ status: "error", row: row, message: errorMessage });
            }
        }

        if (results.length > 0) {
            let successMessages = results
                .filter(r => r.status === "success")
                .map(r => r.message)
                .join("\n");
    
            let errorMessages = results
                .filter(r => r.status === "error")
                .map(r => r.message)
                .join("\n");
    
            let finalMessage = "";
    
            if (successMessages) {
                finalMessage += `Başarılı Kayıtlar:\n${successMessages}\n`;
            }
    
            if (errorMessages) {
                finalMessage += `Başarısız Kayıtlar:\n${errorMessages}`;
            }
    
            dispatchAlert({ message: finalMessage.trim(), type: MessageBoxTypes.Success });
            closeExcelDialog();
        }

        await getData();
        // dispatchBusy({ isBusy: false });

    }
    return (
        <DashboardLayout>

            <Dialog
                onAfterClose={() => setdialogOpen(false)}
                open={dialogOpen}
                className="headerPartNoPadding footerPartNoPadding"
                // footer={<Bar design="Footer" endContent={<Button type='Submit'>Kaydet</Button>} />}
                header={<Bar endContent={<Button color='white' icon='decline' design="Transparent" onClick={closeDialog}></Button>}><Title>Detay</Title></Bar>}
                headerText="Dialog Header2"

                onAfterOpen={function _a() { }}
                onBeforeClose={function _a() { }}
                onBeforeOpen={function _a() { }}
            >
                <ThemeProvider>
                    <Form
                        onSubmit={onSubmit}
                        backgroundDesign={FormBackgroundDesign.Transparent}
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={12}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={12}
                        style={{
                            width: '600px',
                            alignItems: 'center'
                        }}
                    >
                        <FormGroup>
                            <FormItem label={<Label style={{ width: "150px" }} required>Dönem Kodu</Label>}>
                                <ComboBox
                                    disabled={isedit}
                                    loading={periodComboBusy}
                                    icon={<Icon name="employee" />}
                                    onSelectionChange={handlePeriodCodeUserInput}
                                    value={periodCode}

                                    valueState="None"
                                >
                                    {periodComboData.map((item) => (
                                        <ComboBoxItem key={item.id} text={item.periodCode!} data-id={item.id} />
                                    ))}
                                </ComboBox>
                            </FormItem>

                            <FormItem label={<Label style={{ width: "150px" }} required>Kullanıcı</Label>}>
                                <Input onInput={handleUserInput} disabled={isedit} value={userName} icon={<Icon name="search" onClick={handleopenSearchDialog} />} readonly required={true} type="Text" />
                            </FormItem>

                            <FormItem label={<Label style={{ width: "150px" }} required>Ekran</Label>}>
                                <ComboBox
                                    loading={periodComboBusy}
                                    icon={<Icon name="employee" />}
                                    onInput={function _a() { }}
                                    onSelectionChange={handlecomboscreenType}
                                    value={comboscreenType}
                                    valueState="None"
                                >
                                    <ComboBoxItem key="1" text="Norm Kadro Talebi" data-id="1" />
                                    <ComboBoxItem key="2" text="Mevcut Kadro" data-id="2" />
                                    <ComboBoxItem key="3" text="Terfi Talepleri" data-id="3" />
                                    <ComboBoxItem key="4" text="Yeni Pozisyon İsmi Talebi" data-id="4" />
                                    <ComboBoxItem key="5" text="Hepsi" data-id="5" />
                                </ComboBox>
                            </FormItem>
                            <FormItem label={<Label>İstek Tipi</Label>}>
                                <ComboBox
                                    onInput={function _a() { }}
                                    onSelectionChange={handleProcessType}
                                    value={comboprocessType}
                                    loading={periodComboBusy}
                                    valueState="None"
                                >
                                    <ComboBoxItem key="1" text="Yıllık Kadro Planı" data-id="1" />
                                    <ComboBoxItem key="2" text="Ek Kadro Planı" data-id="2" />
                                </ComboBox>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>Yetki Seviyesi</Label>}>
                                <ComboBox

                                    loading={periodComboBusy}
                                    icon={<Icon name="employee" />}
                                    onSelectionChange={handlePermissionLevel}
                                    value={comboPermissionType}
                                    valueState="None"
                                >
                                    <ComboBoxItem key="1" text="Görüntüleme" data-id="1" />
                                    <ComboBoxItem key="2" text="Yaratma" data-id="2" />

                                </ComboBox>
                            </FormItem>

                            <FormItem>
                                <Button icon='save' design="Positive" type={ButtonType.Submit}>Kaydet</Button>
                            </FormItem>
                        </FormGroup>
                    </Form>
                </ThemeProvider>
            </Dialog>
            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={<DynamicPageTitle actions={<>
                    <Button icon='add' onClick={onNew} design="Emphasized">Yeni</Button>
                    <Button icon='excel-attachment' onClick={openExcelDialog} design="Default">Excel Import</Button>
                </>}
                    breadcrumbs={<Breadcrumbs><BreadcrumbsItem>Dönem Yetki Tanımlama</BreadcrumbsItem></Breadcrumbs>} header={<Title>Dönem  Yetki Tanımlama</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>} subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'Dönem Kodu',
                            accessor: 'budgetPeriodCode',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 100
                        },
                        {
                            Header: 'Dönem Adı',
                            accessor: 'budgetPeriod.name',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 130
                        },
                        {
                            Header: 'Kullanıcı',
                            accessor: 'userName',
                            autoResizable: true,
                            headerTooltip: 'Kullanıcı',
                            width: 100
                        },
                        {
                            Header: 'Ad Soyad',
                            accessor: 'nameSurname',
                            autoResizable: true,
                            headerTooltip: 'Ad Soyad',
                            width: 150
                        },
                        {
                            Header: 'Başlangıç Tarihi',
                            accessor: 'budgetPeriod.startDate',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 130,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => format(parseISO(value), 'dd/MM/yyyy') // Tarih formatlama
                        },
                        {
                            Header: 'Bitiş Tarihi',
                            accessor: 'budgetPeriod.enDate',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 130,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => format(parseISO(value), 'dd/MM/yyyy') // Tarih formatlama
                        },
                        {
                            Header: 'Ekran',
                            accessor: 'requestTypeText',
                            autoResizable: true,
                            headerTooltip: 'İstek Tipi',
                            width: 180,
                            hAlign: TextAlign.Center,
                        },
                        {
                            Header: 'İstek  Türü',
                            accessor: 'processtypeText',
                            autoResizable: true,
                            headerTooltip: 'İstek  Türü',
                            width: 180,
                            hAlign: TextAlign.Center,
                        },
                        {
                            Header: 'Yetki Seviyesi',
                            accessor: 'permissiontypeText',
                            autoResizable: true,
                            headerTooltip: 'Yetki Seviyesi',
                            width: 130,
                            hAlign: TextAlign.Center,
                        },

                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={isOverlay} />
                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 100
                        }
                    ]}

                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    visibleRows={gridData.length}
                    // header="Table Title"
                    infiniteScroll

                    selectionMode="None"
                    tableHooks={[]} />
            </DynamicPage>


            <SelectDialog

                open={openSearchDialog}

                style={{ width: "800px" }}
                headerText="Kullanıcı Seç"
                onAfterClose={() => setopenSearchDialog(false)}
                onAfterOpen={function _a() { }}
                onBeforeClose={function _a() { }}
                onBeforeOpen={function _a() { }}
                onCancel={function _a() { }}
                onClear={function _a() { }}
                onConfirm={function _a() { }}
                onLoadMore={function _a() { }}
                onSearch={getSfUserList}
                onSearchInput={function _a() { }}
                onSearchReset={getSfUserList}

            >
                <div style={{ marginBottom: "5px", width: '100%' }}>
                    <AnalyticalTable

                        columns={[
                            {
                                Header: 'Kullanıcı Adı',
                                accessor: 'userId',
                                autoResizable: true,
                                headerTooltip: 'Dönem Kodu',
                                width: 150
                            },
                            {
                                Header: 'Adı',
                                accessor: 'firstName',
                                autoResizable: true,
                                headerTooltip: 'Dönem Kodu',
                                width: 200
                            },

                            {
                                Header: 'Soyadı',
                                accessor: 'lastName',
                                autoResizable: true,
                                headerTooltip: 'Dönem Kodu',
                                width: 200
                            },
                            {
                                Cell: (instance: any) => {
                                    const { cell: any, row, webComponentsReactProperties } = instance;
                                    // disable buttons if overlay is active to prevent focus
                                    const isOverlay = webComponentsReactProperties.showOverlay;
                                    // console.log('This is your row data', row.original);
                                    return (
                                        <FlexBox>
                                            <Button icon="accept" onClick={() => onSearhboxSelect(instance)} disabled={isOverlay} />

                                        </FlexBox>
                                    );
                                },
                                // cellLabel: ({ cell }) => {
                                //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                // },
                                Header: 'Seç',
                                hAlign: TextAlign.Center,
                                accessor: '.',
                                disableFilters: true,
                                disableGroupBy: true,
                                disableResizing: true,
                                disableSortBy: true,
                                id: 'actions',
                                width: 70
                            }
                        ]}
                        loading={searchBusy}
                        data={sfUserList}
                        filterable
                        // header="Table Title"
                        onAutoResize={function _a() { }}
                        onColumnsReorder={function _a() { }}
                        onGroup={function _a() { }}
                        onLoadMore={function _a() { }}
                        onRowClick={function _a() { }}
                        onRowExpandChange={function _a() { }}
                        onRowSelect={function _a() { }}
                        onSort={function _a() { }}
                        onTableScroll={function _a() { }}

                        selectionMode="None"
                        tableHooks={[]}
                        withRowHighlight
                        withNavigationHighlight={false}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ReactPaginate
                        previousLabel="Geri"
                        nextLabel="İleri"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    // forcePage={pageOffset}
                    /></div>
            </SelectDialog>

            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen dönem yetki tanımı silinecektir, devam edilsin mi?
            </MessageBox>

            <Dialog
                headerText="Excel Dosyasını İçe Aktar"
                open={dialogExcelOpen}
                style={{ width: "1000px" }}
                footer={<>
                    <ToolbarSpacer />
                    <Button onClick={onExcelSubmit} design="Emphasized">Onayla</Button>
                    <Button onClick={closeExcelDialog} design="Transparent">Kapat</Button>
                </>}
                onAfterClose={closeExcelDialog}
            >
                <TabContainer
                    contentBackgroundDesign="Solid"
                    headerBackgroundDesign="Solid"
                    onTabSelect={handleTabSelect}
                    tabLayout="Standard"
                >
                    <Tab data-key="tab1" text="Dosya Seç" icon="upload">
                        <p>Lütfen bir dosya seçiniz:</p>
                        <FileUploader onChange={handleFileUpload} value={fileName} />
                    </Tab>

                    <Tab data-key="tab2" text="Verileri Görüntüle" icon="menu">
                        <Table>
                            <TableColumn style={{ width: "150px" }}>Dönem Kodu</TableColumn>
                            <TableColumn style={{ width: "150px" }}>Kullanıcı</TableColumn>
                            <TableColumn style={{ width: "150px" }}>Ekran</TableColumn>
                            <TableColumn style={{ width: "150px" }}>İstek Türü</TableColumn>

                            {excelData.slice(1).map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {row.map((cell: any, cellIndex: number) => (
                                        <TableCell key={cellIndex}>
                                            <span>{cell}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </Table>
                    </Tab>

                    <Tab data-key="tab3" text="Onayla" icon="accept">
                        <p>{excelData.length - 1} satır veri alınmıştır. İşlemi onaylıyor musunuz?</p>
                    </Tab>
                </TabContainer>
            </Dialog>
        </DashboardLayout>
    );
}
export default PeriodUser;
function setSelectedId(arg0: any) {
    throw new Error('Function not implemented.');
}

