import { Color } from '@mui/material';
import { BusyIndicator, MessageBox, MessageBoxTypes } from '@ui5/webcomponents-react';
import React, { createContext, useCallback, useContext, useState } from 'react'



export type BusySeverity = Color;

export interface BusyDispatchArgs {
	//   severity: BusySeverity,
	isBusy: boolean,
}

export type BusyContextInterface = (args: BusyDispatchArgs) => void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emptyState: BusyContextInterface = (args) => { return }

export const BusyContext = createContext<BusyContextInterface>(emptyState)

export const useBusy: () => BusyContextInterface = () => useContext(BusyContext)

interface BusyProviderProps {
	children: React.ReactNode;
}

export function BusyProvider({ children }: BusyProviderProps): JSX.Element {
	const [isBusy, setisBusy] = useState(false)

	const dispatchBusy = useCallback(({ isBusy }: BusyDispatchArgs) => {
		setisBusy(isBusy)
	}, [])

	return (
		<BusyContext.Provider
			value={dispatchBusy}
		>

			{isBusy && (
				<div  className="overlay">
					<BusyIndicator

						active
						delay={1000}
						size="Large"
					/>
				</div>
			)}
			{children}
		</BusyContext.Provider>
	)
}