//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";
import "@ui5/webcomponents-icons/dist/add";
import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/delete";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, Select, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPeriodApi, BudgetPeriodInsertDto, BudgetPeriodListDto, BudgetPeriodUpdateDto, Configuration } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { isValidDate } from '../utils/utils';
import PickListComboBoxComponent from '../Components/PickListComponent';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");
// setTheme("sap_belize");
function PeriodDefination() {

    const [ismessageBoxOpen, setmessageBoxOpen] = useState(false);
    const [isQuestionmessageBoxOpen, setQuestionmessageBoxOpen] = useState(false);
    const [messsageBoxText, setmesssageBoxText] = useState("");
    const [messsageBoxType, setmesssageBoxType] = useState<MessageBoxTypes>(MessageBoxTypes.Success);
    const [selectedRow, setselectedRow] = useState(null);
    const [isedit, setisedit] = useState(false);
    const [dialogOpen, setdialogOpen] = useState(false);
    const [gridData, setGridData] = useState<BudgetPeriodListDto[]>([]);
    const [periodCode, setperiodCode] = useState("");
    const [name, setname] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [note, setNote] = useState("");

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });
    const parseDateString = (dateString: any) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };
    const handlePeriodCodeInput = (e: any) => {
        setperiodCode(e.target.value);
    };

    const handlePeriodNameInput = (e: any) => {
        setname(e.target.value);
    };
    const handleStartDateInput = (e: any) => {
        setstartDate(e.target.value);
    };
    const handleEndDateInput = (e: any) => {
        setEndDate(e.target.value);
    };
    const handleNoteInput = (e: any) => {
        setNote(e.target.value);
    };
    useEffect(() => {
        getData();
    }, []); //
    async function getData() {

        var api = new BudgetPeriodApi(configuration);
        var data = await api.apiBudgetPeriodGet();
        setGridData(data.data);
    }
    function onNew(): void {

        setname("");
        setperiodCode("");
        setstartDate("");
        setEndDate("");
        setNote("");
        setdialogOpen(true);
        setisedit(false);
    }
    function onDelete(obj: any): void {
        setselectedRow(obj);
        setQuestionmessageBoxOpen(true);
    }
    function onEdit(obj: any): void {
        setisedit(true);
        var api = new BudgetPeriodApi(configuration);
        var result = api.apiBudgetPeriodIdGet(obj.cell.row.original.periodCode)
            .then((response: AxiosResponse<BudgetPeriodListDto>) => {

                setdialogOpen(true);
                setname(response.data.name!);
                setperiodCode(response.data.periodCode!);
                setstartDate(format(parseISO(response.data.startDate!), 'dd/MM/yyyy'));
                setEndDate(format(parseISO(response.data.enDate!), 'dd/MM/yyyy'));
            })
            .catch(error => {
                showMessage("Bir hata oluştu", MessageBoxTypes.Error);
            });
    }
    function closeDialog(): void {
        setdialogOpen(false);
    }
    function onSubmit(e: any) {

        if (isValidDate(startDate) == false || isValidDate(endDate) == false) {
            e.preventDefault();
            return;
        }

        let api = new BudgetPeriodApi(configuration);
        if (isedit == false) {
            let insertDto: BudgetPeriodInsertDto = {};
            insertDto.periodCode = periodCode;
            insertDto.name = name;
            insertDto.startDate = parseDateString(startDate).toISOString();
            insertDto.enDate = parseDateString(endDate).toISOString();
            insertDto.note = note;
            var result = api.apiBudgetPeriodPost(insertDto)
                .then(response => {
                    showMessage("Kayıt Başarılı", MessageBoxTypes.Success);
                    setdialogOpen(false);
                    getData();
                })
                .catch(error => {
                    showMessage(error.response?.data! || 'Bilinmeyen bir hata oluştu', MessageBoxTypes.Error);
                });
        }
        else {

            let updateDto: BudgetPeriodUpdateDto = {};
            updateDto.periodCode = periodCode;
            updateDto.name = name;
            updateDto.startDate = parseDateString(startDate).toISOString();
            updateDto.enDate = parseDateString(endDate).toISOString();
            updateDto.note = note;
            var result = api.apiBudgetPeriodPut(updateDto)
                .then(response => {
                    showMessage("Kayıt Güncelleme Başarılı", MessageBoxTypes.Success);

                    setdialogOpen(false);
                    getData();
                })
                .catch(error => {
                    showMessage(error.response?.data! || 'Bilinmeyen bir hata oluştu', MessageBoxTypes.Error);
                });
        }
        e.preventDefault();
    }
    function showMessage(message: string, type: MessageBoxTypes) {
        setmessageBoxOpen(true);
        setmesssageBoxText(message);
        setmesssageBoxType(type);
    }

    function handleCloseQuestionBox(event: any) {

        var data = (selectedRow! as any).cell.row.original.periodCode


        if (event.detail.action == "Yes") {

            let api = new BudgetPeriodApi(configuration);
            api.apiBudgetPeriodIdDelete(data)
                .then(response => {
                    showMessage(data + " Dönemi Silindi", MessageBoxTypes.Success);

                    getData();
                })
                .catch(error => {

                    showMessage(error.response?.data! || 'Bilinmeyen bir hata oluştu', MessageBoxTypes.Error);
                });
        }
        setQuestionmessageBoxOpen(false);
    }

    return (
        <DashboardLayout>
            <MessageBox
                onClose={() => {
                    setmessageBoxOpen(false)
                }}
                type={messsageBoxType}
                open={ismessageBoxOpen}
            >
                {messsageBoxText}
            </MessageBox>

            <MessageBox
                open={isQuestionmessageBoxOpen}
                onClose={handleCloseQuestionBox}
                actions={[MessageBoxActions.Yes, MessageBoxActions.No, MessageBoxActions.Cancel]}
            >
                Kayıt Silinecektir Onaylıyor musunuz?
            </MessageBox>

            <Dialog
                open={dialogOpen}
                className="headerPartNoPadding footerPartNoPadding"
                // footer={<Bar design="Footer" endContent={<Button type='Submit'>Kaydet</Button>} />}
                header={<Bar endContent={<Button color='white' icon='decline' design="Transparent" onClick={closeDialog}></Button>}><Title>Detay</Title></Bar>}
                headerText="Dialog Header2"
                onAfterClose={function _a() { }}
                onAfterOpen={function _a() { }}
                onBeforeClose={function _a() { }}
                onBeforeOpen={function _a() { }}
            >
                <ThemeProvider>
                    <Form
                        onSubmit={onSubmit}
                        backgroundDesign={FormBackgroundDesign.Transparent}
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={12}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={12}
                        style={{
                            width: '600px',
                            alignItems: 'center'
                        }}
                    >
                        <FormGroup>
                            <FormItem label={<Label style={{ width: "150px" }} required>Dönem Kodu</Label>}>
                                <Input disabled={isedit} color='white' onInput={handlePeriodCodeInput} value={periodCode} required={true} type="Text" />
                            </FormItem>
                            <FormItem label={<Label>Dönem Adı</Label>}>
                                <Input onInput={handlePeriodNameInput} value={name} required={true} type="Text" />
                            </FormItem>
                            
                            <FormItem label={<Label required>Başlangıç Tarihi</Label>}>
                                <DatePicker
                                    required
                                    onChange={handleStartDateInput}
                                    onValueStateChange={function _a() { }}
                                    primaryCalendarType="Gregorian"
                                    formatPattern='dd/MM/yyyy'
                                    value={startDate.toString()}
                                    valueState="None"
                                />
                            </FormItem>
                            <FormItem label={<Label required>Bitiş Tarihi</Label>}>
                                <DatePicker
                                    required
                                    onChange={handleEndDateInput}
                                    value={endDate.toString()}

                                    formatPattern='dd/MM/yyyy'
                                    primaryCalendarType="Gregorian"
                                    valueState="None"
                                />
                            </FormItem>
                            <FormItem label={<Label style={{ alignSelf: 'start', paddingTop: '0.25rem' }}>Dönem Notu</Label>}>
                                <TextArea
                                    value={note}
                                    onChange={handleNoteInput}
                                    placeholder="Not"
                                    rows={5}
                                />
                            </FormItem>
                            <FormItem>
                                <Button icon='save' design="Positive" type={ButtonType.Submit}>Kaydet</Button>
                            </FormItem>
                        </FormGroup>
                    </Form>
                </ThemeProvider>
            </Dialog>
            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={<DynamicPageTitle actions={<><Button icon='add' design="Emphasized" onClick={onNew}>Yeni</Button><Button design="Transparent" /></>} breadcrumbs={<Breadcrumbs><BreadcrumbsItem>Dönem Tanımlama</BreadcrumbsItem></Breadcrumbs>} header={<Title>Dönem Tanımlama</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>}  subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'Dönem Kodu',
                            accessor: 'periodCode',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 150
                        },
                        {
                            Header: 'Dönem Adı',
                            accessor: 'name',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 200
                        },
                        {
                            Header: 'Başlangıç Tarihi',
                            accessor: 'startDate',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 200,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => format(parseISO(value), 'dd/MM/yyyy') // Tarih formatlama
                        },
                        {
                            Header: 'Bitiş Tarihi',
                            accessor: 'enDate',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 200,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => format(parseISO(value), 'dd/MM/yyyy') // Tarih formatlama

                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={isOverlay} />
                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 100
                        }
                    ]}
                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    // header="Table Title"
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="None"
                    tableHooks={[]}
                />
            </DynamicPage>
        </DashboardLayout>
    );
}
export default PeriodDefination;
