//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";

import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/numbered-text";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/history";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, SegmentedButton, SegmentedButtonItem, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { ApproverStatus, BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetNormCodeRequestApi, BudgetNormCodeRequestListDto, BudgetNormCodeRequestUpdateDto, BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserInsertDto, BudgetPeriodUserUpdateDto, Configuration, InternalEmploymentType, Permission, ProcessType, RequestType, SFUSerList, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { getStatusText, isValidDate, parseDateString } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { getuid } from 'process';
import { uuid } from 'uuidv4';
import { useAlert } from '../hooks/useAlert'
import { useBusy } from '../hooks/useBusy'
import ShowHistory from '../Components/ShowHistory';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/userName';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");

// setTheme("sap_belize");
function NormList() {
    const { loginUser, setLoginUser } = useUser();
    const { username, setUsername } = useUser();
    const [addButonActive, setaddButonActive] = useState(false);
    const [historyOpen, sethistoryOpen] = useState(false);
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");
    const [gridData, setGridData] = useState<BudgetNormCodeRequestListDto[]>([]);

    const itemsPerPage = 10;
    const [normDataCount, setnormDataCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);

    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handleRowSelect = (event: any) => {
        const selectedRow = event.detail.row;
        console.log(selectedRow);
        if (selectedRow.original.workflowHead?.workFlowStatus != 1 || selectedRow.original.workflowHead?.workFlowStatus != 2 ||
            selectedRow.original.workflowHead?.workFlowStatus != 3) {
            setSelectedRows((prevSelectedRows: any[]) => {
                const isAlreadySelected = prevSelectedRows.some((row: any) => row.id === selectedRow.id);

                // Eğer satır daha önce seçilmişse ve şu an seçim kaldırılıyorsa
                if (event.detail.isSelected === false) {
                    return prevSelectedRows.filter((row: any) => row.id !== selectedRow.id); // Seçimi kaldır, listeden çıkar
                }

                // Satır zaten seçili değilse ve seçiliyorsa ekle
                if (!isAlreadySelected) {
                    return [...prevSelectedRows, selectedRow];
                }

                // Zaten seçili ise, mevcut listeyi döndür
                return prevSelectedRows;
            });
        }

        console.log(selectedRows)
    };
    function onMultiApprove() {
        console.log(selectedRows);
        var isInApprove = false;
        selectedRows.forEach(row => {
            if (row.original.workflowHead?.workFlowStatus == 1 || row.original.workflowHead?.workFlowStatus == 2 ||
                row.original.workflowHead?.workFlowStatus == 3
            ) {
                isInApprove = true;
            }
        })
        if (isInApprove == false) {
            selectedRows.forEach(async row => {

                dispatchBusy({ isBusy: true });
                if (row.original.id) {
                    var usernameTerfi = "";
                    var userApi = new SFUserApi(configuration);
                    if (row.original.internalSourceEmp) {
                        var res = await userApi.apiSFUserUserUseridGet(row.original.internalSourceEmp);
                        var reslist = res.data.sfuSerList;
                        if (reslist) {
                            usernameTerfi = reslist[0].defaultFullName!;
                        }
                    }

                    var workFlowApi = new WorkFlowApi(configuration);
                    let startDto: WorkFlowStartApiDto = {};
                    startDto.definationId = "b08b506c-1d98-4feb-a680-64ddc67b3c39";
                    startDto.userName = username;
                    startDto.workFlowInfo = "Terfi Edecek Kişi:" + `${usernameTerfi}(${row.original.internalSourceEmp})`;
                    var result = await workFlowApi.apiWorkFlowStartPost(startDto)
                        .then(async response => {
                            onSubmit(response.data.id!, row.original);
                            dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                            dispatchBusy({ isBusy: false });

                            await getData(pageCount, itemsPerPage);
                        })
                }
            });
        }
        else {
            dispatchAlert({ message: "Lütfen onaylanmış veya onay sürecindeki kayıtların seçimlerini kaldırın", type: MessageBoxTypes.Information });
            return;
        }

    }
    function onSubmit(workflowHeadId: string = "", row: any) {
        console.log(row)
        dispatchBusy({ isBusy: true });
        let api = new BudgetNormCodeRequestApi(configuration);
        let updateDto = {} as BudgetNormCodeRequestUpdateDto;
        updateDto.id = row.id;

        updateDto.jobCode = row.jobCode!;
        updateDto.businessUnit = row.businessUnit!;
        updateDto.code = row.code!;
        updateDto.comment = row.comment!;
        updateDto.cust_IseBaslamaTarihi = row.cust_IseBaslamaTarihi!;
        updateDto.cust_calismaYeriTuru = row.cust_calismaYeriTuru!;
        updateDto.cust_customlegalEntity = row.cust_customlegalEntity!;
        updateDto.cust_plannedEndDate = row.cust_plannedEndDate!
        updateDto.division = row.division!;
        updateDto.cust_sub_division = row.cust_sub_division!;
        updateDto.effectiveStatus = "A";
        updateDto.processType = row.processType!
        updateDto.isInternalSource = row.isInternalSource!;
        updateDto.relationEmployess = row.relationEmployess!;
        updateDto.relationManager = row.relationManager!;

        updateDto.jobCodeDescription = row.jobCodeDescription;
        updateDto.relationEmployess = row.relationEmployess;
        updateDto.relationManager = row.relationManager;
        updateDto.propotionReasonTxt = row.propotionReasonTxt
        updateDto.promotionPeriod = row.promotionPeriod;
        updateDto.promotionPeriodTxt = row.promotionPeriodTxt;

        if (workflowHeadId != "")
            updateDto.workflowHeadId = workflowHeadId;


        console.log(updateDto)
        api.apiBudgetNormCodeRequestPut(updateDto)
            .then(response => {
                if (workflowHeadId != null) {
                    dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                }
                else {
                    dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                }
                navigate("/NormList")
                dispatchBusy({ isBusy: false });
            })
            .catch(error => {
                dispatchBusy({ isBusy: false });
                // Hata mesajlarını kontrol edin ve birleştirin
                let errorMessage = 'Bilinmeyen bir hata oluştu';
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        errorMessage = error.response.data.errors.join(', ');
                    } else if (error.response.data) {
                        errorMessage = error.response.data;
                    }
                }
                // Hata mesajını loglayın ve dispatchAlert ile gösterin
                console.log(errorMessage);
                dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
            });

        dispatchBusy({ isBusy: true });
    }
    const navigate = useNavigate();
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()

    useEffect(() => {


        getData(pageCount, itemsPerPage);
        getActivePeriod();

    }, []); //

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % normDataCount;
        getData(newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    async function getData(skip: number = 0, top: number = 10) {
        var api = new BudgetNormCodeRequestApi(configuration);
        var data = await api.apiBudgetNormCodeRequestGet(skip, top);

        setGridData(data.data.budgetNormCodeRequestListDtoList!);
        console.log(data.data)

        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = data.data.budgetNormCodeRequestListDtoList!.slice(itemOffset, endOffset);
        setnormDataCount(data.data.count!);
        setpageCount(Math.ceil(data.data.count! / itemsPerPage));

    }

    function onNew(): void {
        navigate("/NormDetail")
    }
    function showHistory(obj: any): void {

        setselectedWorkFlowId(obj.cell.row.original.workflowHeadId);
        sethistoryOpen(true);
    }
    function onEdit(obj: any): void {
        navigate("/NormDetail?id=" + obj.cell.row.original.id);
        return;
    }
    async function onDelete(obj: any): Promise<void> {
        setdeleteOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
        // var api = new BudgetNormCodeRequestApi(configuration);
        // dispatchBusy({ isBusy: true });
        // var result = await api.apiBudgetNormCodeRequestIdDelete(obj.cell.row.original.id)
        // await getData();
        // dispatchBusy({ isBusy: false });
    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetNormCodeRequestApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetNormCodeRequestIdDelete(selectedWorkFlowId)
            await getData(0, 10);
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Norm Kadro Talep Listesi':
                navigate("/NormList")
                break;
            case "Norm Kadro Talep Detayı":
                navigate("/NormDetail")
                break;
            default:
                break;
        }

    }

    async function onSegmentedButtonChange(event: CustomEvent) {
        dispatchBusy({ isBusy: true });
        var api = new BudgetNormCodeRequestApi(configuration);
        var data = await api.apiBudgetNormCodeRequestGet();

        const selectedItemTxt = event.detail.selectedItem.innerText;
        switch (selectedItemTxt) {
            case "Hepsi":
                setGridData(data.data.budgetNormCodeRequestListDtoList!);
                break;
            case "Onay Sürecinde":
                var inProgress = data.data.budgetNormCodeRequestListDtoList!.filter(i => i.workflowHead?.workFlowStatus == 1)
                setGridData(inProgress)
                break;
            case "Tamamlandı":
                var completed = data.data.budgetNormCodeRequestListDtoList!.filter(i => i.workflowHead?.workFlowStatus == 2)
                setGridData(completed)
                break;

            default:
                break;
        }
        dispatchBusy({ isBusy: false });
    }

    async function getActivePeriod() {
        dispatchBusy({ isBusy: true });
        var api = new BudgetPeriodUserApi(configuration);
        console.log(loginUser)
        var result = api.apiBudgetPeriodUserGetActivePeriodUseridGet(username, 1)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {
                setaddButonActive(false);
            })
            .catch(error => {
                setaddButonActive(true);
            }).finally(() => {

            });
        console.log(result);
        dispatchBusy({ isBusy: false });
    }

    return (
        <DashboardLayout>


            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={
                    <DynamicPageTitle actions={
                        <>
                            <Button disabled={addButonActive} icon='add' onClick={onNew} design="Emphasized">Yeni</Button>
                            <Button disabled={selectedRows.length > 0 ? false : true} icon='accept' onClick={onMultiApprove} design="Positive">Onaya Gönder</Button>
                        </>}
                        breadcrumbs={
                            <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                                <BreadcrumbsItem>Norm Kadro</BreadcrumbsItem>
                            </Breadcrumbs>}
                        header={<Title>Norm Kadrolar</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>} subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <SegmentedButton
                    id="idSB1"
                    onSelectionChange={onSegmentedButtonChange}
                >
                    <SegmentedButtonItem>
                        Hepsi
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Onay Sürecinde
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Tamamlandı
                    </SegmentedButtonItem>
                </SegmentedButton>
                <AnalyticalTable
                    columns={[

                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={(instance.cell.row.original.workflowHead?.workFlowStatus != null &&
                                            instance.cell.row.original.workflowHead?.workFlowStatus != 4
                                        ) ? true : false} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 85
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox style={{ alignItems: 'center' }}>
                                        <Button icon="history" onClick={() => showHistory(instance)} disabled={instance.cell.row.original.workflowHeadId != null ? false : true} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Tarihçe',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'history',
                            width: 80
                        },
                        {
                            Header: 'Oluşturan Kişi',
                            accessor: 'createdBy',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Kayıt Oluşturma Tarihi',
                            accessor: 'createdDate',
                            autoResizable: true,
                            headerTooltip: 'createdDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Header: 'Durum',
                            accessor: 'workflowHead.workFlowStatus',
                            autoResizable: true,
                            headerTooltip: 'Durum',
                            width: 150,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => {
                                // Satır rengi belirleme
                                let textcolor: string;
                                switch (value) {
                                    case 1:
                                        textcolor = "#ffce00"; //turuncu
                                        break;
                                    case 2:
                                        textcolor = "#399918"; //yesil
                                        break;
                                    case 4:
                                        textcolor = "#fd0200"; //kirmizi
                                        break;
                                    default:
                                        textcolor = "black";
                                }

                                // Tüm hücreler için arka plan rengini ayarla
                                return (
                                    <div style={{ color: textcolor }}>
                                        {getStatusText(value)}
                                    </div>
                                );
                            },
                        },
                        {
                            Header: 'Pozisyon İsmi',
                            accessor: 'jobCodeDescription',
                            autoResizable: true,
                            width: 250
                        },
                        // {
                        //     Header: 'Pozisyon Kodu',
                        //     accessor: 'code',
                        //     autoResizable: true,
                        //     width: 130
                        // },
                        {
                            Header: 'Kişi Sayısı',
                            accessor: 'targetFTE',
                            autoResizable: true,
                            width: 100
                        },
                        {
                            Header: 'Grup/Başkanlık',
                            accessor: 'cust_customlegalEntity',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Şirket',
                            accessor: 'businessUnit',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Bölüm',
                            accessor: '',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Bölüm/Proje/İşletme',
                            accessor: 'cust_sub_division',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Birim',
                            accessor: 'department',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Terfi/Çalışan',
                            accessor: 'internalSourceEmp',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Başlangıç Tarih',
                            accessor: 'effectiveStartDate',
                            autoResizable: true,
                            width: 130,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                        },
                        {
                            Header: 'Bitiş Tarih',
                            accessor: 'cust_plannedEndDate',
                            autoResizable: true,
                            width: 130,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                        },
                        {
                            Header: 'İstek Türü',
                            accessor: 'processType',
                            autoResizable: true,
                            width: 130,
                            Cell: ({ value }: any) => {
                                return (
                                    <div>
                                        {value == 1 ? "Yıllık Kadro Planı" : "Ek Kadro Planı"}
                                    </div>
                                );
                            },
                        },
                        {
                            Header: 'İş Talebi',
                            accessor: '',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Güncellenme Tarihi',
                            accessor: 'updatedDate',
                            autoResizable: true,
                            headerTooltip: 'updatedDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        }

                    ]}

                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    // header="Table Title"
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="MultiSelect"
                    tableHooks={[]}
                    withRowHighlight={false}
                    onRowSelect={handleRowSelect}

                />

                <div style={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 10,
                    padding: '10px 0'
                }}>
                    <ReactPaginate
                        previousLabel="Geri"
                        nextLabel="İleri"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>

            </DynamicPage>
            {historyOpen && (
                <ShowHistory

                    approveId={selectedWorkFlowId}
                    open={historyOpen}
                    onClose={() => sethistoryOpen(false)}
                />
            )}
            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen norm kadro silinecektir, devam edilsin mi?
            </MessageBox>
        </DashboardLayout>
    );
}
export default NormList;

