//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";

import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/search";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/person-placeholder";
import "@ui5/webcomponents-icons/dist/group";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, OverflowToolbarButton, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Tab, TabContainer, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetNormCodeRequestApi, BudgetNormCodeRequestListDto, BudgetNormCodeRequestListDtoOnlyCodeResult, BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserInsertDto, BudgetPeriodUserUpdateDto, BudgetPromotionRequestApi, BudgetPromotionRequestListDto, Configuration, Permission, PositionSFDto, RequestType, SFPositionList, SFPositionsApi, SFUSerList, SFUserApi } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { getStatusText, isValidDate } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { number } from 'yup';
import { useBusy } from '../hooks/useBusy';
import { useUser } from '../hooks/userName';
import { useNavigate } from 'react-router-dom';


import * as XLSX from 'xlsx';
import ShowHistory from '../Components/ShowHistory';
import { useAlert } from '../hooks/useAlert';

// setTheme("sap_horizon");
setTheme("sap_fiori_3");


// setTheme("sap_belize");
function PositionList() {

    const dispatchAlert = useAlert()
    const navigate = useNavigate();
    const { loginUser, setLoginUser } = useUser();
    const { username, setUsername } = useUser();
    const [addButonActive, setaddButonActive] = useState(false);

    const dispatchBusy = useBusy()
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [gridData, setGridData] = useState<SFPositionList[]>([]);


    const [normgridData, setNormGridData] = useState<BudgetNormCodeRequestListDto[]>([]);
    const [historyOpen, sethistoryOpen] = useState(false);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");
    const [deleteOpen, setdeleteOpen] = useState(false);

    const [currentEmployeesCount, setcurrentEmployeesCount] = useState(0);
    const [currentNormCount, setcurrentNormCount] = useState(0);
    const [approveNormCount, setapproveNormCount] = useState(0);
    const [totalCount, settotalCount] = useState(0);


    const [promotiongridData, setPromotionGridData] = useState<BudgetPromotionRequestListDto[]>([]);
    const [deletepromotionOpen, setdeletepromotionOpen] = useState(false);

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    useEffect(() => {
        getData(0, 10);
        getNormData(0, 10)
        getPromotionData(0, 10)
        getActivePeriod();


    }, []); //

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * 10) % pageCount;
        getData(newOffset, 10);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    async function getData(skip: number = 0, top: number = 10) {

        dispatchBusy({ isBusy: true });

        var api = new SFPositionsApi(configuration);
        api.apiSFPositionsByUserIdUserIdGet(username == "" ? loginUser : username)
            .then(async data => {
                console.log(">>>", data.data.sfPositionList)
                // setGridData(data.data.sfPositionList!);
                // setpageCount(data.data.count!);
                // setpageCount(Math.ceil(data.data.count! / 10));

                dispatchBusy({ isBusy: false });
                await getAllData(data.data.sfPositionList!);
            })
            .then(() => {
                console.log("Data fetched and grid set successfully.");
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });

    }

    async function getNormData(skip: number = 0, top: number = 10) {
        var api = new BudgetNormCodeRequestApi(configuration);
        var data = await api.apiBudgetNormCodeRequestGet(skip, top);

        setNormGridData(data.data.budgetNormCodeRequestListDtoList!);
        console.log(data.data)

        var aprNormCount = data.data.budgetNormCodeRequestListDtoList!.filter((i: any) => i.workflowHead?.workFlowStatus === 1).length;
        setapproveNormCount(aprNormCount);

        setpageCount(data.data.count!);
        setpageCount(Math.ceil(data.data.count! / 10));

    }
    async function getPromotionData(skip: number = 0, top: number = 10) {
        var api = new BudgetPromotionRequestApi(configuration);
        var data = await api.apiBudgetPromotionRequestGet();

        setPromotionGridData(data.data);
        console.log(data)

    }

    async function onNewNorm() {
        navigate("/NormDetail");
    }

    async function getWaitingAprNormCount(a: int, b: int) {
        dispatchBusy({ isBusy: true });
        var api = new BudgetNormCodeRequestApi(configuration);
        var result = await api.apiBudgetNormCodeRequestAllWaitingApproveCountGet();
        setapproveNormCount(result.data);
        var total = a + b + result.data;
        settotalCount(total);

        dispatchBusy({ isBusy: false });
    }

    async function getAllData(list: SFPositionList[]) {

        var api = new BudgetNormCodeRequestApi(configuration);
        var data = [] as any;
        var result = api.apiBudgetNormCodeRequestAllGet()
            .then(async (response: AxiosResponse<BudgetNormCodeRequestListDtoOnlyCodeResult>) => {

                console.log("alldata", response)
                console.log("grdidata", gridData)
                console.log("list", list)
                // response.data.budgetNormCodeRequestListDtoList!.forEach(item => {
                //     list.forEach(gridItem => {
                //         if (gridItem.externalCode == item.code) {
                //             var x = {
                //                 "workflow": item.workflowHead?.workFlowStatus,
                //                 "externalCode": gridItem.externalCode,
                //                 "name": gridItem.name,
                //                 "userName": gridItem.userName,
                //                 "vacant": gridItem.vacant,
                //             }
                //             data.push(x);
                //         }
                //     });
                // });
                list.forEach(listItem => {
                    var x = {
                        "workflow": "",
                        "externalCode": listItem.externalCode,
                        "name": listItem.name,
                        "userName": listItem.userName,
                        "userId":listItem.userId,
                        "vacant": listItem.vacant,
                        "cust_IseBaslamaTarihi": listItem.cust_IseBaslamaTarihi,
                        "cust_plannedEndDate": listItem.cust_plannedEndDate,
                        "businessUnit": listItem.businessUnit,
                        "cust_customlegalEntity": listItem.cust_customlegalEntity,
                    };

                    response.data.budgetNormCodeRequestListDtoList!.forEach(element => {
                        if (element.workflowHead?.workFlowStatus == 1 && element.code == listItem.externalCode) {
                            x.workflow = element.workflowHead.workFlowStatus.toString();
                        }
                    });

                    data.push(x);
                });


                console.log("data", data)
                setGridData(data)

                var currrentEmpCount = data.filter((i: any) => i.vacant === true && i.workflow === "").length;
                var currrentNormCount = data.filter((i: any) => i.vacant === false && i.workflow === "").length;
                setcurrentEmployeesCount(currrentEmpCount);
                setcurrentNormCount(currrentNormCount);
                await getWaitingAprNormCount(currrentEmpCount, currrentNormCount);
            })
            .catch(error => {

            }).finally(() => {

            });

        dispatchBusy({ isBusy: false });

    }

    async function getActivePeriod() {
        dispatchBusy({ isBusy: true });
        var api = new BudgetPeriodUserApi(configuration);
        var result = api.apiBudgetPeriodUserGetActivePeriodUseridGet(username, 1)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {
                setaddButonActive(false);
            })
            .catch(error => {
                setaddButonActive(true);
            }).finally(() => {

            });
        console.log(result);
        dispatchBusy({ isBusy: false });
    }

    async function onTabSelect(event: any) {
        if (event.detail.tabIndex == 0) {
            getData(0, 0);
        }
    }

    async function GoDetail(obj: any) {
        //         dispatchBusy({ isBusy: true });
        //         var normApi = new BudgetNormCodeRequestApi(configuration);
        //         var resultCheck = await normApi.apiBudgetNormCodeRequestCheckPositionHasJobPostGet(obj.cell.row.original.externalCode!);
        // console.log(resultCheck.data)
        //         if (resultCheck.data) {
        //             dispatchAlert({ message: "Kadroya ait işe alım ilanı bulunmaktadır..!", type: MessageBoxTypes.Warning })
        //             dispatchBusy({ isBusy: false });
        //             return;
        //         }
        //         else {
        //             dispatchBusy({ isBusy: false });

        //         }
        navigate("/NormDetail?id=" + obj.cell.row.original.externalCode + "&type=edita");
        return;
    }

    async function onDeleteNorm(obj: any) {
        console.log(obj.cell.row)
        dispatchBusy({ isBusy: true });
        var normApi = new BudgetNormCodeRequestApi(configuration);
        var resultCheck = await normApi.apiBudgetNormCodeRequestCheckPositionHasJobPostGet(obj.cell.row.original.externalCode!);
        console.log(resultCheck.data)

        if (resultCheck.data != "") {
            dispatchAlert({ message: `Kadroya ait ${resultCheck.data} nolu işe alım ilanı bulunmaktadır..!`, type: MessageBoxTypes.Warning })
            dispatchBusy({ isBusy: false });
            return;
        }
        else {
            await onDelete(obj)
            dispatchBusy({ isBusy: false });
        }


    }

    function showHistory(obj: any): void {

        setselectedWorkFlowId(obj.cell.row.original.workflowHeadId);
        sethistoryOpen(true);
    }
    function onEdit(obj: any): void {
        navigate("/NormDetail?id=" + obj.cell.row.original.id);
        return;
    }
    async function onDelete(obj: any): Promise<void> {
        setdeleteOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
        // var api = new BudgetNormCodeRequestApi(configuration);
        // dispatchBusy({ isBusy: true });
        // var result = await api.apiBudgetNormCodeRequestIdDelete(obj.cell.row.original.id)
        // await getData();
        // dispatchBusy({ isBusy: false });
    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetNormCodeRequestApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetNormCodeRequestIdDelete(selectedWorkFlowId)
            await getData(pageCount, itemOffset);
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }
    function onEditPromotion(obj: any): void {
        navigate("/PromotionDetail?id=" + obj.cell.row.original.id);
        return;
    }
    async function onDeletePromotion(obj: any): Promise<void> {
        setdeletepromotionOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
    }
    async function handleDeletePromotionDialog(event: any) {
        setdeletepromotionOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetPromotionRequestApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetPromotionRequestIdDelete(selectedWorkFlowId)
            await getData();
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(gridData); // Convert JSON data to worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "MevcutKadro");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "MevcutKadro.xlsx");
    };

    const statuName = (_state: string, isVacant: boolean) => {
        if (_state != "") {
            var state = parseInt(_state);
            switch (state) {
                case 2:
                    return 'Tamamlandı';
                case 4:
                    return 'Süreç Başa Döndü';
                case 1:
                    return 'Onay Sürecinde';
            }
        }
        else {
            switch (isVacant) {
                case true:
                    return 'Devreden Norm Kadro';
                case false:
                    return 'Mevcut Çalışan';
            }
        }

    };
    return (
        <DashboardLayout>


            <DynamicPage
                // onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                {gridData ? (
                    <>
                        <TabContainer
                            contentBackgroundDesign="Transparent"
                            headerBackgroundDesign="Transparent"

                            onTabSelect={onTabSelect}
                            tabLayout="Standard"
                        >
                            <Tab
                                additionalText={gridData ? gridData.length.toString() : "0"}
                                icon="menu"
                                selected
                                text="Mevcut&Devreden Kadro Listesi"
                            >
                                <Button icon='excel-attachment' onClick={exportToExcel} design="Default">Excel Export</Button>

                                <Label style={{ marginRight: "24px", marginLeft: "24px", color: "black" }}>Devreden Norm Kadro Sayısı: {currentNormCount}</Label>
                                <Label style={{ marginRight: "12px", marginLeft: "12px", color: "black" }}>Mevcut Çalışan Sayısı: {currentEmployeesCount}</Label>
                                <Label style={{ marginRight: "12px", marginLeft: "12px", color: "black" }}>Onaydaki Norm Kadro Sayısı: {approveNormCount}</Label>
                                <Label style={{ marginRight: "12px", marginLeft: "12px", color: "black" }}>Toplam Sayı: {totalCount}</Label>
                                <div className="fixed-scroll">
                                    <div className="custom-scroll">
                                        <AnalyticalTable
                                            columns={[

                                                {
                                                    Cell: (instance: any) => {
                                                        const { cell: any, row, webComponentsReactProperties } = instance;
                                                        // disable buttons if overlay is active to prevent focus
                                                        const isOverlay = webComponentsReactProperties.showOverlay;
                                                        return (
                                                            <FlexBox>
                                                                {instance.cell.row.original.workflow === "" ? (
                                                                    <Button icon="edit" onClick={() => GoDetail(instance)} disabled={!instance.cell.row.original.vacant} />
                                                                ) : (
                                                                    // Koşul sağlanmadığında çalışacak kod buraya gelir
                                                                    <Button icon="edit" onClick={() => GoDetail(instance)} disabled={instance.cell.row.original.workflow === "1" ? true : false} />
                                                                )}
                                                                <Button icon="delete" onClick={() => onDeleteNorm(instance)} disabled={instance.cell.row.original.workflow === "1" || instance.cell.row.original.vacant == false ? true : false} />
                                                            </FlexBox>
                                                        );
                                                    },
                                                    // cellLabel: ({ cell }) => {
                                                    //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                                    //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                                    // },
                                                    Header: 'Actions',
                                                    accessor: '.',
                                                    disableFilters: true,
                                                    disableGroupBy: true,
                                                    disableResizing: true,
                                                    disableSortBy: true,
                                                    id: 'actions',
                                                    width: 85
                                                },
                                                {
                                                    Cell: (instance: any) => {
                                                        const { cell: any, row, webComponentsReactProperties } = instance;
                                                        // disable buttons if overlay is active to prevent focus
                                                        const isOverlay = webComponentsReactProperties.showOverlay;
                                                        // Satır rengi belirleme
                                                        let textcolor: string;
                                                        switch (parseInt(instance.cell.row.original.workflow)) {
                                                            case 1:
                                                                textcolor = "#ffce00"; //turuncu
                                                                break;
                                                            case 2:
                                                                textcolor = "#399918"; //yesil
                                                                break;
                                                            case 4:
                                                                textcolor = "#fd0200"; //kirmizi
                                                                break;
                                                            default:
                                                                textcolor = "black";
                                                        }

                                                        // Tüm hücreler için arka plan rengini ayarla
                                                        return (
                                                            <div style={{ color: textcolor }}>
                                                                {statuName(instance.cell.row.original.workflow, instance.cell.row.original.vacant)}
                                                            </div>
                                                        );
                                                    },
                                                    // cellLabel: ({ cell }) => {
                                                    //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                                    //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                                    // },
                                                    Header: 'Durum',
                                                    accessor: '.',
                                                    id: 'statu',
                                                    width: 150,
                                                    autoResizable: true,
                                                },
                                                {
                                                    Header: 'Pozisyon Adı',
                                                    accessor: 'name',
                                                    autoResizable: true,
                                                    headerTooltip: 'Dönem Kodu',
                                                    width: 300
                                                },
                                                {
                                                    Header: 'Çalışan',
                                                    accessor: 'userName',
                                                    autoResizable: true,
                                                    headerTooltip: 'Kullanıcı',
                                                    width: 300
                                                },
                                                {
                                                    Header: 'Sicil No',
                                                    accessor: 'userId',
                                                    autoResizable: true,
                                                    headerTooltip: 'Kullanıcı',
                                                    width: 100
                                                },
                                                {
                                                    Header: 'Pozisyon Kodu',
                                                    accessor: 'externalCode',
                                                    autoResizable: true,
                                                    headerTooltip: 'Dönem Kodu',
                                                    width: 150
                                                },
                                                {
                                                    Header: 'Şirket',
                                                    accessor: 'businessUnit',
                                                    autoResizable: true,
                                                    headerTooltip: 'Kullanıcı',
                                                    width: 100
                                                },
                                                {
                                                    Header: 'Bölge/Fonksiyon/BU',
                                                    accessor: 'cust_customlegalEntity',
                                                    autoResizable: true,
                                                    headerTooltip: 'Kullanıcı',
                                                    width: 150
                                                },
                                                {
                                                    Header: 'Bölüm/Proje/İşletme',
                                                    accessor: 'cust_sub_division',
                                                    autoResizable: true,
                                                    width: 150
                                                },
                                                {
                                                    Header: 'Birim',
                                                    accessor: 'department',
                                                    autoResizable: true,
                                                    width: 130
                                                },
                                                {
                                                    Header: 'Planlanan İşe Başlama Tarihi',
                                                    accessor: 'cust_IseBaslamaTarihi',
                                                    autoResizable: true,
                                                    headerTooltip: 'Bütçe-Planlanan İşe Giriş Tarihi',
                                                    width: 220,
                                                    Cell: (instance: any) => {
                                                        const rawDate = instance.cell.row.original.cust_IseBaslamaTarihi;
                                                        const formattedDate = new Date(rawDate).toLocaleDateString('tr-TR', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                        });

                                                        return (
                                                            <div>
                                                                {formattedDate}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    Header: 'Planlanan Bitiş Tarihi',
                                                    accessor: 'cust_plannedEndDate',
                                                    autoResizable: true,
                                                    headerTooltip: 'Planlanan Bitiş Tarihi',
                                                    width: 170,
                                                    Cell: (instance: any) => {
                                                        const rawDate = instance.cell.row.original.cust_plannedEndDate;
                                                        const formattedDate = new Date(rawDate).toLocaleDateString('tr-TR', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                        });

                                                        return (
                                                            <div>
                                                                {formattedDate}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    Header: 'Kadro Dolu mu',
                                                    accessor: 'vacant',
                                                    autoResizable: true,
                                                    headerTooltip: 'Kadro Dolu mu',
                                                    width: 150,
                                                    Cell: ({ value }: any) => {

                                                        // Tüm hücreler için arka plan rengini ayarla
                                                        return (
                                                            <div>
                                                                {value ? "Hayır" : "Evet"}
                                                            </div>
                                                        );
                                                    },
                                                }
                                            ]}

                                            data={gridData!}
                                            filterable
                                            groupBy={[]}
                                            groupable
                                            // header="Table Title"
                                            infiniteScroll
                                            visibleRows={gridData!.length}
                                            selectionMode="None"
                                            tableHooks={[]}
                                            style={{ height: "450px" }} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                additionalText=""
                                icon="group"
                                text="Yeni Kadro Talebi"
                            >
                                <Button disabled={addButonActive} icon='add' onClick={onNewNorm} design="Emphasized">Yeni Norm Kadro</Button>
                                <AnalyticalTable
                                    columns={[

                                        {
                                            Cell: (instance: any) => {
                                                const { cell: any, row, webComponentsReactProperties } = instance;
                                                // disable buttons if overlay is active to prevent focus
                                                const isOverlay = webComponentsReactProperties.showOverlay;
                                                // console.log('This is your row data', row.original);
                                                return (
                                                    <FlexBox>
                                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={(instance.cell.row.original.workflowHead?.workFlowStatus != null &&
                                                            instance.cell.row.original.workflowHead?.workFlowStatus != 4
                                                        ) ? true : false} />

                                                    </FlexBox>
                                                );
                                            },
                                            // cellLabel: ({ cell }) => {
                                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                            // },
                                            Header: 'Actions',
                                            accessor: '.',
                                            disableFilters: true,
                                            disableGroupBy: true,
                                            disableResizing: true,
                                            disableSortBy: true,
                                            id: 'actions',
                                            width: 85
                                        },
                                        {
                                            Cell: (instance: any) => {
                                                const { cell: any, row, webComponentsReactProperties } = instance;
                                                // disable buttons if overlay is active to prevent focus
                                                const isOverlay = webComponentsReactProperties.showOverlay;
                                                // console.log('This is your row data', row.original);
                                                return (
                                                    <FlexBox style={{ alignItems: 'center' }}>
                                                        <Button icon="history" onClick={() => showHistory(instance)} disabled={instance.cell.row.original.workflowHeadId != null ? false : true} />

                                                    </FlexBox>
                                                );
                                            },
                                            // cellLabel: ({ cell }) => {
                                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                            // },
                                            Header: 'Tarihçe',
                                            accessor: '.',
                                            disableFilters: true,
                                            disableGroupBy: true,
                                            disableResizing: true,
                                            disableSortBy: true,
                                            id: 'history',
                                            width: 80
                                        },
                                        {
                                            Header: 'Oluşturan Kişi',
                                            accessor: 'createdBy',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Kayıt Oluşturma Tarihi',
                                            accessor: 'createdDate',
                                            autoResizable: true,
                                            headerTooltip: 'createdDate',
                                            width: 170,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                                        },
                                        {
                                            Header: 'Durum',
                                            accessor: 'workflowHead.workFlowStatus',
                                            autoResizable: true,
                                            headerTooltip: 'Durum',
                                            width: 150,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => {
                                                // Satır rengi belirleme
                                                let textcolor: string;
                                                switch (value) {
                                                    case 1:
                                                        textcolor = "#ffce00"; //turuncu
                                                        break;
                                                    case 2:
                                                        textcolor = "#399918"; //yesil
                                                        break;
                                                    case 4:
                                                        textcolor = "#fd0200"; //kirmizi
                                                        break;
                                                    default:
                                                        textcolor = "black";
                                                }

                                                // Tüm hücreler için arka plan rengini ayarla
                                                return (
                                                    <div style={{ color: textcolor }}>
                                                        {getStatusText(value)}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            Header: 'Pozisyon İsmi',
                                            accessor: 'jobCodeDescription',
                                            autoResizable: true,
                                            width: 250
                                        },
                                        {
                                            Header: 'Pozisyon Kodu',
                                            accessor: 'code',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Kişi Sayısı',
                                            accessor: 'targetFTE',
                                            autoResizable: true,
                                            width: 100
                                        },
                                        {
                                            Header: 'Grup/Başkanlık',
                                            accessor: 'cust_customlegalEntity',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Şirket',
                                            accessor: 'customlegalEntity',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Bölge/Fonksiyon/BU',
                                            accessor: 'businessUnit',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Bölüm/Proje/İşletme',
                                            accessor: 'cust_sub_division',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Birim',
                                            accessor: 'department',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Terfi/Çalışan',
                                            accessor: 'internalEmploymentType',
                                            autoResizable: true,
                                            width: 130,
                                            Cell: ({ value }: any) => {
                                                return (
                                                    <div>
                                                        {value == 1 ? "Terfi" : "Çalışan/Transfer"}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            Header: 'Başlangıç Tarih',
                                            accessor: 'effectiveStartDate',
                                            autoResizable: true,
                                            width: 130,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                                        },
                                        {
                                            Header: 'Bitiş Tarih',
                                            accessor: 'cust_plannedEndDate',
                                            autoResizable: true,
                                            width: 130,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                                        },
                                        {
                                            Header: 'İstek Türü',
                                            accessor: 'processType',
                                            autoResizable: true,
                                            width: 130,
                                            Cell: ({ value }: any) => {
                                                return (
                                                    <div>
                                                        {value == 1 ? "Yıllık Kadro Planı" : "Ek Kadro Planı"}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            Header: 'İş Talebi',
                                            accessor: '',
                                            autoResizable: true,
                                            width: 130
                                        },
                                        {
                                            Header: 'Güncellenme Tarihi',
                                            accessor: 'updatedDate',
                                            autoResizable: true,
                                            headerTooltip: 'updatedDate',
                                            width: 170,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                                        }

                                    ]}

                                    data={normgridData}
                                    filterable
                                    groupBy={[]}
                                    groupable
                                    // header="Table Title"
                                    infiniteScroll
                                    visibleRows={normgridData.length}
                                    selectionMode="None"
                                    tableHooks={[]}
                                    withRowHighlight={false}
                                />

                            </Tab>
                            {/* <Tab
                                additionalText=""
                                icon="person-placeholder"
                                text="Terfi Talepleri"
                            >
                                <AnalyticalTable
                                    columns={[
                                        {
                                            Header: 'Sicil No',
                                            accessor: 'empCode',
                                            autoResizable: true,
                                            headerTooltip: 'Çalışan Grubu',
                                            width: 130
                                        },
                                        {
                                            Header: 'Pozisyon Kodu',
                                            accessor: 'positionCode',
                                            autoResizable: true,
                                            headerTooltip: 'Şirket',
                                            width: 130
                                        },
                                        {
                                            Header: 'Terfi Tarihi',
                                            accessor: 'promotionDate',
                                            autoResizable: true,
                                            headerTooltip: 'Durum',
                                            width: 150,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null
                                        },
                                        {
                                            Header: 'Açıklama',
                                            accessor: 'description',
                                            autoResizable: true,
                                            headerTooltip: 'Durum',
                                            width: 150,
                                            hAlign: TextAlign.Center,
                                        },
                                        {
                                            Header: 'Güncellenme Tarihi',
                                            accessor: 'updatedDate',
                                            autoResizable: true,
                                            headerTooltip: 'updatedDate',
                                            width: 170,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                                        },
                                        {
                                            Header: 'Durumu',
                                            accessor: 'workflowHead.workFlowStatus',
                                            autoResizable: true,
                                            headerTooltip: 'Durum',
                                            width: 150,
                                            hAlign: TextAlign.Center,
                                            Cell: ({ value }: any) => {
                                                // Satır rengi belirleme
                                                let textcolor: string;
                                                switch (value) {
                                                    case 1:
                                                        textcolor = "#ffce00"; //turuncu
                                                        break;
                                                    case 2:
                                                        textcolor = "#399918"; //yesil
                                                        break;
                                                    case 4:
                                                        textcolor = "#fd0200"; //kirmizi
                                                        break;
                                                    default:
                                                        textcolor = "black";
                                                }

                                                // Tüm hücreler için arka plan rengini ayarla
                                                return (
                                                    <div style={{ color: textcolor }}>
                                                        {getStatusText(value)}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            Cell: (instance: any) => {
                                                const { cell: any, row, webComponentsReactProperties } = instance;
                                                // disable buttons if overlay is active to prevent focus
                                                // const isOverlay = webComponentsReactProperties.showOverlay;
                                                // console.log('This is your row data', row.original);
                                                return (
                                                    <FlexBox>
                                                        <Button icon="edit" onClick={() => onEditPromotion(instance)} />
                                                        <Button icon="delete" onClick={() => onDeletePromotion(instance)} disabled={(instance.cell.row.original.workflowHead?.workFlowStatus != null &&
                                                            instance.cell.row.original.workflowHead?.workFlowStatus != 4
                                                        ) ? true : false} />

                                                    </FlexBox>
                                                );
                                            },
                                            // cellLabel: ({ cell }) => {
                                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                            // },
                                            Header: 'Actions',
                                            accessor: '.',
                                            disableFilters: true,
                                            disableGroupBy: true,
                                            disableResizing: true,
                                            disableSortBy: true,
                                            id: 'actions',
                                            width: 85
                                        },
                                        {
                                            Cell: (instance: any) => {
                                                const { cell: any, row, webComponentsReactProperties } = instance;
                                                // disable buttons if overlay is active to prevent focus
                                                const isOverlay = webComponentsReactProperties.showOverlay;
                                                // console.log('This is your row data', row.original);
                                                return (
                                                    <FlexBox style={{ alignItems: 'center' }}>
                                                        <Button icon="history" onClick={() => showHistory(instance)} disabled={instance.cell.row.original.workflowHeadId != null ? false : true} />

                                                    </FlexBox>
                                                );
                                            },
                                            Header: 'Tarihçe',
                                            accessor: '.',
                                            disableFilters: true,
                                            disableGroupBy: true,
                                            disableResizing: true,
                                            disableSortBy: true,
                                            id: 'history',
                                            width: 80
                                        }
                                    ]}

                                    data={promotiongridData}
                                    filterable
                                    groupBy={[]}
                                    groupable
                                    // header="Table Title"
                                    infiniteScroll
                                    visibleRows={promotiongridData.length}
                                    selectionMode="None"
                                    tableHooks={[]}
                                    withRowHighlight={false}
                                />

                            </Tab> */}


                        </TabContainer>
                    </>

                ) : (
                    <p>Veri mevcut değil</p>
                )}
            </DynamicPage>
            {historyOpen && (
                <ShowHistory

                    approveId={selectedWorkFlowId}
                    open={historyOpen}
                    onClose={() => sethistoryOpen(false)}
                />
            )}
            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen norm kadro silinecektir, devam edilsin mi?
            </MessageBox>
            <MessageBox
                open={deletepromotionOpen}
                onClose={handleDeletePromotionDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen terfi talebi silinecektir, devam edilsin mi?
            </MessageBox>

        </DashboardLayout >
    );
}

export default PositionList;
