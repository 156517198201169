//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";

import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/numbered-text";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/history";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, SegmentedButton, SegmentedButtonItem, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetAdminUserApi, BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetNormCodeRequestApi, BudgetNormCodeRequestListDto, BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserInsertDto, BudgetPeriodUserUpdateDto, Configuration, Permission, RequestType, SFUSerList, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { getStatusText, isValidDate, parseDateString } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { getuid } from 'process';
import { uuid } from 'uuidv4';
import { useAlert } from '../hooks/useAlert'
import { useBusy } from '../hooks/useBusy'
import ShowHistory from '../Components/ShowHistory';
import { useNavigate } from 'react-router-dom';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");

// setTheme("sap_belize");
function AdminUserList() {
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");
    const [gridData, setGridData] = useState<BudgetNormCodeRequestListDto[]>([]);

    const navigate = useNavigate();
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()

    useEffect(() => {


        getData();

    }, []); //
    async function getData() {
        var api = new BudgetAdminUserApi(configuration);
        var data = await api.apiBudgetAdminUserGet();

        setGridData(data.data);
        console.log(data.data)

    }

    function onNew(): void {
        navigate("/AdminUserCreate")
    }
    function onEdit(obj: any): void {
        navigate("/AdminUserCreate?id=" + obj.cell.row.original.id);
        return;
    }
    async function onDelete(obj: any): Promise<void> {
        setdeleteOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetAdminUserApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetAdminUserIdDelete(selectedWorkFlowId)
            await getData();
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Admin Kullanıcılar':
                navigate("/AdminUserList")
                break;
            case "Admin Kullanıcı Oluştur":
                navigate("/AdminMailCreate")
                break;
            default:
                break;
        }

    }


    return (
        <DashboardLayout>


            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={<DynamicPageTitle actions={<><Button icon='add' onClick={onNew} design="Emphasized">Yeni</Button><Button design="Transparent" /></>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem>Admin Kullanıcılar</BreadcrumbsItem>
                        </Breadcrumbs>}
                    header={<Title>Admin Kullanıcılar</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>} subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'Kullanıcı Adı',
                            accessor: 'userName',
                            autoResizable: true,
                            width: 130
                        },
                        {
                            Header: 'Mail Adresi',
                            accessor: 'mail',
                            autoResizable: true,
                            width: 100
                        },
                        {
                            Header: 'Admin',
                            accessor: 'isAdmin',
                            autoResizable: true,
                            width: 100
                        },
                        {
                            Header: 'Proxy',
                            accessor: 'isDoProxy',
                            autoResizable: true,
                            width: 100
                        },
                        {
                            Header: 'Proxy User',
                            accessor: 'proxyUser',
                            autoResizable: true,
                            width: 100
                        },
                        {
                            Header: 'Admin Level',
                            accessor: 'adminLevel',
                            autoResizable: true,
                            width: 100,
                            Cell: ({ value }: any) => value==1 ? "Admin" : "PowerUser"
                        },
                        {
                            Header: 'Güncellenme Tarihi',
                            accessor: 'updatedDate',
                            autoResizable: true,
                            headerTooltip: 'updatedDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} />

                                    </FlexBox>
                                );
                            },

                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 85
                        },
                    ]}

                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="None"
                    tableHooks={[]}
                />
            </DynamicPage>

            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen kullanıcı tanımı silinecektir, devam edilsin mi?
            </MessageBox>
        </DashboardLayout>
    );
}
export default AdminUserList;

