/* tslint:disable */
/* eslint-disable */
/**
 * vesa.api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AdminLevel = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AdminLevel = typeof AdminLevel[keyof typeof AdminLevel];


/**
 * 
 * @export
 * @interface ApproveHeadInfo
 */
export interface ApproveHeadInfo {
    /**
     * 
     * @type {number}
     * @memberof ApproveHeadInfo
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveHeadInfo
     */
    'rejectCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveHeadInfo
     */
    'approveCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveHeadInfo
     */
    'sendCount'?: number;
    /**
     * 
     * @type {Array<ApproveItemsDto>}
     * @memberof ApproveHeadInfo
     */
    'items'?: Array<ApproveItemsDto> | null;
}
/**
 * 
 * @export
 * @interface ApproveItems
 */
export interface ApproveItems {
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'mainClientId'?: string | null;
    /**
     * 
     * @type {MainClient}
     * @memberof ApproveItems
     */
    'mainClient'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof ApproveItems
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'plantId'?: string | null;
    /**
     * 
     * @type {Plant}
     * @memberof ApproveItems
     */
    'plant'?: Plant;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'workflowItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'approveUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'approveUserNameSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'approvedUser_Runtime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'approvedUser_RuntimeNameSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'approvedUser_RuntimeNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItems
     */
    'workFlowDescription'?: string | null;
    /**
     * 
     * @type {ApproverStatus}
     * @memberof ApproveItems
     */
    'approverStatus'?: ApproverStatus;
    /**
     * 
     * @type {WorkflowItem}
     * @memberof ApproveItems
     */
    'workflowItem'?: WorkflowItem;
}


/**
 * 
 * @export
 * @interface ApproveItemsDto
 */
export interface ApproveItemsDto {
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'workflowItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'shortId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'shortWorkflowItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'approveUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'approvedUser_Runtime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'approvedUser_RuntimeNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'approveUserNameSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'approvedUser_RuntimeNameSurname'?: string | null;
    /**
     * 
     * @type {ApproverStatus}
     * @memberof ApproveItemsDto
     */
    'approverStatus'?: ApproverStatus;
    /**
     * 
     * @type {WorkFlowItemDto}
     * @memberof ApproveItemsDto
     */
    'workFlowItem'?: WorkFlowItemDto;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveItemsDto
     */
    'updatedDate'?: string;
}


/**
 * 
 * @export
 * @interface ApproveItemsDtoResult
 */
export interface ApproveItemsDtoResult {
    /**
     * 
     * @type {number}
     * @memberof ApproveItemsDtoResult
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ApproveItemsDto>}
     * @memberof ApproveItemsDtoResult
     */
    'approveItemsDtoList'?: Array<ApproveItemsDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApproverStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ApproverStatus = typeof ApproverStatus[keyof typeof ApproverStatus];


/**
 * 
 * @export
 * @interface BudgetAdminUserInsertDto
 */
export interface BudgetAdminUserInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserInsertDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserInsertDto
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserInsertDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserInsertDto
     */
    'isDoProxy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserInsertDto
     */
    'proxyUser'?: string | null;
    /**
     * 
     * @type {AdminLevel}
     * @memberof BudgetAdminUserInsertDto
     */
    'adminLevel'?: AdminLevel;
}


/**
 * 
 * @export
 * @interface BudgetAdminUserListDto
 */
export interface BudgetAdminUserListDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserListDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserListDto
     */
    'isDoProxy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'proxyUser'?: string | null;
    /**
     * 
     * @type {AdminLevel}
     * @memberof BudgetAdminUserListDto
     */
    'adminLevel'?: AdminLevel;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'adminLevelText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserListDto
     */
    'updatedDate'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetAdminUserUpdateDto
 */
export interface BudgetAdminUserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserUpdateDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserUpdateDto
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserUpdateDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetAdminUserUpdateDto
     */
    'isDoProxy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetAdminUserUpdateDto
     */
    'proxyUser'?: string | null;
    /**
     * 
     * @type {AdminLevel}
     * @memberof BudgetAdminUserUpdateDto
     */
    'adminLevel'?: AdminLevel;
}


/**
 * 
 * @export
 * @interface BudgetJobCodeRequestInsertDto
 */
export interface BudgetJobCodeRequestInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'jobCode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name_En': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'requestReason': string;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name_Ru_RU': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name_Tr_TR': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'name_En_US': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'description_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'description_En_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'description_Ru_RU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'description_Tr_TR'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'isFullTime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'regularTemporary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'defaultEmployeeClass': string;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'isFulltimeEmployee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'grade': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'jobFunction': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'positionLevel': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Joblevelgroup': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Metin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Jobcode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_AdinesStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_EmploymentType': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_GorevBirimTipi': string;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_IsManager'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Bolum': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Ronesanskademe': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestInsertDto
     */
    'cust_Haykademe': string;
}
/**
 * 
 * @export
 * @interface BudgetJobCodeRequestListDto
 */
export interface BudgetJobCodeRequestListDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'jobCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name_En'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'requestReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestListDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'workflowHeadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name_Ru_RU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name_Tr_TR'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'name_En_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'description_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'description_En_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'description_Ru_RU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'description_Tr_TR'?: string | null;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof BudgetJobCodeRequestListDto
     */
    'workflowHead'?: WorkflowHead;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestListDto
     */
    'isFullTime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'regularTemporary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'defaultEmployeeClass'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestListDto
     */
    'isFulltimeEmployee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'grade'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'jobFunction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'positionLevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Joblevelgroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Metin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Jobcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_AdinesStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_EmploymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_GorevBirimTipi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_IsManager'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Bolum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Ronesanskademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'cust_Haykademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestListDto
     */
    'updatedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetJobCodeRequestListDtoResult
 */
export interface BudgetJobCodeRequestListDtoResult {
    /**
     * 
     * @type {Array<BudgetJobCodeRequestListDto>}
     * @memberof BudgetJobCodeRequestListDtoResult
     */
    'budgetJobCodeRequestListDtoList'?: Array<BudgetJobCodeRequestListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BudgetJobCodeRequestListDtoResult
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface BudgetJobCodeRequestUpdateDto
 */
export interface BudgetJobCodeRequestUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'jobCode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name_En': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'requestReason': string;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name_Ru_RU': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name_Tr_TR': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'name_En_US': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'description_En_Debug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'description_En_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'description_Ru_RU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'description_Tr_TR'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'isFullTime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'regularTemporary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'defaultEmployeeClass': string;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'isFulltimeEmployee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'grade': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'jobFunction': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'positionLevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Joblevelgroup': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Metin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Jobcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_AdinesStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_EmploymentType': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_GorevBirimTipi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_IsManager'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Bolum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Ronesanskademe': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetJobCodeRequestUpdateDto
     */
    'cust_Haykademe': string;
}
/**
 * 
 * @export
 * @interface BudgetNormCodeRequestInsertDto
 */
export interface BudgetNormCodeRequestInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'effectiveStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_IseBaslamaTarihi'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_PlanlananIseGiris'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_plannedEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_actualhiredate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'effectiveStatus'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'vacant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'changeReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_GeoZone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'externalName_tr_TR'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'externalName_defaultValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'externalName_en_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'externalName_en_DEBUG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'externalName_ru_RU'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'multipleIncumbentsAllowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'targetFTE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'standardHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'jobCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_jobfunction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_ronesansjoblevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_ronesansKademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'payGrade'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'employeeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_empSubGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_EmpGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_companyGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_customlegalEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'businessUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_sub_division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_parentDepartment2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_parentDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_locationGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_calismaYeriTuru'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_payGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_isAlani'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_phisicalLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_ticket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_HayKademe'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'cust_ChiefPosition'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'parentPosition'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'processType'?: ProcessType;
    /**
     * 
     * @type {InternalEmploymentType}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'internalEmploymentType'?: InternalEmploymentType;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'isInternalSource'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'relationManager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'relationEmployess'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'hardware'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'licence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'internalSourceEmp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'jobCodeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'isDeleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'promotionPeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'promotionPeriodTxt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestInsertDto
     */
    'propotionReasonTxt'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetNormCodeRequestListDto
 */
export interface BudgetNormCodeRequestListDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'effectiveStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_IseBaslamaTarihi'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_PlanlananIseGiris'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_plannedEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_actualhiredate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'effectiveStatus'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'vacant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'changeReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_GeoZone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'externalName_tr_TR'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'externalName_defaultValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'externalName_en_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'externalName_en_DEBUG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'externalName_ru_RU'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'multipleIncumbentsAllowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'targetFTE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'standardHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'jobCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_jobfunction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_ronesansjoblevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_ronesansKademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'payGrade'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'employeeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_empSubGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_EmpGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_companyGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_customlegalEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'businessUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_sub_division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_parentDepartment2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_parentDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_locationGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_calismaYeriTuru'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_payGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_isAlani'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_phisicalLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_ticket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_HayKademe'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'cust_ChiefPosition'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'parentPosition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof BudgetNormCodeRequestListDto
     */
    'workflowHead'?: WorkflowHead;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetNormCodeRequestListDto
     */
    'processType'?: ProcessType;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'processTypeText'?: string | null;
    /**
     * 
     * @type {InternalEmploymentType}
     * @memberof BudgetNormCodeRequestListDto
     */
    'internalEmploymentType'?: InternalEmploymentType;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'internalEmploymentTypeText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'isInternalSource'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'relationManager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'relationEmployess'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'hardware'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'licence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'internalSourceEmp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'jobCodeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'promotionPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestListDto
     */
    'isDeleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'promotionPeriodTxt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListDto
     */
    'propotionReasonTxt'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetNormCodeRequestListDtoOnlyCodeResult
 */
export interface BudgetNormCodeRequestListDtoOnlyCodeResult {
    /**
     * 
     * @type {Array<BudgetNormCodeRequestListOnlyCodeDto>}
     * @memberof BudgetNormCodeRequestListDtoOnlyCodeResult
     */
    'budgetNormCodeRequestListDtoList'?: Array<BudgetNormCodeRequestListOnlyCodeDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BudgetNormCodeRequestListDtoOnlyCodeResult
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface BudgetNormCodeRequestListDtoResult
 */
export interface BudgetNormCodeRequestListDtoResult {
    /**
     * 
     * @type {Array<BudgetNormCodeRequestListDto>}
     * @memberof BudgetNormCodeRequestListDtoResult
     */
    'budgetNormCodeRequestListDtoList'?: Array<BudgetNormCodeRequestListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BudgetNormCodeRequestListDtoResult
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface BudgetNormCodeRequestListOnlyCodeDto
 */
export interface BudgetNormCodeRequestListOnlyCodeDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListOnlyCodeDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestListOnlyCodeDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof BudgetNormCodeRequestListOnlyCodeDto
     */
    'workflowHead'?: WorkflowHead;
}
/**
 * 
 * @export
 * @interface BudgetNormCodeRequestUpdateDto
 */
export interface BudgetNormCodeRequestUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'effectiveStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_IseBaslamaTarihi'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_PlanlananIseGiris'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_plannedEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_actualhiredate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'effectiveStatus'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'vacant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'changeReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_GeoZone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'externalName_tr_TR'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'externalName_defaultValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'externalName_en_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'externalName_en_DEBUG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'externalName_ru_RU'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'multipleIncumbentsAllowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'targetFTE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'standardHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'jobCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_jobfunction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_ronesansjoblevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_ronesansKademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'payGrade'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'employeeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_empSubGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_EmpGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_companyGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_customlegalEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'businessUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_sub_division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_parentDepartment2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_parentDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_locationGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_calismaYeriTuru'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_payGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_isAlani'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_phisicalLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_ticket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_HayKademe'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'cust_ChiefPosition'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'parentPosition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'processType'?: ProcessType;
    /**
     * 
     * @type {InternalEmploymentType}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'internalEmploymentType'?: InternalEmploymentType;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'isInternalSource'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'relationManager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'relationEmployess'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'hardware'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'licence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'internalSourceEmp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'jobCodeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'promotionPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'isDeleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'promotionPeriodTxt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetNormCodeRequestUpdateDto
     */
    'propotionReasonTxt'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetPeriodInsertDto
 */
export interface BudgetPeriodInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodInsertDto
     */
    'periodCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodInsertDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodInsertDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodInsertDto
     */
    'enDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodInsertDto
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetPeriodListDto
 */
export interface BudgetPeriodListDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'periodCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'enDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodListDto
     */
    'updatedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetPeriodUpdateDto
 */
export interface BudgetPeriodUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'periodCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'enDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUpdateDto
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetPeriodUserInsertDto
 */
export interface BudgetPeriodUserInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserInsertDto
     */
    'budgetPeriodCode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserInsertDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {RequestType}
     * @memberof BudgetPeriodUserInsertDto
     */
    'requestType'?: RequestType;
    /**
     * 
     * @type {Permission}
     * @memberof BudgetPeriodUserInsertDto
     */
    'permission'?: Permission;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetPeriodUserInsertDto
     */
    'processType'?: ProcessType;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserInsertDto
     */
    'nameSurname'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetPeriodUserListDto
 */
export interface BudgetPeriodUserListDto {
    /**
     * 
     * @type {BudgetPeriodListDto}
     * @memberof BudgetPeriodUserListDto
     */
    'budgetPeriod'?: BudgetPeriodListDto;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'budgetPeriodCode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {RequestType}
     * @memberof BudgetPeriodUserListDto
     */
    'requestType'?: RequestType;
    /**
     * 
     * @type {Permission}
     * @memberof BudgetPeriodUserListDto
     */
    'permission'?: Permission;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetPeriodUserListDto
     */
    'processType'?: ProcessType;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'requestTypeText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'permissiontypeText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'processtypeText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserListDto
     */
    'nameSurname'?: string | null;
}


/**
 * 
 * @export
 * @interface BudgetPeriodUserUpdateDto
 */
export interface BudgetPeriodUserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'budgetPeriodCode': string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {RequestType}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'requestType'?: RequestType;
    /**
     * 
     * @type {Permission}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'permission'?: Permission;
    /**
     * 
     * @type {ProcessType}
     * @memberof BudgetPeriodUserUpdateDto
     */
    'processType'?: ProcessType;
}


/**
 * 
 * @export
 * @interface BudgetPromotionRequestInsertDto
 */
export interface BudgetPromotionRequestInsertDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'empCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'positionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'promotionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'managerUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestInsertDto
     */
    'teamUsers'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetPromotionRequestListDto
 */
export interface BudgetPromotionRequestListDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'empCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'positionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'promotionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof BudgetPromotionRequestListDto
     */
    'workflowHead'?: WorkflowHead;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'managerUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestListDto
     */
    'teamUsers'?: string | null;
}
/**
 * 
 * @export
 * @interface BudgetPromotionRequestUpdateDto
 */
export interface BudgetPromotionRequestUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'empCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'positionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'promotionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'workflowHeadId'?: string | null;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'workflowHead'?: WorkflowHead;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'isSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'managerUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetPromotionRequestUpdateDto
     */
    'teamUsers'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientLoginDto
 */
export interface ClientLoginDto {
    /**
     * 
     * @type {string}
     * @memberof ClientLoginDto
     */
    'clientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientLoginDto
     */
    'clientSecret'?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'clientId': string;
    /**
     * 
     * @type {MainClient}
     * @memberof Company
     */
    'client'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {Array<Plant>}
     * @memberof Company
     */
    'plant'?: Array<Plant> | null;
}
/**
 * 
 * @export
 * @interface CustCompanyGroupDto
 */
export interface CustCompanyGroupDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof CustCompanyGroupDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof CustCompanyGroupDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CustCompanyGroupList>}
     * @memberof CustCompanyGroupDto
     */
    'cust_companyGroupList'?: Array<CustCompanyGroupList> | null;
}
/**
 * 
 * @export
 * @interface CustCompanyGroupList
 */
export interface CustCompanyGroupList {
    /**
     * 
     * @type {string}
     * @memberof CustCompanyGroupList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustCompanyGroupList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CustLegalEntityDto
 */
export interface CustLegalEntityDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof CustLegalEntityDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof CustLegalEntityDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CustLegalEntityList>}
     * @memberof CustLegalEntityDto
     */
    'cust_legalEntityList'?: Array<CustLegalEntityList> | null;
}
/**
 * 
 * @export
 * @interface CustLegalEntityList
 */
export interface CustLegalEntityList {
    /**
     * 
     * @type {string}
     * @memberof CustLegalEntityList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustLegalEntityList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CustSubDivisioList
 */
export interface CustSubDivisioList {
    /**
     * 
     * @type {string}
     * @memberof CustSubDivisioList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustSubDivisioList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CustSubDivisionDto
 */
export interface CustSubDivisionDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof CustSubDivisionDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof CustSubDivisionDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CustSubDivisioList>}
     * @memberof CustSubDivisionDto
     */
    'cust_sub_divisioList'?: Array<CustSubDivisioList> | null;
}
/**
 * 
 * @export
 * @interface FOBusinessUnitDto
 */
export interface FOBusinessUnitDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOBusinessUnitDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOBusinessUnitDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOBusinessUnitList>}
     * @memberof FOBusinessUnitDto
     */
    'foBusinessUnitList'?: Array<FOBusinessUnitList> | null;
}
/**
 * 
 * @export
 * @interface FOBusinessUnitList
 */
export interface FOBusinessUnitList {
    /**
     * 
     * @type {string}
     * @memberof FOBusinessUnitList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOBusinessUnitList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOCompanyDto
 */
export interface FOCompanyDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOCompanyDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOCompanyDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOCompanyDtoList>}
     * @memberof FOCompanyDto
     */
    'foCompanyDtoList'?: Array<FOCompanyDtoList> | null;
}
/**
 * 
 * @export
 * @interface FOCompanyDtoList
 */
export interface FOCompanyDtoList {
    /**
     * 
     * @type {string}
     * @memberof FOCompanyDtoList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOCompanyDtoList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FODepartmentList
 */
export interface FODepartmentList {
    /**
     * 
     * @type {string}
     * @memberof FODepartmentList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FODepartmentList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FODepartmentSFDto
 */
export interface FODepartmentSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FODepartmentSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FODepartmentSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FODepartmentList>}
     * @memberof FODepartmentSFDto
     */
    'foDepartmentList'?: Array<FODepartmentList> | null;
}
/**
 * 
 * @export
 * @interface FODivisionDto
 */
export interface FODivisionDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FODivisionDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FODivisionDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FODivisionList>}
     * @memberof FODivisionDto
     */
    'foDivisionList'?: Array<FODivisionList> | null;
}
/**
 * 
 * @export
 * @interface FODivisionList
 */
export interface FODivisionList {
    /**
     * 
     * @type {string}
     * @memberof FODivisionList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FODivisionList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOGeozoneDto
 */
export interface FOGeozoneDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOGeozoneDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOGeozoneDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOGeozoneDtoList>}
     * @memberof FOGeozoneDto
     */
    'foGeozoneDtoList'?: Array<FOGeozoneDtoList> | null;
}
/**
 * 
 * @export
 * @interface FOGeozoneDtoList
 */
export interface FOGeozoneDtoList {
    /**
     * 
     * @type {string}
     * @memberof FOGeozoneDtoList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOGeozoneDtoList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOJobCodeDto
 */
export interface FOJobCodeDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOJobCodeDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOJobCodeDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOJobCodeList>}
     * @memberof FOJobCodeDto
     */
    'foJobCodeList'?: Array<FOJobCodeList> | null;
}
/**
 * 
 * @export
 * @interface FOJobCodeList
 */
export interface FOJobCodeList {
    /**
     * 
     * @type {string}
     * @memberof FOJobCodeList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOJobCodeList
     */
    'externalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOJobCodeList
     */
    'cust_ronesanskademe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOJobCodeList
     */
    'cust_joblevelgroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOJobCodeList
     */
    'grade'?: string | null;
}
/**
 * 
 * @export
 * @interface FOJobFunctionList
 */
export interface FOJobFunctionList {
    /**
     * 
     * @type {string}
     * @memberof FOJobFunctionList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOJobFunctionList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOJobFunctionSFDto
 */
export interface FOJobFunctionSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOJobFunctionSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOJobFunctionSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOJobFunctionList>}
     * @memberof FOJobFunctionSFDto
     */
    'foJobFunctionList'?: Array<FOJobFunctionList> | null;
}
/**
 * 
 * @export
 * @interface FOLocationGroupList
 */
export interface FOLocationGroupList {
    /**
     * 
     * @type {string}
     * @memberof FOLocationGroupList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOLocationGroupList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOLocationGroupSFDTO
 */
export interface FOLocationGroupSFDTO {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOLocationGroupSFDTO
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOLocationGroupSFDTO
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOLocationGroupList>}
     * @memberof FOLocationGroupSFDTO
     */
    'foLocationGroupList'?: Array<FOLocationGroupList> | null;
}
/**
 * 
 * @export
 * @interface FOLocationList
 */
export interface FOLocationList {
    /**
     * 
     * @type {string}
     * @memberof FOLocationList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOLocationList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOLocationSFDto
 */
export interface FOLocationSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOLocationSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOLocationSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOLocationList>}
     * @memberof FOLocationSFDto
     */
    'foLocationList'?: Array<FOLocationList> | null;
}
/**
 * 
 * @export
 * @interface FOPayGradeList
 */
export interface FOPayGradeList {
    /**
     * 
     * @type {string}
     * @memberof FOPayGradeList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOPayGradeList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FOPayGradeSFDto
 */
export interface FOPayGradeSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOPayGradeSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOPayGradeSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOPayGradeList>}
     * @memberof FOPayGradeSFDto
     */
    'foPayGradeList'?: Array<FOPayGradeList> | null;
}
/**
 * 
 * @export
 * @interface FOPayGroupSFDto
 */
export interface FOPayGroupSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof FOPayGroupSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof FOPayGroupSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FOPayGroupSFList>}
     * @memberof FOPayGroupSFDto
     */
    'foPayGroupSFList'?: Array<FOPayGroupSFList> | null;
}
/**
 * 
 * @export
 * @interface FOPayGroupSFList
 */
export interface FOPayGroupSFList {
    /**
     * 
     * @type {string}
     * @memberof FOPayGroupSFList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FOPayGroupSFList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'mainClientId'?: string | null;
    /**
     * 
     * @type {MainClient}
     * @memberof Form
     */
    'mainClient'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof Form
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'plantId'?: string | null;
    /**
     * 
     * @type {Plant}
     * @memberof Form
     */
    'plant'?: Plant;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'formName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'formDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'revision'?: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    'formDesign'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    'isActive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GenericList = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19
} as const;

export type GenericList = typeof GenericList[keyof typeof GenericList];


/**
 * 
 * @export
 * @interface HardwareList
 */
export interface HardwareList {
    /**
     * 
     * @type {string}
     * @memberof HardwareList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HardwareList
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface IGenericListDto
 */
export interface IGenericListDto {
    /**
     * 
     * @type {number}
     * @memberof IGenericListDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof IGenericListDto
     */
    'list'?: Array<any> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InternalEmploymentType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type InternalEmploymentType = typeof InternalEmploymentType[keyof typeof InternalEmploymentType];


/**
 * 
 * @export
 * @interface LicenceList
 */
export interface LicenceList {
    /**
     * 
     * @type {string}
     * @memberof LicenceList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenceList
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface MainClient
 */
export interface MainClient {
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof MainClient
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MainClient
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<Company>}
     * @memberof MainClient
     */
    'companies'?: Array<Company> | null;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'uri'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Permission = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type Permission = typeof Permission[keyof typeof Permission];


/**
 * 
 * @export
 * @enum {string}
 */

export const PickList = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15
} as const;

export type PickList = typeof PickList[keyof typeof PickList];


/**
 * 
 * @export
 * @interface PickListDto
 */
export interface PickListDto {
    /**
     * 
     * @type {string}
     * @memberof PickListDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickListDto
     */
    'externalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickListDto
     */
    'label_tr_TR'?: string | null;
}
/**
 * 
 * @export
 * @interface Plant
 */
export interface Plant {
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    'companyId': string;
    /**
     * 
     * @type {Company}
     * @memberof Plant
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    'updatedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface PositionSFDto
 */
export interface PositionSFDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof PositionSFDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof PositionSFDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SFPositionList>}
     * @memberof PositionSFDto
     */
    'sfPositionList'?: Array<SFPositionList> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProcessType = typeof ProcessType[keyof typeof ProcessType];


/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RequestType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type RequestType = typeof RequestType[keyof typeof RequestType];


/**
 * 
 * @export
 * @interface SFEmpJobDto
 */
export interface SFEmpJobDto {
    /**
     * 
     * @type {string}
     * @memberof SFEmpJobDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFEmpJobDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFEmpJobDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFEmpJobDto
     */
    'managerId'?: string | null;
}
/**
 * 
 * @export
 * @interface SFFOCostCenterDto
 */
export interface SFFOCostCenterDto {
    /**
     * 
     * @type {Array<any>}
     * @memberof SFFOCostCenterDto
     */
    'list'?: Array<any> | null;
    /**
     * 
     * @type {number}
     * @memberof SFFOCostCenterDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SFFOCostCenterList>}
     * @memberof SFFOCostCenterDto
     */
    'sffoCostCenterList'?: Array<SFFOCostCenterList> | null;
}
/**
 * 
 * @export
 * @interface SFFOCostCenterList
 */
export interface SFFOCostCenterList {
    /**
     * 
     * @type {string}
     * @memberof SFFOCostCenterList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFFOCostCenterList
     */
    'externalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface SFPositionAllPropertyDto
 */
export interface SFPositionAllPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof SFPositionAllPropertyDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionAllPropertyDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionAllPropertyDto
     */
    'effectiveStartDate'?: string | null;
}
/**
 * 
 * @export
 * @interface SFPositionDto
 */
export interface SFPositionDto {
    /**
     * 
     * @type {Metadata}
     * @memberof SFPositionDto
     */
    '__metadata'?: Metadata;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'effectiveStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_jobfunction'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_calismaYeriTuru'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customlegalEntity'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_integrationCheck'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'createdDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'jobCode'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'mdfSystemVersionId'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_EmpGroup'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_plannedEndDate'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'legacyPositionId'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'externalName_localized'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'mdfSystemRecordStatus'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_CompanyGroup8'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof SFPositionDto
     */
    'vacant'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_locationGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'externalName_tr_TR'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_IseBaslamaTarihi'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'positionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_legalEntityOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'externalName_defaultValue'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_incumbent'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'payGrade'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_ticket'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_orgChartNameText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'mdfSystemObjectType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'creationSource'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_actualhiredate'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_tr_TR'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'targetFTE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'externalName_ru_RU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_defaultValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_hasChildPosition'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'jobLevel'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'mdfSystemRecordId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_CompanyGroup6Org'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof SFPositionDto
     */
    'multipleIncumbentsAllowed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'businessUnit'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_parentDepartment'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'lastModifiedDateTime'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_businessUnitOrg'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'criticality'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_en_DEBUG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_ronesansjoblevel'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'incumbent'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_subDivisionOrg'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_phisicalLocation'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'mdfSystemEntityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_companyGroup'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'payRange'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_DivisionOrg'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'regularTemporary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORG'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_legalEntityOrg'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_sub_division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'standardHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_departmentOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_businessUnitOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'effectiveStatus'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_sidebenefit'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'technicalParameters'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_incumbentName'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_PlanlananIseGiris'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_ChiefPosition'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_CompanyGroup4Org'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_empSubGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_en_US'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_ru_RU'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_payGroup'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'effectiveEndDate'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'positionCriticality'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_positionstartdate'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'description'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_parentDepartment2'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'positionControlled'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_parentDepartmentOrg'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_GeoZone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_HayKademe'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_departmentOrg'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_ronesansKademe'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'employeeClass'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_isAlani'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_parentDepartmentOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_subDivisionOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'changeReason'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_workArea'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'lastModifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'lastModifiedDateWithTZ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'transactionSequence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_customORGLabel_localized'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'cust_divisonOrgCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'mdfSystemOptimisticLockUUID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'location'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'cust_jobFamily'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'externalName_en_US'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof SFPositionDto
     */
    'externalName_en_DEBUG'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'parentPositionTxt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionDto
     */
    'parentPositionValue'?: string | null;
}
/**
 * 
 * @export
 * @interface SFPositionList
 */
export interface SFPositionList {
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'externalCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SFPositionList
     */
    'vacant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'cust_plannedEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'cust_IseBaslamaTarihi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'businessUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'cust_customlegalEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'cust_sub_division'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFPositionList
     */
    'department'?: string | null;
}
/**
 * 
 * @export
 * @interface SFUSerList
 */
export interface SFUSerList {
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'defaultFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SFUSerList
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSFListDto
 */
export interface UserSFListDto {
    /**
     * 
     * @type {number}
     * @memberof UserSFListDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SFUSerList>}
     * @memberof UserSFListDto
     */
    'sfuSerList'?: Array<SFUSerList> | null;
}
/**
 * 
 * @export
 * @interface WorkFlowContiuneApiDto
 */
export interface WorkFlowContiuneApiDto {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowContiuneApiDto
     */
    'approveItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowContiuneApiDto
     */
    'workFlowItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowContiuneApiDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowContiuneApiDto
     */
    'input'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowContiuneApiDto
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkFlowDefination
 */
export interface WorkFlowDefination {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'mainClientId'?: string | null;
    /**
     * 
     * @type {MainClient}
     * @memberof WorkFlowDefination
     */
    'mainClient'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof WorkFlowDefination
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'plantId'?: string | null;
    /**
     * 
     * @type {Plant}
     * @memberof WorkFlowDefination
     */
    'plant'?: Plant;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'defination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkFlowDefination
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkFlowDefination
     */
    'revision'?: number;
    /**
     * 
     * @type {Array<WorkflowHead>}
     * @memberof WorkFlowDefination
     */
    'workflows'?: Array<WorkflowHead> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefination
     */
    'formId'?: string | null;
    /**
     * 
     * @type {Form}
     * @memberof WorkFlowDefination
     */
    'form'?: Form;
}
/**
 * 
 * @export
 * @interface WorkFlowDefinationInsertDto
 */
export interface WorkFlowDefinationInsertDto {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationInsertDto
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationInsertDto
     */
    'defination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkFlowDefinationInsertDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkFlowDefinationInsertDto
     */
    'revision'?: number;
}
/**
 * 
 * @export
 * @interface WorkFlowDefinationListDto
 */
export interface WorkFlowDefinationListDto {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationListDto
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationListDto
     */
    'defination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkFlowDefinationListDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkFlowDefinationListDto
     */
    'revision'?: number;
}
/**
 * 
 * @export
 * @interface WorkFlowDefinationUpdateDto
 */
export interface WorkFlowDefinationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationUpdateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationUpdateDto
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowDefinationUpdateDto
     */
    'defination'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkFlowDefinationUpdateDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkFlowDefinationUpdateDto
     */
    'revision'?: number;
}
/**
 * 
 * @export
 * @interface WorkFlowHeadDtoResultStartOrContinue
 */
export interface WorkFlowHeadDtoResultStartOrContinue {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoResultStartOrContinue
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoResultStartOrContinue
     */
    'workFlowInfo'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkFlowHeadDtoWithoutItems
 */
export interface WorkFlowHeadDtoWithoutItems {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'currentNodeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'currentNodeName'?: string | null;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'workFlowStatus'?: WorkflowStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'createUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'workFlowDefinationId'?: string;
    /**
     * 
     * @type {WorkFlowDefination}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'workFlowDefination'?: WorkFlowDefination;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowHeadDtoWithoutItems
     */
    'workFlowInfo'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkFlowItemDto
 */
export interface WorkFlowItemDto {
    /**
     * 
     * @type {WorkFlowHeadDtoWithoutItems}
     * @memberof WorkFlowItemDto
     */
    'workflowHead'?: WorkFlowHeadDtoWithoutItems;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDto
     */
    'nodeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDto
     */
    'nodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDto
     */
    'nodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDto
     */
    'nodeDescription'?: string | null;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkFlowItemDto
     */
    'workFlowNodeStatus'?: WorkflowStatus;
}


/**
 * 
 * @export
 * @interface WorkFlowItemDtoWithApproveItems
 */
export interface WorkFlowItemDtoWithApproveItems {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'nodeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'nodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'nodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'nodeDescription'?: string | null;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'workFlowNodeStatus'?: WorkflowStatus;
    /**
     * 
     * @type {Array<ApproveItemsDto>}
     * @memberof WorkFlowItemDtoWithApproveItems
     */
    'approveItems'?: Array<ApproveItemsDto> | null;
}


/**
 * 
 * @export
 * @interface WorkFlowStartApiDto
 */
export interface WorkFlowStartApiDto {
    /**
     * 
     * @type {string}
     * @memberof WorkFlowStartApiDto
     */
    'workFlowInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowStartApiDto
     */
    'definationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkFlowStartApiDto
     */
    'userName'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkflowHead
 */
export interface WorkflowHead {
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'mainClientId'?: string | null;
    /**
     * 
     * @type {MainClient}
     * @memberof WorkflowHead
     */
    'mainClient'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof WorkflowHead
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'plantId'?: string | null;
    /**
     * 
     * @type {Plant}
     * @memberof WorkflowHead
     */
    'plant'?: Plant;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'workflowName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'currentNodeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'currentNodeName'?: string | null;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkflowHead
     */
    'workFlowStatus'?: WorkflowStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'workFlowInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'createUser'?: string | null;
    /**
     * 
     * @type {Array<WorkflowItem>}
     * @memberof WorkflowHead
     */
    'workflowItems'?: Array<WorkflowItem> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'workFlowDefinationId'?: string;
    /**
     * 
     * @type {WorkFlowDefination}
     * @memberof WorkflowHead
     */
    'workFlowDefination'?: WorkFlowDefination;
    /**
     * 
     * @type {string}
     * @memberof WorkflowHead
     */
    'workFlowDefinationJson'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkflowItem
 */
export interface WorkflowItem {
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'mainClientId'?: string | null;
    /**
     * 
     * @type {MainClient}
     * @memberof WorkflowItem
     */
    'mainClient'?: MainClient;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof WorkflowItem
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'plantId'?: string | null;
    /**
     * 
     * @type {Plant}
     * @memberof WorkflowItem
     */
    'plant'?: Plant;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'workflowHeadId'?: string;
    /**
     * 
     * @type {WorkflowHead}
     * @memberof WorkflowItem
     */
    'workflowHead'?: WorkflowHead;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'nodeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'nodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'nodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowItem
     */
    'nodeDescription'?: string | null;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkflowItem
     */
    'workFlowNodeStatus'?: WorkflowStatus;
    /**
     * 
     * @type {Array<ApproveItems>}
     * @memberof WorkflowItem
     */
    'approveItems'?: Array<ApproveItems> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkflowStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type WorkflowStatus = typeof WorkflowStatus[keyof typeof WorkflowStatus];



/**
 * ApproveItemsApi - axios parameter creator
 * @export
 */
export const ApproveItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsAllGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApproveItems/All`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {ApproverStatus} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetAdminApprovesUserNameGet: async (userName: string, type?: ApproverStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('apiApproveItemsGetAdminApprovesUserNameGet', 'userName', userName)
            const localVarPath = `/api/ApproveItems/GetAdminApproves/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [username] 
         * @param {ApproverStatus} [type] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [workFlowDefinationId] 
         * @param {string} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetApprovesUserNameGet: async (userName: string, username?: string, type?: ApproverStatus, skip?: number, top?: number, workFlowDefinationId?: string, createUser?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('apiApproveItemsGetApprovesUserNameGet', 'userName', userName)
            const localVarPath = `/api/ApproveItems/GetApproves/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (workFlowDefinationId !== undefined) {
                localVarQueryParameter['WorkFlowDefinationId'] = workFlowDefinationId;
            }

            if (createUser !== undefined) {
                localVarQueryParameter['createUser'] = createUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetByIdIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApproveItemsGetByIdIdGet', 'id', id)
            const localVarPath = `/api/ApproveItems/GetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetOpenApprovesUserNameGet: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('apiApproveItemsGetOpenApprovesUserNameGet', 'userName', userName)
            const localVarPath = `/api/ApproveItems/GetOpenApproves/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetOpenDetailGetOpenDetailGet: async (workFlowId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApproveItems/GetOpenDetail/GetOpenDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workFlowId !== undefined) {
                localVarQueryParameter['workFlowId'] = workFlowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApproveItemsApi - functional programming interface
 * @export
 */
export const ApproveItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApproveItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsAllGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveHeadInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsAllGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {ApproverStatus} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsGetAdminApprovesUserNameGet(userName: string, type?: ApproverStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApproveItemsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsGetAdminApprovesUserNameGet(userName, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsGetAdminApprovesUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [username] 
         * @param {ApproverStatus} [type] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [workFlowDefinationId] 
         * @param {string} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsGetApprovesUserNameGet(userName: string, username?: string, type?: ApproverStatus, skip?: number, top?: number, workFlowDefinationId?: string, createUser?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveItemsDtoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsGetApprovesUserNameGet(userName, username, type, skip, top, workFlowDefinationId, createUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsGetApprovesUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsGetByIdIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveItemsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsGetByIdIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsGetByIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsGetOpenApprovesUserNameGet(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApproveItemsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsGetOpenApprovesUserNameGet(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsGetOpenApprovesUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApproveItemsApi.apiApproveItemsGetOpenDetailGetOpenDetailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApproveItemsApi - factory interface
 * @export
 */
export const ApproveItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApproveItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsAllGet(options?: any): AxiosPromise<ApproveHeadInfo> {
            return localVarFp.apiApproveItemsAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {ApproverStatus} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetAdminApprovesUserNameGet(userName: string, type?: ApproverStatus, options?: any): AxiosPromise<Array<ApproveItemsDto>> {
            return localVarFp.apiApproveItemsGetAdminApprovesUserNameGet(userName, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [username] 
         * @param {ApproverStatus} [type] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [workFlowDefinationId] 
         * @param {string} [createUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetApprovesUserNameGet(userName: string, username?: string, type?: ApproverStatus, skip?: number, top?: number, workFlowDefinationId?: string, createUser?: string, options?: any): AxiosPromise<ApproveItemsDtoResult> {
            return localVarFp.apiApproveItemsGetApprovesUserNameGet(userName, username, type, skip, top, workFlowDefinationId, createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetByIdIdGet(id: string, options?: any): AxiosPromise<ApproveItemsDto> {
            return localVarFp.apiApproveItemsGetByIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetOpenApprovesUserNameGet(userName: string, options?: any): AxiosPromise<Array<ApproveItemsDto>> {
            return localVarFp.apiApproveItemsGetOpenApprovesUserNameGet(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApproveItemsApi - object-oriented interface
 * @export
 * @class ApproveItemsApi
 * @extends {BaseAPI}
 */
export class ApproveItemsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsAllGet(options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {ApproverStatus} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsGetAdminApprovesUserNameGet(userName: string, type?: ApproverStatus, options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsGetAdminApprovesUserNameGet(userName, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {string} [username] 
     * @param {ApproverStatus} [type] 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [workFlowDefinationId] 
     * @param {string} [createUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsGetApprovesUserNameGet(userName: string, username?: string, type?: ApproverStatus, skip?: number, top?: number, workFlowDefinationId?: string, createUser?: string, options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsGetApprovesUserNameGet(userName, username, type, skip, top, workFlowDefinationId, createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsGetByIdIdGet(id: string, options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsGetByIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsGetOpenApprovesUserNameGet(userName: string, options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsGetOpenApprovesUserNameGet(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [workFlowId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApproveItemsApi
     */
    public apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId?: string, options?: RawAxiosRequestConfig) {
        return ApproveItemsApiFp(this.configuration).apiApproveItemsGetOpenDetailGetOpenDetailGet(workFlowId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ClientLoginDto} [clientLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenByClientPost: async (clientLoginDto?: ClientLoginDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/CreateTokenByClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenByRefreshTokenPost: async (refreshTokenDto?: RefreshTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/CreateTokenByRefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenPost: async (loginDto?: LoginDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/CreateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRevokeRefreshTokenPost: async (refreshTokenDto?: RefreshTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/RevokeRefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ClientLoginDto} [clientLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthCreateTokenByClientPost(clientLoginDto?: ClientLoginDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthCreateTokenByClientPost(clientLoginDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.apiAuthCreateTokenByClientPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.apiAuthCreateTokenByRefreshTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthCreateTokenPost(loginDto?: LoginDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthCreateTokenPost(loginDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.apiAuthCreateTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRevokeRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRevokeRefreshTokenPost(refreshTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.apiAuthRevokeRefreshTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientLoginDto} [clientLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenByClientPost(clientLoginDto?: ClientLoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthCreateTokenByClientPost(clientLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCreateTokenPost(loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthCreateTokenPost(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenDto} [refreshTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRevokeRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthRevokeRefreshTokenPost(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {ClientLoginDto} [clientLoginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthCreateTokenByClientPost(clientLoginDto?: ClientLoginDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthCreateTokenByClientPost(clientLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenDto} [refreshTokenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthCreateTokenByRefreshTokenPost(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthCreateTokenPost(loginDto?: LoginDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthCreateTokenPost(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenDto} [refreshTokenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthRevokeRefreshTokenPost(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthRevokeRefreshTokenPost(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetAdminUserApi - axios parameter creator
 * @export
 */
export const BudgetAdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userid 
         * @param {string} proxyUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserCheckProxyUserUseridproxyUserGet: async (userid: string, proxyUser: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('apiBudgetAdminUserCheckProxyUserUseridproxyUserGet', 'userid', userid)
            // verify required parameter 'proxyUser' is not null or undefined
            assertParamExists('apiBudgetAdminUserCheckProxyUserUseridproxyUserGet', 'proxyUser', proxyUser)
            const localVarPath = `/api/BudgetAdminUser/CheckProxyUser/{userid},{proxyUser}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)))
                .replace(`{${"proxyUser"}}`, encodeURIComponent(String(proxyUser)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetAdminUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserGetUsernNameUserNameGet: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('apiBudgetAdminUserGetUsernNameUserNameGet', 'userName', userName)
            const localVarPath = `/api/BudgetAdminUser/GetUsernName/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetAdminUserIdDelete', 'id', id)
            const localVarPath = `/api/BudgetAdminUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetAdminUserIdGet', 'id', id)
            const localVarPath = `/api/BudgetAdminUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetAdminUserInsertDto} [budgetAdminUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserPost: async (budgetAdminUserInsertDto?: BudgetAdminUserInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetAdminUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetAdminUserInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetAdminUserUpdateDto} [budgetAdminUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserPut: async (budgetAdminUserUpdateDto?: BudgetAdminUserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetAdminUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetAdminUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetAdminUserApi - functional programming interface
 * @export
 */
export const BudgetAdminUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetAdminUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userid 
         * @param {string} proxyUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid: string, proxyUser: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid, proxyUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserCheckProxyUserUseridproxyUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BudgetAdminUserListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserGetUsernNameUserNameGet(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetAdminUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserGetUsernNameUserNameGet(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserGetUsernNameUserNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetAdminUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetAdminUserInsertDto} [budgetAdminUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserPost(budgetAdminUserInsertDto?: BudgetAdminUserInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetAdminUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserPost(budgetAdminUserInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetAdminUserUpdateDto} [budgetAdminUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetAdminUserPut(budgetAdminUserUpdateDto?: BudgetAdminUserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetAdminUserUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetAdminUserPut(budgetAdminUserUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetAdminUserApi.apiBudgetAdminUserPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetAdminUserApi - factory interface
 * @export
 */
export const BudgetAdminUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetAdminUserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userid 
         * @param {string} proxyUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid: string, proxyUser: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid, proxyUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserGet(options?: any): AxiosPromise<Array<BudgetAdminUserListDto>> {
            return localVarFp.apiBudgetAdminUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserGetUsernNameUserNameGet(userName: string, options?: any): AxiosPromise<BudgetAdminUserListDto> {
            return localVarFp.apiBudgetAdminUserGetUsernNameUserNameGet(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetAdminUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserIdGet(id: string, options?: any): AxiosPromise<BudgetAdminUserListDto> {
            return localVarFp.apiBudgetAdminUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetAdminUserInsertDto} [budgetAdminUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserPost(budgetAdminUserInsertDto?: BudgetAdminUserInsertDto, options?: any): AxiosPromise<BudgetAdminUserListDto> {
            return localVarFp.apiBudgetAdminUserPost(budgetAdminUserInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetAdminUserUpdateDto} [budgetAdminUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetAdminUserPut(budgetAdminUserUpdateDto?: BudgetAdminUserUpdateDto, options?: any): AxiosPromise<BudgetAdminUserUpdateDto> {
            return localVarFp.apiBudgetAdminUserPut(budgetAdminUserUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetAdminUserApi - object-oriented interface
 * @export
 * @class BudgetAdminUserApi
 * @extends {BaseAPI}
 */
export class BudgetAdminUserApi extends BaseAPI {
    /**
     * 
     * @param {string} userid 
     * @param {string} proxyUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid: string, proxyUser: string, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(userid, proxyUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserGet(options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserGetUsernNameUserNameGet(userName: string, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserGetUsernNameUserNameGet(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetAdminUserInsertDto} [budgetAdminUserInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserPost(budgetAdminUserInsertDto?: BudgetAdminUserInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserPost(budgetAdminUserInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetAdminUserUpdateDto} [budgetAdminUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetAdminUserApi
     */
    public apiBudgetAdminUserPut(budgetAdminUserUpdateDto?: BudgetAdminUserUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetAdminUserApiFp(this.configuration).apiBudgetAdminUserPut(budgetAdminUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetJobCodeRequestApi - axios parameter creator
 * @export
 */
export const BudgetJobCodeRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestGet: async (skip?: number, top?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetJobCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetJobCodeRequestIdDelete', 'id', id)
            const localVarPath = `/api/BudgetJobCodeRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetJobCodeRequestIdGet', 'id', id)
            const localVarPath = `/api/BudgetJobCodeRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetJobCodeRequestInsertDto} [budgetJobCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestPost: async (budgetJobCodeRequestInsertDto?: BudgetJobCodeRequestInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetJobCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetJobCodeRequestInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetJobCodeRequestUpdateDto} [budgetJobCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestPut: async (budgetJobCodeRequestUpdateDto?: BudgetJobCodeRequestUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetJobCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetJobCodeRequestUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetJobCodeRequestApi - functional programming interface
 * @export
 */
export const BudgetJobCodeRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetJobCodeRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetJobCodeRequestGet(skip?: number, top?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetJobCodeRequestListDtoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetJobCodeRequestGet(skip, top, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetJobCodeRequestApi.apiBudgetJobCodeRequestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetJobCodeRequestIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetJobCodeRequestIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetJobCodeRequestApi.apiBudgetJobCodeRequestIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetJobCodeRequestIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetJobCodeRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetJobCodeRequestIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetJobCodeRequestApi.apiBudgetJobCodeRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetJobCodeRequestInsertDto} [budgetJobCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto?: BudgetJobCodeRequestInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetJobCodeRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetJobCodeRequestApi.apiBudgetJobCodeRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetJobCodeRequestUpdateDto} [budgetJobCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto?: BudgetJobCodeRequestUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetJobCodeRequestUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetJobCodeRequestApi.apiBudgetJobCodeRequestPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetJobCodeRequestApi - factory interface
 * @export
 */
export const BudgetJobCodeRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetJobCodeRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestGet(skip?: number, top?: number, options?: any): AxiosPromise<BudgetJobCodeRequestListDtoResult> {
            return localVarFp.apiBudgetJobCodeRequestGet(skip, top, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetJobCodeRequestIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestIdGet(id: string, options?: any): AxiosPromise<BudgetJobCodeRequestListDto> {
            return localVarFp.apiBudgetJobCodeRequestIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetJobCodeRequestInsertDto} [budgetJobCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto?: BudgetJobCodeRequestInsertDto, options?: any): AxiosPromise<BudgetJobCodeRequestListDto> {
            return localVarFp.apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetJobCodeRequestUpdateDto} [budgetJobCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto?: BudgetJobCodeRequestUpdateDto, options?: any): AxiosPromise<BudgetJobCodeRequestUpdateDto> {
            return localVarFp.apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetJobCodeRequestApi - object-oriented interface
 * @export
 * @class BudgetJobCodeRequestApi
 * @extends {BaseAPI}
 */
export class BudgetJobCodeRequestApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetJobCodeRequestApi
     */
    public apiBudgetJobCodeRequestGet(skip?: number, top?: number, options?: RawAxiosRequestConfig) {
        return BudgetJobCodeRequestApiFp(this.configuration).apiBudgetJobCodeRequestGet(skip, top, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetJobCodeRequestApi
     */
    public apiBudgetJobCodeRequestIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetJobCodeRequestApiFp(this.configuration).apiBudgetJobCodeRequestIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetJobCodeRequestApi
     */
    public apiBudgetJobCodeRequestIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetJobCodeRequestApiFp(this.configuration).apiBudgetJobCodeRequestIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetJobCodeRequestInsertDto} [budgetJobCodeRequestInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetJobCodeRequestApi
     */
    public apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto?: BudgetJobCodeRequestInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetJobCodeRequestApiFp(this.configuration).apiBudgetJobCodeRequestPost(budgetJobCodeRequestInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetJobCodeRequestUpdateDto} [budgetJobCodeRequestUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetJobCodeRequestApi
     */
    public apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto?: BudgetJobCodeRequestUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetJobCodeRequestApiFp(this.configuration).apiBudgetJobCodeRequestPut(budgetJobCodeRequestUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetNormCodeRequestApi - axios parameter creator
 * @export
 */
export const BudgetNormCodeRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestAllGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestAllWaitingApproveCountGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest/allWaitingApproveCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestByCodeCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiBudgetNormCodeRequestByCodeCodeGet', 'code', code)
            const localVarPath = `/api/BudgetNormCodeRequest/byCode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestCheckPositionHasJobPostGet: async (code?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest/CheckPositionHasJobPost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [createdBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestGet: async (skip?: number, top?: number, createdBy?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetNormCodeRequestIdDelete', 'id', id)
            const localVarPath = `/api/BudgetNormCodeRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetNormCodeRequestIdGet', 'id', id)
            const localVarPath = `/api/BudgetNormCodeRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetNormCodeRequestInsertDto} [budgetNormCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestPost: async (budgetNormCodeRequestInsertDto?: BudgetNormCodeRequestInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetNormCodeRequestInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetNormCodeRequestUpdateDto} [budgetNormCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestPut: async (budgetNormCodeRequestUpdateDto?: BudgetNormCodeRequestUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetNormCodeRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetNormCodeRequestUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetNormCodeRequestApi - functional programming interface
 * @export
 */
export const BudgetNormCodeRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetNormCodeRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestAllGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestListDtoOnlyCodeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestAllGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestAllWaitingApproveCountGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestAllWaitingApproveCountGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestAllWaitingApproveCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestByCodeCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestByCodeCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestByCodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestCheckPositionHasJobPostGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [createdBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestGet(skip?: number, top?: number, createdBy?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestListDtoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestGet(skip, top, createdBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetNormCodeRequestInsertDto} [budgetNormCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto?: BudgetNormCodeRequestInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetNormCodeRequestUpdateDto} [budgetNormCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto?: BudgetNormCodeRequestUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetNormCodeRequestUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetNormCodeRequestApi.apiBudgetNormCodeRequestPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetNormCodeRequestApi - factory interface
 * @export
 */
export const BudgetNormCodeRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetNormCodeRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestAllGet(options?: any): AxiosPromise<BudgetNormCodeRequestListDtoOnlyCodeResult> {
            return localVarFp.apiBudgetNormCodeRequestAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestAllWaitingApproveCountGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiBudgetNormCodeRequestAllWaitingApproveCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestByCodeCodeGet(code: string, options?: any): AxiosPromise<BudgetNormCodeRequestListDto> {
            return localVarFp.apiBudgetNormCodeRequestByCodeCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [createdBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestGet(skip?: number, top?: number, createdBy?: string, options?: any): AxiosPromise<BudgetNormCodeRequestListDtoResult> {
            return localVarFp.apiBudgetNormCodeRequestGet(skip, top, createdBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetNormCodeRequestIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestIdGet(id: string, options?: any): AxiosPromise<BudgetNormCodeRequestListDto> {
            return localVarFp.apiBudgetNormCodeRequestIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetNormCodeRequestInsertDto} [budgetNormCodeRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto?: BudgetNormCodeRequestInsertDto, options?: any): AxiosPromise<BudgetNormCodeRequestListDto> {
            return localVarFp.apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetNormCodeRequestUpdateDto} [budgetNormCodeRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto?: BudgetNormCodeRequestUpdateDto, options?: any): AxiosPromise<BudgetNormCodeRequestUpdateDto> {
            return localVarFp.apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetNormCodeRequestApi - object-oriented interface
 * @export
 * @class BudgetNormCodeRequestApi
 * @extends {BaseAPI}
 */
export class BudgetNormCodeRequestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestAllGet(options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestAllWaitingApproveCountGet(options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestAllWaitingApproveCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestByCodeCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestByCodeCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code?: string, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestCheckPositionHasJobPostGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [createdBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestGet(skip?: number, top?: number, createdBy?: string, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestGet(skip, top, createdBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetNormCodeRequestInsertDto} [budgetNormCodeRequestInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto?: BudgetNormCodeRequestInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestPost(budgetNormCodeRequestInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetNormCodeRequestUpdateDto} [budgetNormCodeRequestUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetNormCodeRequestApi
     */
    public apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto?: BudgetNormCodeRequestUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetNormCodeRequestApiFp(this.configuration).apiBudgetNormCodeRequestPut(budgetNormCodeRequestUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetPeriodApi - axios parameter creator
 * @export
 */
export const BudgetPeriodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPeriodIdDelete', 'id', id)
            const localVarPath = `/api/BudgetPeriod/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPeriodIdGet', 'id', id)
            const localVarPath = `/api/BudgetPeriod/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPeriodInsertDto} [budgetPeriodInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodPost: async (budgetPeriodInsertDto?: BudgetPeriodInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPeriodInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPeriodUpdateDto} [budgetPeriodUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodPut: async (budgetPeriodUpdateDto?: BudgetPeriodUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPeriodUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetPeriodApi - functional programming interface
 * @export
 */
export const BudgetPeriodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetPeriodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BudgetPeriodListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodApi.apiBudgetPeriodGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodApi.apiBudgetPeriodIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodApi.apiBudgetPeriodIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPeriodInsertDto} [budgetPeriodInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodPost(budgetPeriodInsertDto?: BudgetPeriodInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodPost(budgetPeriodInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodApi.apiBudgetPeriodPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPeriodUpdateDto} [budgetPeriodUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodPut(budgetPeriodUpdateDto?: BudgetPeriodUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodPut(budgetPeriodUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodApi.apiBudgetPeriodPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetPeriodApi - factory interface
 * @export
 */
export const BudgetPeriodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetPeriodApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodGet(options?: any): AxiosPromise<Array<BudgetPeriodListDto>> {
            return localVarFp.apiBudgetPeriodGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetPeriodIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodIdGet(id: string, options?: any): AxiosPromise<BudgetPeriodListDto> {
            return localVarFp.apiBudgetPeriodIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPeriodInsertDto} [budgetPeriodInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodPost(budgetPeriodInsertDto?: BudgetPeriodInsertDto, options?: any): AxiosPromise<BudgetPeriodListDto> {
            return localVarFp.apiBudgetPeriodPost(budgetPeriodInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPeriodUpdateDto} [budgetPeriodUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodPut(budgetPeriodUpdateDto?: BudgetPeriodUpdateDto, options?: any): AxiosPromise<BudgetPeriodUpdateDto> {
            return localVarFp.apiBudgetPeriodPut(budgetPeriodUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetPeriodApi - object-oriented interface
 * @export
 * @class BudgetPeriodApi
 * @extends {BaseAPI}
 */
export class BudgetPeriodApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodApi
     */
    public apiBudgetPeriodGet(options?: RawAxiosRequestConfig) {
        return BudgetPeriodApiFp(this.configuration).apiBudgetPeriodGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodApi
     */
    public apiBudgetPeriodIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPeriodApiFp(this.configuration).apiBudgetPeriodIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodApi
     */
    public apiBudgetPeriodIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPeriodApiFp(this.configuration).apiBudgetPeriodIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPeriodInsertDto} [budgetPeriodInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodApi
     */
    public apiBudgetPeriodPost(budgetPeriodInsertDto?: BudgetPeriodInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetPeriodApiFp(this.configuration).apiBudgetPeriodPost(budgetPeriodInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPeriodUpdateDto} [budgetPeriodUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodApi
     */
    public apiBudgetPeriodPut(budgetPeriodUpdateDto?: BudgetPeriodUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetPeriodApiFp(this.configuration).apiBudgetPeriodPut(budgetPeriodUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetPeriodUserApi - axios parameter creator
 * @export
 */
export const BudgetPeriodUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriodUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userid 
         * @param {RequestType} [screenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserGetActivePeriodUseridGet: async (userid: string, screenType?: RequestType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('apiBudgetPeriodUserGetActivePeriodUseridGet', 'userid', userid)
            const localVarPath = `/api/BudgetPeriodUser/GetActivePeriod/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (screenType !== undefined) {
                localVarQueryParameter['ScreenType'] = screenType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPeriodUserIdDelete', 'id', id)
            const localVarPath = `/api/BudgetPeriodUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPeriodUserIdGet', 'id', id)
            const localVarPath = `/api/BudgetPeriodUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPeriodUserInsertDto} [budgetPeriodUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserPost: async (budgetPeriodUserInsertDto?: BudgetPeriodUserInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriodUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPeriodUserInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPeriodUserUpdateDto} [budgetPeriodUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserPut: async (budgetPeriodUserUpdateDto?: BudgetPeriodUserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPeriodUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPeriodUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetPeriodUserApi - functional programming interface
 * @export
 */
export const BudgetPeriodUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetPeriodUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BudgetPeriodUserListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userid 
         * @param {RequestType} [screenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserGetActivePeriodUseridGet(userid: string, screenType?: RequestType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserGetActivePeriodUseridGet(userid, screenType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserGetActivePeriodUseridGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPeriodUserInsertDto} [budgetPeriodUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserPost(budgetPeriodUserInsertDto?: BudgetPeriodUserInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodUserListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserPost(budgetPeriodUserInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPeriodUserUpdateDto} [budgetPeriodUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto?: BudgetPeriodUserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPeriodUserUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPeriodUserApi.apiBudgetPeriodUserPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetPeriodUserApi - factory interface
 * @export
 */
export const BudgetPeriodUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetPeriodUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserGet(options?: any): AxiosPromise<Array<BudgetPeriodUserListDto>> {
            return localVarFp.apiBudgetPeriodUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userid 
         * @param {RequestType} [screenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserGetActivePeriodUseridGet(userid: string, screenType?: RequestType, options?: any): AxiosPromise<BudgetPeriodUserListDto> {
            return localVarFp.apiBudgetPeriodUserGetActivePeriodUseridGet(userid, screenType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetPeriodUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserIdGet(id: string, options?: any): AxiosPromise<BudgetPeriodUserListDto> {
            return localVarFp.apiBudgetPeriodUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPeriodUserInsertDto} [budgetPeriodUserInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserPost(budgetPeriodUserInsertDto?: BudgetPeriodUserInsertDto, options?: any): AxiosPromise<BudgetPeriodUserListDto> {
            return localVarFp.apiBudgetPeriodUserPost(budgetPeriodUserInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPeriodUserUpdateDto} [budgetPeriodUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto?: BudgetPeriodUserUpdateDto, options?: any): AxiosPromise<BudgetPeriodUserUpdateDto> {
            return localVarFp.apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetPeriodUserApi - object-oriented interface
 * @export
 * @class BudgetPeriodUserApi
 * @extends {BaseAPI}
 */
export class BudgetPeriodUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserGet(options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userid 
     * @param {RequestType} [screenType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserGetActivePeriodUseridGet(userid: string, screenType?: RequestType, options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserGetActivePeriodUseridGet(userid, screenType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPeriodUserInsertDto} [budgetPeriodUserInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserPost(budgetPeriodUserInsertDto?: BudgetPeriodUserInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserPost(budgetPeriodUserInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPeriodUserUpdateDto} [budgetPeriodUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPeriodUserApi
     */
    public apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto?: BudgetPeriodUserUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetPeriodUserApiFp(this.configuration).apiBudgetPeriodUserPut(budgetPeriodUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetPromotionRequestApi - axios parameter creator
 * @export
 */
export const BudgetPromotionRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPromotionRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPromotionRequestIdDelete', 'id', id)
            const localVarPath = `/api/BudgetPromotionRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBudgetPromotionRequestIdGet', 'id', id)
            const localVarPath = `/api/BudgetPromotionRequest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPromotionRequestInsertDto} [budgetPromotionRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestPost: async (budgetPromotionRequestInsertDto?: BudgetPromotionRequestInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPromotionRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPromotionRequestInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BudgetPromotionRequestUpdateDto} [budgetPromotionRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestPut: async (budgetPromotionRequestUpdateDto?: BudgetPromotionRequestUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BudgetPromotionRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(budgetPromotionRequestUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetPromotionRequestApi - functional programming interface
 * @export
 */
export const BudgetPromotionRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetPromotionRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPromotionRequestGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BudgetPromotionRequestListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPromotionRequestGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPromotionRequestApi.apiBudgetPromotionRequestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPromotionRequestIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPromotionRequestIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPromotionRequestApi.apiBudgetPromotionRequestIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPromotionRequestIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPromotionRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPromotionRequestIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPromotionRequestApi.apiBudgetPromotionRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPromotionRequestInsertDto} [budgetPromotionRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto?: BudgetPromotionRequestInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPromotionRequestListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPromotionRequestApi.apiBudgetPromotionRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BudgetPromotionRequestUpdateDto} [budgetPromotionRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto?: BudgetPromotionRequestUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BudgetPromotionRequestUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BudgetPromotionRequestApi.apiBudgetPromotionRequestPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BudgetPromotionRequestApi - factory interface
 * @export
 */
export const BudgetPromotionRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetPromotionRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestGet(options?: any): AxiosPromise<Array<BudgetPromotionRequestListDto>> {
            return localVarFp.apiBudgetPromotionRequestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBudgetPromotionRequestIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestIdGet(id: string, options?: any): AxiosPromise<BudgetPromotionRequestListDto> {
            return localVarFp.apiBudgetPromotionRequestIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPromotionRequestInsertDto} [budgetPromotionRequestInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto?: BudgetPromotionRequestInsertDto, options?: any): AxiosPromise<BudgetPromotionRequestListDto> {
            return localVarFp.apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BudgetPromotionRequestUpdateDto} [budgetPromotionRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto?: BudgetPromotionRequestUpdateDto, options?: any): AxiosPromise<BudgetPromotionRequestUpdateDto> {
            return localVarFp.apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetPromotionRequestApi - object-oriented interface
 * @export
 * @class BudgetPromotionRequestApi
 * @extends {BaseAPI}
 */
export class BudgetPromotionRequestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPromotionRequestApi
     */
    public apiBudgetPromotionRequestGet(options?: RawAxiosRequestConfig) {
        return BudgetPromotionRequestApiFp(this.configuration).apiBudgetPromotionRequestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPromotionRequestApi
     */
    public apiBudgetPromotionRequestIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPromotionRequestApiFp(this.configuration).apiBudgetPromotionRequestIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPromotionRequestApi
     */
    public apiBudgetPromotionRequestIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BudgetPromotionRequestApiFp(this.configuration).apiBudgetPromotionRequestIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPromotionRequestInsertDto} [budgetPromotionRequestInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPromotionRequestApi
     */
    public apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto?: BudgetPromotionRequestInsertDto, options?: RawAxiosRequestConfig) {
        return BudgetPromotionRequestApiFp(this.configuration).apiBudgetPromotionRequestPost(budgetPromotionRequestInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BudgetPromotionRequestUpdateDto} [budgetPromotionRequestUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPromotionRequestApi
     */
    public apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto?: BudgetPromotionRequestUpdateDto, options?: RawAxiosRequestConfig) {
        return BudgetPromotionRequestApiFp(this.configuration).apiBudgetPromotionRequestPut(budgetPromotionRequestUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GenericListApi - axios parameter creator
 * @export
 */
export const GenericListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet: async (skip?: number, top?: number, name?: string, company?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/byBolgeFonksiyon/GetBolumProjeByBolgeFonksiyon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet: async (skip?: number, top?: number, name?: string, company?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/byCompany/GetBolgeFonksiyonByCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet: async (skip?: number, top?: number, name?: string, grupBaskanlik?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/bygrupBaskanlik/GetCompanyByGrupBaskanlik`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (grupBaskanlik !== undefined) {
                localVarQueryParameter['grupBaskanlik'] = grupBaskanlik;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGet: async (pc?: GenericList, skip?: number, top?: number, name?: string, parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pc !== undefined) {
                localVarQueryParameter['pc'] = pc;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetFilteredDataAsyncGet: async (pc?: GenericList, value?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/GetFilteredDataAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pc !== undefined) {
                localVarQueryParameter['pc'] = pc;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetHardwareListGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/GetHardwareList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetLicenceListGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/GenericList/GetLicenceList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericListApi - functional programming interface
 * @export
 */
export const GenericListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenericListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip?: number, top?: number, name?: string, company?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip, top, name, company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip?: number, top?: number, name?: string, company?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip, top, name, company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListGet(pc?: GenericList, skip?: number, top?: number, name?: string, parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListGet(pc, skip, top, name, parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListGetFilteredDataAsyncGet(pc?: GenericList, value?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListGetFilteredDataAsyncGet(pc, value, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListGetFilteredDataAsyncGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListGetHardwareListGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HardwareList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListGetHardwareListGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListGetHardwareListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGenericListGetLicenceListGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGenericListGetLicenceListGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenericListApi.apiGenericListGetLicenceListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GenericListApi - factory interface
 * @export
 */
export const GenericListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenericListApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip?: number, top?: number, name?: string, company?: string, options?: any): AxiosPromise<IGenericListDto> {
            return localVarFp.apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip, top, name, company, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip?: number, top?: number, name?: string, company?: string, options?: any): AxiosPromise<IGenericListDto> {
            return localVarFp.apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip, top, name, company, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: any): AxiosPromise<IGenericListDto> {
            return localVarFp.apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGet(pc?: GenericList, skip?: number, top?: number, name?: string, parentId?: string, options?: any): AxiosPromise<IGenericListDto> {
            return localVarFp.apiGenericListGet(pc, skip, top, name, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericList} [pc] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetFilteredDataAsyncGet(pc?: GenericList, value?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiGenericListGetFilteredDataAsyncGet(pc, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetHardwareListGet(options?: any): AxiosPromise<Array<HardwareList>> {
            return localVarFp.apiGenericListGetHardwareListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGenericListGetLicenceListGet(options?: any): AxiosPromise<Array<LicenceList>> {
            return localVarFp.apiGenericListGetLicenceListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenericListApi - object-oriented interface
 * @export
 * @class GenericListApi
 * @extends {BaseAPI}
 */
export class GenericListApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip?: number, top?: number, name?: string, company?: string, options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListByBolgeFonksiyonGetBolumProjeByBolgeFonksiyonGet(skip, top, name, company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip?: number, top?: number, name?: string, company?: string, options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListByCompanyGetBolgeFonksiyonByCompanyGet(skip, top, name, company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [grupBaskanlik] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListBygrupBaskanlikGetCompanyByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericList} [pc] 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListGet(pc?: GenericList, skip?: number, top?: number, name?: string, parentId?: string, options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListGet(pc, skip, top, name, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericList} [pc] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListGetFilteredDataAsyncGet(pc?: GenericList, value?: string, options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListGetFilteredDataAsyncGet(pc, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListGetHardwareListGet(options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListGetHardwareListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericListApi
     */
    public apiGenericListGetLicenceListGet(options?: RawAxiosRequestConfig) {
        return GenericListApiFp(this.configuration).apiGenericListGetLicenceListGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PickListApi - axios parameter creator
 * @export
 */
export const PickListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PickList} [pc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPickListGet: async (pc?: PickList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PickList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pc !== undefined) {
                localVarQueryParameter['pc'] = pc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PickListApi - functional programming interface
 * @export
 */
export const PickListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PickListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PickList} [pc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPickListGet(pc?: PickList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PickListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPickListGet(pc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickListApi.apiPickListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PickListApi - factory interface
 * @export
 */
export const PickListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PickListApiFp(configuration)
    return {
        /**
         * 
         * @param {PickList} [pc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPickListGet(pc?: PickList, options?: any): AxiosPromise<Array<PickListDto>> {
            return localVarFp.apiPickListGet(pc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PickListApi - object-oriented interface
 * @export
 * @class PickListApi
 * @extends {BaseAPI}
 */
export class PickListApi extends BaseAPI {
    /**
     * 
     * @param {PickList} [pc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickListApi
     */
    public apiPickListGet(pc?: PickList, options?: RawAxiosRequestConfig) {
        return PickListApiFp(this.configuration).apiPickListGet(pc, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFCustCompanyGroupApi - axios parameter creator
 * @export
 */
export const SFCustCompanyGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFCustCompanyGroupGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFCust_companyGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFCustCompanyGroupApi - functional programming interface
 * @export
 */
export const SFCustCompanyGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFCustCompanyGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFCustCompanyGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustCompanyGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFCustCompanyGroupGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFCustCompanyGroupApi.apiSFCustCompanyGroupGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFCustCompanyGroupApi - factory interface
 * @export
 */
export const SFCustCompanyGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFCustCompanyGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFCustCompanyGroupGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<CustCompanyGroupDto> {
            return localVarFp.apiSFCustCompanyGroupGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFCustCompanyGroupApi - object-oriented interface
 * @export
 * @class SFCustCompanyGroupApi
 * @extends {BaseAPI}
 */
export class SFCustCompanyGroupApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFCustCompanyGroupApi
     */
    public apiSFCustCompanyGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFCustCompanyGroupApiFp(this.configuration).apiSFCustCompanyGroupGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFCustLegalEntityApi - axios parameter creator
 * @export
 */
export const SFCustLegalEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFCustLegalEntityGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFCust_legalEntity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFCustLegalEntityApi - functional programming interface
 * @export
 */
export const SFCustLegalEntityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFCustLegalEntityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFCustLegalEntityGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustLegalEntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFCustLegalEntityGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFCustLegalEntityApi.apiSFCustLegalEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFCustLegalEntityApi - factory interface
 * @export
 */
export const SFCustLegalEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFCustLegalEntityApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFCustLegalEntityGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<CustLegalEntityDto> {
            return localVarFp.apiSFCustLegalEntityGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFCustLegalEntityApi - object-oriented interface
 * @export
 * @class SFCustLegalEntityApi
 * @extends {BaseAPI}
 */
export class SFCustLegalEntityApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFCustLegalEntityApi
     */
    public apiSFCustLegalEntityGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFCustLegalEntityApiFp(this.configuration).apiSFCustLegalEntityGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOBusinessUnitApi - axios parameter creator
 * @export
 */
export const SFFOBusinessUnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOBusinessUnitAllByGrupBaskanlikGet: async (skip?: number, top?: number, name?: string, grupBaskanlik?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOBusinessUnit/AllByGrupBaskanlik`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (grupBaskanlik !== undefined) {
                localVarQueryParameter['grupBaskanlik'] = grupBaskanlik;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOBusinessUnitGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOBusinessUnit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOBusinessUnitApi - functional programming interface
 * @export
 */
export const SFFOBusinessUnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOBusinessUnitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOBusinessUnitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOBusinessUnitApi.apiSFFOBusinessUnitAllByGrupBaskanlikGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOBusinessUnitGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOBusinessUnitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOBusinessUnitGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOBusinessUnitApi.apiSFFOBusinessUnitGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOBusinessUnitApi - factory interface
 * @export
 */
export const SFFOBusinessUnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOBusinessUnitApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [grupBaskanlik] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: any): AxiosPromise<FOBusinessUnitDto> {
            return localVarFp.apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOBusinessUnitGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOBusinessUnitDto> {
            return localVarFp.apiSFFOBusinessUnitGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOBusinessUnitApi - object-oriented interface
 * @export
 * @class SFFOBusinessUnitApi
 * @extends {BaseAPI}
 */
export class SFFOBusinessUnitApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [grupBaskanlik] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOBusinessUnitApi
     */
    public apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip?: number, top?: number, name?: string, grupBaskanlik?: string, options?: RawAxiosRequestConfig) {
        return SFFOBusinessUnitApiFp(this.configuration).apiSFFOBusinessUnitAllByGrupBaskanlikGet(skip, top, name, grupBaskanlik, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOBusinessUnitApi
     */
    public apiSFFOBusinessUnitGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOBusinessUnitApiFp(this.configuration).apiSFFOBusinessUnitGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOCompanyApi - axios parameter creator
 * @export
 */
export const SFFOCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOCompanyGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOCompanyApi - functional programming interface
 * @export
 */
export const SFFOCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOCompanyGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOCompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOCompanyGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOCompanyApi.apiSFFOCompanyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOCompanyApi - factory interface
 * @export
 */
export const SFFOCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOCompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOCompanyGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOCompanyDto> {
            return localVarFp.apiSFFOCompanyGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOCompanyApi - object-oriented interface
 * @export
 * @class SFFOCompanyApi
 * @extends {BaseAPI}
 */
export class SFFOCompanyApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOCompanyApi
     */
    public apiSFFOCompanyGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOCompanyApiFp(this.configuration).apiSFFOCompanyGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOCostCenterApi - axios parameter creator
 * @export
 */
export const SFFOCostCenterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOCostCenterGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOCostCenterApi - functional programming interface
 * @export
 */
export const SFFOCostCenterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOCostCenterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOCostCenterGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SFFOCostCenterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOCostCenterGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOCostCenterApi.apiSFFOCostCenterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOCostCenterApi - factory interface
 * @export
 */
export const SFFOCostCenterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOCostCenterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOCostCenterGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<SFFOCostCenterDto> {
            return localVarFp.apiSFFOCostCenterGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOCostCenterApi - object-oriented interface
 * @export
 * @class SFFOCostCenterApi
 * @extends {BaseAPI}
 */
export class SFFOCostCenterApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOCostCenterApi
     */
    public apiSFFOCostCenterGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOCostCenterApiFp(this.configuration).apiSFFOCostCenterGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFODepartmentApi - axios parameter creator
 * @export
 */
export const SFFODepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODepartmentGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFODepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFODepartmentApi - functional programming interface
 * @export
 */
export const SFFODepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFODepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFODepartmentGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FODepartmentSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFODepartmentGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFODepartmentApi.apiSFFODepartmentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFODepartmentApi - factory interface
 * @export
 */
export const SFFODepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFODepartmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODepartmentGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FODepartmentSFDto> {
            return localVarFp.apiSFFODepartmentGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFODepartmentApi - object-oriented interface
 * @export
 * @class SFFODepartmentApi
 * @extends {BaseAPI}
 */
export class SFFODepartmentApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFODepartmentApi
     */
    public apiSFFODepartmentGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFODepartmentApiFp(this.configuration).apiSFFODepartmentGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFODivisionApi - axios parameter creator
 * @export
 */
export const SFFODivisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [businessUnit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODivisionAllByBusinessUnitGet: async (skip?: number, top?: number, name?: string, businessUnit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFODivision/AllByBusinessUnit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (businessUnit !== undefined) {
                localVarQueryParameter['businessUnit'] = businessUnit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODivisionGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFODivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFODivisionApi - functional programming interface
 * @export
 */
export const SFFODivisionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFODivisionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [businessUnit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFODivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, businessUnit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FODivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFODivisionAllByBusinessUnitGet(skip, top, name, businessUnit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFODivisionApi.apiSFFODivisionAllByBusinessUnitGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFODivisionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FODivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFODivisionGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFODivisionApi.apiSFFODivisionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFODivisionApi - factory interface
 * @export
 */
export const SFFODivisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFODivisionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [businessUnit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, businessUnit?: string, options?: any): AxiosPromise<FODivisionDto> {
            return localVarFp.apiSFFODivisionAllByBusinessUnitGet(skip, top, name, businessUnit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFODivisionGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FODivisionDto> {
            return localVarFp.apiSFFODivisionGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFODivisionApi - object-oriented interface
 * @export
 * @class SFFODivisionApi
 * @extends {BaseAPI}
 */
export class SFFODivisionApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [businessUnit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFODivisionApi
     */
    public apiSFFODivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, businessUnit?: string, options?: RawAxiosRequestConfig) {
        return SFFODivisionApiFp(this.configuration).apiSFFODivisionAllByBusinessUnitGet(skip, top, name, businessUnit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFODivisionApi
     */
    public apiSFFODivisionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFODivisionApiFp(this.configuration).apiSFFODivisionGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOFOPayGroupApi - axios parameter creator
 * @export
 */
export const SFFOFOPayGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOFOPayGroupGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOFOPayGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOFOPayGroupApi - functional programming interface
 * @export
 */
export const SFFOFOPayGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOFOPayGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOFOPayGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOPayGroupSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOFOPayGroupGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOFOPayGroupApi.apiSFFOFOPayGroupGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOFOPayGroupApi - factory interface
 * @export
 */
export const SFFOFOPayGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOFOPayGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOFOPayGroupGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOPayGroupSFDto> {
            return localVarFp.apiSFFOFOPayGroupGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOFOPayGroupApi - object-oriented interface
 * @export
 * @class SFFOFOPayGroupApi
 * @extends {BaseAPI}
 */
export class SFFOFOPayGroupApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOFOPayGroupApi
     */
    public apiSFFOFOPayGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOFOPayGroupApiFp(this.configuration).apiSFFOFOPayGroupGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOGeozoneApi - axios parameter creator
 * @export
 */
export const SFFOGeozoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOGeozoneGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOGeozone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOGeozoneApi - functional programming interface
 * @export
 */
export const SFFOGeozoneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOGeozoneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOGeozoneGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOGeozoneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOGeozoneGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOGeozoneApi.apiSFFOGeozoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOGeozoneApi - factory interface
 * @export
 */
export const SFFOGeozoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOGeozoneApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOGeozoneGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOGeozoneDto> {
            return localVarFp.apiSFFOGeozoneGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOGeozoneApi - object-oriented interface
 * @export
 * @class SFFOGeozoneApi
 * @extends {BaseAPI}
 */
export class SFFOGeozoneApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOGeozoneApi
     */
    public apiSFFOGeozoneGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOGeozoneApiFp(this.configuration).apiSFFOGeozoneGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOJobCodeApi - axios parameter creator
 * @export
 */
export const SFFOJobCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeCheckRecruitmentCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSFFOJobCodeCheckRecruitmentCodeGet', 'code', code)
            const localVarPath = `/api/SFFOJobCode/checkRecruitment/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSFFOJobCodeCodeGet', 'code', code)
            const localVarPath = `/api/SFFOJobCode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOJobCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodePost: async (workFlowId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOJobCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workFlowId !== undefined) {
                localVarQueryParameter['workFlowId'] = workFlowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOJobCodeApi - functional programming interface
 * @export
 */
export const SFFOJobCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOJobCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOJobCodeCheckRecruitmentCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOJobCodeCheckRecruitmentCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOJobCodeApi.apiSFFOJobCodeCheckRecruitmentCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOJobCodeCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOJobCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOJobCodeCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOJobCodeApi.apiSFFOJobCodeCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOJobCodeGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOJobCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOJobCodeGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOJobCodeApi.apiSFFOJobCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOJobCodePost(workFlowId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOJobCodePost(workFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOJobCodeApi.apiSFFOJobCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOJobCodeApi - factory interface
 * @export
 */
export const SFFOJobCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOJobCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeCheckRecruitmentCodeGet(code: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiSFFOJobCodeCheckRecruitmentCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeCodeGet(code: string, options?: any): AxiosPromise<FOJobCodeDto> {
            return localVarFp.apiSFFOJobCodeCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodeGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOJobCodeDto> {
            return localVarFp.apiSFFOJobCodeGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobCodePost(workFlowId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFFOJobCodePost(workFlowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOJobCodeApi - object-oriented interface
 * @export
 * @class SFFOJobCodeApi
 * @extends {BaseAPI}
 */
export class SFFOJobCodeApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOJobCodeApi
     */
    public apiSFFOJobCodeCheckRecruitmentCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return SFFOJobCodeApiFp(this.configuration).apiSFFOJobCodeCheckRecruitmentCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOJobCodeApi
     */
    public apiSFFOJobCodeCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return SFFOJobCodeApiFp(this.configuration).apiSFFOJobCodeCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOJobCodeApi
     */
    public apiSFFOJobCodeGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOJobCodeApiFp(this.configuration).apiSFFOJobCodeGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [workFlowId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOJobCodeApi
     */
    public apiSFFOJobCodePost(workFlowId?: string, options?: RawAxiosRequestConfig) {
        return SFFOJobCodeApiFp(this.configuration).apiSFFOJobCodePost(workFlowId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOJobFunctionApi - axios parameter creator
 * @export
 */
export const SFFOJobFunctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobFunctionGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOJobFunction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOJobFunctionApi - functional programming interface
 * @export
 */
export const SFFOJobFunctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOJobFunctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOJobFunctionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOJobFunctionSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOJobFunctionGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOJobFunctionApi.apiSFFOJobFunctionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOJobFunctionApi - factory interface
 * @export
 */
export const SFFOJobFunctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOJobFunctionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOJobFunctionGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOJobFunctionSFDto> {
            return localVarFp.apiSFFOJobFunctionGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOJobFunctionApi - object-oriented interface
 * @export
 * @class SFFOJobFunctionApi
 * @extends {BaseAPI}
 */
export class SFFOJobFunctionApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOJobFunctionApi
     */
    public apiSFFOJobFunctionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOJobFunctionApiFp(this.configuration).apiSFFOJobFunctionGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOLocationApi - axios parameter creator
 * @export
 */
export const SFFOLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOLocationGet: async (skip?: number, top?: number, name?: string, parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOLocationApi - functional programming interface
 * @export
 */
export const SFFOLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOLocationGet(skip?: number, top?: number, name?: string, parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOLocationSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOLocationGet(skip, top, name, parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOLocationApi.apiSFFOLocationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOLocationApi - factory interface
 * @export
 */
export const SFFOLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOLocationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOLocationGet(skip?: number, top?: number, name?: string, parentId?: string, options?: any): AxiosPromise<FOLocationSFDto> {
            return localVarFp.apiSFFOLocationGet(skip, top, name, parentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOLocationApi - object-oriented interface
 * @export
 * @class SFFOLocationApi
 * @extends {BaseAPI}
 */
export class SFFOLocationApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOLocationApi
     */
    public apiSFFOLocationGet(skip?: number, top?: number, name?: string, parentId?: string, options?: RawAxiosRequestConfig) {
        return SFFOLocationApiFp(this.configuration).apiSFFOLocationGet(skip, top, name, parentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOLocationGroupApi - axios parameter creator
 * @export
 */
export const SFFOLocationGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOLocationGroupGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOLocationGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOLocationGroupApi - functional programming interface
 * @export
 */
export const SFFOLocationGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOLocationGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOLocationGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOLocationGroupSFDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOLocationGroupGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOLocationGroupApi.apiSFFOLocationGroupGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOLocationGroupApi - factory interface
 * @export
 */
export const SFFOLocationGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOLocationGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOLocationGroupGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOLocationGroupSFDTO> {
            return localVarFp.apiSFFOLocationGroupGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOLocationGroupApi - object-oriented interface
 * @export
 * @class SFFOLocationGroupApi
 * @extends {BaseAPI}
 */
export class SFFOLocationGroupApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOLocationGroupApi
     */
    public apiSFFOLocationGroupGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOLocationGroupApiFp(this.configuration).apiSFFOLocationGroupGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFFOPayGradeApi - axios parameter creator
 * @export
 */
export const SFFOPayGradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOPayGradeGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFFOPayGrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFFOPayGradeApi - functional programming interface
 * @export
 */
export const SFFOPayGradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFFOPayGradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFFOPayGradeGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FOPayGradeSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFFOPayGradeGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFFOPayGradeApi.apiSFFOPayGradeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFFOPayGradeApi - factory interface
 * @export
 */
export const SFFOPayGradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFFOPayGradeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFFOPayGradeGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<FOPayGradeSFDto> {
            return localVarFp.apiSFFOPayGradeGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFFOPayGradeApi - object-oriented interface
 * @export
 * @class SFFOPayGradeApi
 * @extends {BaseAPI}
 */
export class SFFOPayGradeApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFFOPayGradeApi
     */
    public apiSFFOPayGradeGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFFOPayGradeApiFp(this.configuration).apiSFFOPayGradeGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFPositionsApi - axios parameter creator
 * @export
 */
export const SFPositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByPositionCodeUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsByPositionCodeUserIdGet', 'userId', userId)
            const localVarPath = `/api/SFPositions/byPositionCode/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {PositionSFDto} [positionSFDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByUserIdAllUserIdPost: async (userId: string, positionSFDto?: PositionSFDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsByUserIdAllUserIdPost', 'userId', userId)
            const localVarPath = `/api/SFPositions/byUserIdAll/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionSFDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByUserIdUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsByUserIdUserIdGet', 'userId', userId)
            const localVarPath = `/api/SFPositions/byUserId/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSFPositionsCodeGet', 'code', code)
            const localVarPath = `/api/SFPositions/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsDetailByUserIdUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsDetailByUserIdUserIdGet', 'userId', userId)
            const localVarPath = `/api/SFPositions/detailByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsDetailCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSFPositionsDetailCodeGet', 'code', code)
            const localVarPath = `/api/SFPositions/detail/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsEmpCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSFPositionsEmpCodeGet', 'code', code)
            const localVarPath = `/api/SFPositions/emp/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGet: async (skip?: number, top?: number, firstName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFPositions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} positioncode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet: async (positioncode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'positioncode' is not null or undefined
            assertParamExists('apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet', 'positioncode', positioncode)
            const localVarPath = `/api/SFPositions/getMngByCodeFromEmpJob/{positioncode}`
                .replace(`{${"positioncode"}}`, encodeURIComponent(String(positioncode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/SFPositions/getMngDisplayNameFromEmpUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngFromEmpJobUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSFPositionsGetMngFromEmpJobUserIdGet', 'userId', userId)
            const localVarPath = `/api/SFPositions/getMngFromEmpJob/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsPost: async (workFlowId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFPositions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workFlowId !== undefined) {
                localVarQueryParameter['workFlowId'] = workFlowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFPositionsApi - functional programming interface
 * @export
 */
export const SFPositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFPositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsByPositionCodeUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsByPositionCodeUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsByPositionCodeUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {PositionSFDto} [positionSFDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsByUserIdAllUserIdPost(userId: string, positionSFDto?: PositionSFDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsByUserIdAllUserIdPost(userId, positionSFDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsByUserIdAllUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsByUserIdUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsByUserIdUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsByUserIdUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SFPositionAllPropertyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsDetailByUserIdUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SFPositionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsDetailByUserIdUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsDetailByUserIdUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsDetailCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SFPositionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsDetailCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsDetailCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsEmpCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SFEmpJobDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsEmpCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsEmpCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsGet(skip?: number, top?: number, firstName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionSFDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsGet(skip, top, firstName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} positioncode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsGetMngFromEmpJobUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsGetMngFromEmpJobUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsGetMngFromEmpJobUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFPositionsPost(workFlowId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFPositionsPost(workFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFPositionsApi.apiSFPositionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFPositionsApi - factory interface
 * @export
 */
export const SFPositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFPositionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByPositionCodeUserIdGet(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFPositionsByPositionCodeUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {PositionSFDto} [positionSFDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByUserIdAllUserIdPost(userId: string, positionSFDto?: PositionSFDto, options?: any): AxiosPromise<PositionSFDto> {
            return localVarFp.apiSFPositionsByUserIdAllUserIdPost(userId, positionSFDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsByUserIdUserIdGet(userId: string, options?: any): AxiosPromise<PositionSFDto> {
            return localVarFp.apiSFPositionsByUserIdUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsCodeGet(code: string, options?: any): AxiosPromise<Array<SFPositionAllPropertyDto>> {
            return localVarFp.apiSFPositionsCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsDetailByUserIdUserIdGet(userId: string, options?: any): AxiosPromise<Array<SFPositionDto>> {
            return localVarFp.apiSFPositionsDetailByUserIdUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsDetailCodeGet(code: string, options?: any): AxiosPromise<Array<SFPositionDto>> {
            return localVarFp.apiSFPositionsDetailCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsEmpCodeGet(code: string, options?: any): AxiosPromise<Array<SFEmpJobDto>> {
            return localVarFp.apiSFPositionsEmpCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGet(skip?: number, top?: number, firstName?: string, options?: any): AxiosPromise<PositionSFDto> {
            return localVarFp.apiSFPositionsGet(skip, top, firstName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} positioncode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsGetMngFromEmpJobUserIdGet(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFPositionsGetMngFromEmpJobUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [workFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFPositionsPost(workFlowId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSFPositionsPost(workFlowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFPositionsApi - object-oriented interface
 * @export
 * @class SFPositionsApi
 * @extends {BaseAPI}
 */
export class SFPositionsApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsByPositionCodeUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsByPositionCodeUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {PositionSFDto} [positionSFDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsByUserIdAllUserIdPost(userId: string, positionSFDto?: PositionSFDto, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsByUserIdAllUserIdPost(userId, positionSFDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsByUserIdUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsByUserIdUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsDetailByUserIdUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsDetailByUserIdUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsDetailCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsDetailCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsEmpCodeGet(code: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsEmpCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [firstName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsGet(skip?: number, top?: number, firstName?: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsGet(skip, top, firstName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} positioncode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(positioncode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsGetMngDisplayNameFromEmpUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsGetMngFromEmpJobUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsGetMngFromEmpJobUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [workFlowId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFPositionsApi
     */
    public apiSFPositionsPost(workFlowId?: string, options?: RawAxiosRequestConfig) {
        return SFPositionsApiFp(this.configuration).apiSFPositionsPost(workFlowId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFUserApi - axios parameter creator
 * @export
 */
export const SFUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFUserGet: async (skip?: number, top?: number, firstName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFUserUserUseridGet: async (userid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('apiSFUserUserUseridGet', 'userid', userid)
            const localVarPath = `/api/SFUser/user/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFUserApi - functional programming interface
 * @export
 */
export const SFUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFUserGet(skip?: number, top?: number, firstName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSFListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFUserGet(skip, top, firstName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFUserApi.apiSFUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFUserUserUseridGet(userid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSFListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFUserUserUseridGet(userid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFUserApi.apiSFUserUserUseridGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFUserApi - factory interface
 * @export
 */
export const SFUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFUserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [firstName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFUserGet(skip?: number, top?: number, firstName?: string, options?: any): AxiosPromise<UserSFListDto> {
            return localVarFp.apiSFUserGet(skip, top, firstName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFUserUserUseridGet(userid: string, options?: any): AxiosPromise<UserSFListDto> {
            return localVarFp.apiSFUserUserUseridGet(userid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFUserApi - object-oriented interface
 * @export
 * @class SFUserApi
 * @extends {BaseAPI}
 */
export class SFUserApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [firstName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFUserApi
     */
    public apiSFUserGet(skip?: number, top?: number, firstName?: string, options?: RawAxiosRequestConfig) {
        return SFUserApiFp(this.configuration).apiSFUserGet(skip, top, firstName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFUserApi
     */
    public apiSFUserUserUseridGet(userid: string, options?: RawAxiosRequestConfig) {
        return SFUserApiFp(this.configuration).apiSFUserUserUseridGet(userid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SFcustSubDivisionApi - axios parameter creator
 * @export
 */
export const SFcustSubDivisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [division] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFcustSubDivisionAllByBusinessUnitGet: async (skip?: number, top?: number, name?: string, division?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFcust_sub_division/AllByBusinessUnit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (division !== undefined) {
                localVarQueryParameter['division'] = division;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFcustSubDivisionGet: async (skip?: number, top?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SFcust_sub_division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SFcustSubDivisionApi - functional programming interface
 * @export
 */
export const SFcustSubDivisionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SFcustSubDivisionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [division] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFcustSubDivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, division?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustSubDivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFcustSubDivisionAllByBusinessUnitGet(skip, top, name, division, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFcustSubDivisionApi.apiSFcustSubDivisionAllByBusinessUnitGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSFcustSubDivisionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustSubDivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSFcustSubDivisionGet(skip, top, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SFcustSubDivisionApi.apiSFcustSubDivisionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SFcustSubDivisionApi - factory interface
 * @export
 */
export const SFcustSubDivisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SFcustSubDivisionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {string} [division] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFcustSubDivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, division?: string, options?: any): AxiosPromise<CustSubDivisionDto> {
            return localVarFp.apiSFcustSubDivisionAllByBusinessUnitGet(skip, top, name, division, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [top] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSFcustSubDivisionGet(skip?: number, top?: number, name?: string, options?: any): AxiosPromise<CustSubDivisionDto> {
            return localVarFp.apiSFcustSubDivisionGet(skip, top, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SFcustSubDivisionApi - object-oriented interface
 * @export
 * @class SFcustSubDivisionApi
 * @extends {BaseAPI}
 */
export class SFcustSubDivisionApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {string} [division] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFcustSubDivisionApi
     */
    public apiSFcustSubDivisionAllByBusinessUnitGet(skip?: number, top?: number, name?: string, division?: string, options?: RawAxiosRequestConfig) {
        return SFcustSubDivisionApiFp(this.configuration).apiSFcustSubDivisionAllByBusinessUnitGet(skip, top, name, division, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [top] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SFcustSubDivisionApi
     */
    public apiSFcustSubDivisionGet(skip?: number, top?: number, name?: string, options?: RawAxiosRequestConfig) {
        return SFcustSubDivisionApiFp(this.configuration).apiSFcustSubDivisionGet(skip, top, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkFlowApi - axios parameter creator
 * @export
 */
export const WorkFlowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkFlowContiuneApiDto} [workFlowContiuneApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowContiunePost: async (workFlowContiuneApiDto?: WorkFlowContiuneApiDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlow/Contiune`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workFlowContiuneApiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkFlowStartApiDto} [workFlowStartApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowStartPost: async (workFlowStartApiDto?: WorkFlowStartApiDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlow/Start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workFlowStartApiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkFlowApi - functional programming interface
 * @export
 */
export const WorkFlowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkFlowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkFlowContiuneApiDto} [workFlowContiuneApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowContiunePost(workFlowContiuneApiDto?: WorkFlowContiuneApiDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkFlowHeadDtoResultStartOrContinue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowContiunePost(workFlowContiuneApiDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowApi.apiWorkFlowContiunePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkFlowStartApiDto} [workFlowStartApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowStartPost(workFlowStartApiDto?: WorkFlowStartApiDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkFlowHeadDtoResultStartOrContinue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowStartPost(workFlowStartApiDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowApi.apiWorkFlowStartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkFlowApi - factory interface
 * @export
 */
export const WorkFlowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkFlowApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkFlowContiuneApiDto} [workFlowContiuneApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowContiunePost(workFlowContiuneApiDto?: WorkFlowContiuneApiDto, options?: any): AxiosPromise<WorkFlowHeadDtoResultStartOrContinue> {
            return localVarFp.apiWorkFlowContiunePost(workFlowContiuneApiDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkFlowStartApiDto} [workFlowStartApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowStartPost(workFlowStartApiDto?: WorkFlowStartApiDto, options?: any): AxiosPromise<WorkFlowHeadDtoResultStartOrContinue> {
            return localVarFp.apiWorkFlowStartPost(workFlowStartApiDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkFlowApi - object-oriented interface
 * @export
 * @class WorkFlowApi
 * @extends {BaseAPI}
 */
export class WorkFlowApi extends BaseAPI {
    /**
     * 
     * @param {WorkFlowContiuneApiDto} [workFlowContiuneApiDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowApi
     */
    public apiWorkFlowContiunePost(workFlowContiuneApiDto?: WorkFlowContiuneApiDto, options?: RawAxiosRequestConfig) {
        return WorkFlowApiFp(this.configuration).apiWorkFlowContiunePost(workFlowContiuneApiDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkFlowStartApiDto} [workFlowStartApiDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowApi
     */
    public apiWorkFlowStartPost(workFlowStartApiDto?: WorkFlowStartApiDto, options?: RawAxiosRequestConfig) {
        return WorkFlowApiFp(this.configuration).apiWorkFlowStartPost(workFlowStartApiDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkFlowDefinationApi - axios parameter creator
 * @export
 */
export const WorkFlowDefinationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlowDefination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationGetWorkFlowListByMenuGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlowDefination/GetWorkFlowListByMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkFlowDefinationIdGet', 'id', id)
            const localVarPath = `/api/WorkFlowDefination/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkFlowDefinationInsertDto} [workFlowDefinationInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationPost: async (workFlowDefinationInsertDto?: WorkFlowDefinationInsertDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlowDefination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workFlowDefinationInsertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkFlowDefinationUpdateDto} [workFlowDefinationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationPut: async (workFlowDefinationUpdateDto?: WorkFlowDefinationUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkFlowDefination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workFlowDefinationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkFlowDefinationApi - functional programming interface
 * @export
 */
export const WorkFlowDefinationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkFlowDefinationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowDefinationGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkFlowDefinationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowDefinationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowDefinationApi.apiWorkFlowDefinationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowDefinationGetWorkFlowListByMenuGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowDefinationGetWorkFlowListByMenuGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowDefinationApi.apiWorkFlowDefinationGetWorkFlowListByMenuGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowDefinationIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkFlowDefinationListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowDefinationIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowDefinationApi.apiWorkFlowDefinationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkFlowDefinationInsertDto} [workFlowDefinationInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowDefinationPost(workFlowDefinationInsertDto?: WorkFlowDefinationInsertDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkFlowDefination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowDefinationPost(workFlowDefinationInsertDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowDefinationApi.apiWorkFlowDefinationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkFlowDefinationUpdateDto} [workFlowDefinationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowDefinationPut(workFlowDefinationUpdateDto?: WorkFlowDefinationUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkFlowDefinationUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowDefinationPut(workFlowDefinationUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowDefinationApi.apiWorkFlowDefinationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkFlowDefinationApi - factory interface
 * @export
 */
export const WorkFlowDefinationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkFlowDefinationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationGet(options?: any): AxiosPromise<Array<WorkFlowDefinationListDto>> {
            return localVarFp.apiWorkFlowDefinationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationGetWorkFlowListByMenuGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkFlowDefinationGetWorkFlowListByMenuGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationIdGet(id: string, options?: any): AxiosPromise<WorkFlowDefinationListDto> {
            return localVarFp.apiWorkFlowDefinationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkFlowDefinationInsertDto} [workFlowDefinationInsertDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationPost(workFlowDefinationInsertDto?: WorkFlowDefinationInsertDto, options?: any): AxiosPromise<WorkFlowDefination> {
            return localVarFp.apiWorkFlowDefinationPost(workFlowDefinationInsertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkFlowDefinationUpdateDto} [workFlowDefinationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowDefinationPut(workFlowDefinationUpdateDto?: WorkFlowDefinationUpdateDto, options?: any): AxiosPromise<WorkFlowDefinationUpdateDto> {
            return localVarFp.apiWorkFlowDefinationPut(workFlowDefinationUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkFlowDefinationApi - object-oriented interface
 * @export
 * @class WorkFlowDefinationApi
 * @extends {BaseAPI}
 */
export class WorkFlowDefinationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowDefinationApi
     */
    public apiWorkFlowDefinationGet(options?: RawAxiosRequestConfig) {
        return WorkFlowDefinationApiFp(this.configuration).apiWorkFlowDefinationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowDefinationApi
     */
    public apiWorkFlowDefinationGetWorkFlowListByMenuGet(options?: RawAxiosRequestConfig) {
        return WorkFlowDefinationApiFp(this.configuration).apiWorkFlowDefinationGetWorkFlowListByMenuGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowDefinationApi
     */
    public apiWorkFlowDefinationIdGet(id: string, options?: RawAxiosRequestConfig) {
        return WorkFlowDefinationApiFp(this.configuration).apiWorkFlowDefinationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkFlowDefinationInsertDto} [workFlowDefinationInsertDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowDefinationApi
     */
    public apiWorkFlowDefinationPost(workFlowDefinationInsertDto?: WorkFlowDefinationInsertDto, options?: RawAxiosRequestConfig) {
        return WorkFlowDefinationApiFp(this.configuration).apiWorkFlowDefinationPost(workFlowDefinationInsertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkFlowDefinationUpdateDto} [workFlowDefinationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowDefinationApi
     */
    public apiWorkFlowDefinationPut(workFlowDefinationUpdateDto?: WorkFlowDefinationUpdateDto, options?: RawAxiosRequestConfig) {
        return WorkFlowDefinationApiFp(this.configuration).apiWorkFlowDefinationPut(workFlowDefinationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkFlowItemApi - axios parameter creator
 * @export
 */
export const WorkFlowItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet: async (workFlowHeadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workFlowHeadId' is not null or undefined
            assertParamExists('apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet', 'workFlowHeadId', workFlowHeadId)
            const localVarPath = `/api/WorkFlowItem/GetApproveItems/{workFlowHeadId}`
                .replace(`{${"workFlowHeadId"}}`, encodeURIComponent(String(workFlowHeadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowItemGetStatusWorkFlowHeadIdGet: async (workFlowHeadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workFlowHeadId' is not null or undefined
            assertParamExists('apiWorkFlowItemGetStatusWorkFlowHeadIdGet', 'workFlowHeadId', workFlowHeadId)
            const localVarPath = `/api/WorkFlowItem/getStatus/{workFlowHeadId}`
                .replace(`{${"workFlowHeadId"}}`, encodeURIComponent(String(workFlowHeadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkFlowItemApi - functional programming interface
 * @export
 */
export const WorkFlowItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkFlowItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkFlowItemDtoWithApproveItems>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowItemApi.apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkFlowItemApi.apiWorkFlowItemGetStatusWorkFlowHeadIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkFlowItemApi - factory interface
 * @export
 */
export const WorkFlowItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkFlowItemApiFp(configuration)
    return {
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId: string, options?: any): AxiosPromise<Array<WorkFlowItemDtoWithApproveItems>> {
            return localVarFp.apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workFlowHeadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkFlowItemApi - object-oriented interface
 * @export
 * @class WorkFlowItemApi
 * @extends {BaseAPI}
 */
export class WorkFlowItemApi extends BaseAPI {
    /**
     * 
     * @param {string} workFlowHeadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowItemApi
     */
    public apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId: string, options?: RawAxiosRequestConfig) {
        return WorkFlowItemApiFp(this.configuration).apiWorkFlowItemGetApproveItemsWorkFlowHeadIdGet(workFlowHeadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workFlowHeadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkFlowItemApi
     */
    public apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId: string, options?: RawAxiosRequestConfig) {
        return WorkFlowItemApiFp(this.configuration).apiWorkFlowItemGetStatusWorkFlowHeadIdGet(workFlowHeadId, options).then((request) => request(this.axios, this.basePath));
    }
}



