/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Button, CheckBox, Input } from '@ui5/webcomponents-react';
import { Components, Form, FormBuilder, Formio, form } from "@formio/react";

import { useEffect, useRef, useState } from "react";
import DashboardLayout from '../Components/DashboardLayout';
import "../style/Builder.css";

import { ErrorMessage, Field, Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// import { setLoading, useMaterialUIController } from "context";


import React from "react";
import { Splitter, SplitterPanel } from 'primereact/splitter';

import { Accordion, AccordionTab } from 'primereact/accordion';
import components from "./Custom";
import { useSearchParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';
import { Tab } from '@mui/material';



const CreateForm = (): JSX.Element => {
  // const [controller, dispatch] = useMaterialUIController();
  const [searchParams] = useSearchParams();
  // const urlParams = new URLSearchParams(location.search);
  const [value, setValue] = React.useState('1');
  // const configuration = ConfigurationConfig;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const testData = [
    { id: 0, kolon1: 'Veri 1111-1', kolon2: 'Veri 1-2', kolon3: 'Veri 1-3' },
    { id: 1, kolon1: 'Veri 2-1', kolon2: 'Veri 2-2', kolon3: 'Veri 2-3' },
    { id: 2, kolon1: 'Veri 3-1', kolon2: 'Veri 3-2', kolon3: 'Veri 3-3' },
    { id: 3, kolon1: 'Veri 4-1', kolon2: 'Veri 4-2', kolon3: 'Veri 4-3' },
    { id: 4, kolon1: 'Veri 5-1', kolon2: 'Veri 5-2', kolon3: 'Veri 5-3' },
    { id: 5, kolon1: 'Veri 6-1', kolon2: 'Veri 6-2', kolon3: 'Veri 6-3' },
    { id: 6, kolon1: 'Veri 7-1', kolon2: 'Veri 7-2', kolon3: 'Veri 7-3' },
    { id: 7, kolon1: 'Veri 8-1', kolon2: 'Veri 8-2', kolon3: 'Veri 8-3' }
  ];

  let initialValues = {
    formName: '', // Eğer InputText'e bağlamak istediğiniz alan bu alansa buraya ekleyin
    formDescription: '', // Eğer InputText'e bağlamak istediğiniz alan bu alansa buraya ekleyin
    Revision: '',
    formid: '',
    isActive: true
    // Eğer InputText'e bağlamak istediğiniz alan bu alansa buraya ekleyin
    // Diğer form alanları buraya eklenebilir
  };


  const onSubmit = (values: any) => {
    // Form gönderildiğinde yapılacak işlemler burada olmalı
    console.log('Form values:', values);
  };
  const formikProps = useFormik({

    initialValues: initialValues,
    validationSchema: null,
    onSubmit: onSubmit,

  });

  useEffect(() => {

    Components.setComponents(components);

    Formio.setBaseUrl('https://api.cfapps.us21.hana.ondemand.com/api');

    // Alternatif olarak, projenin URL'ini değiştirmek için
    Formio.setProjectUrl('https://api.cfapps.us21.hana.ondemand.com/api');


  }, []);
  const [jsonSchema, setSchema] = useState({ components: [] });
  const [formEditActive, setformEditActive] = useState(false);
  // const [detailForm, setDetailForm] = useState<FormDto>();




  const onFormChange = (schema: any) => {
    setSchema({ ...schema, components: [...schema.components] });
  };

  async function getFormId(id: any) {

    // setLoading(dispatch, true);
    // let formApi = new FormDataApi(configuration);
    // var formDetail = await formApi.apiFormDataIdGet(id);

    // // console.log(formDetail);

    // formikProps.setFieldValue("formName", formDetail.data.formName);
    // formikProps.setFieldValue("formDescription", formDetail.data.formDescription);
    // formikProps.setFieldValue("formid", formDetail.data.id);
    // formikProps.setFieldValue("isActive", formDetail.data.isActive);


    // setDetailForm(formDetail.data);

    // setformEditActive(true);


    // setSchema(JSON.parse(formDetail.data.formDesign));
    // setLoading(dispatch, false);

  }

  const options = {
    builder: {

      custom: {
        title: 'Özel Alanlar',
        Key: "dscomponents",
        weight: 10,
        components: {
          dsinput: {
            title: 'DS Inbox',
            key: 'dsinput',
            icon: 'dsinput',
            schema: {
              label: 'dsinput',
              type: 'dsinput',
              key: 'dsinput'
            }
          },
          dsselect: {
            title: 'DS Select',
            key: 'dsselect',
            icon: 'dsselect',
            schema: {
              label: 'dsselect',
              type: 'dsselect',
              key: 'dsselect'
            }
          },
          dscheckbox: {
            title: 'DS CheckBox',
            key: 'dscheckbox',
            icon: 'dscheckbox',
            schema: {
              label: 'dscheckbox',
              type: 'dscheckbox',
              key: 'dscheckbox'
            }
          },
          dsselectboxes: {
            title: 'DS Selectboxes',
            key: 'dsselectboxes',
            icon: 'dsselectboxes',
            schema: {
              label: 'dsselectboxes',
              type: 'dsselectboxes',
              key: 'dsselectboxes'
            }
          },
          dsradio: {
            title: 'DS Radio',
            key: 'dsradio',
            icon: 'dsradio',
            schema: {
              label: 'dsradio',
              type: 'dsradio',
              key: 'dsradio'
            }
          },
          dsnumber: {
            title: 'DS Number',
            key: 'dsnumber',
            icon: 'dsnumber',
            schema: {
              label: 'dsnumber',
              type: 'dsnumber',
              key: 'dsnumber'
            }
          },
          dspassword: {
            title: 'DS Password',
            key: 'dspassword',
            icon: 'dspassword',
            schema: {
              label: 'dspassword',
              type: 'dspassword',
              key: 'dspassword'
            }
          },
          dsbutton: {
            title: 'DS Button',
            key: 'dsbutton',
            icon: 'dsbutton',
            schema: {
              label: 'dsbutton',
              type: 'dsbutton',
              key: 'dsbutton'
            }
          },
          dsemail: {
            title: 'DS Email',
            key: 'dsemail',
            icon: 'dsemail',
            schema: {
              label: 'dsemail',
              type: 'dsemail',
              key: 'dsemail'
            }
          },
          dsphone: {
            title: 'DS Phone',
            key: 'dsphone',
            icon: 'dsphone',
            schema: {
              label: 'dsphone',
              type: 'dsphone',
              key: 'dsphone'
            }
          },
          dsdatetime: {
            title: 'DS DateTime',
            key: 'dsdatetime',
            icon: 'dsdatetime',
            schema: {
              label: 'dsdatetime',
              type: 'dsdatetime',
              key: 'dsdatetime'
            }
          },
          dsday: {
            title: 'DS Day',
            key: 'dsday',
            icon: 'dsday',
            schema: {
              label: 'dsday',
              type: 'dsday',
              key: 'dsday'
            }
          },
          dstime: {
            title: 'DS Time',
            key: 'dstime',
            icon: 'dstime',
            schema: {
              label: 'dstime',
              type: 'dstime',
              key: 'dstime'
            }
          },
          dscurrency: {
            title: 'DS Currency',
            key: 'dscurrency',
            icon: 'dscurrency',
            schema: {
              label: 'dscurrency',
              type: 'dscurrency',
              key: 'dscurrency'
            }
          },
          dssurvey: {
            title: 'DS Survey',
            key: 'dssurvey',
            icon: 'dssurvey',
            schema: {
              label: 'dssurvey',
              type: 'dssurvey',
              key: 'dssurvey'
            }
          },
          dssignature: {
            title: 'DS Signature',
            key: 'dssignature',
            icon: 'dssignature',
            schema: {
              label: 'dssignature',
              type: 'dssignature',
              key: 'dssignature'
            }
          },
          dstable: {
            title: 'DS Table',
            key: 'dstable',
            icon: 'dstable',
            schema: {
              label: 'dstable',
              type: 'dstable',
              key: 'dstable'
            }
          },
        }
      }
    }
  };
  const validationSchema = Yup.object().shape({
    formName: Yup.string().required('Bu alan boş bırakılamaz'),
    // Diğer form alanlarına ait doğrulama kurallarını buraya ekleyebilirsiniz
  });

  return (
    <>

      <DashboardLayout>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Editor" value="1" />
              <Tab label="Kolonlar" value="2" />
              <Tab label="Item Three" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">

            <Splitter style={{ height: '100%' }}>
              <SplitterPanel className="flex align-items-center justify-content-center" size={100} minSize={10}>
                <FormBuilder
                  options={options}
                  form={jsonSchema}
                  onChange={onFormChange}
                />
              </SplitterPanel>
              <SplitterPanel className="flex align-items-center justify-content-center" size={15}>

                <Accordion style={{ width: "300px", height: "100%" }} activeIndex={0}>
                  <AccordionTab header="Form Özellikleri">
                    <FormikProvider value={formikProps}>
                      <Formik

                        enableReinitialize={true}
                        initialValues={formikProps.values}
                        validationSchema={validationSchema}
                        onSubmit={async (values: any, actions: any) => {

                          let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                            const r = Math.random() * 16 | 0;
                            const v = c === 'x' ? r : (r & 0x3 | 0x8);
                            return v.toString(16);
                          });


                          // let formRepo: FormReposistory;
                          // formRepo = new FormReposistory();

                          // let form: IForm;
                          // form = new IForm();

                          // const formRepo = new FormDataApi(configuration);


                          // console.log(values);

                          // form.formDesign = JSON.stringify(jsonSchema);
                          // form.formDescription = values.formDescription;


                          // if (formEditActive == false) {
                          //   form.id = guid;
                          //   Helper.showSuccess("Kayıt Başarılı");
                          //   form.formName = values.formName
                          //   form.isActive = +values.isActive;
                          //   formRepo.apiFormDataPost(form);
                          // }
                          // else {

                          //   Helper.showSuccess("Güncelleme Başarılı");
                          //   form.id = detailForm.id;
                          //   form.formName = detailForm.formName
                          //   form.formDescription = values.formDescription;
                          //   form.isActive = +values.isActive;

                          //   // await formRepo.update(formikProps.initialValues.formid, form);
                          //   await formRepo.apiFormDataPut(form);

                          //   Helper.showSuccess("Güncelleme Başarılı");
                          // }
                        }}
                      >
                        {({ values, handleChange, handleSubmit, handleBlur }) => (
                          <form onSubmit={handleSubmit}>
                            <label style={{ marginTop: '10px', marginLeft: '10px' }} htmlFor="">Form Adı</label>
                            <Input disabled={formEditActive} style={{ marginLeft: '10px' }} value={values.formName} onChange={handleChange} onBlur={handleBlur} name="formName" />

                            <label style={{ marginTop: '10px', marginLeft: '10px' }} htmlFor="description">Form Açıklama</label>
                            <Input style={{ marginLeft: '10px' }} value={values.formDescription} onChange={handleChange} onBlur={handleBlur} name="description" />


                            <label style={{ marginTop: '10px', marginLeft: '10px' }} htmlFor="firstName">Revizyon</label>
                            <Input disabled={true} style={{ marginLeft: '10px' }} value={values.Revision} onChange={handleChange} onBlur={handleBlur} name="formRevision" />
                            <label style={{ marginTop: '10px', marginLeft: '10px' }} htmlFor="isActive">Aktif mi?</label>
                            <CheckBox
                              id="check"
                              name="isActive"
                              checked={values.isActive} // State ile checked değerini bağla.
                              onChange={(e) => formikProps.setFieldValue("isActive", e.target.checked)
                              } // Değişiklikleri handle et ve state'i güncelle.


                            />

                            <Button style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '10px' }} type="Submit" color="success">
                              Kaydet
                            </Button>
                          </form>
                        )}
                      </Formik>
                    </FormikProvider>
                  </AccordionTab>
                </Accordion>

              </SplitterPanel>
            </Splitter>
          </TabPanel>
          <TabPanel value='2'>
            <div>
              <Input></Input>
            </div>
          </TabPanel>
          <TabPanel value='3' >Item Three</TabPanel>
        </TabContext>
      </DashboardLayout >
    </>
  );
};
export default CreateForm;
