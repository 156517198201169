import ronesanslogo from "../assets/Ronesans_holding.png"
import React, { createContext, useEffect, useRef, useState } from 'react';
import { ShellBar, StandardListItem, Avatar, Input, Icon, ShellBarItem, PopoverDomRef, Popover, ListMode, List, PopoverPlacementType, Button, MessageBoxTypes, Loader } from '@ui5/webcomponents-react';
import paletteIcon from "@ui5/webcomponents-icons/dist/palette.js";
import employeeIcon from "@ui5/webcomponents-icons/dist/employee.js";
import decline from "@ui5/webcomponents-icons/dist/decline.js";
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme.js";
import "@ui5/webcomponents-icons/dist/menu";
import { useUser } from "../hooks/userName";
import UserSelectDialog from "./UserSelectDialog";
import { ApproveItemsApi, BudgetAdminUserApi, BudgetAdminUserListDto, Configuration } from "../api/generated";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../hooks/useAlert";


import '../App.css';

const defaultTheme = "sap_horizon";


const themes = [
  { key: "sap_horizon", name: "Morning Horizon (Light)" },
  { key: "sap_horizon_dark", name: "Evening Horizon (Dark)" },
  { key: "sap_horizon_hcb", name: "Horizon High Contrast Black" },
  { key: "sap_horizon_hcw", name: "Horizon High Contrast White" },
];

export const ThemeContext = createContext(defaultTheme);

const ShellBarComponent = () => {
  const dispatchAlert = useAlert()

  const { username, setUsername } = useUser();
  const { userNameAndSurname, setuserNameAndSurname } = useUser();
  const { didProxy, setdidProxy } = useUser();


  const { loginUser, setLoginUser } = useUser();
  const { loginUserNameSurname, setloginUserNameSurname } = useUser();


  const [UserDialogVisible, setUserDialogVisible] = useState(false);
  const popoverRef = useRef<PopoverDomRef>(null);
  const [theme, setThemeState] = useState(defaultTheme);
  const [isProxy, setisProxy] = useState(false);

  const navigate = useNavigate();
  const [waitingCount, setwaitingCount] = useState(0);
  // const handleLogoClick = () => {
  //   navigate("/");
  // };
  const handleShellBarItemClick = (e: any) => {
    const { targetRef } = e.detail;
    popoverRef.current!.showAt(targetRef);
  };

  const handleThemeSwitch = (e: any) => {
    const { targetItem } = e.detail;
    setTheme(targetItem.dataset.key);



    setThemeState(targetItem.dataset.key);
  };

  const configuration = new Configuration({
    basePath: process.env.REACT_APP_BASE_PATH,
    apiKey: process.env.REACT_APP_API_KEY,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  useEffect(() => {
    // if (username == "") {
    //   setUsername("47064332");
    //   setuserNameAndSurname("MEYSE ÖZKESKİNNNNN");
    // }

    // setLoginUser("47064332");
    // setloginUserNameSurname("MEYSE ÖZKESKİNNNNN");
    if (username == "") {
      setUsername("GLB270495");
      setuserNameAndSurname("MÜGE BIÇAKÇI");
    }

    setLoginUser("GLB270495");
    setloginUserNameSurname("MÜGE BIÇAKÇI");


    getIsProxy()
    getApproveDetail();

  }, [loginUser]); //
  const changeGlobaluser = (e: any) => {
    console.log(e);

    var api = new BudgetAdminUserApi(configuration);
    var result = api.apiBudgetAdminUserCheckProxyUserUseridproxyUserGet(loginUser, e.userId)
      .then((response: AxiosResponse<boolean>) => {
        var canProxy = response.data;
        if (canProxy) {
          setUsername(e.userId);
          setuserNameAndSurname(e.defaultFullName)
          setUserDialogVisible(false);

          setdidProxy(true);
        }
        else {
          dispatchAlert({ message: "Bu kullanıcıya proxy olamazsınız..!", type: MessageBoxTypes.Error })
        }
      })



  };

  async function getIsProxy() {

    var api = new BudgetAdminUserApi(configuration);
    if (loginUser != null && loginUser != "") {
      var result = api.apiBudgetAdminUserGetUsernNameUserNameGet(loginUser)
        .then((response: AxiosResponse<BudgetAdminUserListDto>) => {
          setisProxy(response.data.isDoProxy!)
        })
        .catch(error => {

        }).finally(() => {

        });
    }
    else {
      setisProxy(false)
    }

  }

  async function getApproveDetail() {

    let api = new ApproveItemsApi(configuration);

    if (username != null && username != "") {
      var result = await api.apiApproveItemsGetApprovesUserNameGet(username, username, 0);

      console.log("ApproveItemsApi>>>>", result.data)
      setwaitingCount(result.data.count!);
    }


  }

  async function cancelProxy() {
    // setUsername("47064332");
    // setuserNameAndSurname("MEYSE ÖZKESKİNNNNN");

    setUsername("GLB270495");
    setuserNameAndSurname("MÜGE BIÇAKÇI");
    setdidProxy(false);
  }

  // const loaderClass = didProxy ? 'loader-green' : 'loader-red';

  return (
    <ThemeContext.Provider value={theme}>

      <ShellBar
        primaryTitle="Bütçe Yönetimi"
        logo={<img alt="SAP Logo" style={{ color: 'white', backgroundColor: 'white' }} src={ronesanslogo} />}

        // searchField={<Input readonly onChange={changeGlobaluser} icon={<Icon design="Contrast" interactive name="search" />} showClearIcon />}
        notificationsCount={waitingCount.toString()}
        onCoPilotClick={() => { }}
        onLogoClick={() => { }}
        onMenuItemClick={() => { }}
        onNotificationsClick={() => { navigate("/ApproveList") }}
        onProductSwitchClick={() => { }}
        onProfileClick={() => { }}
        onSearchButtonClick={() => { setUserDialogVisible(true) }}

        // profile={<Avatar><img src="" /></Avatar>}
        // searchField={<Input icon={<Icon interactive name="search" />} showClearIcon />}
        secondaryTitle={username + "-" + userNameAndSurname}
        // showCoPilot
        showNotifications
        showProductSwitch
      >
        {/* <ShellBarItem
        count="3"

        text="Bütçe Yönetimi"
      /> */}
        {isProxy && (
          <>
            <ShellBarItem icon={employeeIcon} onClick={() => setUserDialogVisible(true)} />
            {didProxy && (
              <ShellBarItem title="Proxy İptal" icon={decline} onClick={() => cancelProxy()} />
            )}
          </>
        )}
        <ShellBarItem icon={paletteIcon} onClick={handleShellBarItemClick} />

      </ShellBar>
      <Loader
        className={didProxy ? "Loader_loader_qpbhk_1.Loader_loaderDeterminate_qpbhk_16 loader-proxy" : "Loader_loader_qpbhk_1.Loader_loaderDeterminate_qpbhk_16 loader-nonproxy"}
        style={{ color: "brown" }}
        progress="100%"
        type="Determinate"
        title={didProxy ? "Proxy Kullanıcı" : ""}
      />


      <Popover
        ref={popoverRef}
        placementType={PopoverPlacementType.Bottom}
        className="contentNoPadding"
      >
        <List
          mode={ListMode.SingleSelect}
          onSelectionChange={handleThemeSwitch}
        >
          {themes.map((item) => (
            <StandardListItem
              data-key={item.key}
              key={item.key}
              selected={theme === item.key}
            >
              {item.name}
            </StandardListItem>
          ))}
        </List>
      </Popover>

      <UserSelectDialog
        open={UserDialogVisible}
        onClose={() => setUserDialogVisible(false)}

        onConfirm={changeGlobaluser}

      />
    </ThemeContext.Provider>
  );
};

export default ShellBarComponent;
