import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BusyIndicator, ThemeProvider } from '@ui5/webcomponents-react'; // Import ThemeProvider from UI5 Web Components for React
import { BrowserRouter } from 'react-router-dom';
import { BusyProvider } from './hooks/useBusy';
import { AlertProvider } from './hooks/useAlert';
import { UserProvider } from './hooks/userName';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>


    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ThemeProvider>
          <UserProvider> <BusyProvider> <AlertProvider><App /></AlertProvider></BusyProvider></UserProvider>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();






