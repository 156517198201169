//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";

import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/numbered-text";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/history";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, SegmentedButton, SegmentedButtonItem, Select, SelectDialog, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserInsertDto, BudgetPeriodUserUpdateDto, Configuration, GenericListApi, Permission, RequestType, SFUSerList, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { getStatusText, isValidDate, parseDateString } from '../utils/utils';
import { BudgetPeriodUserApi, BudgetPeriodUserListDto } from '../api/generated'
import ReactPaginate from 'react-paginate';
import { getuid } from 'process';
import { uuid } from 'uuidv4';
import { useAlert } from '../hooks/useAlert'
import { useBusy } from '../hooks/useBusy'
import ShowHistory from '../Components/ShowHistory';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/userName';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");

// setTheme("sap_belize");
function JobCodeRequest() {
    const { loginUser, setLoginUser } = useUser();
    const { username, setUsername } = useUser();
    const { sideNavCollapsed, setSideNavCollapsed } = useUser();
    const [addButonActive, setaddButonActive] = useState(false);

    const [id, setId] = useState("");
    const [dialogOpen, setdialogOpen] = useState(false);
    const [historyOpen, sethistoryOpen] = useState(false);
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");
    const [isedit, setisedit] = useState(false);
    const [gridData, setGridData] = useState<BudgetJobCodeRequestListDto[]>([]);
    const itemsPerPage = 10;

    const [jobCode, setJobCode] = useState("");
    const [jobCodeName, setjobCodeName] = useState("");
    const [jobCodeNameEnglish, setjobCodeNameEnglish] = useState("");
    const [jobRequestReason, setjobRequestReason] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const [jobCodeDataCount, setjobCodeDataCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);

    const navigate = useNavigate();
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()

    useEffect(() => {
        getActivePeriod();
        getData(pageCount, itemsPerPage);

    }, []); //

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % jobCodeDataCount;
        getData(newOffset);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    async function getData(skip: number = 0, top: number = 10) {

        console.log("gelenler", skip, top)
        var api = new BudgetJobCodeRequestApi(configuration);
        var data = await api.apiBudgetJobCodeRequestGet(skip, top);

        setGridData(data.data.budgetJobCodeRequestListDtoList!);
        console.log("data", data.data);

        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = data.data.budgetJobCodeRequestListDtoList!.slice(itemOffset, endOffset);
        setjobCodeDataCount(data.data.count!);
        setpageCount(Math.ceil(data.data.count! / itemsPerPage));
    }


    function onSubmit(e: any, approveId: string = "", sendApprove: boolean = false) {

        dispatchBusy({ isBusy: true });
        var api = new BudgetJobCodeRequestApi(configuration);
        if (isedit == true) {
            let updateDto = {} as BudgetJobCodeRequestUpdateDto;
            updateDto.id = id;
            updateDto.jobCode = jobCode;
            updateDto.name = jobCodeName;
            updateDto.startDate = parseDateString(startDate).toISOString();
            updateDto.endDate = parseDateString(endDate).toISOString();
            updateDto.isSend = false;
            updateDto.workflowHeadId = approveId;
            updateDto.name_En = jobCodeName;
            updateDto.requestReason = jobRequestReason;
            api.apiBudgetJobCodeRequestPut(updateDto)
                .then(response => {
                    if (sendApprove == false) {
                        dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    }
                    else {
                        dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                    }
                    setdialogOpen(false);
                    getData(pageCount, itemOffset);
                    dispatchBusy({ isBusy: false });
                })
                .catch(error => {
                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }

                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });

                });
        }
        else {
            let insertDto = {} as BudgetJobCodeRequestInsertDto;
            insertDto.jobCode = jobCode;
            insertDto.name = jobCodeName;
            insertDto.startDate = parseDateString(startDate).toISOString();
            insertDto.endDate = parseDateString(endDate).toISOString();
            insertDto.isSend = false;
            insertDto.name_En = jobCodeName;
            insertDto.requestReason = jobRequestReason;
            api.apiBudgetJobCodeRequestPost(insertDto)
                .then(response => {
                    dispatchAlert({ message: "Kayıt Ekleme Başarılı", type: MessageBoxTypes.Success })
                    setdialogOpen(false);
                    getData(pageCount, itemOffset);
                })
                .catch(error => {
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }

                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });

                });
        }
        if (e != null)
            e.preventDefault();
    }
    function closeDialog(): void {
        setdialogOpen(false);
    }
    function onNew(): void {


        navigate("/PositionDetail")
        // setisedit(false);
        // setdialogOpen(true);

    }
    async function onSearhboxSelect(event: any) {

    }
    function showHistory(obj: any): void {

        setselectedWorkFlowId(obj.cell.row.original.workflowHeadId);
        sethistoryOpen(true);
    }
    function onEdit(obj: any): void {


        navigate("/PositionDetail?id=" + obj.cell.row.original.id);
        return;

        setisedit(true);
        setdialogOpen(true);
        dispatchBusy({ isBusy: true });
        var api = new BudgetJobCodeRequestApi(configuration);
        var result = api.apiBudgetJobCodeRequestIdGet(obj.cell.row.original.id)
            .then((response: AxiosResponse<BudgetJobCodeRequestListDto>) => {

                setId(response.data.id!);
                setdialogOpen(true);
                setJobCode(response.data.jobCode!);
                setjobRequestReason(response.data.requestReason!);
                setjobCodeName(response.data.name!);
                setjobCodeNameEnglish(response.data.name_En!)
                setstartDate(format(parseISO(response.data.startDate!), 'dd/MM/yyyy'));
                setEndDate(format(parseISO(response.data.endDate!), 'dd/MM/yyyy'));
            })
            .catch(error => {
                dispatchAlert({ message: "Bir Hata Oluştu", type: MessageBoxTypes.Error });
            });

        dispatchBusy({ isBusy: false });
    }
    function onDelete(obj: any): void {
        setdeleteOpen(true);
        setselectedWorkFlowId(obj.cell.row.original.id)
    }
    async function handleDeleteDialog(event: any) {
        setdeleteOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            var api = new BudgetJobCodeRequestApi(configuration);
            dispatchBusy({ isBusy: true });
            var result = await api.apiBudgetJobCodeRequestIdDelete(selectedWorkFlowId)
            await getData(pageCount, itemOffset);
            dispatchBusy({ isBusy: false });
        }
        else {
            return;
        }

    }
    const handleJobCodeInput = (e: any) => {
        setJobCode(e.target.value);
    };
    const handleJobCodeNameInput = (e: any) => {
        setjobCodeName(e.target.value);
    };
    const handleJobCodeNameEnglishInput = (e: any) => {
        setjobCodeNameEnglish(e.target.value);
    };
    const handleJobCodeRequestReasonInput = (e: any) => {
        setjobRequestReason(e.target.value);
    };
    const handleStartDateInput = (e: any) => {
        setstartDate(e.target.value);
    };
    const handleEndDateInput = (e: any) => {
        setEndDate(e.target.value);
    };

    async function onSegmentedButtonChange(event: CustomEvent) {
        dispatchBusy({ isBusy: true });
        var api = new BudgetJobCodeRequestApi(configuration);
        var data = await api.apiBudgetJobCodeRequestGet();

        const selectedItemTxt = event.detail.selectedItem.innerText;
        switch (selectedItemTxt) {
            case "Hepsi":
                setGridData(data.data.budgetJobCodeRequestListDtoList!);
                break;
            case "Onay Sürecinde":
                var inProgress = data.data.budgetJobCodeRequestListDtoList!.filter(i => i.workflowHead?.workFlowStatus == 1)
                setGridData(inProgress)
                break;
            case "Tamamlandı":
                var completed = data.data.budgetJobCodeRequestListDtoList!.filter(i => i.workflowHead?.workFlowStatus == 2)
                setGridData(completed)
                break;

            default:
                break;
        }
        dispatchBusy({ isBusy: false });
    }

    async function getActivePeriod() {
        dispatchBusy({ isBusy: true });
        var api = new BudgetPeriodUserApi(configuration);
        console.log(loginUser)

        var result = api.apiBudgetPeriodUserGetActivePeriodUseridGet(username, 4)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {
                setaddButonActive(false);
            })
            .catch(error => {
                setaddButonActive(true);
            }).finally(() => {

            });
        console.log(result);
        dispatchBusy({ isBusy: false });
    }

    return (
        <DashboardLayout>


            <DynamicPage
                headerContent={
                    <DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={
                    <DynamicPageTitle
                        actions={<><Button icon='add' disabled={addButonActive} onClick={onNew} design="Emphasized">Yeni</Button><Button design="Transparent" /></>}
                        breadcrumbs={<Breadcrumbs><BreadcrumbsItem>Pozisyon</BreadcrumbsItem></Breadcrumbs>} header={<Title>Pozisyonlar</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>} subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <SegmentedButton
                    id="idSB1"
                    onSelectionChange={onSegmentedButtonChange}
                >
                    <SegmentedButtonItem>
                        Hepsi
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Onay Sürecinde
                    </SegmentedButtonItem>
                    <SegmentedButtonItem>
                        Tamamlandı
                    </SegmentedButtonItem>
                </SegmentedButton>
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'İş Kodu',
                            accessor: 'jobCode',
                            autoResizable: true,
                            headerTooltip: 'İş Kodu',
                            width: 100
                        },
                        {
                            Header: 'İş Kodu Adı',
                            accessor: 'name',
                            autoResizable: true,
                            headerTooltip: 'İş Kodu Adı',
                            width: 130
                        },
                        {
                            Header: 'Başlangıç Tarihi',
                            accessor: 'startDate',
                            autoResizable: true,
                            headerTooltip: 'Başlangıç Tarihi',
                            width: 130,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Header: 'Bitiş Tarihi',
                            accessor: 'endDate',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 130,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Header: 'Durumu',
                            accessor: 'workflowHead.workFlowStatus',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 150,
                            hAlign: TextAlign.Center,
                            // Cell: ({ value }: any) => getStatusText(value)
                            Cell: ({ value }: any) => {
                                // Satır rengi belirleme
                                let textcolor: string;
                                switch (value) {
                                    case 1:
                                        textcolor = "#ffce00"; //turuncu
                                        break;
                                    case 2:
                                        textcolor = "#399918"; //yesil
                                        break;
                                    case 4:
                                        textcolor = "#fd0200"; //kirmizi
                                        break;
                                    default:
                                        textcolor = "black";
                                }

                                // Tüm hücreler için arka plan rengini ayarla
                                return (
                                    <div style={{ color: textcolor }}>
                                        {getStatusText(value)}
                                    </div>
                                );
                            },
                        },
                        {
                            Header: 'Kayıt Oluşturma Tarihi',
                            accessor: 'createdDate',
                            autoResizable: true,
                            headerTooltip: 'createdDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Header: 'Güncellenme Tarihi',
                            accessor: 'updatedDate',
                            autoResizable: true,
                            headerTooltip: 'updatedDate',
                            width: 170,
                            hAlign: TextAlign.Center,
                            Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={(instance.cell.row.original.workflowHead?.workFlowStatus != null &&
                                            instance.cell.row.original.workflowHead?.workFlowStatus != 4
                                        ) ? true : false} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 85
                        },
                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox style={{ alignItems: 'center' }}>
                                        <Button icon="history" onClick={() => showHistory(instance)} disabled={instance.cell.row.original.workflowHeadId != null ? false : true} />

                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Tarihçe',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'history',
                            width: 80
                        }
                    ]}

                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    // header="Table Title"
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="None"
                    tableHooks={[]} />
                <div style={{
                    // marginRight: "16.5%",
                    width: sideNavCollapsed ? "83.5%" : "94.4%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 10,
                    padding: '10px 0'
                }}>  <ReactPaginate
                        previousLabel="Geri"
                        nextLabel="İleri"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>
            </DynamicPage>
            {historyOpen && (
                <ShowHistory

                    approveId={selectedWorkFlowId}
                    open={historyOpen}
                    onClose={() => sethistoryOpen(false)}
                />
            )}
            <MessageBox
                open={deleteOpen}
                onClose={handleDeleteDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Seçilen pozisyon silinecektir, devam edilsin mi?
            </MessageBox>
        </DashboardLayout>
    );
}
export default JobCodeRequest;
function setSelectedId(arg0: any) {
    throw new Error('Function not implemented.');
}

