import { HeaderComponent } from "./Toggle";
import { DSInputComponent } from "./DSInput";
import { DSSelectComponent } from "./DSSelect";
import { DSCheckboxComponent } from "./DSCheckBox";
import { DSSelectBoxesComponent } from "./DSSelectBoxes";
import { DSRadioComponent } from "./DSRadio";
import { DSNumberComponent } from "./DSNumber";
import { DSPasswordComponent } from "./DSPassword";
import { DSButtonComponent } from "./DSButton";
import { DSEmailComponent } from "./DSEmail";
import { DSPhoneComponent } from "./DSPhone";
import { DSDayComponent } from "./DSDay";
import { DSDatetimeComponent } from "./DSDatetime";
import { DSTimeComponent } from "./DSTime";
import { DSCurrencyComponent } from "./DSCurrency";
import { DSSurveyComponent } from "./DSSurvey";
import { DSSignatureComponent } from "./DSSignature";
import { DSTableComponent } from "./DSTable";
export default {
  HeaderComponent,
  DSInputComponent,
  DSSelectComponent,
  DSCheckboxComponent,
  DSSelectBoxesComponent,
  DSRadioComponent,
  DSNumberComponent,
  DSPasswordComponent,
  DSButtonComponent,
  DSEmailComponent,
  DSPhoneComponent,
  DSDatetimeComponent,
  DSDayComponent,
  DSTimeComponent,
  DSCurrencyComponent,
  DSSurveyComponent,
  DSSignatureComponent,
  DSTableComponent
};
