//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/sys-cancel";
import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/delete";
import "@ui5/webcomponents-icons/dist/detail-view";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FilterBar, FilterGroupItem, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, Select, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, SplitterElement, SplitterLayout, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, Toolbar, ToolbarSeparator, ToolbarSpacer, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { ApproveHeadInfo, ApproveItems, ApproveItemsApi, ApproveItemsDto, ApproverStatus, BudgetJobCodeRequestListDto, BudgetPeriodApi, BudgetPeriodInsertDto, BudgetPeriodListDto, BudgetPeriodUpdateDto, Configuration, WorkFlowApi, WorkFlowContiuneApiDto, WorkFlowDefinationApi, WorkFlowDefinationListDto } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parse, parseISO } from 'date-fns';
import { tr } from "date-fns/locale";
import { isValidDate, parseDateString } from '../utils/utils';
import { useAlert } from '../hooks/useAlert';
import { useBusy } from '../hooks/useBusy'
import { FaLaughSquint } from 'react-icons/fa';
import { useUser } from '../hooks/userName';
import { useNavigate } from 'react-router-dom';
import ShowHistory from '../Components/ShowHistory';
import UserSelectDialog from '../Components/UserSelectDialog';
import ReactPaginate from 'react-paginate';

import "@ui5/webcomponents-icons/dist/paper-plane";
import "@ui5/webcomponents-icons/dist/pending";
import "@ui5/webcomponents-icons/dist/accept";
import "@ui5/webcomponents-icons/dist/decline";
// setTheme("sap_horizon");
setTheme("sap_fiori_3");
// setTheme("sap_belize");
function AdminApprove() {

    const [historyOpen, sethistoryOpen] = useState(false);
    const navigate = useNavigate();
    const [listData, setListDto] = useState<ApproveHeadInfo>();
    const [gridData, setGridData] = useState<ApproveItemsDto[]>([]);
    const { username, setUsername } = useUser();
    const [rejectdialogOpen, setrejectdialogOpen] = useState(false);
    var [rejectText, setrejectText] = useState("");
    const [selectedInstance, setselectedInstance] = useState(null);
    const [selectedWorkFlowId, setselectedWorkFlowId] = useState("");

    const [selectedStatus, setselectedStatus] = useState<ApproverStatus>();

    const [UserDialogVisible, setUserDialogVisible] = useState(false);
    const [selectedRequestUser, setselectedRequestUser] = useState("");
    const [selectedWaitingUser, setselectedWaitingUser] = useState("");
    const [activeInput, setActiveInput] = useState<'request' | 'waiting'>('request');

    const [statusText, setstatusText] = useState("");

    const formatDate = (date: Date | null) => {
        if (!date) return '';
        return new Intl.DateTimeFormat('tr-TR', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
    };
    const [selectedDate, setSelectedDate] = useState<string>(formatDate(new Date()));



    const dispatchAlert = useAlert()
    const dispatchBusy = useBusy()


    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const [processTypes, setProcessTypes] = useState<WorkFlowDefinationListDto[]>([]);
    const [selectedProcessType, setselectedProcessType] = useState("");
    const [selectedProcessTypeId, setselectedProcessTypeId] = useState("");

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    async function getApproveList() {
        var api = new ApproveItemsApi(configuration);
        var data = await (await api.apiApproveItemsAllGet()).data;

        setListDto(data);
        return data;
    }
    function showHistory(obj: any): void {

        if (obj.cell.row.original.workFlowItem.workflowHead.id != null) {
            setselectedWorkFlowId(obj.cell.row.original.workFlowItem.workflowHead.id);
            sethistoryOpen(true);
        }
    }
    async function getApproveDetail(status: ApproverStatus) {

        if (status == 0) {
            setstatusText("Bekleyenler");
        }
        else if (status == 1) {
            setstatusText("Onaylananlar");
        }
        else if (status == 2) {
            setstatusText("Reddedilenler");
        }
        else if (status == 3) {
            setstatusText("Gönderdiklerim");
        }

        setselectedStatus(status);
        dispatchBusy({ isBusy: true });

        let api = new ApproveItemsApi(configuration);

        var result = await api.apiApproveItemsGetAdminApprovesUserNameGet(username, status);

        // var result = listData!.items?.filter(e => e.approverStatus == type);
        result.data.sort((a, b) => {
            let dateA = a.workFlowItem?.workflowHead?.createdDate ? new Date(a.workFlowItem?.workflowHead?.createdDate).getTime() : 0;
            let dateB = b.workFlowItem?.workflowHead?.createdDate ? new Date(b.workFlowItem?.workflowHead?.createdDate).getTime() : 0;
            return dateB - dateA;
        });
        setGridData(result.data!);
        console.log(">>>>", result.data)
        dispatchBusy({ isBusy: false });

    }

    const handleSelection = (event: any) => {
        const selectedIndices = Object.keys(event.detail.selectedRowIds).filter(index => event.detail.selectedRowIds[index]);
        const selectedRowsData = selectedIndices.map(index => gridData[parseInt(index)]);

        setSelectedRows(selectedRowsData);
    };


    function multipleApprove() {
        console.log("Selected Rows:", selectedRows);
        selectedRows.forEach(row => {
            if (row) {
                dispatchBusy({ isBusy: true });
                var workFlowApi = new WorkFlowApi(configuration);
                let contiuneDto: WorkFlowContiuneApiDto = {};
                contiuneDto.approveItem = row.id;
                contiuneDto.workFlowItemId = row.workflowItemId;
                contiuneDto.userName = username;
                contiuneDto.input = "yes";
                contiuneDto.note = "";

                workFlowApi.apiWorkFlowContiunePost(contiuneDto)
                    .then(async response => {
                        // await getApproveList().then(async response => {
                        //     var result = response!.items?.filter(e => e.approverStatus == 0);
                        //     setGridData(result!);
                        // })
                        await getApproveDetail(selectedStatus!);

                        dispatchAlert({ message: "Onay Başarılı", type: MessageBoxTypes.Success });
                    })
                    .catch(error => {
                        dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Warning });
                    }).finally(() => {
                        dispatchBusy({ isBusy: false });
                    });

                setSelectedRows([]);
            } else {
                console.error("hata", row);
            }
        });
    }

    function multipleReject() {
        console.log("Selected Rows:", selectedRows);
        selectedRows.forEach(row => {
            if (row) {
                dispatchBusy({ isBusy: true });
                var workFlowApi = new WorkFlowApi(configuration);
                let contiuneDto: WorkFlowContiuneApiDto = {};
                contiuneDto.approveItem = row.id;
                contiuneDto.workFlowItemId = row.workflowItemId;
                contiuneDto.userName = username;
                contiuneDto.input = "no";
                contiuneDto.note = rejectText;


                workFlowApi.apiWorkFlowContiunePost(contiuneDto)
                    .then(async response => {

                        // await getApproveList().then(async response => {
                        //     var result = response!.items?.filter(e => e.approverStatus == 0);
                        //     setGridData(result!);
                        // })
                        await getApproveDetail(selectedStatus!);

                        dispatchAlert({ message: "Red Başarılı", type: MessageBoxTypes.Success });


                    })
                    .catch(error => {
                        dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Warning });
                    }).finally(() => {
                        dispatchBusy({ isBusy: false });
                    });


                getApproveList();

                setrejectdialogOpen(false);
                setrejectText("");
                setSelectedRows([]);
            } else {
                console.error("hata", row);
            }
        });
    }

    function onApprove(obj: any): void {

        dispatchBusy({ isBusy: true });
        var workFlowApi = new WorkFlowApi(configuration);
        let contiuneDto: WorkFlowContiuneApiDto = {};
        contiuneDto.approveItem = obj.cell.row.original.id;
        contiuneDto.workFlowItemId = obj.cell.row.original.workflowItemId;
        contiuneDto.userName = username;
        contiuneDto.input = "yes";
        contiuneDto.note = "";




        workFlowApi.apiWorkFlowContiunePost(contiuneDto)
            .then(async response => {


                // await getApproveList().then(async response => {
                //     var result = response!.items?.filter(e => e.approverStatus == 0);
                //     setGridData(result!);
                // })
                await getApproveDetail(selectedStatus!);

                dispatchAlert({ message: "Onay Başarılı", type: MessageBoxTypes.Success });


            })
            .catch(error => {
                dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Warning });
            }).finally(() => {
                dispatchBusy({ isBusy: false });
            });

    }


    async function openDetail(obj: any) {


        let api = new ApproveItemsApi(configuration);
        let result = await api.apiApproveItemsGetOpenDetailGetOpenDetailGet(obj.cell.row.original.workFlowItem.workflowHead.id)

        window.open(result.data);
        // navigate(result.data.toString());

    }
    function openRejectDialog(obj: any) {
        setrejectdialogOpen(true);
        setselectedInstance(obj);
    }
    function handleTextChange(event: any) {
        const newText = event.target.value;
        setrejectText(newText);
    }
    function onReject(obj: any): void {
        dispatchBusy({ isBusy: true });
        var workFlowApi = new WorkFlowApi(configuration);
        let contiuneDto: WorkFlowContiuneApiDto = {};
        contiuneDto.approveItem = obj.cell.row.original.id;
        contiuneDto.workFlowItemId = obj.cell.row.original.workflowItemId;
        contiuneDto.userName = username;
        contiuneDto.input = "no";
        contiuneDto.note = rejectText;


        workFlowApi.apiWorkFlowContiunePost(contiuneDto)
            .then(async response => {

                // await getApproveList().then(async response => {
                //     var result = response!.items?.filter(e => e.approverStatus == 0);
                //     setGridData(result!);
                // })
                await getApproveDetail(selectedStatus!);

                dispatchAlert({ message: "Red Başarılı", type: MessageBoxTypes.Success });


            })
            .catch(error => {
                dispatchAlert({ message: "Bir hata oluştu", type: MessageBoxTypes.Warning });
            }).finally(() => {
                dispatchBusy({ isBusy: false });
            });


        getApproveList();

        setrejectdialogOpen(false);
        setrejectText("");
    }
    useEffect(() => {
        getProcessTypes();
        getApproveList();

        const defaultDate = formatDate(new Date());
        setSelectedDate(defaultDate);

        getApproveDetail(ApproverStatus.NUMBER_0)
        setselectedStatus(ApproverStatus.NUMBER_0);
    }, []); //

    async function getProcessTypes() {
        var api = new WorkFlowDefinationApi(configuration);
        var result = api.apiWorkFlowDefinationGet()
            .then((response: AxiosResponse<WorkFlowDefinationListDto[]>) => {

                console.log("WorkFlowDefinationApi", response.data)
                setProcessTypes(response.data);
            })
            .catch(error => {

            }).finally(() => {

            });
    }

    async function onProcessComboChange(event: any) {
        console.log(event)
        const selectedItem = event.detail.item;
        var selectedItemId = selectedItem.getAttribute('data-id');
        setselectedProcessType(selectedItem.text)
        setselectedProcessTypeId(selectedItemId)
        dispatchBusy({ isBusy: true });
        // await getApproveDetail(selectedStatus!,0,7,selectedItemId);
    }

    async function onApproveDatePickerChange(event: any) {
        dispatchBusy({ isBusy: true });
        console.log(event)
        const selectedDate = event.detail.value;
        const selectedDateObj = parse(selectedDate, 'd MMM yyyy', new Date(), { locale: tr });


        var toShowData: ApproveItemsDto[] = [];
        await getApproveList().then(async response => {
            var result = response!.items;
            if (result) {
                result.forEach(item => {
                    const testDateObj = new Date(item.workFlowItem?.workflowHead?.createdDate!);
                    if (
                        selectedDateObj.getFullYear() === testDateObj.getFullYear() &&
                        selectedDateObj.getMonth() === testDateObj.getMonth() &&
                        selectedDateObj.getDate() === testDateObj.getDate()
                    ) {
                        toShowData.push(item);
                    }

                });
            }

        })
        setGridData(toShowData);
        dispatchBusy({ isBusy: false });

    }
    async function onProcessDatePickerChange(event: any) {
        dispatchBusy({ isBusy: true });
        console.log(event)
        const selectedDate = event.detail.value;
        const selectedDateObj = parse(selectedDate, 'd MMM yyyy', new Date(), { locale: tr });


        var toShowData: ApproveItemsDto[] = [];
        await getApproveList().then(async response => {
            var result = response!.items;
            if (result) {
                result.forEach(item => {
                    const testDateObj = new Date(item.updatedDate!);
                    if (
                        selectedDateObj.getFullYear() === testDateObj.getFullYear() &&
                        selectedDateObj.getMonth() === testDateObj.getMonth() &&
                        selectedDateObj.getDate() === testDateObj.getDate()
                    ) {
                        toShowData.push(item);
                    }

                });
            }

        })
        setGridData(toShowData);
        dispatchBusy({ isBusy: false });

    }
    // async function onRequestingUserChange(event: any) {

    // }
    async function onWaitingUserChange(event: any) {

    }

    const onRequestingUserChange = (e: any) => {
        console.log("onRequestingUserChange", e)
        if (activeInput === 'request') {
            setselectedRequestUser(e.defaultFullName);
        } else if (activeInput === 'waiting') {
            setselectedWaitingUser(e.defaultFullName);
        }
        setUserDialogVisible(false);
    };

    return (
        <>
            <DashboardLayout>
                <SplitterLayout

                    style={{
                        height: '800px',
                        width: '100%'
                    }}
                >
                    <SplitterElement minSize={180} resizable={false} size="10%">

                        <FlexBox style={{ height: '100%', width: '100%' }} alignItems="Center" justifyContent="Center">
                            <List
                                style={{ width: '100%', height: '100%' }}
                                growing="None"
                                headerText="Onay Kutusu"
                                mode="None"
                                onItemClick={function _a() { }}
                                onItemClose={function _a() { }}
                                onItemDelete={function _a() { }}
                                onItemToggle={function _a() { }}
                                onLoadMore={function _a() { }}
                                onSelectionChange={function _a() { }}
                                separators="All"
                            >
                                <StandardListItem icon='paper-plane' onClick={() => getApproveDetail(ApproverStatus.NUMBER_3)} >
                                    Gönderdiklerim
                                </StandardListItem>
                                <StandardListItem icon='pending' onClick={() => getApproveDetail(ApproverStatus.NUMBER_0)} >
                                    Bekleyenler
                                </StandardListItem>
                                <StandardListItem icon='accept' onClick={() => getApproveDetail(ApproverStatus.NUMBER_1)} >
                                    Onaylananlar
                                </StandardListItem>
                                <StandardListItem icon='decline' onClick={() => getApproveDetail(ApproverStatus.NUMBER_2)} >
                                    Red
                                </StandardListItem>
                            </List>
                        </FlexBox>

                    </SplitterElement>
                    <SplitterElement style={{ width: '80%', marginBottom: '30px' }}>
                        <div style={{ width: '75%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <FilterBar style={{ paddingInlineEnd: '100px', fontWeight: "bold" }} header={statusText}>
                                <FilterGroupItem
                                    label="Süreç Adı"
                                >
                                    <ComboBox
                                        value={selectedProcessType}
                                        onSelectionChange={onProcessComboChange}
                                    >
                                        {processTypes.map((entry) => (
                                            <ComboBoxItem key={entry.id} text={entry.workflowName!} data-id={entry.id} />

                                        ))}
                                    </ComboBox>
                                </FilterGroupItem>

                                <FilterGroupItem
                                    label="Talep Eden"
                                >
                                    <Input
                                        icon={<Icon name="search" />}
                                        value={selectedRequestUser}
                                        onClick={() => {
                                            setActiveInput('request');
                                            setUserDialogVisible(true);
                                        }}
                                    />
                                </FilterGroupItem>
                                <FilterGroupItem label='Onay Oluşturma Tarihi'>
                                    <DatePicker
                                        onChange={onApproveDatePickerChange}
                                        primaryCalendarType="Gregorian"
                                        valueState="None"
                                        value={selectedDate}
                                    />
                                </FilterGroupItem>
                                <FilterGroupItem label='İşlem Tarihi'>
                                    <DatePicker
                                        onChange={onProcessDatePickerChange}
                                        primaryCalendarType="Gregorian"
                                        valueState="None"
                                    />
                                </FilterGroupItem>
                            </FilterBar>
                            <AnalyticalTable
                                style={{ width: '100%', overflowY: 'auto' }}
                                columns={[
                                    {
                                        Cell: (instance: any) => {

                                            const { cell: any, row, webComponentsReactProperties } = instance;
                                            // disable buttons if overlay is active to prevent focus
                                            const isOverlay = webComponentsReactProperties.showOverlay;
                                            // console.log('This is your row data', row.original);
                                            const isSelectable = row.original.approverStatus === 0;

                                            if (row.original.approverStatus != 0) {

                                                return (
                                                    <FlexBox >
                                                        <Button tooltip='Detay' color='red' design='Emphasized' icon="detail-view" onClick={() => openDetail(instance)} disabled={isOverlay} />
                                                    </FlexBox>)
                                            }
                                            else {
                                                return (
                                                    <FlexBox >
                                                        <Button hidden={selectedStatus === 0 ? false : true} tooltip='Onayla' icon="accept" design='Positive' onClick={() => onApprove(instance)} disabled={isOverlay} />
                                                        <Button hidden={selectedStatus === 0 ? false : true} tooltip='Reddet' color='red' design='Negative' icon="sys-cancel" onClick={() => openRejectDialog(instance)} disabled={isOverlay} />
                                                        <Button tooltip='Detay' color='red' design='Emphasized' icon="detail-view" onClick={() => openDetail(instance)} disabled={isOverlay} />
                                                    </FlexBox>
                                                );
                                            }
                                        },
                                        // cellLabel: ({ cell }) => {
                                        //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                        //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                        // },
                                        Header: 'Actions',
                                        accessor: '.',
                                        disableFilters: true,
                                        disableGroupBy: true,
                                        disableResizing: true,
                                        disableSortBy: true,
                                        id: 'actions',

                                        width: 130
                                    },
                                    {
                                        Header: 'Süreç Adı',
                                        accessor: 'workFlowItem.workflowHead.workflowName',
                                        autoResizable: true,
                                        headerTooltip: 'Süreç Adı',
                                        width: 150
                                    },

                                    {
                                        Header: 'Süreç Numarası',
                                        accessor: 'shortWorkflowItemId',
                                        autoResizable: true,
                                        headerTooltip: 'Süreç Numarası',
                                        width: 150
                                    },
                                    {
                                        Header: 'Onay Numarası',
                                        accessor: 'shortId',
                                        autoResizable: true,
                                        headerTooltip: 'Süreç Adı',
                                        width: 150
                                    },

                                    {
                                        Header: 'Onay Adı',
                                        accessor: 'workFlowItem.nodeName',
                                        autoResizable: true,
                                        headerTooltip: 'Onay Adı',
                                        width: 130
                                    },
                                    {
                                        Header: 'Detay',
                                        accessor: 'workFlowItem.workflowHead.workFlowInfo',
                                        autoResizable: true,
                                        headerTooltip: 'Onay Adı',
                                        width: 150
                                    },
                                    {
                                        Header: 'Talep Eden',
                                        accessor: 'workFlowItem.workflowHead.createUser',
                                        autoResizable: true,
                                        headerTooltip: 'Talep Eden',
                                        width: 100
                                    },
                                    {
                                        Header: 'Beklenen',
                                        accessor: 'approveUserNameSurname',
                                        autoResizable: true,
                                        headerTooltip: 'Onayı Beklenen',
                                        width: 120
                                    },
                                    {
                                        Header: 'İşlem Yapan Kullanıcı',
                                        accessor: 'approvedUser_RuntimeNameSurname',
                                        autoResizable: true,
                                        headerTooltip: 'Onayı Beklenen',
                                        width: 150
                                    },

                                    {
                                        Header: 'Onay Oluşma Zamanı',
                                        accessor: 'workFlowItem.workflowHead.createdDate',
                                        autoResizable: true,
                                        headerTooltip: 'Talep Tarihi',
                                        width: 170,
                                        hAlign: TextAlign.Center,
                                        Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                                    },

                                    {
                                        Header: 'İşlem Tarihi',
                                        accessor: 'updatedDate',
                                        autoResizable: true,
                                        headerTooltip: 'Talep Tarihi',
                                        width: 150,
                                        hAlign: TextAlign.Center,
                                        Cell: ({ value }: any) => value ? format(parseISO(value!), 'dd/MM/yyyy hh:mm') : null // Tarih formatlama
                                    },
                                    ...(selectedStatus === 2 || selectedStatus === 3
                                        ? [{
                                            Header: 'Red nedeni',
                                            accessor: 'approvedUser_RuntimeNote',
                                            autoResizable: true,
                                            headerTooltip: 'Red nedeni',
                                            width: 150,
                                            hAlign: TextAlign.Center
                                        }]
                                        : []),

                                    {
                                        Cell: (instance: any) => {
                                            const { cell: any, row, webComponentsReactProperties } = instance;
                                            // disable buttons if overlay is active to prevent focus
                                            const isOverlay = webComponentsReactProperties.showOverlay;
                                            // console.log('This is your row data', row.original);
                                            return (
                                                <FlexBox style={{ alignItems: 'center' }}>
                                                    <Button icon="history" onClick={() => showHistory(instance)} disabled={isOverlay} />

                                                </FlexBox>
                                            );
                                        },
                                        // cellLabel: ({ cell }) => {
                                        //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                                        //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                                        // },
                                        Header: 'Tarihçe',
                                        accessor: '.',
                                        disableFilters: true,
                                        disableGroupBy: true,
                                        disableResizing: true,
                                        disableSortBy: true,
                                        id: 'history',
                                        width: 60
                                    }


                                ]}

                                data={gridData}
                                filterable
                                groupBy={[]}
                                groupable
                                // header="Table Title"
                                infiniteScroll
                                onRowSelect={handleSelection}
                                visibleRows={gridData.length}
                                selectionMode={selectedStatus === 0 ? 'MultiSelect' : 'None'}
                                tableHooks={[]}
                            />
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: 'white',
                                zIndex: 10,
                                padding: '10px 0'
                            }}>
                                <ReactPaginate
                                    previousLabel="Geri"
                                    nextLabel="İleri"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={5}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    // onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        </div>

                    </SplitterElement>


                </SplitterLayout>

                {Object.keys(selectedRows).length > 0 && (
                    <footer>
                        <Toolbar >
                            <ToolbarSpacer />
                            <Button tooltip='Onayla' icon="accept" design='Positive' onClick={multipleApprove}>Onayla</Button>
                            <Button tooltip='Reddet' color='red' design='Negative' icon="sys-cancel" onClick={multipleReject}>Reddet</Button>
                        </Toolbar>

                    </footer>
                )}

            </DashboardLayout>

            <Dialog open={rejectdialogOpen} style={{ width: "40%", height: "40%" }}
                footer={
                    <Bar
                        endContent={[
                            <Button onClick={() => onReject(selectedInstance)}>Gönder</Button>,
                            <Button onClick={() => setrejectdialogOpen(false)}>İptal</Button>
                        ]}
                    />
                }>
                <Label children='Red sebebi giriniz' />
                <TextArea onChange={handleTextChange} value={rejectText} />
            </Dialog>

            {historyOpen && (
                <ShowHistory

                    approveId={selectedWorkFlowId}
                    open={historyOpen}
                    onClose={() => sethistoryOpen(false)}
                />
            )}

            <UserSelectDialog
                open={UserDialogVisible}
                onClose={() => setUserDialogVisible(false)}
                onConfirm={onRequestingUserChange}
            />
        </>

    );
}
export default AdminApprove;
