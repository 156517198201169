//https://sdk.openui5.org/test-resources/sap/m/demokit/iconExplorer/webapp/index.html#/overview/SAP-icons/?tab=grid&icon=add&search=Add
import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import "@ui5/webcomponents-icons-tnt/dist/antenna.js";
import "@ui5/webcomponents-icons/dist/decline";
import "@ui5/webcomponents-icons/dist/add";
import { useNavigate } from "react-router-dom";
import "@ui5/webcomponents-icons/dist/save";
import "@ui5/webcomponents-icons/dist/delete";
import { useForm, Controller } from 'react-hook-form';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { AnalyticalTable, Avatar, Badge, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, DatePicker, Dialog, DynamicPage, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, List, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, Select, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Table, TableCell, TableColumn, TableRow, TextAlign, TextArea, ThemeProvider, Title, VerticalAlign } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPeriodApi, BudgetPeriodInsertDto, BudgetPeriodListDto, BudgetPeriodUpdateDto, Configuration, WorkFlowDefinationApi, WorkFlowDefinationListDto } from '../api/generated';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { isValidDate } from '../utils/utils';
// setTheme("sap_horizon");
setTheme("sap_fiori_3");
// setTheme("sap_belize");
function WorkFlowList() {
    const navigate = useNavigate();
    const [gridData, setGridData] = useState<WorkFlowDefinationListDto[]>([]);

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });


    useEffect(() => {
        getData();
    }, []); //
    async function getData() {

        var api = new WorkFlowDefinationApi(configuration);
        var data = await api.apiWorkFlowDefinationGet();
        setGridData(data.data);
    }
    function onNew(obj: any): void {
        navigate("/CreateWorkFlow");
    }
    function onDelete(obj: any): void {

    }
    function onEdit(obj: any): void {
        navigate("/CreateWorkFlow?id=" + obj.cell.row.original.id);

    }
    return (
        <DashboardLayout>



            <DynamicPage
                headerContent={<DynamicPageHeader><FlexBox wrap="Wrap"><FlexBox direction="Column"></FlexBox><span style={{ width: '1rem' }} /><FlexBox direction="Column"></FlexBox></FlexBox></DynamicPageHeader>}
                headerTitle={<DynamicPageTitle actions={<><Button icon='add' design="Emphasized" onClick={onNew}>Yeni</Button><Button design="Transparent" /></>} header={<Title>İş Akışı Tanımlama</Title>} navigationActions={<><Button design="Transparent" /><Button design="Transparent" /><Button design="Transparent" /></>}  subHeader={null}></DynamicPageTitle>}
                onPinnedStateChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                style={{
                    maxHeight: '700px'
                }}
            >
                <AnalyticalTable
                    columns={[
                        {
                            Header: 'İş Akışı Kodu',
                            accessor: 'id',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 150
                        },
                        {
                            Header: 'İş Akışı Kodu',
                            accessor: 'workflowName',
                            autoResizable: true,
                            headerTooltip: 'Dönem Kodu',
                            width: 200
                        },

                        {
                            Cell: (instance: any) => {
                                const { cell: any, row, webComponentsReactProperties } = instance;
                                // disable buttons if overlay is active to prevent focus
                                const isOverlay = webComponentsReactProperties.showOverlay;
                                // console.log('This is your row data', row.original);
                                return (
                                    <FlexBox>
                                        <Button icon="edit" onClick={() => onEdit(instance)} disabled={isOverlay} />
                                        <Button icon="delete" onClick={() => onDelete(instance)} disabled={isOverlay} />
                                    </FlexBox>
                                );
                            },
                            // cellLabel: ({ cell }) => {
                            //     // `cell.cellLabel` contains the internal `aria-label` of the respective cell
                            //     return `${cell.cellLabel} press TAB to focus active elements inside this cell`;
                            // },
                            Header: 'Actions',
                            accessor: '.',
                            disableFilters: true,
                            disableGroupBy: true,
                            disableResizing: true,
                            disableSortBy: true,
                            id: 'actions',
                            width: 100
                        }
                    ]}
                    data={gridData}
                    filterable
                    groupBy={[]}
                    groupable
                    // header="Table Title"
                    infiniteScroll
                    visibleRows={gridData.length}
                    selectionMode="None"
                    tableHooks={[]}
                />
            </DynamicPage>
        </DashboardLayout>
    );
}
export default WorkFlowList;
