import React, { useEffect, useRef, useState } from 'react';
import '../App.css';


import "@ui5/webcomponents-base/dist/features/F6Navigation";
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/DatePicker";
import "@ui5/webcomponents/dist/List";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/CustomListItem";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Dialog";
import "@ui5/webcomponents/dist/Popover";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/TextArea";
import "@ui5/webcomponents/dist/Switch";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/palette.js";
import "@ui5/webcomponents-icons/dist/settings.js";
import "@ui5/webcomponents-icons/dist/sys-help.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/account.js";
import "@ui5/webcomponents-icons/dist/private.js";
import "@ui5/webcomponents-icons/dist/loan.js";
import "@ui5/webcomponents-icons/dist/globe.js";
import "@ui5/webcomponents-icons/dist/workflow-tasks";
import "@ui5/webcomponents-icons/dist/chain-link";
import "@ui5/webcomponents-icons/dist/decline";
import "@ui5/webcomponents-icons/dist/employee-approvals";
import "@ui5/webcomponents-icons/dist/employee";
import { Avatar, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, MultiComboBox, MultiComboBoxItem, MultiInput, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, Tab, TabContainer, TextArea, Title, Token, Toolbar, ToolbarSpacer } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetNormCodeRequestApi, BudgetNormCodeRequestInsertDto, BudgetNormCodeRequestListDto, BudgetNormCodeRequestUpdateDto, BudgetPeriodApi, BudgetPeriodListDto, BudgetPeriodUserApi, BudgetPeriodUserListDto, Configuration, CustCompanyGroupList, CustLegalEntityList, CustSubDivisioList, FOBusinessUnitList, FOCompanyDtoList, FODepartmentList, FODivisionList, FOGeozoneDtoList, FOJobCodeList, FOJobFunctionList, FOJobFunctionSFDto, FOLocationGroupList, FOLocationList, FOPayGradeList, FOPayGradeSFDto, FOPayGroupSFList, GenericListApi, HardwareList, InternalEmploymentType, LicenceList, PickListApi, PickListDto, PositionSFDto, ProcessType, SFCustCompanyGroupApi, SFCustLegalEntityApi, SFcustSubDivisionApi, SFFOBusinessUnitApi, SFFOCompanyApi, SFFOCostCenterApi, SFFOCostCenterList, SFFODepartmentApi, SFFODivisionApi, SFFOFOPayGroupApi, SFFOGeozoneApi, SFFOJobCodeApi, SFFOJobFunctionApi, SFFOLocationApi, SFFOLocationGroupApi, SFFOPayGradeApi, SFPositionList, SFPositionsApi, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';

import { number, string } from 'yup';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useBusy } from '../hooks/useBusy';
import { getStatusText, isDecimal, isValidDate, parseDateString } from '../utils/utils';
import { useAlert } from '../hooks/useAlert';
import PickListComboBoxComponent from '../Components/PickListComponent';
import CustomInputComponent from '../Components/CustomInput';
import { isReadable } from 'stream';
import { useUser } from '../hooks/userName';
import GenericSelectDialogWithInputEnum, { SelectDialog } from '../Components/GenericSelectDialogWithInputEnum';
import UserSelectDialog from '../Components/UserSelectDialog';
import RelatedUserSelectDialog from '../Components/RelatedUserSelectDialog';
import GenericSelectDialogWithInputEnumCompany from '../Components/GenericSelectDialogWithInputEnumCompany';
import GenericSelectDialogWithInputEnumBolgeFonksiyon from '../Components/GenericSelectDialogWithInputEnumBolgeFonksiyon';
import GenericSelectDialogWithInputEnumBolumProje from '../Components/GenericSelectDialogWithInputEnumBolumProje';
import RelatedUserSelectDialogMultiSelect from '../Components/RelatedUserSelectDialogMultiSelect.';
// setTheme("sap_horizon");
// setTheme("sap_fiori_3");
// setTheme("sap_belize");
function NormDetail() {
    const { username, setUsername } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {

        getDetail();
        getActivePeriod();
        getGenericList();


        // getPeriodList();

    }, [username]); //
    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState("");
    const [genericDialogOpen, setGenericDialogOpen] = useState(false);
    const [isedit, setisedit] = useState(false);

    const [comboprocessTypeId, setComboprocessTypeId] = useState("");
    const [comboprocessType, setComboprocessType] = useState("");
    const { loginUser, setLoginUser } = useUser();
    const [comboInternalEmployment, setcomboInternalEmployment] = useState("");
    const [comboInternalEmploymentId, setcomboInternalEmploymentId] = useState("");

    const [comboPromotionPeriod, setcomboPromotionPeriod] = useState("");
    const [combocomboPromotionPeriodId, setcomboPromotionPeriodId] = useState("");

    const comboChangeReason = useRef<string>("");
    const comboIsAlani = useRef<string>("")
    const comboCalismaYeriTuru = useRef<string>("")
    const comboRonesansKademe = useRef<string>("")
    const comboEmpGroup = useRef<string>("")
    const comboEmpSubGroup = useRef<string>("")
    const comboEmployeeClass = useRef<string>("")
    const comboHayKademe = useRef<string>("")
    const comboTicket = useRef<string>("")
    const txtJobFunction = useRef<SelectDialog | null>(null);

    const txtJobCodeName = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobCodeNameEnglish = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const [startDate, setstartDate] = useState("");

    const [detail, setDetail] = useState<BudgetNormCodeRequestListDto>();

    const [iseBaslamaTarihi, setIseBaslamaTarihi] = useState("");
    const [planlananIseGiris, setPlanlananIseGiris] = useState("");
    const [plannedEndDate, setPlannedEndDate] = useState("");
    const [actualhiredate, setActualhiredate] = useState("");

    const [isSend, setIsSend] = useState(false);

    const txtJobCode = useRef<SelectDialog | null>(null);
    const txtlocationGroup = useRef<SelectDialog | null>(null);
    const txtlocation = useRef<SelectDialog | null>(null);
    const txtSubDivision = useRef<SelectDialog | null>(null);
    const txtDepartment = useRef<SelectDialog | null>(null);
    const txtparentDepartment2 = useRef<SelectDialog | null>(null);
    const txtparentDepartment = useRef<SelectDialog | null>(null);
    const txtbusinessUnit = useRef<SelectDialog | null>(null);
    const txtcompanyGroup = useRef<SelectDialog | null>(null);
    const txtcustomlegalEntity = useRef<SelectDialog | null>(null);
    const txtdivision = useRef<SelectDialog | null>(null);
    const txtphisicalLocation = useRef<SelectDialog | null>(null);
    const txtGeoZone = useRef<SelectDialog | null>(null);
    const txtpositionCode = useRef<SelectDialog | null>(null);
    const txtcompany = useRef<SelectDialog | null>(null);
    const txtparentPosition = useRef<SelectDialog | null>(null);
    const txtpayGroup = useRef<SelectDialog | null>(null);
    const txtcostCenter = useRef<SelectDialog | null>(null);
    const txtpayGrade = useRef<SelectDialog | null>(null);

    const txtChiefPosition = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtronesansjoblevel = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtjobTitle = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtcode = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtexternalName_tr = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtexternalName_en = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtexternalName_ru = useRef<{ current: string, setValue: (value: string) => void }>(null);
    // const txttype = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtstandardHours = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txteffectiveStatus = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtcomment = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtpropotionReason = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txttargetFTE = useRef<{ current: string, setValue: (value: string) => void }>(null);

    const [IsmultipleIncumbentsAllowed, setIsmultipleIncumbentsAllowed] = useState(false);
    const [IsVacant, setIsVacant] = useState(false);
    const [IsChiefPosition, setIsChiefPosition] = useState(false);


    const [realPositionCode, setRealrealPositionCode] = useState("");
    const [empMngName, setempMngName] = useState("");
    const [empTeamName, setempTeamName] = useState("");
    const [UserMngDialogVisible, setUserMngDialogVisible] = useState(false);
    const [UserTeamDialogVisible, setUserTeamDialogVisible] = useState(false);
    const [RefUserDialogVisible, setRefUserDialogVisible] = useState(false);
    const [IcKaynakUserDialogVisible, setIcKaynakUserDialogVisible] = useState(false);
    const [internalSourceVsb, setinternalSourceVsb] = useState(false);

    const [hardwareItems, setHardwareItems] = useState<HardwareList[]>([]);
    const [licenceItems, setLicenceItems] = useState<LicenceList[]>([]);


    const [selectedHardwareItems, setSelectedHardwareItems] = useState("");
    const [selectedLicenceItems, setSelectedLicenceItems] = useState("");

    const [refempname, setrefempname] = useState("");
    const [IcKaynakname, setIcKaynakname] = useState("");

    const [periodCodeId, setperiodCodeId] = useState("");
    const [periodCode, setperiodCode] = useState("");

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const [msgOpen, setmsgOpen] = useState(false);


    const dispatchBusy = useBusy()
    const dispatchAlert = useAlert()

    const handleRealPositionInput = (e: any) => {
        setRealrealPositionCode(e.target.value); // Input alanındaki değeri günceller
    };

    async function getDetail() {

        const configuration = new Configuration({
            basePath: process.env.REACT_APP_BASE_PATH,
            apiKey: process.env.REACT_APP_API_KEY,
            accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        });

        const id = queryParams.get('id');
        const type = queryParams.get('type');

        if (type == "edita") {
            getPositionCodeDetail(id);
            return;
        }
        if (!id) {

            dispatchBusy({ isBusy: true });
            fetchPositionByLoginUser(username);
            dispatchBusy({ isBusy: false });
        }


        if (id) {


            dispatchBusy({ isBusy: true });
            setisedit(true);
            var api = new BudgetNormCodeRequestApi(configuration);
            var result = api.apiBudgetNormCodeRequestIdGet(id!)
                .then((response: AxiosResponse<BudgetNormCodeRequestListDto>) => {

                    setId(response.data.id!);

                    if (response.data.workflowHead?.workFlowStatus == 1 || response.data.workflowHead?.workFlowStatus == 2)
                        setIsSend(true);


                    setDetail(response.data);
                    console.log("response>>", response.data)


                    setRealrealPositionCode(response.data.code!);
                    setIseBaslamaTarihi(format(parseISO(response.data.cust_IseBaslamaTarihi!), 'dd/MM/yyyy'));
                    setPlanlananIseGiris(format(parseISO(response.data.cust_PlanlananIseGiris!), 'dd/MM/yyyy'));
                    setPlannedEndDate(format(parseISO(response.data.cust_plannedEndDate!), 'dd/MM/yyyy'));
                    setActualhiredate(format(parseISO(response.data.cust_actualhiredate!), 'dd/MM/yyyy'));
                    setstartDate(format(parseISO(response.data.effectiveStartDate!), 'dd/MM/yyyy'));
                    setIsmultipleIncumbentsAllowed(response.data.multipleIncumbentsAllowed!);
                    setIsVacant(response.data.vacant!);
                    setIsChiefPosition(response.data.cust_ChiefPosition!);

                    setComboprocessType(response.data.processTypeText!);
                    setComboprocessTypeId(response.data.processType!.toString());

                    setcomboPromotionPeriodId(response.data.promotionPeriod!);
                    setcomboPromotionPeriod(response.data.promotionPeriod == "1" ? "Ana Dönem" : "Ara Dönem");

                    setcomboInternalEmployment(response.data.internalEmploymentTypeText ? response.data.internalEmploymentTypeText : "");
                    setcomboInternalEmploymentId(response.data.internalEmploymentType ? response.data.internalEmploymentType.toString() : "");
                    setinternalSourceVsb(response.data.isInternalSource!);

                    setempMngName(response.data.relationManager!);
                    setempTeamName(response.data.relationEmployess!);

                    // var hardwares = response.data.hardware!.split(",");
                    // selectedHardwareItems(hardwares);
                    // setLicenceItems(response.data.licence);

                    setIcKaynakname(response.data.internalSourceEmp!);

                    txtJobCodeName.current!.setValue(response.data.externalName_tr_TR!);
                    txtJobCodeNameEnglish.current!.setValue(response.data.externalName_en_US!);
                    txtronesansjoblevel.current!.setValue(response.data.cust_ronesansjoblevel!);
                    txtjobTitle.current!.setValue(response.data.jobTitle!);
                    txtcode.current!.setValue(response.data.code!);
                    txtexternalName_tr.current!.setValue(response.data.externalName_tr_TR!);
                    txtexternalName_en.current!.setValue(response.data.externalName_en_US!);
                    txtexternalName_ru.current!.setValue(response.data.externalName_ru_RU!);
                    // txttype.current!.setValue(response.data.type!);
                    txtstandardHours.current!.setValue(response.data.standardHours!);
                    txteffectiveStatus.current!.setValue(response.data.effectiveStatus!);
                    txtcomment.current!.setValue(response.data.comment!);
                    txttargetFTE.current!.setValue(response.data.targetFTE!);
                    txtpropotionReason.current!.setValue(response.data.propotionReasonTxt!);

                    let jobCode: SelectDialog = { externalCode: response.data!.jobCode!, name: response.data!.jobCode! };
                    let locationGroup: SelectDialog = { externalCode: response.data.cust_locationGroup!, name: response.data!.cust_locationGroup! };
                    let location: SelectDialog = { externalCode: response.data.location!, name: response.data!.location! };
                    let subDivision: SelectDialog = { externalCode: response.data.cust_sub_division!, name: response.data!.cust_sub_division! };
                    let department: SelectDialog = { externalCode: response.data.department!, name: response.data!.department! };
                    let parentDepartment2: SelectDialog = { externalCode: response.data.cust_parentDepartment2!, name: response.data!.cust_parentDepartment2! };
                    let parentDepartment: SelectDialog = { externalCode: response.data.cust_parentDepartment!, name: response.data!.cust_parentDepartment! };
                    let businessUnit: SelectDialog = { externalCode: response.data.businessUnit!, name: response.data!.businessUnit! };
                    let companyGroup: SelectDialog = { externalCode: response.data.cust_companyGroup!, name: response.data!.cust_company! };
                    let customlegalEntity: SelectDialog = { externalCode: response.data.cust_customlegalEntity!, name: response.data!.cust_customlegalEntity! };
                    let division: SelectDialog = { externalCode: response.data.division!, name: response.data!.division! };
                    let phisicalLocation: SelectDialog = { externalCode: response.data.cust_phisicalLocation!, name: response.data!.cust_phisicalLocation! };
                    let geoZone: SelectDialog = { externalCode: response.data.cust_GeoZone!, name: response.data!.cust_GeoZone! };
                    let company: SelectDialog = { externalCode: response.data.cust_company!, name: response.data!.cust_company! };
                    let parentPosition: SelectDialog = { externalCode: response.data.parentPosition!, name: response.data!.parentPosition! };
                    let payGroup: SelectDialog = { externalCode: response.data.cust_payGroup!, name: response.data!.cust_payGroup! };
                    let costCenter: SelectDialog = { externalCode: response.data.costCenter!, name: response.data!.costCenter! };
                    let payGrade: SelectDialog = { externalCode: response.data.payGrade!, name: response.data!.payGrade! };



                    txtJobCode.current! = jobCode;
                    txtlocationGroup.current! = locationGroup;
                    txtlocation.current! = location;
                    txtSubDivision.current! = subDivision;
                    txtDepartment.current! = department;
                    txtparentDepartment2.current! = parentDepartment2;
                    txtparentDepartment.current! = parentDepartment;
                    txtbusinessUnit.current! = businessUnit;
                    txtcompanyGroup.current! = companyGroup;
                    txtcustomlegalEntity.current! = customlegalEntity;
                    txtdivision.current! = division;
                    txtphisicalLocation.current! = phisicalLocation;
                    txtGeoZone.current! = geoZone;
                    txtcompany.current! = company;
                    txtparentPosition.current! = parentPosition;
                    txtpayGroup.current! = payGroup;
                    txtcostCenter.current! = costCenter;
                    txtpayGrade.current! = payGrade;




                    comboChangeReason.current = response.data.changeReason!;
                    comboIsAlani.current = response.data.cust_isAlani!;
                    comboCalismaYeriTuru.current = response.data.cust_calismaYeriTuru!;
                    comboRonesansKademe.current = response.data.cust_ronesansKademe!;
                    comboEmpGroup.current = response.data.cust_EmpGroup!;
                    comboEmpSubGroup.current = response.data.cust_empSubGroup!;
                    comboEmployeeClass.current = response.data.employeeClass!;
                    comboHayKademe.current = response.data.cust_HayKademe!;
                    comboTicket.current = response.data.cust_ticket!;





                })
                .catch(error => {

                });
            dispatchBusy({ isBusy: false });
        }
    }
    //#endregion

    const handleProcessType = (event: any) => {
        const selectedItem = event.detail.item;

        setComboprocessTypeId(selectedItem.getAttribute('data-id'));
        setComboprocessType(selectedItem.text);
    }

    const handleStartDateInput = (e: any) => {
        setstartDate(e.target.value);
    };
    const handleIseBaslamaTarihiInput = (e: any) => {
        setIseBaslamaTarihi(e.target.value);
    };
    const handleplanlananIseGirisInput = (e: any) => {
        setPlanlananIseGiris(e.target.value);
    };
    const handlePlannedEndDateInput = (e: any) => {
        setPlannedEndDate(e.target.value);
    };
    const handleActualhiredateInput = (e: any) => {
        setActualhiredate(e.target.value);
    };
    const handleIsmultipleIncumbentsAllowedCheckBoxChange = (e: any) => {
        setIsmultipleIncumbentsAllowed(e.target.checked);
    };
    const handleIsVacantCheckBoxChange = (e: any) => {
        setIsVacant(e.target.checked);
    };
    const handleIsChiefPositionCheckBoxChange = (e: any) => {
        setIsChiefPosition(e.target.checked);
    };


    const fetchPositionByLoginUser = async (empId: any) => {
        dispatchBusy({ isBusy: true });
        var api = new SFPositionsApi(configuration);
        var result = await api.apiSFPositionsDetailByUserIdUserIdGet(empId);

        var resultD = result.data[0];

        console.log("resultD", resultD);


        if (resultD) {
            var mngResult = await api.apiSFPositionsGetMngByCodeFromEmpJobPositioncodeGet(resultD.parentPositionValue!);

            if(mngResult.data != "" && mngResult.data != null && mngResult.data != "NO_MANAGER"){
                setempMngName(mngResult.data!);
            }
            if (txtexternalName_en.current != null)
                txtexternalName_en.current!.setValue(resultD.externalName_en_US! != null ? resultD.externalName_en_US : "");

            if (txtexternalName_ru.current != null)
                txtexternalName_ru.current!.setValue(resultD.externalName_ru_RU! != null ? resultD.externalName_ru_RU : "");

            if (txtexternalName_tr.current != null)
                txtexternalName_tr.current!.setValue(resultD.externalName_tr_TR! != null ? resultD.externalName_tr_TR : "");


            setDetail({

                jobCode: resultD.jobCode != null ? resultD.jobCode : "",
                code: resultD.code != null ? resultD.code : null,
                cust_GeoZone: resultD.cust_GeoZone != null ? resultD.cust_GeoZone : null,
                cust_company: resultD.company != null ? resultD.company : "",
                targetFTE: resultD.targetFTE != null ? resultD.targetFTE : "",
                standardHours: resultD.standardHours != null ? resultD.standardHours : "",
                cust_isAlani: resultD.cust_isAlani != null ? resultD.cust_isAlani : null,
                cust_calismaYeriTuru: resultD.cust_calismaYeriTuru != null ? resultD.cust_calismaYeriTuru : null,
                cust_companyGroup: resultD.cust_companyGroup != null ? resultD.cust_companyGroup : "",
                employeeClass: resultD.employeeClass != null ? resultD.employeeClass : "",
                cust_empSubGroup: resultD.cust_empSubGroup != null ? resultD.cust_empSubGroup : "",
                cust_EmpGroup: resultD.cust_EmpGroup != null ? resultD.cust_EmpGroup : "",
                cust_locationGroup: resultD.cust_locationGroup != null ? resultD.cust_locationGroup : "",
                location: resultD.location != null ? resultD.location : null,
                cust_jobfunction: resultD.cust_jobfunction != null ? resultD.cust_jobfunction : "",
                cust_ronesansjoblevel: resultD.cust_ronesansjoblevel != null ? resultD.cust_ronesansjoblevel : "",
                jobTitle: resultD.jobTitle != null ? resultD.jobTitle : "",
                cust_sub_division: resultD.cust_sub_division != null ? resultD.cust_sub_division : "",
                department: resultD.department != null ? resultD.department : "",
                cust_parentDepartment: resultD.cust_parentDepartment != null ? resultD.cust_parentDepartment : null,
                cust_parentDepartment2: resultD.cust_parentDepartment2 != null ? resultD.cust_parentDepartment2 : "",
                businessUnit: resultD.businessUnit != null ? resultD.businessUnit : "",
                cust_customlegalEntity: resultD.cust_customlegalEntity != null ? resultD.cust_customlegalEntity : "",
                division: resultD.division != null ? resultD.division : "",
                cust_phisicalLocation: resultD.cust_phisicalLocation != null ? resultD.cust_phisicalLocation : null,
                cust_ronesansKademe: resultD.cust_ronesansKademe != null ? resultD.cust_ronesansKademe : null,
                payGrade: resultD.payGrade != null ? resultD.payGrade : null,
                costCenter: resultD.costCenter != null ? resultD.costCenter : "",
                cust_payGroup: resultD.cust_payGroup != null ? resultD.cust_payGroup : null,
                changeReason: resultD.changeReason != null ? resultD.changeReason : "",
                cust_HayKademe: resultD.cust_HayKademe != null ? resultD.cust_HayKademe : null,
                comment: resultD.comment != null ? resultD.comment : "",
                cust_ticket: resultD.cust_ticket != null ? resultD.cust_ticket : null,
                cust_ChiefPosition: resultD.cust_ChiefPosition != null ? resultD.cust_ChiefPosition : null,
                cust_IseBaslamaTarihi: resultD.cust_IseBaslamaTarihi != null ? resultD.cust_IseBaslamaTarihi : null,
                cust_PlanlananIseGiris: resultD.cust_PlanlananIseGiris != null ? resultD.cust_PlanlananIseGiris : null,
                cust_plannedEndDate: resultD.cust_plannedEndDate != null ? resultD.cust_plannedEndDate : null,
                multipleIncumbentsAllowed: resultD.multipleIncumbentsAllowed != null ? resultD.multipleIncumbentsAllowed : false,
                parentPosition: resultD.parentPositionValue != null ? resultD.parentPositionValue : ""
            });

        }

        dispatchBusy({ isBusy: false });
    }

    const handleSave = () => {
        onSubmit(); // Örnek parametrelerle fonksiyonu çağır
    };

    function onSubmit(workflowHeadId: string = "", isSend: boolean = false) {
        // if (isValidDate(startDate) == false || isValidDate(iseBaslamaTarihi) == false || isValidDate(planlananIseGiris) == false
        //     || isValidDate(plannedEndDate) == false || isValidDate(actualhiredate) == false) {
        //     dispatchAlert({ message: "Tarih alanlarını kontrol ediniz..!", type: MessageBoxTypes.Error })
        //     return;
        // }
        // if (txttargetFTE.current?.current != "" && !isDecimal(txttargetFTE.current?.current!)) {
        //     dispatchAlert({ message: "Tam Zamanlı alanı sayı türünde olmalıdır", type: MessageBoxTypes.Warning })
        //     return;
        // }
        // if (txtstandardHours.current?.current != "" && !isDecimal(txtstandardHours.current?.current!)) {
        //     dispatchAlert({ message: "Haftalık Çalışma Saati alanı sayı türünde olmalıdır", type: MessageBoxTypes.Warning })
        //     return;
        // }
        if (internalSourceVsb) {
            if (comboInternalEmployment == "" || comboInternalEmployment == null) {
                dispatchAlert({ message: "İç Kaynak Terfi/Çalışan bilgisini giriniz", type: MessageBoxTypes.Warning })
                return;
            }
            if (IcKaynakname == "" || IcKaynakname == null) {
                dispatchAlert({ message: "İç Kaynak Personel bilgisini giriniz", type: MessageBoxTypes.Warning })
                return;
            }
            if (combocomboPromotionPeriodId == "" || combocomboPromotionPeriodId == null) {
                dispatchAlert({ message: "Terfi Dönemi bilgisini giriniz", type: MessageBoxTypes.Warning })
                return;
            }
            if (combocomboPromotionPeriodId == "1" && iseBaslamaTarihi != "01/03/2025") {
                dispatchAlert({ message: "Ana Dönem Seçildiyse Tarih 01/03/2025 Olmalıdır", type: MessageBoxTypes.Warning })
                return;
            }
            if (combocomboPromotionPeriodId == "2" && iseBaslamaTarihi != "01/09/2025") {
                dispatchAlert({ message: "Ara Dönem Seçildiyse Tarih 01/09/2025 Olmalıdır", type: MessageBoxTypes.Warning })
                return;
            }
            if (comboInternalEmploymentId == "1" && (txtpropotionReason.current?.current == "" || txtpropotionReason == null)) {
                dispatchAlert({ message: "Terfi nedeni alanı zorunludur", type: MessageBoxTypes.Warning })
                return;
            }
        }

        // if (txtronesansjoblevel.current?.current != "" && !isDecimal(txtronesansjoblevel.current?.current!)) {
        //     dispatchAlert({ message: "İş Seviyesi alanı sayı türünde olmalıdır", type: MessageBoxTypes.Warning })
        //     return;
        // }

        if (txtcomment.current?.current == "" || txtcomment == null) {
            dispatchAlert({ message: "Talep nedeni alanı zorunludur", type: MessageBoxTypes.Warning })
            return;
        }

        var isebaslama = new Date(iseBaslamaTarihi);
        var bitistarihi = new Date(plannedEndDate);
        var mindate = new Date("01/01/2025");
        if (iseBaslamaTarihi == "" || iseBaslamaTarihi == null) {
            dispatchAlert({ message: "Planlanan İşe Başlama Tarihi alanı zorunludur", type: MessageBoxTypes.Warning })
            return;
        }
        else if (isebaslama.getTime() < mindate.getTime() && bitistarihi.getTime() < mindate.getTime()) {
            dispatchAlert({ message: "Tarihler en erken 2025 yılı için seçilebilir", type: MessageBoxTypes.Warning })
            return;
        }
        if (empMngName == "" || empMngName == null) {
            dispatchAlert({ message: "Bağlı Olacağı Yönetici alanı zorunludur", type: MessageBoxTypes.Warning })
            return;
        }

        dispatchBusy({ isBusy: true });
        let api = new BudgetNormCodeRequestApi(configuration);
        if (isedit == false) {

            let insertDto = {} as BudgetNormCodeRequestInsertDto;

            insertDto.isDeleted = false;

            insertDto.jobCode = txtJobCode.current?.externalCode!;
            insertDto.jobCodeDescription = txtJobCode.current?.name!;

            insertDto.code = realPositionCode;

            insertDto.businessUnit = txtbusinessUnit.current?.externalCode!;
            insertDto.comment = txtcomment.current?.current;
            insertDto.cust_IseBaslamaTarihi = parseDateString(iseBaslamaTarihi).toISOString();
            insertDto.cust_calismaYeriTuru = comboCalismaYeriTuru.current;
            insertDto.cust_customlegalEntity = txtcustomlegalEntity.current?.externalCode!;
            insertDto.cust_plannedEndDate = parseDateString(plannedEndDate).toISOString();
            insertDto.cust_sub_division = txtSubDivision.current?.externalCode!
            insertDto.division = txtdivision.current?.externalCode!;

            insertDto.processType = Number.parseInt(comboprocessTypeId) as ProcessType;
            insertDto.internalEmploymentType = Number.parseInt(comboInternalEmploymentId) as InternalEmploymentType;
            insertDto.isInternalSource = internalSourceVsb;
            insertDto.promotionPeriod = combocomboPromotionPeriodId;
            insertDto.promotionPeriodTxt = comboPromotionPeriod;

            insertDto.hardware = selectedHardwareItems;
            insertDto.licence = selectedLicenceItems;
            insertDto.internalSourceEmp = IcKaynakname;
            insertDto.propotionReasonTxt = txtpropotionReason.current?.current;
            insertDto.changeReason = comboChangeReason.current;
            insertDto.code = realPositionCode;
            insertDto.cust_company = txtcompany.current?.externalCode!
            insertDto.costCenter = txtcostCenter.current?.externalCode!
            insertDto.cust_ChiefPosition = IsChiefPosition;
            insertDto.cust_EmpGroup = comboEmpGroup.current;
            insertDto.cust_GeoZone = txtGeoZone.current?.externalCode!;
            insertDto.cust_HayKademe = comboHayKademe.current;
            // insertDto.cust_PlanlananIseGiris = parseDateString(planlananIseGiris).toISOString();
            // insertDto.cust_actualhiredate = parseDateString(actualhiredate).toISOString();
            insertDto.effectiveStartDate = parseDateString(startDate).toISOString();
            insertDto.cust_companyGroup = txtcompanyGroup.current?.externalCode!;
            insertDto.cust_empSubGroup = comboEmpSubGroup.current;
            insertDto.cust_isAlani = comboIsAlani.current;
            insertDto.cust_jobfunction = txtJobFunction.current?.externalCode!
            insertDto.cust_locationGroup = txtlocationGroup.current?.externalCode!
            insertDto.cust_parentDepartment2 = txtparentDepartment2.current?.externalCode!
            insertDto.cust_parentDepartment = txtparentDepartment.current?.externalCode!
            insertDto.cust_payGroup = txtpayGroup.current?.externalCode!;
            insertDto.cust_phisicalLocation = txtphisicalLocation.current?.externalCode!;
            insertDto.cust_ronesansKademe = comboRonesansKademe.current;;
            insertDto.cust_ronesansjoblevel = txtronesansjoblevel.current?.current!;
            insertDto.cust_ticket = comboTicket.current;
            insertDto.department = txtDepartment.current?.externalCode!;
            insertDto.effectiveStatus = "A";
            insertDto.employeeClass = comboEmployeeClass.current;
            insertDto.externalName_defaultValue = txtexternalName_tr.current?.current;
            insertDto.externalName_en_DEBUG = "";
            insertDto.externalName_en_US = txtexternalName_en.current?.current;
            insertDto.externalName_ru_RU = txtexternalName_ru.current?.current;
            insertDto.externalName_tr_TR = txtexternalName_tr.current?.current!;
            insertDto.jobTitle = txtjobTitle.current?.current;
            insertDto.location = txtlocation.current?.externalCode!;
            insertDto.multipleIncumbentsAllowed = IsmultipleIncumbentsAllowed;
            insertDto.parentPosition = txtparentPosition.current?.externalCode!;
            insertDto.payGrade = txtpayGrade.current?.externalCode!;
            insertDto.standardHours = txtstandardHours.current?.current!;
            insertDto.targetFTE = txttargetFTE.current?.current!;
            insertDto.vacant = IsVacant;
            insertDto.isSend = isSend;
            insertDto.relationEmployess = empTeamName;
            insertDto.relationManager = empMngName;

            console.log("insertdto", insertDto)

            api.apiBudgetNormCodeRequestPost(insertDto)
                .then(response => {
                    dispatchBusy({ isBusy: false });
                    dispatchAlert({ message: "Kayıt Ekleme Başarılı", type: MessageBoxTypes.Success })
                    navigate("/NormList")

                })
                .catch(error => {

                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });


        }
        else {
            let updateDto = {} as BudgetNormCodeRequestUpdateDto;
            updateDto.id = id;

            updateDto.isDeleted = false;
            updateDto.jobCode = txtJobCode.current?.externalCode!;

            updateDto.businessUnit = txtbusinessUnit.current?.externalCode!;
            updateDto.changeReason = comboChangeReason.current;
            updateDto.code = realPositionCode;// txtcode.current?.current;
            updateDto.comment = txtcomment.current?.current;
            updateDto.cust_company = txtcompany.current?.externalCode!
            updateDto.costCenter = txtcostCenter.current?.externalCode!

            updateDto.cust_ChiefPosition = IsChiefPosition;
            updateDto.cust_EmpGroup = comboEmpGroup.current;
            updateDto.cust_GeoZone = txtGeoZone.current?.externalCode!;
            updateDto.cust_HayKademe = comboHayKademe.current;
            updateDto.cust_IseBaslamaTarihi = parseDateString(iseBaslamaTarihi).toISOString();
            updateDto.cust_PlanlananIseGiris = parseDateString(planlananIseGiris).toISOString();
            updateDto.cust_actualhiredate = parseDateString(actualhiredate).toISOString();
            updateDto.effectiveStartDate = parseDateString(startDate).toISOString();
            updateDto.cust_calismaYeriTuru = comboCalismaYeriTuru.current;
            updateDto.cust_companyGroup = txtcompanyGroup.current?.externalCode!;
            updateDto.cust_customlegalEntity = txtcustomlegalEntity.current?.externalCode!;
            updateDto.cust_empSubGroup = comboEmpSubGroup.current;
            updateDto.cust_isAlani = comboIsAlani.current;
            updateDto.cust_jobfunction = txtJobFunction.current?.externalCode!
            updateDto.cust_locationGroup = txtlocationGroup.current?.externalCode!
            updateDto.cust_parentDepartment2 = txtparentDepartment2.current?.externalCode!
            updateDto.cust_parentDepartment = txtparentDepartment.current?.externalCode!
            updateDto.cust_payGroup = txtpayGroup.current?.externalCode!;
            updateDto.cust_phisicalLocation = txtphisicalLocation.current?.externalCode!;
            updateDto.cust_plannedEndDate = parseDateString(plannedEndDate).toISOString();
            updateDto.cust_ronesansKademe = comboRonesansKademe.current;;
            updateDto.cust_ronesansjoblevel = txtronesansjoblevel.current?.current!;
            updateDto.cust_sub_division = txtSubDivision.current?.externalCode!
            updateDto.cust_ticket = comboTicket.current;
            updateDto.department = txtDepartment.current?.externalCode!;
            updateDto.division = txtdivision.current?.externalCode!;
            updateDto.effectiveStatus = "A";
            updateDto.employeeClass = comboEmployeeClass.current;
            updateDto.externalName_defaultValue = txtexternalName_tr.current?.current;
            updateDto.externalName_en_DEBUG = "";
            updateDto.externalName_en_US = txtexternalName_en.current?.current;
            updateDto.externalName_ru_RU = txtexternalName_ru.current?.current;
            updateDto.externalName_tr_TR = txtexternalName_tr.current?.current!;
            updateDto.jobTitle = txtjobTitle.current?.current;
            updateDto.location = txtlocation.current?.externalCode!;
            updateDto.multipleIncumbentsAllowed = IsmultipleIncumbentsAllowed;
            updateDto.parentPosition = txtparentPosition.current?.externalCode!;
            updateDto.payGrade = txtpayGrade.current?.externalCode!;
            updateDto.standardHours = txtstandardHours.current?.current!;
            updateDto.targetFTE = txttargetFTE.current?.current!;
            updateDto.vacant = IsVacant;
            updateDto.isSend = isSend;

            updateDto.processType = Number.parseInt(comboprocessTypeId) as ProcessType;
            updateDto.internalEmploymentType = Number.parseInt(comboInternalEmploymentId) as InternalEmploymentType;
            updateDto.isInternalSource = internalSourceVsb;
            updateDto.relationEmployess = empTeamName;
            updateDto.relationManager = empMngName;
            updateDto.propotionReasonTxt = txtpropotionReason.current?.current!;
            updateDto.promotionPeriod = combocomboPromotionPeriodId;
            updateDto.promotionPeriodTxt = comboPromotionPeriod;
            
            updateDto.jobCodeDescription = txtJobCode.current?.name!;

            if (workflowHeadId != "")
                updateDto.workflowHeadId = workflowHeadId;


            console.log(updateDto)
            api.apiBudgetNormCodeRequestPut(updateDto)
                .then(response => {
                    if (workflowHeadId != null) {
                        dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    }
                    else {
                        dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                    }
                    navigate("/NormList")
                    dispatchBusy({ isBusy: false });
                })
                .catch(error => {
                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }

        dispatchBusy({ isBusy: true });
    }

    const handleApprovalClick = async () => {

        dispatchBusy({ isBusy: true });
        if (id) {
            var usernameTerfi = "";
            var userApi = new SFUserApi(configuration);
            if (IcKaynakname) {
                var res = await userApi.apiSFUserUserUseridGet(IcKaynakname);
                var reslist = res.data.sfuSerList;
                if (reslist) {
                    usernameTerfi = reslist[0].defaultFullName!;
                }
            }

            var workFlowApi = new WorkFlowApi(configuration);
            let startDto: WorkFlowStartApiDto = {};
            startDto.definationId = "b08b506c-1d98-4feb-a680-64ddc67b3c39";
            startDto.userName = username;
            startDto.workFlowInfo = "Terfi Edecek Kişi:" + `${usernameTerfi}(${IcKaynakname})`;
            var result = await workFlowApi.apiWorkFlowStartPost(startDto)
                .then(response => {
                    onSubmit(response.data.id!, true);
                    dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    dispatchBusy({ isBusy: false });
                })
        }
    };

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Norm Kadro':
                navigate("/NormList")
                break;
            case "Norm Kadro Talep Detayı":
                navigate("/NormDetail")
                break;
            default:
                break;
        }

    }

    async function getGenericList() {
        var api = new GenericListApi(configuration);
        var data = await api.apiGenericListGetHardwareListGet();
        console.log("genericlistdata hardware", data.data)
        setHardwareItems(data.data);

        var data = await api.apiGenericListGetLicenceListGet();
        console.log("genericlistdata licence", data.data)
        setLicenceItems(data.data);
    }

    const handleHardwareSelectionChange = (event: any) => {
        const selected = event.detail.items.map((item: any) => item._state.text);
        const formattedSelected = selected.join(', ');
        setSelectedHardwareItems(formattedSelected);
        console.log("Selected Hardware items:", selected);
    };

    const handleLicenceSelectionChange = (event: any) => {
        const selected = event.detail.items.map((item: any) => item._state.text);
        const formattedSelected = selected.join(', ');
        setSelectedLicenceItems(formattedSelected);
        console.log("Selected Licence items:", selected);
    };

    async function getActivePeriod() {


        var api = new BudgetPeriodUserApi(configuration);
        console.log(username)
        var result = api.apiBudgetPeriodUserGetActivePeriodUseridGet(username, 1)
            .then((response: AxiosResponse<BudgetPeriodUserListDto>) => {



                console.log("BudgetPeriodUserApi", response.data)
                const dateStart = new Date(response.data.budgetPeriod?.startDate!);
                var formatteddateStart = dateStart.toLocaleDateString('tr-TR');
                formatteddateStart = formatteddateStart.replace(/\./g, "/");
                setstartDate(formatteddateStart);
                const dateEnd = new Date(response.data.budgetPeriod?.enDate!);
                var formatteddateEnd = dateEnd.toLocaleDateString('tr-TR');
                formatteddateEnd = formatteddateEnd.replace(/\./g, "/");
                setPlannedEndDate(formatteddateEnd);

                setComboprocessType(response.data.processtypeText!)
                setComboprocessTypeId(response.data.processType!.toString());
            })
            .catch(error => {

            }).finally(() => {

            });

    }


    const handleMngUserInput = async () => {
        setUserMngDialogVisible(true);
    }
    const handleIcKaynakUserInput = async () => {
        setIcKaynakUserDialogVisible(true);
    }
    const handleRefUserInput = async () => {
        setRefUserDialogVisible(true);
    }
    const handleTeamUserInput = async () => {
        setUserTeamDialogVisible(true);
    }
    const onUserMngSelected = async (event: any) => {
        console.log(event)
        setUserMngDialogVisible(false);
        setempMngName(event.userId);
    }
    const onTeamUsersSelected = async (event: any) => {
        console.log(event)
        setUserTeamDialogVisible(false);
        if (event.userId != "" && event.userId != null)
            setempTeamName(empTeamName + "," + event.userId);
    }
    const onRefUserSelected = async (event: any) => {
        console.log(event)
        setRefUserDialogVisible(false);
        setrefempname(event.userId);
        fetchPositionByLoginUser(event.userId);
    }
    const onIcKaynakUserSelected = async (event: any) => {
        console.log(event)
        setIcKaynakUserDialogVisible(false);
        setIcKaynakname(event.userId);
    }

    const internalSourcesChange = (e: any) => {
        setinternalSourceVsb(e.target.checked);
        // if (e.target.checked) {
        //     dispatchAlert({ message: "Terfi edecek olan çalışanın mevcut pozisyonunda istihdam devam edecekse “Yeni Pozisyon Talebi” girişi yapmalısınız", type: MessageBoxTypes.Information })
        // }
    }

    const internalEmploymentChange = (event: any) => {
        const selectedItem = event.detail.item;
        setcomboInternalEmployment(selectedItem.text);
        setcomboInternalEmploymentId(selectedItem.getAttribute('data-id'));
    }
    const promotionPeriodChange = (event: any) => {
        const selectedItem = event.detail.item;
        setcomboPromotionPeriod(selectedItem.text);
        setcomboPromotionPeriodId(selectedItem.getAttribute('data-id'));
        dispatchAlert({ message: "Ana Dönem için 01/03/2025 Ara Dönem için 01/09/2025 tarihlerini seçiniz", type: MessageBoxTypes.Information })

        if (selectedItem.text == "Ana Dönem") {
            setIseBaslamaTarihi("01/03/2025");
        }
        if (selectedItem.text == "Ara Dönem") {
            setIseBaslamaTarihi("01/09/2025");
        }
    }

    async function handleMsgDialog(event: any) {
        setmsgOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            navigate("/NormList")
        }
        else {
            return;
        }

    }


    async function closePositionDialog(e: any) {


        var api = new SFFOJobCodeApi(configuration);

        var result = await api.apiSFFOJobCodeCodeGet(e.externalCode);


        console.log(e);

    }


    async function getPositionCodeDetail(positionCode: any) {
        var api = new SFPositionsApi(configuration);
        var result = await api.apiSFPositionsDetailCodeGet(positionCode);


        setRealrealPositionCode(positionCode);
        var resultD = result.data[0];
        console.log("resultD", resultD);

        if (txtexternalName_en.current != null)
            txtexternalName_en.current!.setValue(resultD.externalName_en_US != null ? resultD.externalName_en_US : "");

        if (txtexternalName_ru.current != null)
            txtexternalName_ru.current!.setValue(resultD.externalName_ru_RU != null ? resultD.externalName_ru_RU : "");

        if (txtexternalName_tr.current != null)
            txtexternalName_tr.current!.setValue(resultD.externalName_tr_TR != null ? resultD.externalName_tr_TR : "");





        setDetail({

            jobCode: resultD.jobCode != null ? resultD.jobCode : "",
            cust_GeoZone: resultD.cust_GeoZone != null ? resultD.cust_GeoZone : null,
            cust_company: resultD.company != null ? resultD.company : "",
            targetFTE: resultD.targetFTE != null ? resultD.targetFTE : "",
            standardHours: resultD.standardHours != null ? resultD.standardHours : "",
            cust_isAlani: resultD.cust_isAlani != null ? resultD.cust_isAlani : null,
            cust_calismaYeriTuru: resultD.cust_calismaYeriTuru != null ? resultD.cust_calismaYeriTuru : null,
            cust_companyGroup: resultD.cust_companyGroup != null ? resultD.cust_companyGroup : "",
            employeeClass: resultD.employeeClass != null ? resultD.employeeClass : "",
            cust_empSubGroup: resultD.cust_empSubGroup != null ? resultD.cust_empSubGroup : "",
            cust_EmpGroup: resultD.cust_EmpGroup != null ? resultD.cust_EmpGroup : "",
            cust_locationGroup: resultD.cust_locationGroup != null ? resultD.cust_locationGroup : "",
            location: resultD.location != null ? resultD.location : null,
            cust_jobfunction: resultD.cust_jobfunction != null ? resultD.cust_jobfunction : "",
            cust_ronesansjoblevel: resultD.cust_ronesansjoblevel != null ? resultD.cust_ronesansjoblevel : "",
            jobTitle: resultD.jobTitle != null ? resultD.jobTitle : "",
            cust_sub_division: resultD.cust_sub_division != null ? resultD.cust_sub_division : "",
            department: resultD.department != null ? resultD.department : "",
            cust_parentDepartment: resultD.cust_parentDepartment != null ? resultD.cust_parentDepartment : null,
            cust_parentDepartment2: resultD.cust_parentDepartment2 != null ? resultD.cust_parentDepartment2 : "",
            businessUnit: resultD.businessUnit != null ? resultD.businessUnit : "",
            cust_customlegalEntity: resultD.cust_customlegalEntity != null ? resultD.cust_customlegalEntity : "",
            division: resultD.division != null ? resultD.division : "",
            cust_phisicalLocation: resultD.cust_phisicalLocation != null ? resultD.cust_phisicalLocation : null,
            cust_ronesansKademe: resultD.cust_ronesansKademe != null ? resultD.cust_ronesansKademe : null,
            payGrade: resultD.payGrade != null ? resultD.payGrade : null,
            costCenter: resultD.costCenter != null ? resultD.costCenter : "",
            cust_payGroup: resultD.cust_payGroup != null ? resultD.cust_payGroup : null,
            changeReason: resultD.changeReason != null ? resultD.changeReason : "",
            cust_HayKademe: resultD.cust_HayKademe != null ? resultD.cust_HayKademe : null,
            comment: resultD.comment != null ? resultD.comment : "",
            cust_ticket: resultD.cust_ticket != null ? resultD.cust_ticket : null,
            cust_ChiefPosition: resultD.cust_ChiefPosition != null ? resultD.cust_ChiefPosition : null,
            cust_IseBaslamaTarihi: resultD.cust_IseBaslamaTarihi != null ? resultD.cust_IseBaslamaTarihi : null,
            cust_PlanlananIseGiris: resultD.cust_PlanlananIseGiris != null ? resultD.cust_PlanlananIseGiris : null,
            cust_plannedEndDate: resultD.cust_plannedEndDate != null ? resultD.cust_plannedEndDate : null,
            multipleIncumbentsAllowed: resultD.multipleIncumbentsAllowed != null ? resultD.multipleIncumbentsAllowed : false,
            parentPosition: resultD.parentPositionValue != null ? resultD.parentPositionValue : ""
        });

    }

    async function closeNormDialog(e: any) {
        dispatchBusy({ isBusy: true });
        await getPositionCodeDetail(e.externalCode);
        dispatchBusy({ isBusy: false });
    }

    async function onCreateNorm(workflowHeadId: string = "") {
        if (txtparentDepartment2.current?.externalCode == "" || txtparentDepartment2.current?.externalCode == null) {
            dispatchAlert({ message: "1. Üst Birim alanı zorunludur", type: MessageBoxTypes.Warning })
            return;
        }
        if (txtparentDepartment.current?.externalCode == "" || txtparentDepartment.current?.externalCode == null) {
            dispatchAlert({ message: "Üst Birim alanı zorunludur", type: MessageBoxTypes.Warning })
            return;
        }
        let api = new BudgetNormCodeRequestApi(configuration);
        let updateDto = {} as BudgetNormCodeRequestUpdateDto;
        updateDto.id = id;

        updateDto.jobCode = txtJobCode.current?.externalCode!;

        updateDto.businessUnit = txtbusinessUnit.current?.externalCode!;
        updateDto.changeReason = comboChangeReason.current;
        updateDto.code = realPositionCode;// txtcode.current?.current;
        updateDto.comment = txtcomment.current?.current;
        updateDto.cust_company = txtcompany.current?.externalCode!
        updateDto.costCenter = txtcostCenter.current?.externalCode!

        updateDto.cust_ChiefPosition = IsChiefPosition;
        updateDto.cust_EmpGroup = comboEmpGroup.current;
        updateDto.cust_GeoZone = txtGeoZone.current?.externalCode!;
        updateDto.cust_HayKademe = comboHayKademe.current;
        updateDto.cust_IseBaslamaTarihi = parseDateString(iseBaslamaTarihi).toISOString();
        updateDto.cust_PlanlananIseGiris = parseDateString(planlananIseGiris).toISOString();
        updateDto.cust_actualhiredate = parseDateString(actualhiredate).toISOString();
        updateDto.effectiveStartDate = parseDateString(startDate).toISOString();
        updateDto.cust_calismaYeriTuru = comboCalismaYeriTuru.current;
        updateDto.cust_companyGroup = txtcompanyGroup.current?.externalCode!;
        updateDto.cust_customlegalEntity = txtcustomlegalEntity.current?.externalCode!;
        updateDto.cust_empSubGroup = comboEmpSubGroup.current;
        updateDto.cust_isAlani = comboIsAlani.current;
        updateDto.cust_jobfunction = txtJobFunction.current?.externalCode!
        updateDto.cust_locationGroup = txtlocationGroup.current?.externalCode!
        updateDto.cust_parentDepartment2 = txtparentDepartment2.current?.externalCode!
        updateDto.cust_parentDepartment = txtparentDepartment.current?.externalCode!
        updateDto.cust_payGroup = txtpayGroup.current?.externalCode!;
        updateDto.cust_phisicalLocation = txtphisicalLocation.current?.externalCode!;
        updateDto.cust_plannedEndDate = parseDateString(plannedEndDate).toISOString();
        updateDto.cust_ronesansKademe = comboRonesansKademe.current;;
        updateDto.cust_ronesansjoblevel = txtronesansjoblevel.current?.current!;
        updateDto.cust_sub_division = txtSubDivision.current?.externalCode!
        updateDto.cust_ticket = comboTicket.current;
        updateDto.department = txtDepartment.current?.externalCode!;
        updateDto.division = txtdivision.current?.externalCode!;
        updateDto.effectiveStatus = "A";
        updateDto.employeeClass = comboEmployeeClass.current;
        updateDto.externalName_defaultValue = txtexternalName_tr.current?.current;
        updateDto.externalName_en_DEBUG = "";
        updateDto.externalName_en_US = txtexternalName_en.current?.current;
        updateDto.externalName_ru_RU = txtexternalName_ru.current?.current;
        updateDto.externalName_tr_TR = txtexternalName_tr.current?.current!;
        updateDto.jobTitle = txtjobTitle.current?.current;
        updateDto.location = txtlocation.current?.externalCode!;
        updateDto.multipleIncumbentsAllowed = IsmultipleIncumbentsAllowed;
        updateDto.parentPosition = txtparentPosition.current?.externalCode!;
        updateDto.payGrade = txtpayGrade.current?.externalCode!;
        updateDto.standardHours = txtstandardHours.current?.current!;
        updateDto.targetFTE = txttargetFTE.current?.current!;
        updateDto.vacant = IsVacant;
        updateDto.isSend = isSend;

        updateDto.processType = Number.parseInt(comboprocessTypeId) as ProcessType;
        updateDto.internalEmploymentType = Number.parseInt(comboInternalEmploymentId) as InternalEmploymentType;
        updateDto.isInternalSource = internalSourceVsb;
        updateDto.relationEmployess = empTeamName;
        updateDto.relationManager = empMngName;

        updateDto.propotionReasonTxt = txtpropotionReason.current?.current!;
        updateDto.promotionPeriod = combocomboPromotionPeriodId;
        updateDto.promotionPeriodTxt = comboPromotionPeriod;
        updateDto.jobCodeDescription = txtJobCode.current?.name!;

        if (workflowHeadId != "")
            updateDto.workflowHeadId = workflowHeadId;


        console.log(updateDto)
        api.apiBudgetNormCodeRequestPut(updateDto)
            .then(response => {
                if (workflowHeadId != null) {
                    dispatchAlert({ message: "Norm Kadro Aktarımı Başarılı", type: MessageBoxTypes.Success })
                }
                else {
                    dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                }
                navigate("/NormList")
                dispatchBusy({ isBusy: false });
            })
            .catch(error => {
                dispatchBusy({ isBusy: false });
                // Hata mesajlarını kontrol edin ve birleştirin
                let errorMessage = 'Bilinmeyen bir hata oluştu';
                if (error.response) {
                    if (error.response.data && error.response.data.errors) {
                        errorMessage = error.response.data.errors.join(', ');
                    } else if (error.response.data) {
                        errorMessage = error.response.data;
                    }
                }
                // Hata mesajını loglayın ve dispatchAlert ile gösterin
                console.log(errorMessage);
                dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
            });
    }

    const handlePeriodCodeUserInput = (event: any) => {

        const selectedItem = event.detail.item;
        setperiodCode(selectedItem.text);
        setperiodCodeId(selectedItem.getAttribute('data-id'));
    };
    function tokendelete(event: any) {


        const tokenText = event.detail.token.getAttribute("text"); // Silinen token'ın text değerini alıyoru



        setempTeamName((prevTokens) => {
            return prevTokens
                .split(',') // String'i virgüllere göre ayır
                .filter(token => token.trim() !== tokenText) // Silinen token'ı çıkar
                .join(','); // Geriye kalanları tekrar virgülle birleştir
        });
    }

    return (
        <DashboardLayout>
            <ObjectPage
                // footer={<Bar design="FloatingFooter" endContent={<><Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button></>} />}
                headerContentPinnable
                headerTitle={<DynamicPageTitle actions={<>
                    <Button icon='decline' onClick={() => setmsgOpen(true)} disabled={isSend} design="Emphasized">Vazgeç</Button>
                    <Button icon='save' onClick={handleSave} disabled={isSend} design="Emphasized">Kaydet</Button>
                    <Button disabled={!isedit || isSend} icon="workflow-tasks" onClick={handleApprovalClick}>Onaya Gönder</Button>
                    <Button disabled={detail?.workflowHead?.workFlowStatus == 2 ? false : true} icon="add" onClick={() => onCreateNorm(detail?.workflowHeadId!)}>Norm Kadro Oluştur</Button>
                </>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem onClick={() => navigate("/JobCode")}>Norm Kadro</BreadcrumbsItem>
                            <BreadcrumbsItem>Norm Kadro Talep Detayı</BreadcrumbsItem>
                        </Breadcrumbs>}
                    header={<Title>Norm Kadro Oluştur</Title>}
                    showSubHeaderRight
                    subHeader={txtJobCodeNameEnglish.current?.current}>
                    <ObjectStatus state={isSend ? 'Success' : 'Warning'}>
                        {getStatusText(detail?.workflowHead?.workFlowStatus!)}
                    </ObjectStatus></DynamicPageTitle>}
                imageShapeCircle
                onBeforeNavigate={function _a() { }}
                onPinnedStateChange={function _a() { }}
                onSelectedSectionChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                selectedSectionId="general"
                showHideHeaderButton
            >
                <TabContainer

                    contentBackgroundDesign="Transparent"
                    headerBackgroundDesign="Transparent"
                    // onTabSelect={onTabSelect}
                    tabLayout="Standard"
                >
                    <Tab
                        icon="employee"
                        selected
                        text="Yönetici"
                    >
                        <ObjectPageSection

                            aria-label="YÖNETİCİ ALANI"
                            id="personal-connect-mng"
                            titleText="YÖNETİCİ ALANI"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">

                                    <FormItem label={<Label style={{ width: "150px" }} required>Pozisyon İsmi</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.jobCode!} dataId={19} value={txtJobCode} headerText={"19"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { closePositionDialog(e); }}></GenericSelectDialogWithInputEnum>
                                        <Icon showTooltip title='Pozisyon Talep Ekranı' name='chain-link' style={{ marginTop: '10px', marginLeft: '5px' }} onClick={() => window.open('/JobCode', '_blank')} />
                                    </FormItem>

                                    {/* <div hidden={true}>
                                    <FormItem label={<Label style={{ width: "150px" }} required>İşlem Yapılan Pozisyon Kodu</Label>}>
                                        <Input disabled={detail?.workflowHead ? true : false} value={realPositionCode} onChange={handleRealPositionInput} required={true} type="Text" />
                                    </FormItem>
                                    </div> */}

                                    <FormItem label={<Label style={{ width: "150px" }} required>Çalışma Yeri Türü</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_calismaYeriTuru!} pickListId={13} value={comboCalismaYeriTuru} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label={<Label required>Planlanan İşe Başlama Tarihi</Label>}>
                                        <DatePicker
                                            required
                                            minDate='01/01/2025'
                                            onChange={handleIseBaslamaTarihiInput}
                                            value={iseBaslamaTarihi.toString()}

                                            formatPattern='dd/MM/yyyy'
                                            primaryCalendarType="Gregorian"
                                            valueState="None"
                                        />
                                    </FormItem>
                                    <FormItem label={<Label required>Planlanan Bitiş Tarihi</Label>}>
                                        <DatePicker
                                            required
                                            minDate='01/01/2025'
                                            onChange={handlePlannedEndDateInput}
                                            value={plannedEndDate.toString()}
                                            formatPattern='dd/MM/yyyy'
                                            primaryCalendarType="Gregorian"
                                            valueState="None"
                                        />
                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Bağlı Olacağı Yönetici</Label>}>
                                        <Input onClick={handleMngUserInput} icon={<Icon name="search" onClick={handleMngUserInput} />} value={empMngName} required={true} type="Text" />
                                    </FormItem>
                                    <FormItem label="Bağlı Olacak Kişiler">
                                        {/* <Input onClick={handleTeamUserInput} icon={<Icon name="search" onClick={handleTeamUserInput} />} value={empTeamName} required={true} type="Text" /> */}


                                        <MultiInput
                                            onTokenDelete={tokendelete}
                                            icon={<Icon name="search" onClick={handleTeamUserInput} />}
                                            style={{
                                                width: '400px'
                                            }}
                                            tokens={
                                                <>
                                                    {empTeamName.split(',').map((token: any, index) => {
                                                        const trimmedToken = token.trim();
                                                        if (trimmedToken) { // Eğer token boş değilse
                                                            return <Token key={index} text={trimmedToken} />;
                                                        }
                                                        return null; // Boşsa hiçbir şey render etme
                                                    })}
                                                </>
                                            }
                                            type="Text"
                                            valueState="None"
                                        />

                                    </FormItem>


                                    {/* <FormItem label={<Label style={{ width: "150px" }} required>Referans Pozisyon Kodu</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.code!} dataId={15} value={txtpositionCode} headerText={"15"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { closeNormDialog(e); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem> */}
                                    <FormItem label="Referans Alınacak Kişi">
                                        <Input onClick={handleRefUserInput} icon={<Icon name="search" onClick={handleRefUserInput} />} value={refempname} required={true} type="Text" />
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Grup/Başkanlık</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={10} displayValue={detail?.cust_customlegalEntity!} value={txtcustomlegalEntity} headerText={"10"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Şirket</Label>}>
                                        <GenericSelectDialogWithInputEnumCompany grupBaskanlik={txtcustomlegalEntity} dataId={8} displayValue={detail?.businessUnit!} value={txtbusinessUnit} headerText={"8"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnumCompany>
                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Bölge/Fonksiyon/BU</Label>}>
                                        <GenericSelectDialogWithInputEnumBolgeFonksiyon company={txtbusinessUnit} dataId={11} displayValue={detail?.division!} value={txtdivision} headerText={"11"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnumBolgeFonksiyon>
                                    </FormItem>
                                    <FormItem label="Bölüm/Proje/İşletme">
                                        <GenericSelectDialogWithInputEnumBolumProje company={txtdivision} dataId={4} displayValue={detail?.cust_sub_division!} value={txtSubDivision} headerText={"4"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnumBolumProje>
                                    </FormItem>

                                    <FormItem label="Birim">
                                        <GenericSelectDialogWithInputEnum dataId={5} displayValue={detail?.department!} value={txtDepartment} headerText={"5"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label="Donanım">
                                        <MultiComboBox
                                            onSelectionChange={handleHardwareSelectionChange}
                                            valueState="None"
                                            value={selectedHardwareItems}
                                        >
                                            {hardwareItems.map((hardware) => (
                                                <MultiComboBoxItem key={hardware.value} text={hardware.name!} />
                                            ))}
                                        </MultiComboBox>
                                    </FormItem>
                                    <FormItem label="Lisans">
                                        <MultiComboBox
                                            onSelectionChange={handleLicenceSelectionChange}
                                            valueState="None"
                                        >
                                            {licenceItems.map((licence) => (
                                                <MultiComboBoxItem key={licence.value} text={licence.name!} />
                                            ))}
                                        </MultiComboBox>
                                    </FormItem>

                                    <FormItem label="İstek Türü">
                                        <ComboBox
                                            readonly
                                            onInput={function _a() { }}
                                            onSelectionChange={handleProcessType}
                                            value={comboprocessType}
                                            valueState="None"
                                        >
                                            <ComboBoxItem key="1" text="Yıllık Kadro Planı" data-id="1" />
                                            <ComboBoxItem key="2" text="Ek Kadro Planı" data-id="2" />
                                        </ComboBox>
                                    </FormItem>
                                    <FormItem label="İç Kaynaktan mı karşılanacak?">
                                        <FlexBox>
                                            <div>
                                                <CheckBox
                                                    onChange={internalSourcesChange}
                                                    checked={internalSourceVsb}
                                                    style={{ marginTop: "16px" }}
                                                />
                                            </div>
                                            <div hidden={!internalSourceVsb}>
                                                <Label required>Terfi/Çalışan</Label>
                                                <ComboBox
                                                    hidden={!internalSourceVsb}
                                                    onSelectionChange={internalEmploymentChange}
                                                    value={comboInternalEmployment}
                                                >
                                                    <ComboBoxItem key="1" text="Terfi" data-id="1" />
                                                    <ComboBoxItem key="2" text="Çalışan/Transfer" data-id="2" />
                                                </ComboBox>
                                            </div>
                                            <div hidden={!internalSourceVsb}>
                                                <Label required>Personel</Label>
                                                <Input hidden={!internalSourceVsb} onClick={handleIcKaynakUserInput} icon={<Icon name="search" onClick={handleIcKaynakUserInput} />} value={IcKaynakname} required={true} type="Text" />

                                            </div>
                                            <div hidden={!internalSourceVsb}>
                                                <Label required>Dönem</Label>
                                                <ComboBox
                                                    hidden={!internalSourceVsb}
                                                    onSelectionChange={promotionPeriodChange}
                                                    value={comboPromotionPeriod}
                                                >
                                                    <ComboBoxItem key="1" text="Ana Dönem" data-id="1" />
                                                    <ComboBoxItem key="2" text="Ara Dönem" data-id="2" />
                                                </ComboBox>
                                            </div>
                                            <div hidden={!internalSourceVsb}>
                                                <div hidden={comboInternalEmploymentId == "1" ? false : true}>
                                                    <Label required>Terfi Nedeni</Label>
                                                    <CustomInputComponent value={detail?.propotionReasonTxt!} ref={txtpropotionReason} ></CustomInputComponent>
                                                </div>
                                            </div>

                                        </FlexBox>


                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Talep Nedeni</Label>}>
                                        <CustomInputComponent value={detail?.comment!} ref={txtcomment} ></CustomInputComponent>
                                    </FormItem>
                                </FormGroup>
                            </Form>
                        </ObjectPageSection>
                    </Tab>
                    <Tab
                        icon="employee-approvals"
                        text="İK"
                        disabled={detail?.workflowHead?.workFlowStatus == 2 ? false : true}
                    >
                        <ObjectPageSection

                            aria-label="Pozisyon Bilgisi"
                            id="personal-connect-5"
                            titleText="POZİSYON BİLGİSİ"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">
                                    <FormItem label={<Label style={{ width: "150px" }} required>Pozisyon Şirket Grubu</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.cust_GeoZone!} dataId={13} value={txtGeoZone} headerText={"13"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Pozisyon Bordro Şirketi</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.cust_company!} dataId={14} value={txtcompany} headerText={"14"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Pozisyon Adı - TR</Label>}>
                                        <CustomInputComponent value={detail?.externalName_tr_TR!} ref={txtexternalName_tr} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label="Pozisyon Adı - EN">
                                        <CustomInputComponent value={detail?.externalName_en_US!} ref={txtexternalName_en} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label="Pozisyon Adı - RU">
                                        <CustomInputComponent value={detail?.externalName_ru_RU!} ref={txtexternalName_ru} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label="Toplu Pozisyon">
                                        <CheckBox checked={IsmultipleIncumbentsAllowed} onChange={handleIsmultipleIncumbentsAllowedCheckBoxChange} text='' ></CheckBox>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Tam Zamanlı</Label>}>
                                        <CustomInputComponent value={detail?.targetFTE!} ref={txttargetFTE} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Haftalık Çalışma Saati</Label>}>
                                        <CustomInputComponent value={detail?.standardHours!} ref={txtstandardHours} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Bağlı Olduğu Pozisyon</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.parentPosition!} dataId={15} value={txtparentPosition} headerText={"15"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>İş Alanı</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_isAlani!} pickListId={14} value={comboIsAlani} ></PickListComboBoxComponent>
                                    </FormItem>

                                </FormGroup>
                            </Form>
                        </ObjectPageSection>

                        <ObjectPageSection
                            aria-label="Norm Genel Bilgileri"
                            id="general"
                            titleText="NORM GENEL BİLGİLERİ"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup>
                                    <FormItem label={<Label required>Başlangıç Tarihi</Label>}>
                                        <DatePicker
                                            readonly
                                            required
                                            onChange={handleStartDateInput}
                                            onValueStateChange={function _a() { }}
                                            primaryCalendarType="Gregorian"
                                            formatPattern='dd/MM/yyyy'
                                            value={startDate.toString()}
                                            valueState="None"
                                        />
                                    </FormItem>

                                    <FormItem label={<Label required>Planlanan İşe Giriş Tarihi</Label>}>
                                        <DatePicker
                                            required
                                            onChange={handleplanlananIseGirisInput}
                                            value={planlananIseGiris.toString()}

                                            formatPattern='dd/MM/yyyy'
                                            primaryCalendarType="Gregorian"
                                            valueState="None"
                                        />
                                    </FormItem>
                                    <FormItem label={<Label required>Gerçekleşen İşe Giriş Tarihi</Label>}>
                                        <DatePicker
                                            required
                                            minDate='01/01/2025'
                                            onChange={handleActualhiredateInput}
                                            value={actualhiredate.toString()}

                                            formatPattern='dd/MM/yyyy'
                                            primaryCalendarType="Gregorian"
                                            valueState="None"
                                        />
                                    </FormItem>

                                </FormGroup>
                            </Form>

                        </ObjectPageSection>

                        <ObjectPageSection
                            aria-label="Personel Bilgisi"
                            id="personal-connect-2"
                            titleText="PERSONEL BİLGİSİ"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">
                                    <FormItem label={<Label style={{ width: "150px" }} required>Çalışan Grubu</Label>}>
                                        <PickListComboBoxComponent pickListId={2} displayValue={detail?.employeeClass!} value={comboEmployeeClass} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Çalışan Alt Grubu</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_empSubGroup!} pickListId={10} value={comboEmpSubGroup} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Çalışan Tipi</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_EmpGroup!} pickListId={11} value={comboEmpGroup} ></PickListComboBoxComponent>
                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Personel Alanı</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={1} displayValue={detail?.cust_locationGroup!} value={txtlocationGroup} headerText={"1"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Personel Alt Alanı</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={2} displayValue={detail?.location!} value={txtlocation} headerText={"2"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Yönetici Mi?</Label>}>
                                        <CheckBox checked={IsChiefPosition} onChange={handleIsChiefPositionCheckBoxChange} text='' ></CheckBox>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>İş Fonksiyonu</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={3} displayValue={detail?.cust_jobfunction!} value={txtJobFunction} headerText={"3"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>İş Seviyesi</Label>}>
                                        <CustomInputComponent value={detail?.cust_ronesansjoblevel!} ref={txtronesansjoblevel} ></CustomInputComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>İş Ünvanı</Label>}>
                                        <CustomInputComponent value={detail?.jobTitle!} ref={txtjobTitle} ></CustomInputComponent>

                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>1. Üst Birim</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={5} displayValue={detail?.cust_parentDepartment2!} value={txtparentDepartment2} headerText={"5"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Üst Birim</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={5} displayValue={detail?.cust_parentDepartment!} value={txtparentDepartment} headerText={"5"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                </FormGroup>
                            </Form>
                        </ObjectPageSection>

                        <ObjectPageSection

                            aria-label="Şirket Bilgisi"
                            id="personal-connect-3"
                            titleText="ŞİRKET BİLGİSİ"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">
                                    <FormItem label={<Label style={{ width: "150px" }} required>Şirket Grubu</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={9} displayValue={detail?.cust_companyGroup!} value={txtcompanyGroup} headerText={"9"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required> Fiziksel Lokasyon</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={2} displayValue={detail?.cust_phisicalLocation!} value={txtphisicalLocation} headerText={"2"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                </FormGroup>
                            </Form>
                        </ObjectPageSection>

                        <ObjectPageSection

                            aria-label="Ücret Bilgisi"
                            id="personal-connect-4"
                            titleText="ÜCRET BİLGİSİ"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">
                                    <FormItem label={<Label style={{ width: "150px" }} required>Gelir Seviyesi</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_ronesansKademe!} pickListId={7} value={comboRonesansKademe} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Ücret derecesi</Label>}>
                                        <GenericSelectDialogWithInputEnum displayValue={detail?.payGrade!} dataId={16} value={txtpayGrade} headerText={"16"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>

                                    <FormItem label={<Label style={{ width: "150px" }} required>Masraf Merkezi</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={17} displayValue={detail?.costCenter!} value={txtcostCenter} headerText={"17"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Bodro Alt Birimi</Label>}>
                                        <GenericSelectDialogWithInputEnum dataId={18} displayValue={detail?.cust_payGroup!} value={txtpayGroup} headerText={"18"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                    </FormItem>
                                </FormGroup>
                            </Form>
                        </ObjectPageSection>
                        <ObjectPageSection
                            aria-label="Diğer Bigileri"
                            id="other"
                            titleText="DİĞER BİLGİLER"
                        >
                            <Form
                                columnsL={2}
                                columnsXL={2}
                                style={{
                                    alignItems: 'start'
                                }}
                            >
                                <FormGroup titleText="">
                                    <FormItem label={<Label style={{ width: "150px" }} required>Değiştirme Nedeni</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.changeReason!} pickListId={15} value={comboChangeReason} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Hay Kademe</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_HayKademe!} pickListId={8} value={comboHayKademe} ></PickListComboBoxComponent>
                                    </FormItem>
                                    <FormItem label="İşe Alınacak">
                                        <CheckBox checked={IsVacant} onChange={handleIsVacantCheckBoxChange} text='' ></CheckBox>
                                    </FormItem>
                                    <FormItem label={<Label style={{ width: "150px" }} required>Ticket Alacak Mı?</Label>}>
                                        <PickListComboBoxComponent displayValue={detail?.cust_ticket!} pickListId={12} value={comboTicket} ></PickListComboBoxComponent>
                                    </FormItem>

                                </FormGroup>

                            </Form>

                        </ObjectPageSection>
                    </Tab>

                </TabContainer>

            </ObjectPage>


            <footer>
                <Toolbar >
                    <ToolbarSpacer />
                    <Label children="Oluşturan Kişi:" />
                    <Label children={detail?.createdBy ? detail?.createdBy : ""} />
                    <Label children="Oluşturulma Tarihi:" />
                    <Label children={detail?.createdDate ? new Date(detail.createdDate).toLocaleDateString('tr-TR') : ""} />
                    <Label children="Güncelleyen Kişi:" />
                    <Label children={detail?.updatedBy ? detail?.updatedBy : ""} />
                    <Label children="Güncellenme Tarihi:" />
                    <Label children={detail?.updatedDate ? new Date(detail.updatedDate).toLocaleDateString('tr-TR') : ""} />
                </Toolbar>

            </footer>

            <RelatedUserSelectDialog
                open={UserMngDialogVisible}
                onClose={() => setUserMngDialogVisible(false)}
                onConfirm={onUserMngSelected}

            />
            <RelatedUserSelectDialogMultiSelect
                open={UserTeamDialogVisible}
                onClose={() => setUserTeamDialogVisible(false)}
                onConfirm={onTeamUsersSelected}

            />

            <RelatedUserSelectDialog
                open={RefUserDialogVisible}
                onClose={() => setRefUserDialogVisible(false)}
                onConfirm={onRefUserSelected}

            />

            <RelatedUserSelectDialog
                open={IcKaynakUserDialogVisible}
                onClose={() => setIcKaynakUserDialogVisible(false)}
                onConfirm={onIcKaynakUserSelected}

            />

            <MessageBox
                open={msgOpen}
                onClose={handleMsgDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Verileriniz kaydedilmeyecektir, devam edilsin mi?
            </MessageBox>

        </DashboardLayout>

    );
}
export default NormDetail;
