import React, { useEffect, useState } from 'react';
import { Button, SideNavigation, SideNavigationItem, SideNavigationSubItem } from '@ui5/webcomponents-react';

import "@ui5/webcomponents-icons/dist/copy";
import "@ui5/webcomponents-icons/dist/form";
import "@ui5/webcomponents-icons/dist/share";
import "@ui5/webcomponents-icons/dist/sys-enter";
import "@ui5/webcomponents-icons/dist/key-user-settings";
import "@ui5/webcomponents-icons/dist/activity-individual";

import "@ui5/webcomponents-fiori/dist/SideNavigationItem.js"
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/userName';
import { BudgetAdminUserApi, BudgetAdminUserListDto, Configuration } from '../api/generated';
import { AxiosResponse } from 'axios';
const SideNavigationComponent = () => {
    const navigate = useNavigate();
    // const [sideNavCollapsed, setSideNavCollapsed] = useState(true);
    const { sideNavCollapsed, setSideNavCollapsed } = useUser();
    const { username, setUsername } = useUser();

    const { loginUser, setLoginUser } = useUser();
    const { loginUserNameSurname, setloginUserNameSurname } = useUser();


    const [isAdmin, setisAdmin] = useState(false);
    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      });

    useEffect(() => {
        if (username == "") {
            // setUsername("47064332");
            // setLoginUser("47064332");
            setUsername("GLB270495");
            setLoginUser("GLB270495");
        }
        getIsAdmin()

    }, [loginUser]);

    async function getIsAdmin() {

        var api = new BudgetAdminUserApi(configuration);
        var result = api.apiBudgetAdminUserGetUsernNameUserNameGet(loginUser)
          .then((response: AxiosResponse<BudgetAdminUserListDto>) => {
    
            console.log("BudgetAdminUserApi", response.data)
            setisAdmin(response.data.isAdmin!)
          })
          .catch(error => {
    
          }).finally(() => {
    
          });
      }

    return (

        <div>
            <div style={{ display: "flex", width: '100%', padding: "0.5rem" }}>
                <Button icon="menu" onClick={() => setSideNavCollapsed((prevState) => !prevState)} />
            </div>

            <SideNavigation

                collapsed={sideNavCollapsed}
                style={{ height: "100%", marginLeft: "6px", marginTop: "1px", marginRight: "1px" }}
                fixedItems={
                    <>
                        <SideNavigationItem href="https://ronesans.com/" target="_blank" text="External Link" />
                        <SideNavigationItem text="History" />
                    </>
                }
                onSelectionChange={() => { }}
            >
                {/* <SideNavigationItem

                text="Home"
            /> */}
                {/* <SideNavigationItem

                selected
                text="Locations"
            /> */}



                <SideNavigationItem
                    icon="group"
                    color='green'
                    expanded
                    style={{ color: 'green', marginLeft: '3px', fontWeight: 'bold' }}
                    text="Bütçe Yönetim"
                >
                    <SideNavigationItem
                        icon='key-user-settings'
                        text="Admin Paneli"
                        hidden={!isAdmin}
                    >
                        <SideNavigationSubItem onClick={() => navigate("/AdminUserList")} text="Admin Kullanıcılar" />
                        <SideNavigationSubItem onClick={() => navigate("/AdminApprove")} text="Onaydaki Talepler" />
                    </SideNavigationItem>
                    <SideNavigationItem
                        icon='copy'
                        text="Tanımlamalar"
                    >
                        {/* <SideNavigationSubItem  onClick={() => navigate("/RequestTypeDefination")}   text="İstek Türü Tanımlama" /> */}
                        <SideNavigationSubItem onClick={() => navigate("/PeriodDefination")} text="Dönem Yönetim Ekranı" />
                        <SideNavigationSubItem onClick={() => navigate("/PeriodUser")} text="Dönem Yetki Ekranı" />
                    </SideNavigationItem>

                    <SideNavigationItem
                        icon='form'
                        text="Formlar"
                    >
                        {/* <SideNavigationSubItem  onClick={() => navigate("/RequestTypeDefination")}   text="İstek Türü Tanımlama" /> */}

                        <SideNavigationSubItem onClick={() => navigate("/JobCode")} text="Pozisyon Talep" />
                        <SideNavigationSubItem onClick={() => navigate("/NormList")} text="Norm Kadro Talep" />
                        <SideNavigationSubItem onClick={() => navigate("/PromotionList")} text="Terfi Talep" />
                        {/* <SideNavigationSubItem onClick={() => navigate("/PositionList")} text="Pozisyon Listele" />
         */}
                    </SideNavigationItem>
                    <SideNavigationItem
                        icon='share'
                        text="İş Akışı"
                    >
                        {/* <SideNavigationSubItem  onClick={() => navigate("/RequestTypeDefination")}   text="İstek Türü Tanımlama" /> */}
                        <SideNavigationSubItem onClick={() => navigate("/WorkFlowList")} text="Onay süreçleri" />
                    </SideNavigationItem>

                    <SideNavigationItem
                        icon='sys-enter'
                        text="Onay Süreçlerim"
                    >
                        {/* <SideNavigationSubItem  onClick={() => navigate("/RequestTypeDefination")}   text="İstek Türü Tanımlama" /> */}
                        <SideNavigationSubItem onClick={() => navigate("/ApproveList")} text="Onay Ekranı" />
                        <SideNavigationSubItem onClick={() => navigate("/ReportList")} text="Raporlar" />
                    </SideNavigationItem>

                    <SideNavigationItem
                        icon='activity-individual'
                        text="Kadro ve Taleplerim"
                        onClick={() => navigate("/PositionList")}
                    >
                    </SideNavigationItem>
                </SideNavigationItem>
            </SideNavigation>
        </div>

    );
};

export default SideNavigationComponent;
