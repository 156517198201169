import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Form, ErrorMessage, Field, Formik, FormikProvider, useFormik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import { Button, Input, Icon, Label, CheckBox } from '@ui5/webcomponents-react';
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import UserSelectDialog from "../../Components/UserSelectDialog";


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



export default function AprroveTab({ initialValues, node, onButtonClick }) {
  const [value, setValue] = React.useState(0);


  const [UserDialogVisible, setUserDialogVisible] = useState(false);
  const handleChange = (event, newValue) => {

    setValue(newValue);
  };

  const [inputDisabled, setInputDisabled] = useState(false);
  const handleCheckBox = (event) => {
    alert(event.target.checked)
    setInputDisabled(event.target.checked)
  };

  return (




    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {/* <Tab  icon ={<InfoIcon/>}label="Onaycı Bilgileri" /> */}
          <Tab label="Zamanlama" />
          <Tab label="Action" />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>


      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          console.log(JSON.stringify(node));
          node.data = values;
          setSubmitting(false);
          onButtonClick(node);
        }}
      >
        {({ submitForm, isSubmitting, handleChange, values, setFieldValue }) => (
          <Form>
            <TabPanel value={value} index={0}>

              <Field
                as={Input}
                name="name"
                label="AkışAdı"
                fullWidth
                onChange={handleChange}
                value={values.name}
              />


              <Button style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '10px' }} type="Submit" variant="gradient" color="success" size="small">
                Kaydet
              </Button>

            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CheckBox
                  checked={values.isManager}
                  style={{ marginLeft: "-10px" }}
                  text='Üst Yönetici'
                  onChange={(e) => setFieldValue('isManager', e.target.checked)}
                />
                <div style={{ alignItems: 'center' }}>
                  <Input
                    disabled={inputDisabled}
                    style={{ flexGrow: 1 }}
                    onInput={handleChange}
                    value={values.code ? values.code : undefined}
                    icon={<Icon name="search" onClick={() => { setUserDialogVisible(true) }} />}
                    readonly
                    type="Text"
                  />
                  {values.approvername && (
                    <Label style={{ marginRight: '10px', marginLeft: "10px" }}>
                      {values.approvername}
                    </Label>
                  )}
                </div>
                <Button
                  type="Submit"
                  variant="gradient"
                  color="success"
                  size="small"
                  style={{ width: "100px" }}
                >
                  Kaydet
                </Button>

              </div>
            </TabPanel>


            <UserSelectDialog
              open={UserDialogVisible}
              onClose={() => setUserDialogVisible(false)}

              onConfirm={(e) => {
                setFieldValue("code", e.userId);
                setFieldValue("approvername", e.defaultFullName);
                setUserDialogVisible(false);
              }}

            />

          </Form>
        )}
      </Formik>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}

    </Box>

  );
}
