import React, { useEffect, useRef, useState } from 'react';
import './App.css';


import "@ui5/webcomponents-base/dist/features/F6Navigation";
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/DatePicker";
import "@ui5/webcomponents/dist/List";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/CustomListItem";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Dialog";
import "@ui5/webcomponents/dist/Popover";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/TextArea";
import "@ui5/webcomponents/dist/Switch";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/palette.js";
import "@ui5/webcomponents-icons/dist/settings.js";
import "@ui5/webcomponents-icons/dist/sys-help.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/account.js";
import "@ui5/webcomponents-icons/dist/private.js";
import "@ui5/webcomponents-icons/dist/loan.js";
import "@ui5/webcomponents-icons/dist/globe.js";
import "@ui5/webcomponents-icons/dist/workflow-tasks";
import { Avatar, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, TextArea, Title, Toolbar, ToolbarSpacer } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from './Components/DashboardLayout';
import { BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetPeriodApi, Configuration, FOJobFunctionList, FOJobFunctionSFDto, FOPayGradeList, FOPayGradeSFDto, PickListApi, PickListDto, SFFOJobFunctionApi, SFFOPayGradeApi, WorkFlowApi, WorkFlowStartApiDto } from './api/generated';
import GenericSelectDialog, { SelectDialog } from './Components/GenericSelectDialog';
import { number } from 'yup';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useBusy } from './hooks/useBusy';
import { getStatusText, isDecimal, isValidDate, parseDateString } from './utils/utils';
import { useAlert } from './hooks/useAlert';
import PickListComboBoxComponent from './Components/PickListComponent';
import CustomInputComponent from './Components/CustomInput';
import { isReadable } from 'stream';
import { useUser } from './hooks/userName';
import GenericSelectDialogWithInputEnum from './Components/GenericSelectDialogWithInputEnum';
// setTheme("sap_horizon");
// setTheme("sap_fiori_3");
// setTheme("sap_belize");
function EmployeDetail() {
    const { username, setUsername } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {

        getDetail();
        getPayGradeData(0, 10);
        getJobFunctionData(0, 10)
        getComboDatas();
    }, []); //
    const [msgOpen, setmsgOpen] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState("");
    const [genericDialogOpen, setGenericDialogOpen] = useState(false);

    const [genericSelectPageCount, setgenericSelectPageCount] = useState(0);
    const [isedit, setisedit] = useState(false);
    const [FOPayGradeList, setFOPayGradeList] = useState<FOPayGradeList[]>([]);
    const [jobCodeRequest, setjobCodeRequest] = useState<BudgetJobCodeRequestListDto>();
    const [FOJobFunctionList, setFOJobFunctionList] = useState<FOJobFunctionList[]>([]);
    const [JobFunctionSelectPageCount, setJobFunctionSelectPageCount] = useState(0);
    const [JobFunctionSelectPageTotalCount, setJobFunctionSelectPageTotalCount] = useState(0);
    const [jobFunctionSelectDialogIsOpen, setjobFunctionSelectDialogIsOpen] = useState(false);


    const comboCustPositionLevelName = useRef<string>("");
    const comboRegularTemporaryName = useRef<string>("");
    const comboDefaultEmployeeClassName = useRef<string>("");
    const comboCustEmploymentTypeName = useRef<string>("");
    const comboCustMissionUnitTypeName = useRef<string>("");
    const comboCustHayKademeName = useRef<string>("");
    const comboCustRonesansKademeName = useRef<string>("");
    const comboCustAdinesStatusName = useRef<string>("");
    const txtJobFunction = useRef<SelectDialog | null>(null);
    const txtgrade = useRef<SelectDialog | null>(null);


    const txtJobCode = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtCustomJobCode = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJoblevelgroup = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtBolum = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtMetin = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobRequestReason = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobCodeName = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobCodeNameEnglish = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobCodeNameRussian = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const [genericSelectTotalCount, setgenericSelectTotalCount] = useState(0);
    const itemsPerPage = 10;
    const [startDate, setstartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isFullTime, setisFullTime] = useState(false);
    const [IsRealJob, setIsRealJob] = useState(false);
    const [isSend, setIsSend] = useState(false);


    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchBusy = useBusy()
    const dispatchAlert = useAlert()
    async function getDetail() {

        const configuration = new Configuration({
            basePath: process.env.REACT_APP_BASE_PATH,
            apiKey: process.env.REACT_APP_API_KEY,
            accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        });

        const id = queryParams.get('id');

        if (id) {
            dispatchBusy({ isBusy: true });
            setisedit(true);
            var api = new BudgetJobCodeRequestApi(configuration);
            var result = api.apiBudgetJobCodeRequestIdGet(id!)
                .then((response: AxiosResponse<BudgetJobCodeRequestListDto>) => {

                    setId(response.data.id!);

                    if (response.data.workflowHead?.workFlowStatus == 1 || response.data.workflowHead?.workFlowStatus == 2)
                        setIsSend(true);

                    console.log("response", response)
                    setjobCodeRequest(response.data);
                    txtJobCode.current!.setValue(response.data.jobCode!);
                    txtJobRequestReason.current?.setValue(response.data.requestReason!);
                    txtJobCodeName.current?.setValue(response.data.name!);
                    txtJobCodeNameEnglish.current?.setValue(response.data.name_En_US!);
                    txtJobCodeNameRussian.current?.setValue(response.data.name_Ru_RU!);
                    txtCustomJobCode.current?.setValue(response.data.cust_Jobcode!);
                    txtJoblevelgroup.current?.setValue(response.data.cust_Joblevelgroup!);
                    txtMetin.current?.setValue(response.data.cust_Metin!);
                    txtBolum.current?.setValue(response.data.cust_Bolum!);


                    let gr: SelectDialog = { externalCode: response.data!.grade!, name: response.data!.grade! };
                    let jf: SelectDialog = { externalCode: response.data.jobFunction!, name: response.data!.jobFunction! };

                    txtgrade.current! = gr;
                    txtJobFunction.current! = jf;

                    comboRegularTemporaryName.current = response.data.regularTemporary!;
                    comboCustEmploymentTypeName.current = response.data.cust_EmploymentType!;
                    comboCustAdinesStatusName.current = response.data.cust_AdinesStatus!;
                    comboCustHayKademeName.current = response.data.cust_Haykademe!;
                    comboCustRonesansKademeName.current = response.data.cust_Ronesanskademe!
                    comboCustPositionLevelName.current = response.data.positionLevel!
                    comboDefaultEmployeeClassName.current = response.data.defaultEmployeeClass!
                    comboCustMissionUnitTypeName.current = response.data.cust_GorevBirimTipi!;



                    setisFullTime(response.data.isFullTime!);
                    setIsRealJob(response.data.cust_IsManager!);
                    setstartDate(format(parseISO(response.data.startDate!), 'dd/MM/yyyy'));
                    setEndDate(format(parseISO(response.data.endDate!), 'dd/MM/yyyy'));

                })
                .catch(error => {

                });
            dispatchBusy({ isBusy: false });

        }
    }
    async function getPayGradeData(skip: number = 0, top: number = 10) {


        let api = new SFFOPayGradeApi(configuration);
        var data = await api.apiSFFOPayGradeGet(skip, top);
        setFOPayGradeList(data.data.foPayGradeList!);
        setgenericSelectPageCount(Math.ceil(data.data.count! / itemsPerPage));
        setgenericSelectTotalCount(data.data.count!);
    }

    async function getJobFunctionData(skip: number = 0, top: number = 10) {
        let api = new SFFOJobFunctionApi(configuration);
        var data = await api.apiSFFOJobFunctionGet(skip, top);
        setFOJobFunctionList(data.data.foJobFunctionList!);
        setJobFunctionSelectPageCount(Math.ceil(data.data.count! / itemsPerPage));
        setJobFunctionSelectPageTotalCount(data.data.count!);
    }
    const handlePayGradeInput = (e: any) => {
        setGenericDialogOpen(true);
    };
    const handleJobFunctionCombobox = (e: any) => {
        setjobFunctionSelectDialogIsOpen(true);
    };
    const handleStartDateInput = (e: any) => {
        setstartDate(e.target.value);
    };
    const handleEndDateInput = (e: any) => {
        setEndDate(e.target.value);
    };

    const handleCheckBoxChange = (e: any) => {

        setisFullTime(e.target.checked);
    };
    const handleIsRealCheckBoxChange = (e: any) => {
        setIsRealJob(e.target.checked);
    };
    async function getComboDatas() {

        //     [Description("Regular Temporary")]
        //     RegularTemporary = 1,
        //     [Description("Employee Class")]
        //     EmployeeClass = 2,
        //     [Description("Gorev Seviyesi")]
        //     GorevSeviyesi = 3,
        //     [Description("Adines Status")]
        //     AdinesStatus = 4,
        //     [Description("Employment Type 1")]
        //     EmploymentType1 = 5,
        //     [Description("Type Of Division")]
        //     TypeOfDivision = 6,
        //     [Description("Ronesans Kademesi")]
        //     RonesansKademesi = 7,
        //     [Description("EC Hay Degree")]
        //     EcHayDegree = 8,
        //     [Description("Unknown PickList Name")]
        //     Unknown = 9
    }

    const handleSave = () => {
        onSubmit(); // Örnek parametrelerle fonksiyonu çağır
    };

    function onSubmit(workflowHeadId: string = "", isSend: boolean = false) {
        if (isValidDate(startDate) == false || isValidDate(endDate) == false) {

            dispatchAlert({ message: "Tarih Formatı Yanlış", type: MessageBoxTypes.Error })
            return;
        }

        if (txtJoblevelgroup.current?.current != "" && !isDecimal(txtJoblevelgroup.current?.current!)) {
            dispatchAlert({ message: "JobLevelGroup alanı sayı türünde olmalıdır", type: MessageBoxTypes.Warning })
            return;
        }

        dispatchBusy({ isBusy: true });
        var api = new BudgetJobCodeRequestApi(configuration);
        if (isedit == true) {
            let updateDto = {} as BudgetJobCodeRequestUpdateDto;
            updateDto.id = id;
            updateDto.jobCode = txtJobCode.current?.current!;
            updateDto.cust_Jobcode = txtJobCode.current?.current;
            updateDto.name = txtJobCodeName.current?.current
            updateDto.startDate = parseDateString(startDate).toISOString();
            updateDto.endDate = parseDateString(endDate).toISOString();
            updateDto.isSend = isSend;

            if (workflowHeadId != "")
                updateDto.workflowHeadId = workflowHeadId;

            updateDto.cust_IsManager = IsRealJob;

            updateDto.grade = txtgrade.current?.externalCode!;
            updateDto.cust_Bolum = txtBolum.current?.current
            updateDto.cust_Metin = txtMetin.current?.current
            updateDto.name_En_US = txtJobCodeNameEnglish.current?.current!;
            updateDto.name_Tr_TR = txtJobCodeName.current?.current!
            updateDto.name_Ru_RU = txtJobCodeNameRussian.current?.current!;
            updateDto.description_En_US = "";
            updateDto.description_Ru_RU = "";
            updateDto.description_Tr_TR = txtMetin.current?.current
            updateDto.cust_Joblevelgroup = txtJoblevelgroup.current?.current!;
            updateDto.description_En_Debug = "";
            updateDto.isFullTime = isFullTime;
            updateDto.name_En_Debug = txtJobCodeNameEnglish.current?.current;
            updateDto.name_En = txtJobCodeNameEnglish.current?.current!;
            updateDto.requestReason = txtJobRequestReason.current?.current!;
            updateDto.cust_Haykademe = comboCustHayKademeName.current;
            updateDto.cust_Ronesanskademe = comboCustRonesansKademeName.current;
            updateDto.cust_AdinesStatus = comboCustAdinesStatusName.current;
            updateDto.cust_GorevBirimTipi = comboCustMissionUnitTypeName.current;
            updateDto.cust_EmploymentType = comboCustEmploymentTypeName.current;
            updateDto.defaultEmployeeClass = comboDefaultEmployeeClassName.current;
            updateDto.regularTemporary = comboRegularTemporaryName.current;
            updateDto.positionLevel = comboCustPositionLevelName.current;
            updateDto.jobFunction = txtJobFunction.current?.externalCode!;


            api.apiBudgetJobCodeRequestPut(updateDto)
                .then(response => {
                    if (workflowHeadId != null) {
                        dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    }
                    else {
                        dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                    }
                    navigate("/JobCode")
                    dispatchBusy({ isBusy: false });
                })
                .catch(error => {
                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }
        else {
            let insertDto = {} as BudgetJobCodeRequestInsertDto;
            insertDto.jobCode = txtJobCode.current?.current!;
            insertDto.name = txtJobCodeName.current?.current;
            insertDto.startDate = parseDateString(startDate).toISOString();
            insertDto.endDate = parseDateString(endDate).toISOString();
            insertDto.isSend = false;
            insertDto.name_En = txtJobCodeName.current?.current!
            insertDto.requestReason = txtJobRequestReason.current?.current!
            insertDto.cust_Haykademe = comboCustHayKademeName.current;
            insertDto.cust_Ronesanskademe = comboCustRonesansKademeName.current;
            insertDto.cust_AdinesStatus = comboCustAdinesStatusName.current;
            insertDto.cust_GorevBirimTipi = comboCustMissionUnitTypeName.current;
            insertDto.cust_EmploymentType = comboCustEmploymentTypeName.current;
            insertDto.defaultEmployeeClass = comboDefaultEmployeeClassName.current;
            insertDto.regularTemporary = comboRegularTemporaryName.current;
            insertDto.positionLevel = comboCustPositionLevelName.current;
            insertDto.isFullTime = isFullTime;
            insertDto.grade = txtgrade.current?.externalCode!;
            insertDto.cust_Bolum = txtBolum.current?.current!
            insertDto.cust_Metin = txtMetin.current?.current!
            insertDto.name_En_US = txtJobCodeNameEnglish.current?.current!
            insertDto.name_Tr_TR = txtJobCodeName.current?.current!
            insertDto.name_Ru_RU = txtJobCodeNameRussian.current?.current!;
            insertDto.description_En_US = txtJobCodeNameEnglish.current?.current
            insertDto.description_Ru_RU = "";
            insertDto.description_Tr_TR = txtJobCodeName.current?.current;
            insertDto.cust_Joblevelgroup = txtJoblevelgroup.current?.current!;
            insertDto.description_En_Debug = "";
            insertDto.workflowHeadId = null;
            insertDto.name_En_Debug = txtJobCodeNameEnglish.current?.current
            insertDto.name_En = txtJobCodeNameEnglish.current?.current!
            insertDto.requestReason = txtJobRequestReason.current?.current!
            insertDto.cust_Haykademe = comboCustHayKademeName.current;
            insertDto.cust_Ronesanskademe = comboCustRonesansKademeName.current;
            insertDto.cust_AdinesStatus = comboCustAdinesStatusName.current;
            insertDto.cust_GorevBirimTipi = comboCustMissionUnitTypeName.current;
            insertDto.cust_EmploymentType = comboCustEmploymentTypeName.current;
            insertDto.defaultEmployeeClass = comboDefaultEmployeeClassName.current;
            insertDto.regularTemporary = comboRegularTemporaryName.current;
            insertDto.positionLevel = comboCustPositionLevelName.current;
            insertDto.jobFunction = txtJobFunction.current?.externalCode!;
            insertDto.workflowHeadId = null;
            insertDto.cust_Jobcode = txtCustomJobCode.current?.current!
            insertDto.cust_IsManager = IsRealJob;





            api.apiBudgetJobCodeRequestPost(insertDto)
                .then(response => {
                    dispatchBusy({ isBusy: false });
                    dispatchAlert({ message: "Kayıt Ekleme Başarılı", type: MessageBoxTypes.Success })
                    navigate("/JobCode")

                })
                .catch(error => {

                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }
        // if (e != null)
        //     e.preventDefault();
    }
    const handleApprovalClick = async () => {


        dispatchBusy({ isBusy: true });
        if (id) {
            var workFlowApi = new WorkFlowApi(configuration);
            let startDto: WorkFlowStartApiDto = {};
            startDto.definationId = "521d0cf8-c5a4-42ff-a031-aa61ab319e4a";
            startDto.userName = username;
            startDto.workFlowInfo = "Yeni Pozisyon:" + txtJobCode.current?.current!;
            var result = await workFlowApi.apiWorkFlowStartPost(startDto)
                .then(response => {
                    onSubmit(response.data.id!, true);
                    dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    dispatchBusy({ isBusy: false });
                })
        }
    };

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Pozisyon':
                navigate("/JobCode")
                break;
            case "Pozisyon Talep Detayı":
                navigate("/PositionDetail")
                break;
            default:
                break;
        }

    }

    async function handleMsgDialog(event: any) {
        setmsgOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            navigate("/JobCode")
        }
        else {
            return;
        }

    }

    return (
        <DashboardLayout>
            <ObjectPage
                // footer={<Bar design="FloatingFooter" endContent={<><Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button></>} />}
                headerContentPinnable
                headerTitle={<DynamicPageTitle actions={<>
                    <Button icon='decline' onClick={() => setmsgOpen(true)} disabled={isSend} design="Emphasized">Vazgeç</Button>
                    <Button icon='save' onClick={handleSave} disabled={isSend} design="Emphasized">Kaydet</Button>
                    <Button disabled={!isedit || isSend} icon="workflow-tasks" onClick={handleApprovalClick}>Onaya Gönder</Button>
                </>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem>Pozisyon</BreadcrumbsItem>
                            <BreadcrumbsItem>Pozisyon Talep Detayı</BreadcrumbsItem>
                        </Breadcrumbs>}
                    header={<Title>Pozisyon Oluştur</Title>} showSubHeaderRight subHeader={txtJobCodeNameEnglish.current?.current}>
                    <ObjectStatus state={isSend ? 'Success' : 'Warning'}>
                        {getStatusText(jobCodeRequest?.workflowHead?.workFlowStatus!)}
                    </ObjectStatus></DynamicPageTitle>}
                imageShapeCircle
                onBeforeNavigate={function _a() { }}
                onPinnedStateChange={function _a() { }}
                onSelectedSectionChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                selectedSectionId="general"
                showHideHeaderButton
            >
                <ObjectPageSection
                    aria-label="Pozisyon Genel Bilgileri"
                    id="general"
                    titleText="POZİSYON GENEL BİLGİLERİ"
                >
                    {/* <Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button> */}
                    <Form
                        columnsL={2}
                        columnsXL={2}
                        style={{
                            alignItems: 'start'
                        }}
                    >
                        <FormGroup>
                            <FormItem label={<Label style={{ width: "150px" }} required>İş Kodu</Label>}>
                                <div className={isedit ? 'disabled' : ''}>
                                    <CustomInputComponent ref={txtJobCode} ></CustomInputComponent>
                                </div>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>Adı</Label>}>
                                <CustomInputComponent ref={txtJobCodeName} ></CustomInputComponent>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>İngilizce Adı</Label>}>
                                <CustomInputComponent ref={txtJobCodeNameEnglish} ></CustomInputComponent>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>Rusça Adı</Label>}>
                                <CustomInputComponent ref={txtJobCodeNameRussian} ></CustomInputComponent>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>Talep Nedeni</Label>}>
                                <CustomInputComponent ref={txtJobRequestReason} ></CustomInputComponent>
                            </FormItem>

                            <FormItem label={<Label required>Başlangıç Tarihi</Label>}>
                                <DatePicker
                                    required
                                    onChange={handleStartDateInput}
                                    onValueStateChange={function _a() { }}
                                    primaryCalendarType="Gregorian"
                                    formatPattern='dd/MM/yyyy'
                                    value={startDate.toString()}
                                    valueState="None"
                                />
                            </FormItem>
                            <FormItem label={<Label required>Bitiş Tarihi</Label>}>
                                <DatePicker
                                    required
                                    onChange={handleEndDateInput}
                                    value={endDate.toString()}

                                    formatPattern='dd/MM/yyyy'
                                    primaryCalendarType="Gregorian"
                                    valueState="None"
                                />
                            </FormItem>

                            <FormItem label={<Label style={{ width: "150px" }} >Tam Zamanlı</Label>}>
                                <CheckBox checked={isFullTime} onChange={handleCheckBoxChange} text='' ></CheckBox>
                            </FormItem>

                            <FormItem label={<Label style={{ width: "150px" }}>Düzenli Geçici</Label>}>
                                <PickListComboBoxComponent pickListId={1} value={comboRegularTemporaryName} ></PickListComboBoxComponent>
                            </FormItem>

                        </FormGroup>
                    </Form>

                </ObjectPageSection>
                <ObjectPageSection
                    id="salary"
                    titleText="DİĞER BİLGİLER"
                >
                    <Form
                            columnsL={2}
                            columnsXL={2}
                            style={{
                                alignItems: 'start'
                            }}
                        >
                            <FormGroup titleText="">


                                <FormItem label={<Label style={{ width: "150px" }} required>Varsayılan Çalışan Grubu</Label>}>
                                    <PickListComboBoxComponent pickListId={2} value={comboDefaultEmployeeClassName} ></PickListComboBoxComponent>
                                </FormItem>

                                <FormItem label={<Label style={{ width: "150px" }} required>İş İşlevi</Label>}>
                                    <GenericSelectDialogWithInputEnum dataId={3} displayValue={jobCodeRequest?.jobFunction!} value={txtJobFunction} headerText={"3"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                                </FormItem>

                                <FormItem label="Metin">
                                    <CustomInputComponent ref={txtMetin} ></CustomInputComponent>
                                </FormItem>

                                <FormItem label={<Label style={{ width: "150px" }} required>Custom Job Code</Label>}>
                                    <CustomInputComponent ref={txtCustomJobCode} ></CustomInputComponent>
                                </FormItem>

                                <FormItem label={<Label style={{ width: "150px" }} required>İstihdam Tipi</Label>}>
                                    <PickListComboBoxComponent pickListId={5} value={comboCustEmploymentTypeName} ></PickListComboBoxComponent>
                                </FormItem>
                                <FormItem label={<Label style={{ width: "150px" }} required>Görev Birimi</Label>}>
                                    <PickListComboBoxComponent pickListId={6} value={comboCustMissionUnitTypeName} ></PickListComboBoxComponent>
                                </FormItem>
                                <FormItem label={<Label style={{ width: "150px" }} >Reel Görev mi?</Label>}>
                                    <CheckBox checked={IsRealJob} onChange={handleIsRealCheckBoxChange} text='' ></CheckBox>
                                </FormItem>
                                <FormItem label={<Label style={{ width: "150px" }} required>Bölüm</Label>}>
                                    <CustomInputComponent ref={txtBolum} ></CustomInputComponent>
                                </FormItem>
                                <FormItem label={<Label style={{ width: "150px" }} required>Hay Kademe</Label>}>
                                    <PickListComboBoxComponent pickListId={8} value={comboCustHayKademeName} ></PickListComboBoxComponent>
                                </FormItem>
                                <FormItem label={<Label style={{ width: "150px" }} required>JobLevelGroup</Label>}>
                                    <CustomInputComponent ref={txtJoblevelgroup} ></CustomInputComponent>
                                </FormItem>



                            </FormGroup>

                        </Form>

                </ObjectPageSection>
                <ObjectPageSection

                    aria-label="Ücret"
                    id="personal-connect-2"
                    titleText="ÜCRET"
                >
                    <Form
                        columnsL={2}
                        columnsXL={2}
                        style={{
                            alignItems: 'start'
                        }}
                    >
                        <FormGroup titleText="">

                            <FormItem label={<Label style={{ width: "150px" }} required>Ücret Derecesi</Label>}>
                                {/* <Input placeholder={txtgrade.current?.name} onClick={handlePayGradeInput} readonly value={txtgrade.current?.name} icon={<Icon name="search" onClick={handlePayGradeInput} />} required={true} type="Text" /> */}
                                <GenericSelectDialogWithInputEnum displayValue={jobCodeRequest?.grade!} dataId={16} value={txtgrade} headerText={"16"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                            </FormItem>
                            <FormItem label={<Label style={{ width: "150px" }} required>Görev Seviyesi</Label>}>
                                <PickListComboBoxComponent pickListId={3} value={comboCustPositionLevelName} ></PickListComboBoxComponent>
                            </FormItem>

                            <FormItem label={<Label style={{ width: "150px" }} required>Gelir Seviyesi- ronesanskademe</Label>}>
                                <PickListComboBoxComponent pickListId={7} value={comboCustRonesansKademeName} ></PickListComboBoxComponent>
                            </FormItem>

                        </FormGroup>
                    </Form>
                </ObjectPageSection>


                <ObjectPageSection
                    aria-label=""
                    id="adines"
                    titleText="ADİNES"
                >
                    <Form
                        columnsL={2}
                        columnsXL={2}
                        style={{
                            alignItems: 'start'
                        }}
                    >
                        <FormGroup titleText="">

                            <FormItem label="Adines Status">
                                <PickListComboBoxComponent pickListId={4} value={comboCustAdinesStatusName} ></PickListComboBoxComponent>
                            </FormItem>

                        </FormGroup>
                    </Form>
                </ObjectPageSection>
            </ObjectPage>

            <footer>
                <Toolbar >
                    <ToolbarSpacer />
                    <Label children="Oluşturan Kişi:"/>
                    <Label children={jobCodeRequest?.createdBy ? jobCodeRequest?.createdBy : ""}/>
                    <Label children="Oluşturulma Tarihi:"/>
                    <Label children={jobCodeRequest?.createdDate ? new Date(jobCodeRequest.createdDate).toLocaleDateString('tr-TR') : ""}/>
                    <Label children="Güncelleyen Kişi:" />
                    <Label children={jobCodeRequest?.updatedBy ? jobCodeRequest?.updatedBy : ""} />
                    <Label children="Güncellenme Tarihi:" />
                    <Label children={jobCodeRequest?.updatedDate ? new Date(jobCodeRequest.updatedDate).toLocaleDateString('tr-TR') : ""} />
                </Toolbar>

            </footer>

            <GenericSelectDialog value={txtgrade} datacount={genericSelectTotalCount} method={getPayGradeData} pageCount={genericSelectPageCount} headerText={"Ücret Derecesi Seçiniz"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => {
                setGenericDialogOpen(false);
            }} list={FOPayGradeList}   ></GenericSelectDialog>
            <GenericSelectDialog value={txtJobFunction} datacount={JobFunctionSelectPageTotalCount} method={getJobFunctionData} pageCount={JobFunctionSelectPageCount} headerText={"İş İşlevi Seçiniz"} open={jobFunctionSelectDialogIsOpen} onClose={() => setjobFunctionSelectDialogIsOpen(false)} onConfirm={(e) => {

                setjobFunctionSelectDialogIsOpen(false);

            }}
                list={FOJobFunctionList}   ></GenericSelectDialog>

            <MessageBox
                open={msgOpen}
                onClose={handleMsgDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Verileriniz kaydedilmeyecektir, devam edilsin mi?
            </MessageBox>

        </DashboardLayout>

    );
}
export default EmployeDetail;
