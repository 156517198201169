import React, { ReactNode } from 'react';
import ShellBarComponent from '../Components/ShellBar';
import SideNavigationComponent from '../Components/SideNavigation';
import { BusyIndicator } from '@ui5/webcomponents-react';

function DashboardLayout({ children }: { children: ReactNode }): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <ShellBarComponent />
      <div style={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        <SideNavigationComponent />
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

          {children}
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
