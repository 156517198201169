import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../App.css';


import "@ui5/webcomponents-base/dist/features/F6Navigation";
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/DatePicker";
import "@ui5/webcomponents/dist/List";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/CustomListItem";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Dialog";
import "@ui5/webcomponents/dist/Popover";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/TextArea";
import "@ui5/webcomponents/dist/Switch";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/palette.js";
import "@ui5/webcomponents-icons/dist/settings.js";
import "@ui5/webcomponents-icons/dist/sys-help.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/account.js";
import "@ui5/webcomponents-icons/dist/private.js";
import "@ui5/webcomponents-icons/dist/loan.js";
import "@ui5/webcomponents-icons/dist/globe.js";
import "@ui5/webcomponents-icons/dist/workflow-tasks";
import "@ui5/webcomponents-icons/dist/chain-link";
import { AnalyticalTable, Avatar, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, TextAlign, TextArea, Title, Toolbar, ToolbarSpacer } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPromotionRequestApi, BudgetPromotionRequestInsertDto, BudgetPromotionRequestListDto, BudgetPromotionRequestUpdateDto, Configuration, CustCompanyGroupList, CustLegalEntityList, CustSubDivisioList, FOBusinessUnitList, FOCompanyDtoList, FODepartmentList, FODivisionList, FOGeozoneDtoList, FOJobCodeList, FOJobFunctionList, FOJobFunctionSFDto, FOLocationGroupList, FOLocationList, FOPayGradeList, FOPayGradeSFDto, FOPayGroupSFList, PickListApi, PickListDto, PositionSFDto, SFCustCompanyGroupApi, SFCustLegalEntityApi, SFcustSubDivisionApi, SFFOBusinessUnitApi, SFFOCompanyApi, SFFOCostCenterApi, SFFOCostCenterList, SFFODepartmentApi, SFFODivisionApi, SFFOFOPayGroupApi, SFFOGeozoneApi, SFFOJobCodeApi, SFFOJobFunctionApi, SFFOLocationApi, SFFOLocationGroupApi, SFFOPayGradeApi, SFPositionList, SFPositionsApi, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';

import { number } from 'yup';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useBusy } from '../hooks/useBusy';
import { getStatusText, isDecimal, isValidDate, parseDateString } from '../utils/utils';
import { useAlert } from '../hooks/useAlert';
import PickListComboBoxComponent from '../Components/PickListComponent';
import CustomInputComponent from '../Components/CustomInput';
import { isReadable } from 'stream';
import { useUser } from '../hooks/userName';
import GenericSelectDialogWithInputEnum, { SelectDialog } from '../Components/GenericSelectDialogWithInputEnum';
import UserSelectDialog from '../Components/UserSelectDialog';
// setTheme("sap_horizon");
// setTheme("sap_fiori_3");
// setTheme("sap_belize");

interface TransformedDataItem {
    blmPrjIsl: string;
    [key: string]: number | string;  // Allow additional properties with date keys
}
function ReportList() {
    const { username, setUsername } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {

    }, []); //

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchBusy = useBusy()
    const dispatchAlert = useAlert()

    const ozetKadro = {
        "JsonData": [
            {
                "bolum": "MEKANİK",
                "blmPrjIsl": "",
                "mevcutCalisan": 1,
                "normKadro": 0,
                "toplam": 1
            },
            {
                "bolum": "MEKANİK",
                "blmPrjIsl": "TEKLİF",
                "mevcutCalisan": 1,
                "normKadro": 0,
                "toplam": 1
            },
            {
                "bolum": "MEKANİK",
                "blmPrjIsl": "REC Havacilik",
                "mevcutCalisan": 2,
                "normKadro": 0,
                "toplam": 2
            },
            {
                "bolum": "REC Havacilik",
                "blmPrjIsl": "TEKLİF",
                "mevcutCalisan": 0,
                "normKadro": 1,
                "toplam": 1
            },
            {
                "bolum": "REC Havacilik",
                "blmPrjIsl": "",
                "mevcutCalisan": 0,
                "normKadro": 1,
                "toplam": 1
            },
            {
                "bolum": "TEKLİF",
                "blmPrjIsl": "",
                "mevcutCalisan": 1,
                "normKadro": 0,
                "toplam": 1
            },
            {
                "bolum": "TEKLİF",
                "blmPrjIsl": "MEKANİK",
                "mevcutCalisan": 1,
                "normKadro": 0,
                "toplam": 1
            },
            {
                "bolum": "TEKLİF",
                "blmPrjIsl": "REC Havacilik",
                "mevcutCalisan": 1,
                "normKadro": 0,
                "toplam": 1
            }
        ]
    }

    const adamAyListesi = {
        "Jsondata": [
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "REC Havacilik",
                "akisiSayisi": 2
            },
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "TEKLİF",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "REC Havacilik",
                "akisiSayisi": 2
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "TEKLİF",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "REC Havacilik",
                "akisiSayisi": 2
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "TEKLİF",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "REC Havacılık",
                "akisiSayisi": 2
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "TEKLİF",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "REC Havacılık",
                "akisiSayisi": 2
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "TEKLİF",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-06-30",
                "blmPrjIsl": "MEKANİK",
                "akisiSayisi": 1
            },
            {
                "datum": "2024-06-30",
                "blmPrjIsl": "REC Havacılık",
                "akisiSayisi": 2
            }
        ]
    }

    const aylikMaaliyet = {
        "Jsondata": [
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "MEKANİK",
                "amaliyet": 0
            },
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "REC Havacilik",
                "amaliyet": 7500.80
            },
            {
                "datum": "2024-01-31",
                "blmPrjIsl": "TEKLİF",
                "amaliyet": 0
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "MEKANİK",
                "amaliyet": 0
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "REC Havacilik",
                "amaliyet": 7500.80
            },
            {
                "datum": "2024-02-29",
                "blmPrjIsl": "TEKLİF",
                "amaliyet": 52261.78
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "MEKANİK",
                "amaliyet": 0
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "REC Havacilik",
                "amaliyet": 7500.80
            },
            {
                "datum": "2024-03-31",
                "blmPrjIsl": "TEKLİF",
                "amaliyet": 52261.78
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "MEKANİK",
                "amaliyet": 0
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "REC Havacilik",
                "amaliyet": 7500.80
            },
            {
                "datum": "2024-04-30",
                "blmPrjIsl": "TEKLİF",
                "amaliyet": 52560.54
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "MEKANİK",
                "amaliyet": 0
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "REC Havacilik",
                "amaliyet": 7500.80
            },
            {
                "datum": "2024-05-31",
                "blmPrjIsl": "TEKLİF",
                "amaliyet": 0
            }
        ]
    }

    const toplamMaaliyet = {
        "Jsondata": [
            {
                "bolum": "MEKANİK",
                "mcim": 788358.22,
                "yktim": 0,
                "tim": 788358.22
            },
            {
                "bolum": "REC Havacilik",
                "mcim": 0,
                "yktim": 309101.21,
                "tim": 309101.21
            },
            {
                "bolum": "TEKLİF",
                "mcim": 1493267.75,
                "yktim": 0,
                "tim": 1493267.75
            }
        ]
    }

    //#region Aylik Maaliyet
    //tarihleri al
    const aylikMaliyetDates = aylikMaaliyet.Jsondata.map(item => item.datum).filter((date, index, self) => self.indexOf(date) === index);

    //sutunlari olustur
    const aylikMaliyetcolumns = useMemo(() => {
        const baseColumns = [
            {
                Header: 'Bölüm/Proje/İşletme',
                accessor: 'blmPrjIsl',
                autoResizable: true,
                headerTooltip: 'Bölüm/Proje/İşletme',
                width: 150
            }
        ];

        const aylikMaliyetTarihColumns = aylikMaliyetDates.map(date => ({
            Header: date,
            accessor: date,
            autoResizable: true,
            width: 150,
            hAlign: TextAlign.Center,
        }));

        return [...baseColumns, ...aylikMaliyetTarihColumns];
    }, [aylikMaliyetDates]);

    //tablo veri yapisini olustur
    const aylikmaliyetData = useMemo(() => {
        const groupedData: Record<string, TransformedDataItem> = {};

        aylikMaaliyet.Jsondata.forEach(({ blmPrjIsl, datum, amaliyet }) => {
            if (!groupedData[blmPrjIsl]) {
                groupedData[blmPrjIsl] = { blmPrjIsl };
            }
            groupedData[blmPrjIsl][datum] = amaliyet;
        });

        return Object.values(groupedData);
    }, []);
    //#endregion

    //#region Adam/Ay listesi 
    //tarihleri al
    const adamAyDates = adamAyListesi.Jsondata.map(item => item.datum).filter((date, index, self) => self.indexOf(date) === index);

    //sutunlari olustur
    const adamAycolumns = useMemo(() => {
        const baseColumns = [
            {
                Header: 'Bölüm/Proje/İşletme',
                accessor: 'blmPrjIsl',
                autoResizable: true,
                headerTooltip: 'Bölüm/Proje/İşletme',
                width: 150
            }
        ];

        const adamAyTarihColumns = adamAyDates.map(date => ({
            Header: date,
            accessor: date,
            autoResizable: true,
            width: 150,
            hAlign: TextAlign.Center,
        }));

        return [...baseColumns, ...adamAyTarihColumns];
    }, [adamAyDates]);

    //tablo veri yapisini olustur
    const adamAyData = useMemo(() => {
        const groupedData: Record<string, TransformedDataItem> = {};

        adamAyListesi.Jsondata.forEach(({ blmPrjIsl, datum, akisiSayisi }) => {
            if (!groupedData[blmPrjIsl]) {
                groupedData[blmPrjIsl] = { blmPrjIsl };
            }
            groupedData[blmPrjIsl][datum] = akisiSayisi;
        });

        return Object.values(groupedData);
    }, []);
    //#endregion


    return (
        <DashboardLayout>
            <ObjectPage
                // footer={<Bar design="FloatingFooter" endContent={<><Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button></>} />}
                headerContentPinnable
                headerTitle={<DynamicPageTitle header={<Title>Raporlar</Title>} />}
                imageShapeCircle
                onBeforeNavigate={function _a() { }}
                onPinnedStateChange={function _a() { }}
                onSelectedSectionChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                selectedSectionId="general"
                showHideHeaderButton
            >
                <ObjectPageSection
                    aria-label="ÖZET KADRO"
                    id="tbl1"
                    titleText="ÖZET KADRO"
                >
                    <AnalyticalTable
                        columns={[
                            {
                                Header: 'Bölüm',
                                accessor: 'bolum',
                                autoResizable: true,
                                headerTooltip: 'Bölüm',
                                width: 130,
                            },
                            {
                                Header: 'Bölüm/Proje/İşletme',
                                accessor: 'blmPrjIsl',
                                autoResizable: true,
                                headerTooltip: 'Bölüm/Proje/İşletme',
                                width: 150
                            },
                            {
                                Header: 'Mevcut Çalışan',
                                accessor: 'mevcutCalisan',
                                autoResizable: true,
                                headerTooltip: 'Durum',
                                width: 150,
                                hAlign: TextAlign.Center,
                            },
                            {
                                Header: 'Norm Kadro',
                                accessor: 'normKadro',
                                autoResizable: true,
                                headerTooltip: 'Norm Kadro',
                                width: 150,
                                hAlign: TextAlign.Center,
                            },
                            {
                                Header: 'Toplam',
                                accessor: 'toplam',
                                autoResizable: true,
                                headerTooltip: 'Toplam',
                                width: 170,
                                hAlign: TextAlign.Center,
                            },
                        ]}

                        data={ozetKadro.JsonData}
                        filterable
                        groupBy={[]}
                        groupable
                        infiniteScroll
                        visibleRows={ozetKadro.JsonData.length}
                        selectionMode="None"
                        tableHooks={[]}
                        withRowHighlight={false}
                    />

                </ObjectPageSection>

                <ObjectPageSection
                    aria-label="ADAM/AY LİSTESİ"
                    id="tbl2"
                    titleText="ADAM/AY LİSTESİ"
                >
                    <AnalyticalTable
                       columns={adamAycolumns}
                       data={adamAyData}
                       filterable
                       groupBy={[]}
                       groupable
                       infiniteScroll
                       visibleRows={adamAyData.length}
                       selectionMode="None"
                       tableHooks={[]}
                       withRowHighlight={false}
                    />

                </ObjectPageSection>

                <ObjectPageSection
                    aria-label="TOPLAM MAALİYET"
                    id="tbl3"
                    titleText="TOPLAM MAALİYET"
                >
                    <AnalyticalTable
                        columns={[
                            {
                                Header: 'Bölüm',
                                accessor: 'bolum',
                                autoResizable: true,
                                headerTooltip: 'Bölüm',
                                width: 130
                            },
                            {
                                Header: 'Mevcut Çalışanın İşveren Maliyeti',
                                accessor: 'mcim',
                                autoResizable: true,
                                headerTooltip: 'Mevcut Çalışanın İşveren Maliyeti',
                                width: 250
                            },
                            {
                                Header: 'Yeni Kadro Talebi İşveren Maliyeti',
                                accessor: 'yktim',
                                autoResizable: true,
                                headerTooltip: 'Yeni Kadro Talebi İşveren Maliyeti',
                                width: 260,
                            },
                            {
                                Header: 'Toplam İşveren Maliyeti',
                                accessor: 'tim',
                                autoResizable: true,
                                headerTooltip: 'Toplam İşveren Maliyeti',
                                width: 170,
                            },
                        ]}

                        data={toplamMaaliyet.Jsondata}
                        filterable
                        groupBy={[]}
                        groupable
                        infiniteScroll
                        visibleRows={toplamMaaliyet.Jsondata.length}
                        selectionMode="None"
                        tableHooks={[]}
                        withRowHighlight={false}
                    />

                </ObjectPageSection>

                <ObjectPageSection
                    aria-label="AYLIK MAALİYET"
                    id="tbl4"
                    titleText="AYLIK MAALİYET"
                >
                    <AnalyticalTable
                        columns={aylikMaliyetcolumns}

                        data={aylikmaliyetData}
                        filterable
                        groupBy={[]}
                        groupable
                        infiniteScroll
                        visibleRows={aylikmaliyetData.length}
                        selectionMode="None"
                        tableHooks={[]}
                        withRowHighlight={false}
                    />

                </ObjectPageSection>


            </ObjectPage>

        </DashboardLayout>

    );
}
export default ReportList;
