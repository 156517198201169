import React, { useEffect, useRef, useState } from 'react';
import '../App.css';


import "@ui5/webcomponents-base/dist/features/F6Navigation";
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/DatePicker";
import "@ui5/webcomponents/dist/List";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/CustomListItem";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Dialog";
import "@ui5/webcomponents/dist/Popover";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/TextArea";
import "@ui5/webcomponents/dist/Switch";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/palette.js";
import "@ui5/webcomponents-icons/dist/settings.js";
import "@ui5/webcomponents-icons/dist/sys-help.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/account.js";
import "@ui5/webcomponents-icons/dist/private.js";
import "@ui5/webcomponents-icons/dist/loan.js";
import "@ui5/webcomponents-icons/dist/globe.js";
import "@ui5/webcomponents-icons/dist/workflow-tasks";
import "@ui5/webcomponents-icons/dist/chain-link";
import { Avatar, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, MultiComboBox, MultiComboBoxItem, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, TextArea, Title, Toolbar, ToolbarSpacer } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../Components/DashboardLayout';
import { AdminLevel, BudgetAdminUserApi, BudgetAdminUserInsertDto, BudgetAdminUserListDto, BudgetAdminUserUpdateDto, BudgetJobCodeRequestApi, BudgetJobCodeRequestInsertDto, BudgetJobCodeRequestListDto, BudgetJobCodeRequestUpdateDto, BudgetNormCodeRequestApi, BudgetNormCodeRequestInsertDto, BudgetNormCodeRequestListDto, BudgetNormCodeRequestUpdateDto, BudgetPeriodApi, BudgetPeriodListDto, Configuration, CustCompanyGroupList, CustLegalEntityList, CustSubDivisioList, FOBusinessUnitList, FOCompanyDtoList, FODepartmentList, FODivisionList, FOGeozoneDtoList, FOJobCodeList, FOJobFunctionList, FOJobFunctionSFDto, FOLocationGroupList, FOLocationList, FOPayGradeList, FOPayGradeSFDto, FOPayGroupSFList, PickListApi, PickListDto, PositionSFDto, SFCustCompanyGroupApi, SFCustLegalEntityApi, SFcustSubDivisionApi, SFFOBusinessUnitApi, SFFOCompanyApi, SFFOCostCenterApi, SFFOCostCenterList, SFFODepartmentApi, SFFODivisionApi, SFFOFOPayGroupApi, SFFOGeozoneApi, SFFOJobCodeApi, SFFOJobFunctionApi, SFFOLocationApi, SFFOLocationGroupApi, SFFOPayGradeApi, SFPositionList, SFPositionsApi, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';

import { number } from 'yup';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useBusy } from '../hooks/useBusy';
import { getStatusText, isDecimal, isValidDate, parseDateString } from '../utils/utils';
import { useAlert } from '../hooks/useAlert';
import PickListComboBoxComponent from '../Components/PickListComponent';
import CustomInputComponent from '../Components/CustomInput';
import { isReadable } from 'stream';
import { useUser } from '../hooks/userName';
import GenericSelectDialogWithInputEnum, { SelectDialog } from '../Components/GenericSelectDialogWithInputEnum';
import UserSelectDialog from '../Components/UserSelectDialog';
import { isProxy } from 'util/types';
// setTheme("sap_horizon");
// setTheme("sap_fiori_3");
// setTheme("sap_belize");
function AdminUserCreate() {
    const { username, setUsername } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        getDetail();

    }, []); //
    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState("");
    const [UserDialogVisible, setUserDialogVisible] = useState(false);
    const [isedit, setisedit] = useState(false);

    const [detail, setDetail] = useState<BudgetAdminUserListDto>();
    const [isSend, setIsSend] = useState(false);
    const [empName, setempName] = useState("");
    const [empCode, setempCode] = useState("");
    const txtmail = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const [isAdmin, setisAdmin] = useState(false);
    const [isDoProxy, setisDoProxy] = useState(false);
    const txtproxyUser = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const [comboAdminLevel, setcomboAdminLevel] = useState("");
    const [comboAdminLevelId, setcomboAdminLevelId] = useState("");

    const [msgOpen, setmsgOpen] = useState(false);

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchBusy = useBusy()
    const dispatchAlert = useAlert()
    async function getDetail() {

        const configuration = new Configuration({
            basePath: process.env.REACT_APP_BASE_PATH,
            apiKey: process.env.REACT_APP_API_KEY,
            accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        });

        const id = queryParams.get('id');

        if (id) {
            dispatchBusy({ isBusy: true });
            setisedit(true);
            var api = new BudgetAdminUserApi(configuration);
            var result = api.apiBudgetAdminUserIdGet(id!)
                .then(async (response: AxiosResponse<BudgetAdminUserListDto>) => {

                    setId(response.data.id!);

                    // if (response.data.workflowHead?.workFlowStatus == 1 || response.data.workflowHead?.workFlowStatus == 2)
                    //     setIsSend(true);


                    setDetail(response.data);
                    console.log("response>>", response.data);

                    txtmail.current!.setValue(response.data.mail!);
                    setisAdmin(response.data.isAdmin!);
                    setisDoProxy(response.data.isDoProxy!);
                    txtproxyUser.current!.setValue(response.data.proxyUser!);
                    setcomboAdminLevel(response.data.adminLevel!.toString())
                    // setcomboAdminLevelId(response.data.requestType!.toString());
                    setcomboAdminLevel(response.data.adminLevelText!)
                    setcomboAdminLevelId(response.data.adminLevel!.toString());


                    var api = new SFUserApi(configuration);
                    var data = await api.apiSFUserUserUseridGet(response.data.userName!)
                    var username = data?.data?.sfuSerList?.[0]?.defaultFullName ?? "";
                    setempName(username);
                    setempCode(response.data.userName!);

                })
                .catch(error => {

                });
            dispatchBusy({ isBusy: false });
        }
    }
    //#endregion


    const handleSave = () => {
        onSubmit(); // Örnek parametrelerle fonksiyonu çağır
    };

    function onSubmit(workflowHeadId: string = "", isSend: boolean = false) {
        dispatchBusy({ isBusy: true });
        let api = new BudgetAdminUserApi(configuration);
        if (isedit == false) {
            let insertDto: BudgetAdminUserInsertDto = {};
            insertDto.userName = empCode;
            insertDto.mail = txtmail.current?.current;;
            insertDto.isAdmin = isAdmin!;
            insertDto.isDoProxy = isDoProxy!;
            insertDto.proxyUser = txtproxyUser.current?.current;
            insertDto.adminLevel = Number.parseInt(comboAdminLevelId) as AdminLevel;

            console.log("insertdto", insertDto)

            api.apiBudgetAdminUserPost(insertDto)
                .then(response => {
                    dispatchBusy({ isBusy: false });
                    dispatchAlert({ message: "Kayıt Ekleme Başarılı", type: MessageBoxTypes.Success })
                    navigate("/AdminUserList")

                })
                .catch(error => {

                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }
        else {
            let updateDto: BudgetAdminUserUpdateDto = {};
            updateDto.id = id;

            updateDto.userName = empCode;
            updateDto.mail = txtmail.current?.current;;
            updateDto.isAdmin = isAdmin!;
            updateDto.isDoProxy = isDoProxy!;
            updateDto.proxyUser = txtproxyUser.current?.current;
            updateDto.adminLevel = Number.parseInt(comboAdminLevelId) as AdminLevel;

            // if (workflowHeadId != "")
            //     updateDto.workflowHeadId = workflowHeadId;

            console.log(updateDto)
            api.apiBudgetAdminUserPut(updateDto)
                .then(response => {
                    if (workflowHeadId != null) {
                        dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    }
                    else {
                        dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                    }
                    navigate("/AdminUserList")
                    dispatchBusy({ isBusy: false });
                })
                .catch(error => {
                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }

        dispatchBusy({ isBusy: true });
    }


    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Admin Kullanıcılar':
                navigate("/AdminUserList")
                break;
            case "Admin Kullanıcı Oluştur":
                navigate("/AdminMailCreate")
                break;
            default:
                break;
        }

    }


    const handleUserInput = async () => {
        setUserDialogVisible(true);
    }
    const onUserSelected = async (event: any) => {
        setUserDialogVisible(false);
        setempName(event.defaultFullName);
        setempCode(event.userId);
    }

    const handlecomboAdminLevel = (event: any) => {
        const selectedItem = event.detail.item;
        setcomboAdminLevel(selectedItem.text);
        setcomboAdminLevelId(selectedItem.getAttribute('data-id'));
    };
    const handleIsAdminCheckBoxChange = (e: any) => {
        setisAdmin(e.target.checked);
    };
    const handleIsProxyCheckBoxChange = (e: any) => {
        setisDoProxy(e.target.checked);
        txtproxyUser.current?.setValue("");
        console.log(txtproxyUser)
    };

    async function handleMsgDialog(event: any) {
        setmsgOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            navigate("/AdminUserList")
        }
        else {
            return;
        }

    }

    return (
        <DashboardLayout>
            <ObjectPage
                // footer={<Bar design="FloatingFooter" endContent={<><Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button></>} />}
                headerContentPinnable
                headerTitle={<DynamicPageTitle actions={<>
                    <Button icon='decline' onClick={() => setmsgOpen(true)} disabled={isSend} design="Emphasized">Vazgeç</Button>
                    <Button icon='save' onClick={handleSave} disabled={isSend} design="Emphasized">Kaydet</Button>
                </>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem onClick={() => navigate("/JobCode")}>Admin Kullanıcılar</BreadcrumbsItem>
                            <BreadcrumbsItem>Admin Kullanıcı Oluştur</BreadcrumbsItem>
                        </Breadcrumbs>}
                    header={<Title>Admin Kullanıcı Oluştur</Title>}
                    showSubHeaderRight>
                    <ObjectStatus state={isSend ? 'Success' : 'Warning'}>
                        {/* {getStatusText(detail?.workflowHead?.workFlowStatus!)} */}
                    </ObjectStatus></DynamicPageTitle>}
                imageShapeCircle
                onBeforeNavigate={function _a() { }}
                onPinnedStateChange={function _a() { }}
                onSelectedSectionChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                selectedSectionId="general"
                showHideHeaderButton
            >
                <ObjectPageSection
                    aria-label="Kullanıcı Bilgileri"
                    id="general"
                    titleText="Kullanıcı Bilgileri"
                >
                    {/* <Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button> */}
                    <Form
                        onSubmit={() => alert("")}
                        backgroundDesign={FormBackgroundDesign.Transparent}
                        columnsL={2}
                        columnsM={2}
                        columnsS={2}
                        columnsXL={12}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={12}
                        style={{
                            alignItems: 'start'
                        }}
                    >
                        <FormGroup>
                            <FormItem label="Kullanıcı">
                                <Input onClick={handleUserInput} icon={<Icon name="search" onClick={handleUserInput} />} value={empName} required={true} type="Text" />
                            </FormItem>
                            <FormItem label="Mail Adresi">
                                <CustomInputComponent value={detail?.mail!} ref={txtmail} ></CustomInputComponent>
                            </FormItem>
                            <FormItem label="Admin Mi">
                                <CheckBox checked={isAdmin} onChange={handleIsAdminCheckBoxChange} text='' ></CheckBox>
                            </FormItem>
                            <FormItem label="Admin Level">
                                <ComboBox
                                    onInput={function _a() { }}
                                    onSelectionChange={handlecomboAdminLevel}
                                    value={comboAdminLevel}
                                    valueState="None"
                                >
                                    <ComboBoxItem key="1" text="Admin" data-id="1" />
                                    <ComboBoxItem key="2" text="PowerUser" data-id="2" />
                                </ComboBox>
                            </FormItem>
                            <FormItem label="Proxy Yapabilir Mi?">
                                <CheckBox checked={isDoProxy} onChange={handleIsProxyCheckBoxChange} text='' ></CheckBox>
                            </FormItem>
                            <FormItem label="Proxy Kullanıcı">
                                <div className={isDoProxy ? "" : "disabled"}>
                                    <CustomInputComponent value={detail?.proxyUser!} ref={txtproxyUser} ></CustomInputComponent>
                                </div>
                            </FormItem>



                        </FormGroup>
                    </Form>

                </ObjectPageSection>


            </ObjectPage>

            <footer>
                <Toolbar >
                    <ToolbarSpacer />
                    <Label children="Oluşturan Kişi:" />
                    <Label children={detail?.createdBy ? detail?.createdBy : ""} />
                    <Label children="Oluşturulma Tarihi:" />
                    <Label children={detail?.createdDate ? new Date(detail.createdDate).toLocaleDateString('tr-TR') : ""} />
                    <Label children="Güncelleyen Kişi:" />
                    <Label children={detail?.updatedBy ? detail?.updatedBy : ""} />
                    <Label children="Güncellenme Tarihi:" />
                    <Label children={detail?.updatedDate ? new Date(detail.updatedDate).toLocaleDateString('tr-TR') : ""} />
                </Toolbar>

            </footer>

            <UserSelectDialog
                open={UserDialogVisible}
                onClose={() => setUserDialogVisible(false)}
                onConfirm={onUserSelected}

            />

            <MessageBox
                open={msgOpen}
                onClose={handleMsgDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Verileriniz kaydedilmeyecektir, devam edilsin mi?
            </MessageBox>


        </DashboardLayout>

    );
}
export default AdminUserCreate;
