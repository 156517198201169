import React, { useEffect } from 'react';
// import './App.css';
import { Suspense, lazy } from 'react';
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";

import { Avatar, FlexBox, Icon, Input, Label, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem } from '@ui5/webcomponents-react';

import { Route, Routes } from 'react-router-dom';
import EmployeDetail from './PositionDetail';
import RequestTypeDefination from './Definations/RequestTypeDefination';
import { Configuration } from './api/generated';
import PeriodDefination from './Definations/PeriodDefination';
import PeriodUser from './Definations/PeriodUser';
import WorkFlowDetail from './WorkFlow/WorkFlowDetail';
import CreateForm from './FormManagement/CreateForm';

import JobCodeRequest from './Positions/JobCodeRequest';
import ApproveList from './Approve/ApproveList';
import WorkFlowList from './WorkFlow/WorkFlowList';
import Norm from './Positions/Norm';
import PositionDetail from './PositionDetail';
import NormDetail from './Positions/NormDetail';
import NormList from './Positions/NormList';
import PositionList from './SfData/PositionList';
import PromotionDetail from './Positions/PromotionDetail';
import PromotionList from './Positions/PromotionList';
import AdminApprove from './AdminPanel/AdminApprove';
import AdminUserCreate from './AdminPanel/AdminUserCreate';
import AdminUserList from './AdminPanel/AdminUserList';
import ReportList from './Approve/ReportList';

// import "@ui5/webcomponents-icons/dist/AllIcons.js";
setTheme("sap_fiori_3");

function App() {

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const configuration = new Configuration({
      basePath: 'https://localhost:7191',
      apiKey: 'your_api_key_here',
      accessToken: 'your_access_token_here',
    });


  }

  return (
    <div className="App" >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<PositionList />} />
          <Route path="/PeriodUser" element={<PeriodUser />} />
          <Route path="/CreateWorkFlow" element={<WorkFlowDetail />} />
          <Route path="/WorkFlowList" element={<WorkFlowList />} />
          <Route path="/PeriodDefination" element={<PeriodDefination />} />
          <Route path="/PositionList" element={<PositionList/>} />
          <Route path="/JobCode" element={<JobCodeRequest />} />
          <Route path="/NormDetail" element={<NormDetail />} />
          <Route path="/PositionDetail" element={<PositionDetail />} />
          <Route path="/CrateForm" element={<CreateForm />} />
          <Route path="/ApproveList" element={<ApproveList />} />
          <Route path="/CrateNorm" element={<NormDetail />} />
          <Route path="/NormList" element={<NormList />} />
          <Route path="/PromotionDetail" element={<PromotionDetail />} />
          <Route path="/PromotionList" element={<PromotionList />} />
          <Route path="/AdminApprove" element={<AdminApprove />} />
          <Route path="/AdminUserCreate" element={<AdminUserCreate />} />
          <Route path="/AdminUserList" element={<AdminUserList />} />
          <Route path="/ReportList" element={<ReportList />} />


        </Routes>
      </Suspense>
    </div >
  );
}

export default App;
