import React, { useEffect, useRef, useState } from 'react';
import '../App.css';


import "@ui5/webcomponents-base/dist/features/F6Navigation";
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/DatePicker";
import "@ui5/webcomponents/dist/List";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/CustomListItem";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Dialog";
import "@ui5/webcomponents/dist/Popover";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/TextArea";
import "@ui5/webcomponents/dist/Switch";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/palette.js";
import "@ui5/webcomponents-icons/dist/settings.js";
import "@ui5/webcomponents-icons/dist/sys-help.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/account.js";
import "@ui5/webcomponents-icons/dist/private.js";
import "@ui5/webcomponents-icons/dist/loan.js";
import "@ui5/webcomponents-icons/dist/globe.js";
import "@ui5/webcomponents-icons/dist/workflow-tasks";
import "@ui5/webcomponents-icons/dist/chain-link";
import { Avatar, Bar, Breadcrumbs, BreadcrumbsItem, Button, ButtonType, CheckBox, ComboBox, ComboBoxItem, DatePicker, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormBackgroundDesign, FormGroup, FormItem, Icon, Input, Label, Link, MessageBox, MessageBoxActions, MessageBoxTypes, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, ShellBar, ShellBarItem, SideNavigation, SideNavigationItem, SideNavigationSubItem, StandardListItem, TextArea, Title, Toolbar, ToolbarSpacer } from '@ui5/webcomponents-react';
import { Text } from '@ui5/webcomponents-react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../Components/DashboardLayout';
import { BudgetPromotionRequestApi, BudgetPromotionRequestInsertDto, BudgetPromotionRequestListDto, BudgetPromotionRequestUpdateDto, Configuration, CustCompanyGroupList, CustLegalEntityList, CustSubDivisioList, FOBusinessUnitList, FOCompanyDtoList, FODepartmentList, FODivisionList, FOGeozoneDtoList, FOJobCodeList, FOJobFunctionList, FOJobFunctionSFDto, FOLocationGroupList, FOLocationList, FOPayGradeList, FOPayGradeSFDto, FOPayGroupSFList, PickListApi, PickListDto, PositionSFDto, SFCustCompanyGroupApi, SFCustLegalEntityApi, SFcustSubDivisionApi, SFFOBusinessUnitApi, SFFOCompanyApi, SFFOCostCenterApi, SFFOCostCenterList, SFFODepartmentApi, SFFODivisionApi, SFFOFOPayGroupApi, SFFOGeozoneApi, SFFOJobCodeApi, SFFOJobFunctionApi, SFFOLocationApi, SFFOLocationGroupApi, SFFOPayGradeApi, SFPositionList, SFPositionsApi, SFUserApi, WorkFlowApi, WorkFlowStartApiDto } from '../api/generated';

import { number } from 'yup';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useBusy } from '../hooks/useBusy';
import { getStatusText, isDecimal, isValidDate, parseDateString } from '../utils/utils';
import { useAlert } from '../hooks/useAlert';
import PickListComboBoxComponent from '../Components/PickListComponent';
import CustomInputComponent from '../Components/CustomInput';
import { isReadable } from 'stream';
import { useUser } from '../hooks/userName';
import GenericSelectDialogWithInputEnum, { SelectDialog } from '../Components/GenericSelectDialogWithInputEnum';
import UserSelectDialog from '../Components/UserSelectDialog';
import RelatedUserSelectDialog from '../Components/RelatedUserSelectDialog';
// setTheme("sap_horizon");
// setTheme("sap_fiori_3");
// setTheme("sap_belize");
function PromotionDetail() {
    const { username, setUsername } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        getDetail();

    }, []); //
    const [msgOpen, setmsgOpen] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState("");
    const [genericDialogOpen, setGenericDialogOpen] = useState(false);
    const [UserDialogVisible, setUserDialogVisible] = useState(false);
    const [isedit, setisedit] = useState(false);

    const txtJobCodeName = useRef<{ current: string, setValue: (value: string) => void }>(null);
    const txtJobCodeNameEnglish = useRef<{ current: string, setValue: (value: string) => void }>(null);

    const [detail, setDetail] = useState<BudgetPromotionRequestListDto>();
    const [isSend, setIsSend] = useState(false);

    const txtpositionCode = useRef<SelectDialog | null>(null);
    const [promotionDate, setpromotionDate] = useState("");
    const [empCode, setempCode] = useState("");
    const [empName, setempName] = useState("");
    const txtdescription = useRef<{ current: string, setValue: (value: string) => void }>(null);

    
    const [UserMngDialogVisible, setUserMngDialogVisible] = useState(false);
    const [UserTeamDialogVisible, setUserTeamDialogVisible] = useState(false);
    const [empMngName, setempMngName] = useState("");
    const [empTeamName, setempTeamName] = useState("");

    const configuration = new Configuration({
        basePath: process.env.REACT_APP_BASE_PATH,
        apiKey: process.env.REACT_APP_API_KEY,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    });

    const dispatchBusy = useBusy()
    const dispatchAlert = useAlert()
    async function getDetail() {

        const configuration = new Configuration({
            basePath: process.env.REACT_APP_BASE_PATH,
            apiKey: process.env.REACT_APP_API_KEY,
            accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        });

        const id = queryParams.get('id');

        if (id) {
            dispatchBusy({ isBusy: true });
            setisedit(true);
            var api = new BudgetPromotionRequestApi(configuration);
            var result = api.apiBudgetPromotionRequestIdGet(id!)
                .then(async (response: AxiosResponse<BudgetPromotionRequestListDto>) => {

                    setId(response.data.id!);

                    if (response.data.workflowHead?.workFlowStatus == 1 || response.data.workflowHead?.workFlowStatus == 2)
                        setIsSend(true);


                    setDetail(response.data);
                    console.log("response>>", response.data)


                    txtdescription.current!.setValue(response.data.description!);
                    setpromotionDate(format(parseISO(response.data.promotionDate!), 'dd/MM/yyyy'));
                    setempCode(response.data.empCode!);
                    setempMngName(response.data.managerUser!);
                    setempTeamName(response.data.teamUsers!);

                    var api = new SFUserApi(configuration);
                    var data = await api.apiSFUserUserUseridGet(response.data.empCode!)
                    var username = data?.data?.sfuSerList?.[0]?.defaultFullName ?? "";
                    setempName(username);
                    let positionCode: SelectDialog = { externalCode: response.data.positionCode!, name: response.data!.positionCode! };
                    txtpositionCode.current! = positionCode;



                })
                .catch(error => {

                });
            dispatchBusy({ isBusy: false });
        }
    }
    //#endregion

    const handlePromotionDateInput = (e: any) => {
        setpromotionDate(e.target.value);
    };
    const handleSave = () => {
        onSubmit(); // Örnek parametrelerle fonksiyonu çağır
    };

    function onSubmit(workflowHeadId: string = "", isSend: boolean = false) {

        if (isValidDate(promotionDate) == false) {
            dispatchAlert({ message: "Terfi Dönemi alanını  kontrol edin", type: MessageBoxTypes.Error })
            return;
        }
        if (txtdescription.current?.current == "" || txtdescription.current?.current == null) {
            dispatchAlert({ message: "Terfi Talep Nedeni alanını giriniz", type: MessageBoxTypes.Error })
            return;
        }
        if (empMngName == "" || empMngName == null) {
            dispatchAlert({ message: "Bağlı Olacağı Yönetici alanını giriniz", type: MessageBoxTypes.Error })
            return;
        }

        dispatchBusy({ isBusy: true });
        let api = new BudgetPromotionRequestApi(configuration);
        if (isedit == false) {
            let insertDto: BudgetPromotionRequestInsertDto = {};
            insertDto.description = txtdescription.current?.current;
            insertDto.empCode = empCode;
            insertDto.positionCode = txtpositionCode.current?.externalCode;
            insertDto.promotionDate = parseDateString(promotionDate).toISOString();

            insertDto.managerUser =empMngName;
            insertDto.teamUsers = empTeamName;
            console.log("insertdto", insertDto)

            api.apiBudgetPromotionRequestPost(insertDto)
                .then(response => {
                    dispatchBusy({ isBusy: false });
                    dispatchAlert({ message: "Kayıt Ekleme Başarılı", type: MessageBoxTypes.Success })
                    navigate("/PromotionList")

                })
                .catch(error => {

                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }
        else {
            let updateDto: BudgetPromotionRequestUpdateDto = {};
            updateDto.id = id;

            updateDto.description = txtdescription.current?.current;
            updateDto.empCode = empCode;
            updateDto.positionCode = txtpositionCode.current?.externalCode;
            updateDto.promotionDate = parseDateString(promotionDate).toISOString();
            updateDto.managerUser =empMngName;
            updateDto.teamUsers = empTeamName;

            if (workflowHeadId != "")
                updateDto.workflowHeadId = workflowHeadId;

            console.log(updateDto)
            api.apiBudgetPromotionRequestPut(updateDto)
                .then(response => {
                    if (workflowHeadId != null) {
                        dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    }
                    else {
                        dispatchAlert({ message: "Kayıt Onaya Gönderildi", type: MessageBoxTypes.Success })
                    }
                    navigate("/PromotionList")
                    dispatchBusy({ isBusy: false });
                })
                .catch(error => {
                    dispatchBusy({ isBusy: false });
                    // Hata mesajlarını kontrol edin ve birleştirin
                    let errorMessage = 'Bilinmeyen bir hata oluştu';
                    if (error.response) {
                        if (error.response.data && error.response.data.errors) {
                            errorMessage = error.response.data.errors.join(', ');
                        } else if (error.response.data) {
                            errorMessage = error.response.data;
                        }
                    }
                    // Hata mesajını loglayın ve dispatchAlert ile gösterin
                    console.log(errorMessage);
                    dispatchAlert({ message: errorMessage, type: MessageBoxTypes.Error });
                });
        }

        dispatchBusy({ isBusy: true });
    }

    const handleApprovalClick = async () => {

        dispatchBusy({ isBusy: true });
        if (id) {
            var workFlowApi = new WorkFlowApi(configuration);
            let startDto: WorkFlowStartApiDto = {};
            startDto.definationId = "cc544b49-6d5d-41ee-a248-4270bbd668a4";
            startDto.userName = username;
            startDto.workFlowInfo = "Yeni Terfi:" + empCode;
            var result = await workFlowApi.apiWorkFlowStartPost(startDto)
                .then(response => {
                    onSubmit(response.data.id!, true);
                    dispatchAlert({ message: "Kayıt Güncelleme Başarılı", type: MessageBoxTypes.Success })
                    dispatchBusy({ isBusy: false });
                })
        }
    };

    function handleBreadcrumbsClick(event: CustomEvent) {
        const clickedItemText = event.detail.item.textContent;
        switch (clickedItemText) {
            case 'Terfi':
                navigate("/PromotionList")
                break;
            case "Terfi Talep Detayı":
                navigate("/PromotionDetail")
                break;
            default:
                break;
        }

    }

    const handleUserInput = async () => {
        setUserDialogVisible(true);
    }

    const onUserSelected = async (event: any) => {
        console.log(event)
        setUserDialogVisible(false);
        setempName(event.defaultFullName);
        setempCode(event.userId);
    }

    async function handleMsgDialog(event: any) {
        setmsgOpen(false);
        if (event.detail.action === MessageBoxActions.Yes) {
            navigate("/PromotionList")
        }
        else {
            return;
        }

    }
    const handleMngUserInput = async () => {
        setUserMngDialogVisible(true);
    }
    const handleTeamUserInput = async () => {
        setUserTeamDialogVisible(true);
    }
    const onUserMngSelected = async (event: any) => {
        console.log(event)
        setUserMngDialogVisible(false);
        setempMngName(event.userId);
    }
    const onTeamUsersSelected = async (event: any) => {
        console.log(event)
        setUserTeamDialogVisible(false);
        setempTeamName(event.userId);
    }
    return (
        <DashboardLayout>
            <ObjectPage
                // footer={<Bar design="FloatingFooter" endContent={<><Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button></>} />}
                headerContentPinnable
                headerTitle={<DynamicPageTitle actions={<>
                    <Button icon='decline' onClick={() => setmsgOpen(true)} disabled={isSend} design="Emphasized">Vazgeç</Button>
                    <Button icon='save' onClick={handleSave} disabled={isSend} design="Emphasized">Kaydet</Button>
                    <Button disabled={!isedit || isSend} icon="workflow-tasks" onClick={handleApprovalClick}>Onaya Gönder</Button>
                </>}
                    breadcrumbs={
                        <Breadcrumbs onItemClick={handleBreadcrumbsClick}>
                            <BreadcrumbsItem onClick={() => navigate("/JobCode")}>Terfi</BreadcrumbsItem>
                            <BreadcrumbsItem>Terfi Talep Detayı</BreadcrumbsItem>
                        </Breadcrumbs>}
                    header={<Title>Terfi Oluştur</Title>}
                    subHeader={txtJobCodeNameEnglish.current?.current}>
                    <ObjectStatus state={isSend ? 'Success' : 'Warning'}>
                        {getStatusText(detail?.workflowHead?.workFlowStatus!)}
                    </ObjectStatus></DynamicPageTitle>}
                imageShapeCircle
                onBeforeNavigate={function _a() { }}
                onPinnedStateChange={function _a() { }}
                onSelectedSectionChange={function _a() { }}
                onToggleHeaderContent={function _a() { }}
                selectedSectionId="general"
                showHideHeaderButton
            >
                <ObjectPageSection
                    aria-label="Terfi Genel Bilgileri"
                    id="general"
                    titleText="Terfi Genel Bilgileri"
                >
                    {/* <Button onClick={() => alert("xxx")} type="Button" design="Positive">Kaydet</Button><Button design="Negative">İptal Et</Button> */}
                    <Form
                        onSubmit={() => alert("")}
                        backgroundDesign={FormBackgroundDesign.Transparent}
                        columnsL={2}
                        columnsM={2}
                        columnsS={2}
                        columnsXL={12}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={12}
                        style={{
                            alignItems: 'start'
                        }}
                    >
                        <FormGroup>
                            <FormItem label={<Label style={{ width: "150px" }} required>Personel Ad-Soyad</Label>}>
                                <Input onClick={handleUserInput} icon={<Icon name="search" onClick={handleUserInput} />} value={empName} required={true} type="Text" />
                            </FormItem>

                            <FormItem label={<Label style={{ width: "170px" }} required>Terfi Edeceği Pozisyon</Label>}>
                                <GenericSelectDialogWithInputEnum displayValue={detail?.positionCode!} dataId={15} value={txtpositionCode} headerText={"15"} open={genericDialogOpen} onClose={() => setGenericDialogOpen(false)} onConfirm={(e) => { setGenericDialogOpen(false); }}></GenericSelectDialogWithInputEnum>
                            </FormItem>

                            <FormItem label={<Label required>Terfi Dönemi</Label>}>
                                <DatePicker
                                    required
                                    onChange={handlePromotionDateInput}
                                    onValueStateChange={function _a() { }}
                                    primaryCalendarType="Gregorian"
                                    formatPattern='dd/MM/yyyy'
                                    value={promotionDate.toString()}
                                    valueState="None"
                                />
                            </FormItem>
                            <FormItem label={<Label required>Bağlı Olacağı Yönetici</Label>}>
                                <Input onClick={handleMngUserInput} icon={<Icon name="search" onClick={handleMngUserInput} />} value={empMngName} required={true} type="Text" />
                            </FormItem>
                            <FormItem label="Bağlı Olacak Çalışanlar">
                                <Input onClick={handleTeamUserInput} icon={<Icon name="search" onClick={handleTeamUserInput} />} value={empTeamName} required={true} type="Text" />
                            </FormItem>
                            <FormItem label={<Label required>Terfi Talep Nedeni</Label>}>
                                <CustomInputComponent value={detail?.description!} ref={txtdescription} ></CustomInputComponent>
                            </FormItem>


                        </FormGroup>
                    </Form>

                </ObjectPageSection>


            </ObjectPage>


            <footer>
                <Toolbar >
                    <ToolbarSpacer />
                    <Label children="Oluşturan Kişi:" />
                    <Label children={detail?.createdBy ? detail?.createdBy : ""} />
                    <Label children="Oluşturulma Tarihi:" />
                    <Label children={detail?.createdDate ? new Date(detail.createdDate).toLocaleDateString('tr-TR') : ""} />
                    <Label children="Güncelleyen Kişi:" />
                    <Label children={detail?.updatedBy ? detail?.updatedBy : ""} />
                    <Label children="Güncellenme Tarihi:" />
                    <Label children={detail?.updatedDate ? new Date(detail.updatedDate).toLocaleDateString('tr-TR') : ""} />
                </Toolbar>
            </footer>
            <UserSelectDialog
                open={UserDialogVisible}
                onClose={() => setUserDialogVisible(false)}
                onConfirm={onUserSelected}

            />
            <RelatedUserSelectDialog
                open={UserMngDialogVisible}
                onClose={() => setUserMngDialogVisible(false)}
                onConfirm={onUserMngSelected}

            />
            <RelatedUserSelectDialog
                open={UserTeamDialogVisible}
                onClose={() => setUserTeamDialogVisible(false)}
                onConfirm={onTeamUsersSelected}

            />

            <MessageBox
                open={msgOpen}
                onClose={handleMsgDialog}
                titleText="DİKKAT"
                actions={[MessageBoxActions.Yes, MessageBoxActions.No]}>
                Verileriniz kaydedilmeyecektir, devam edilsin mi?
            </MessageBox>

        </DashboardLayout>

    );
}
export default PromotionDetail;
